import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson23/step7-1.png';
import img2 from '../../../image/lessons/lesson23/step7-2.png';
import img3 from '../../../image/lessons/lesson23/step7-3.png';
import img4 from '../../../image/lessons/lesson23/step7-4.png';
import img5 from '../../../image/lessons/lesson23/step7-5.png';

export default class step7 extends React.Component {

    render() {
        return (
            <div className="step7">
                <div className="head">
                    NATURE’S TABLE™. Натурально и полезно.
                </div>
                <TextBlock
                    text={`Рецептуры NATURE’S TABLE™ изготовлены без добавления:`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_row">
                            <div className="flex_el_row_block">
                                <div className="flex_el_row_head">
                                    <span className="flex_el_row_without">Без</span> сои
                                </div>
                                <div className="flex_el_row_wrp">
                                    <TextBlock
                                        text={`Животный белок – основа здорового рациона и ингредиент №1 в рецептурах NATURE’S TABLE™`}
                                    />
                                </div>
                            </div>
                            <div className="flex_el_row_img-box">
                                <img src={img1} alt="" className="flex_el_row_img"/>
                            </div>
                        </div>
                        <div className="flex_el_row">
                            <div className="flex_el_row_block">
                                <div className="flex_el_row_head">
                                    <span className="flex_el_row_without">Без</span> Консервантов
                                </div>
                                <div className="flex_el_row_wrp">
                                    <TextBlock
                                        text={`Технология позволяет производить корм без добавления консервантов`}
                                    />
                                </div>
                            </div>
                            <div className="flex_el_row_img-box">
                                <img src={img2} alt="" className="flex_el_row_img"/>
                            </div>
                        </div>
                        <div className="flex_el_row">
                            <div className="flex_el_row_block">
                                <div className="flex_el_row_head">
                                    <span className="flex_el_row_without">Без</span> Усилителей вкуса
                                </div>
                                <div className="flex_el_row_wrp">
                                    <TextBlock
                                        text={`Отличный вкус и естественный аромат достигаются особенностями рецептуры`}
                                    />
                                </div>
                            </div>
                            <div className="flex_el_row_img-box">
                                <img src={img3} alt="" className="flex_el_row_img"/>
                            </div>
                        </div>
                        <div className="flex_el_row">
                            <div className="flex_el_row_block">
                                <div className="flex_el_row_head">
                                    <span className="flex_el_row_without">Без</span> Исскуственных красителей
                                </div>
                                <div className="flex_el_row_wrp">
                                    <TextBlock
                                        text={`Естественный цвет корма достигается за счет компонентов рациона`}
                                    />
                                </div>
                            </div>
                            <div className="flex_el_row_img-box">
                                <img src={img4} alt="" className="flex_el_row_img"/>
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <img src={img5} alt="" className="flex_el_img"/>
                    </div>
                </div>
            </div>
        )
    }
}
