import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import Answer from './dnd/Answer';
import Questions from './dnd/Questions';

export default class step1 extends React.Component {

    state = {
        // Ресайз
        windowWidth: window.innerWidth,

        // Работа десктопной версии
        answers: [],
        questions: [],
        correct: '',
        answerCorrect: [],

        //Работа мобильной версии
        answerActive: 0,
        questionActive: 0,
        answersMobile: [],
        questionsMobile: [],
        answersSelected: ['?', '?', '?', '?', '?', '?', '?'],
        correctMobile: '',
        answerCorrectMobile: [],
        showPopup: false
    }

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    //Работа десктопной версии
    setAnswer = (answer_el, question_el) => {
        const {answers} = this.state

        if (answers.indexOf(question_el) === -1) {
            let answer = []
            answers[answer_el] = question_el
            answers.push(answer)
        }
        this.setState({answers})

        let questions = [];
        // eslint-disable-next-line
        answers.map(answer => {

            if (answer.length) {
                questions.push(answer)
            }

        });
        this.setState({questions})
    };

    checkAnswer = () => {
        const {answerCorrect, questions} = this.state
        // eslint-disable-next-line
        questions.map((answer, index) => {
            if (answer === String(index + 1)) {
                answerCorrect.push('correct')
            } else {
                answerCorrect.push('inCorrect')
            }
        })
        this.setState({
            answerCorrect: answerCorrect,
        })
        if (questions.length === 7 && answerCorrect.indexOf('inCorrect') === -1) {
            this.setState({correct: '1'})
        } else {
            this.setState({correct: '0'})
        }
        this.props.showNextBtn()
    };

    _renderTestDesktop = () => {
        const {answerCorrect, correct, questions} = this.state
        return (
            <div className="test_desktop">
                <div className="questions">
                    <div className={'question_el ' + (questions.indexOf('1') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'1'} label={'Кальций'} text={'Кальций'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('2') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'2'} label={'Таурин и L-карнитин'} text={'Таурин и L-карнитин'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('3') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'3'} label={'L-картинин'} text={'L-картинин'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('4') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'4'} label={'Витамин А и таурин'} text={'Витамин А и таурин'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('5') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'5'} label={'Омега-3, 6 жирные кислоты глюкозамин и хондроитин'} text={'Омега-3, 6 жирные кислоты глюкозамин и хондроитин'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('6') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'6'} label={'Баланс минералов (Mg, Ca, P, Na, K)'} text={'Баланс минералов (Mg, Ca, P, Na, K)'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('7') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'7'} label={'Юкка Шидигера'} text={'Юкка Шидигера'}/>
                    </div>
                </div>
                <div className="flex">
                    <div className="flex_el">
                        <div className="answers">
                            <div className="property">
                                Здоровые кости
                            </div>
                            <div className="property">
                                Здоровое сердце
                            </div>
                            <div className="property">
                                Контроль веса
                            </div>
                            <div className="property">
                                Здоровое зрение
                            </div>
                            <div className="property">
                                Здоровые суставы
                            </div>
                            <div className="property">
                                Здоровье мочевыделительной системы
                            </div>
                            <div className="property">
                                Устранение запаха туалета
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="answers">
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'1'} text={'?'} count={'?'} answerCorrect={answerCorrect[0]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'2'} text={'1'} count={'?'} answerCorrect={answerCorrect[1]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'3'} text={'2'} count={'?'} answerCorrect={answerCorrect[2]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'4'} text={'3'} count={'?'} answerCorrect={answerCorrect[3]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'5'} text={'?'} count={'?'} answerCorrect={answerCorrect[4]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'6'} text={'?'} count={'?'} answerCorrect={answerCorrect[5]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'7'} text={'?'} count={'?'} answerCorrect={answerCorrect[6]}/>
                            </div>
                        </div>
                    </div>
                </div>
                {correct !== '' ?
                    <div className={'info ' + (correct === '0' && 'error')}>
                        <div className="info_head">
                            {correct === '1' ? 'все верно!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                    </div>
                    :
                    <button className="btn btn_blue" onClick={() => this.checkAnswer()}>
                        Узнать правильный ответ
                    </button>
                }
            </div>
        )
    }

    //Работа мобильной версии
    questions = [
        `Кальций`,
        `Таурин и L-карнитин`,
        `L-картинин`,
        `Витамин А и таурин`,
        `Омега-3, 6 жирные кислоты глюкозамин и хондроитин`,
        `Баланс минералов (Mg, Ca, P, Na, K)`,
        `Юкка Шидигера`,
    ]

    selectAnswer = (answerActive) => {
        document.body.scrollIntoView( true);
        document.body.style.overflow = "hidden";
        this.setState({
            showPopup: true,
            answerActive: answerActive
        })
    }

    selectQuestion = (questionActive) => {
        const {answerActive, answersMobile, questionsMobile, answersSelected} = this.state

        if (answersMobile.indexOf(answerActive) === -1) {
            answersMobile.push(answerActive)
        }
        this.setState({answersMobile})

        if (questionsMobile.indexOf(questionsMobile) === -1) {
            questionsMobile.push(questionActive)
        }
        this.setState({questionsMobile})

        answersSelected.splice(answerActive - 1, 1, this.questions[questionActive - 1])

        document.body.style.overflow = "";
        this.setState({
            showPopup: false,
            questionActive: questionActive,
            answersSelected: answersSelected
        })
    }

    checkAnswerMobile = () => {
        const {answerCorrectMobile, answersSelected} = this.state
        // eslint-disable-next-line
        answersSelected.map((answer, index) => {
            if (answer === this.questions[index]) {
                answerCorrectMobile.push('correct')
            } else {
                answerCorrectMobile.push('inCorrect')
            }
        })
        this.setState({
            answerCorrect: answerCorrectMobile,
        })
        if (answerCorrectMobile.indexOf('?') === -1 && answerCorrectMobile.indexOf('inCorrect') === -1) {
            this.setState({correctMobile: '1'})
        } else {
            this.setState({correctMobile: '0'})
        }
        this.props.showNextBtn()
    }

    closePopup = () => {
        document.body.style.overflow = "";
        this.setState({showPopup: false})
    }

    _renderTestMobile = () => {
        const {showPopup, answersMobile, questionsMobile, answersSelected, correctMobile, answerCorrectMobile} = this.state
        return (
            <div className="test_mobile">
                {correctMobile !== '' ?
                    <div className={'info ' + (correctMobile === '0' && 'error')}>
                        <div className="info_head">
                            {correctMobile === '1' ? 'все верно!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                    </div>
                    :
                    <button className="btn btn_blue" onClick={() => this.checkAnswerMobile()}>
                        Узнать правильный ответ
                    </button>
                }
                <div className="answers">
                    <div className="answers_box">
                        <div className="property">
                            Здоровые кости
                        </div>
                        <div
                            className={'answers_item ' + (answersMobile.indexOf(1) >=0 ? 'selected ' : '') + answerCorrectMobile[0]}
                            onClick={() => {this.selectAnswer(1)}}
                        >
                            {answersSelected[0]}
                        </div>
                    </div>
                    <div className="answers_box">
                        <div className="property">
                            Здоровое сердце
                        </div>
                        <div
                            className={'answers_item ' + (answersMobile.indexOf(2) >=0 ? 'selected ' : '') + answerCorrectMobile[1]}
                            onClick={() => {this.selectAnswer(2)}}
                        >
                            {answersSelected[1]}
                        </div>
                    </div>
                    <div className="answers_box">
                        <div className="property">
                            Контроль веса
                        </div>
                        <div
                            className={'answers_item ' + (answersMobile.indexOf(3) >=0 ? 'selected ' : '') + answerCorrectMobile[2]}
                            onClick={() => {this.selectAnswer(3)}}
                        >
                            {answersSelected[2]}
                        </div>
                    </div>
                    <div className="answers_box">
                        <div className="property">
                            Здоровое зрение
                        </div>
                        <div
                            className={'answers_item ' + (answersMobile.indexOf(4) >=0 ? 'selected ' : '') + answerCorrectMobile[3]}
                            onClick={() => {this.selectAnswer(4)}}
                        >
                            {answersSelected[3]}
                        </div>
                        <div className="answers_box">
                            <div className="property">
                                Здоровые суставы
                            </div>
                            <div
                                className={'answers_item ' + (answersMobile.indexOf(5) >=0 ? 'selected ' : '') + answerCorrectMobile[4]}
                                onClick={() => {this.selectAnswer(5)}}
                            >
                                {answersSelected[4]}
                            </div>
                        </div>
                        <div className="answers_box">
                            <div className="property">
                                Здоровье мочевыделительной системы
                            </div>
                            <div
                                className={'answers_item ' + (answersMobile.indexOf(6) >=0 ? 'selected ' : '') + answerCorrectMobile[5]}
                                onClick={() => {this.selectAnswer(6)}}
                            >
                                {answersSelected[5]}
                            </div>
                        </div>
                        <div className="answers_box">
                            <div className="property">
                                Устранение запаха туалета
                            </div>
                            <div
                                className={'answers_item ' + (answersMobile.indexOf(7) >=0 ? 'selected ' : '') + answerCorrectMobile[6]}
                                onClick={() => {this.selectAnswer(7)}}
                            >
                                {answersSelected[6]}
                            </div>
                        </div>
                    </div>
                    {/*{this.questions.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className={'answers_item ' + (answersMobile.indexOf(index + 1) >=0 ? 'selected ' : '') + answerCorrectMobile[index]}
                                onClick={() => {this.selectAnswer(index + 1)}}
                            >
                                {answersSelected[index]}
                            </div>
                        )
                    })

                    }*/}
                </div>
                {showPopup &&
                <div className="popup">
                    <div className="popup_wrp">
                        <div
                            className="popup_closed"
                            onClick={() => {this.closePopup()}}
                        />
                        <div className="popup_flex">
                            <div className="questions">
                                {this.questions.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={'questions_item ' + (questionsMobile.indexOf(index + 1) >=0 ? 'selected ' : '')}
                                            onClick={() => {this.selectQuestion(index + 1)}}
                                        >
                                            {item}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }

    render() {
        const {windowWidth} = this.state
        return (
            <div className="step11">
                {/*<TextBlock*/}
                {/*    text={`Корма несут в себе не только 4 основных функциональные преимущества, о которых мы рассказали ранее в уроке, но и другие полезные свойства, которые удовлетворяют другие потребности животных. Давайте посмотрим, какие компоненты в составе кормов также поддерживают нижеперечисленные потребности собак:`}*/}
                {/*/>*/}
                <InfoBlock
                    text={`Перетащите слагаемые элементы к каждой из потребностей, затем нажмите кнопку «Узнать правильный ответ», чтобы проверить результаты.`}
                />
                {windowWidth >= 768 ?
                    <div className="test">
                        {this._renderTestDesktop()}
                    </div>
                    :
                    <div className="test">
                        {this._renderTestMobile()}
                    </div>
                }
            </div>
        )
    }
}
