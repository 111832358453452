import React from 'react'

import Lesson1 from "./Lesson1";
import Lesson2 from "./Lesson2";
import Lesson3 from "./Lesson3";
import Lesson4 from "./Lesson4";
import Lesson5 from "./Lesson5";
import Lesson6 from "./Lesson6";
import Lesson7 from "./Lesson7";
import Lesson8 from "./Lesson8";
import Lesson9 from "./Lesson9";
import Lesson10 from "./Lesson10";
import Lesson11 from "./Lesson11";
import Lesson12 from "./Lesson12";
import Lesson13 from "./Lesson13";
import Lesson14 from "./Lesson14";
import Lesson15 from "./Lesson15";
import Lesson16 from "./Lesson16";
import Lesson17 from "./Lesson17";
import Lesson18 from "./Lesson18";
import Lesson19 from "./Lesson19";
import Lesson20 from "./Lesson20";
import Lesson21 from "./Lesson21";
import Lesson22 from "./Lesson22";
import Lesson23 from "./Lesson23";
import Lesson24 from "./Lesson24";
import Lesson25 from "./Lesson25";
import Lesson26 from "./Lesson26";
import Lesson27 from "./Lesson27";
import Lesson28 from "./Lesson28";
import Lesson29 from "./Lesson29";
import Lesson30 from "./Lesson30";
import Lesson31 from "./Lesson31";
import Lesson32 from "./Lesson32";
import Lesson33 from "./Lesson33";
import Lesson34 from "./Lesson34";
import Lesson35 from "./Lesson35";
import Lesson36 from "./Lesson36";
import Lesson37 from "./Lesson37";
import Lesson38 from "./Lesson38";
import Lesson39 from "./Lesson39";
import Lesson41 from "./Lesson41";
import Lesson42 from "./Lesson42";
import Lesson43 from "./Lesson43";
import Lesson44 from "./Lesson44";
import Lesson45 from "./Lesson45";

export default class lessons extends React.Component{

    componentDidMount() {
    }

    _renderLesson = () => {
        let lesson = this.props.location.state
        switch (lesson.attributes.url) {
            case '1' : {
                return <Lesson1 lesson={lesson}/>
            }
            case '2' : {
                return <Lesson2 lesson={lesson}/>
            }
            case '3' : {
                return <Lesson3 lesson={lesson}/>
            }
            case '4' : {
                return <Lesson4 lesson={lesson}/>
            }
            case '5' : {
                return <Lesson5 lesson={lesson}/>
            }
            case '6' : {
                return <Lesson6 lesson={lesson}/>
            }
            case '7' : {
                return <Lesson7 lesson={lesson}/>
            }
            case '8' : {
                return <Lesson8 lesson={lesson}/>
            }
            case '9' : {
                return <Lesson9 lesson={lesson}/>
            }
            case '10' : {
                return <Lesson10 lesson={lesson}/>
            }
            case '11' : {
                return <Lesson11 lesson={lesson}/>
            }
            case '12' : {
                return <Lesson12 lesson={lesson}/>
            }
            case '13' : {
                return <Lesson13 lesson={lesson}/>
            }
            case '14' : {
                return <Lesson14 lesson={lesson}/>
            }
            case '15' : {
                return <Lesson15 lesson={lesson}/>
            }
            case '16' : {
                return <Lesson16 lesson={lesson}/>
            }
            case '17' : {
                return <Lesson17 lesson={lesson}/>
            }
            case '18' : {
                return <Lesson18 lesson={lesson}/>
            }
            case '19' : {
                return <Lesson19 lesson={lesson}/>
            }
            case '20' : {
                return <Lesson20 lesson={lesson}/>
            }
            case '21' : {
                return <Lesson21 lesson={lesson}/>
            }
            case '22' : {
                return <Lesson22 lesson={lesson}/>
            }
            case '23' : {
                return <Lesson23 lesson={lesson}/>
            }
            case '24' : {
                return <Lesson24 lesson={lesson}/>
            }
            case '25' : {
                return <Lesson25 lesson={lesson}/>
            }
            case '26' : {
                return <Lesson26 lesson={lesson}/>
            }
            case '27' : {
                return <Lesson27 lesson={lesson}/>
            }
            case '28' : {
                return <Lesson28 lesson={lesson}/>
            }
            case '29' : {
                return <Lesson29 lesson={lesson}/>
            }
            case '30' : {
                return <Lesson30 lesson={lesson}/>
            }
            case '31' : {
                return <Lesson31 lesson={lesson}/>
            }
            case '32' : {
                return <Lesson32 lesson={lesson}/>
            }
            case '33' : {
                return <Lesson33 lesson={lesson}/>
            }
            case '34' : {
                return <Lesson34 lesson={lesson}/>
            }
            case '35' : {
                return <Lesson35 lesson={lesson}/>
            }
            case '36' : {
                return <Lesson36 lesson={lesson}/>
            }
            case '37' : {
                return <Lesson37 lesson={lesson}/>
            }
            case '38' : {
                return <Lesson38 lesson={lesson}/>
            }
            case '39' : {
                return <Lesson39 lesson={lesson}/>
            }
            case '41' : {
                return <Lesson41 lesson={lesson}/>
            }
            case '42' : {
                return <Lesson42 lesson={lesson}/>
            }
            case '43' : {
                return <Lesson43 lesson={lesson}/>
            }
            case '44' : {
                return <Lesson44 lesson={lesson}/>
            }
            case '45' : {
                return <Lesson45 lesson={lesson}/>
            }
            default : {
                return false
            }
        }

    }

    render() {
        return (
            this._renderLesson()
        )
    }
}
