import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

export default class step9 extends React.Component {


    render() {
        return (
            <div className="step9">
                <TextBlock
                    text={`Теперь давайте изучим основные компоненты рационов, которые могут присутствовать в составе кормов. Перед вами расположены компоненты животного происхождение, которые можно найти на этикетке.`}
                />
                <h3 className="header">
                    <span className="header_icon">
                        <svg width="48" height="45" viewBox="0 0 48 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M44.2602 17.9329L31.3996 5.07235C28.1287 1.80148 23.7797 0 19.1577 0C14.5312 0 10.1822 1.80148 6.91132 5.07235C4.50934 7.47432 2.90348 10.4631 2.21655 13.7113C2.11647 13.9251 2.01639 14.1571 1.9163 14.4118C-2.03694 24.5838 0.0920792 32.0673 8.62181 37.963C11.092 39.669 14.3902 40.6061 17.9158 40.6061H22.1193C22.1238 40.6061 22.1238 40.6061 22.1284 40.6061C24.0436 40.6061 24.6259 40.7562 26.2727 41.6752L27.9877 42.6305C30.0758 43.7951 32.6688 44.4956 35.2437 44.4956C38.2461 44.4956 41.2213 43.5403 43.3276 41.2384C47.158 37.3898 48.3226 31.3985 47.9268 26.9676C47.9268 26.9176 47.9314 26.8675 47.9314 26.8175C47.9405 23.4602 46.6303 20.303 44.2602 17.9329ZM41.4306 39.3778C41.4169 39.3915 41.4033 39.4051 41.3896 39.4233C38.5055 42.5895 32.9736 42.3575 29.2933 40.3013L27.5783 39.346C25.5539 38.2178 24.4848 37.9403 22.1375 37.9403C22.1329 37.9403 22.1329 37.9403 22.1284 37.9403H17.9249C14.977 37.9403 12.1429 37.1487 10.1458 35.7658C4.79594 32.0764 2.38032 27.9457 2.66692 22.6277C3.49487 25.2116 4.93242 27.5863 6.90677 29.5607L9.13132 31.7852C11.4787 34.1326 14.5949 35.4246 17.9158 35.4246H22.1193C22.1193 35.4246 22.1193 35.4246 22.1238 35.4246C24.0163 35.4246 25.886 35.9113 27.5374 36.8303L29.2524 37.7856C31.1585 38.8501 33.2602 39.3642 35.3483 39.3642C38.3144 39.3642 41.2577 38.3178 43.5778 36.3207C43.0182 37.458 42.304 38.4998 41.4306 39.3778ZM42.3768 33.8141C39.2606 36.9303 34.4021 37.6036 30.5535 35.461L28.8384 34.5056C26.7913 33.3638 24.4712 32.7633 22.1284 32.7633H17.9249C17.9249 32.7633 17.9249 32.7633 17.9204 32.7633C15.3137 32.7633 12.8617 31.7488 11.0192 29.9064L8.79468 27.6818C6.02877 24.9114 4.50479 21.2311 4.50479 17.3188C4.50479 13.4065 6.02877 9.72617 8.79468 6.95571C11.5606 4.18981 15.2409 2.66583 19.1577 2.66583C23.07 2.66583 26.7503 4.18981 29.5208 6.95571L42.3814 19.8163C44.2511 21.686 45.2792 24.1744 45.2792 26.8175C45.2746 29.4606 44.2465 31.9444 42.3768 33.8141Z" fill="#4C8EC5"/>
                            <path d="M20.0767 20.5305C22.3012 21.8771 24.3256 22.332 26.0361 22.332C29.3889 22.332 31.5452 20.5942 31.6817 20.485C32.2458 20.0164 32.3231 19.1885 31.8591 18.6198C31.3951 18.0512 30.558 17.9693 29.9894 18.4242C29.8756 18.5152 27.6511 20.2439 24.2574 19.4341C23.2475 18.215 19.5444 13.3837 20.4861 9.03015C22.3285 9.25761 24.1164 10.0719 25.5266 11.4867L33.5423 19.4978C34.0609 20.0164 34.907 20.0164 35.4256 19.4978C35.9443 18.9792 35.9443 18.1331 35.4256 17.6145L27.41 9.5988C23.0064 5.19518 15.8414 5.19518 11.4378 9.5988C9.2496 11.787 8.1487 14.6575 8.13505 17.5326C8.04861 17.7646 8.02132 18.0284 8.08046 18.2878C8.10321 18.3878 8.13505 18.4788 8.17599 18.5653C8.3989 21.1219 9.48161 23.6149 11.4332 25.5665L12.8207 26.954C14.3765 28.5098 16.4464 29.3696 18.6482 29.3696C18.6482 29.3696 18.6482 29.3696 18.6528 29.3696H21.2686C22.3604 29.3696 23.4431 29.6516 24.3984 30.1839L25.4675 30.7798C25.6722 30.8936 25.8951 30.9482 26.1135 30.9482C26.582 30.9482 27.0324 30.7025 27.278 30.2658C27.6374 29.6243 27.4054 28.81 26.764 28.4552L25.6949 27.8593C24.3438 27.1086 22.8153 26.7083 21.2686 26.7083H18.6528C17.1606 26.7083 15.764 26.126 14.7086 25.0751L13.3211 23.6876C12.0246 22.3911 11.2285 20.7807 10.9328 19.1021C12.9572 18.8564 17.0105 18.6699 20.0767 20.5305ZM10.8782 16.4226C11.1239 14.612 11.9336 12.8697 13.3211 11.4822C14.5813 10.222 16.1371 9.43503 17.7657 9.11658C17.3426 12.1145 18.4117 15.0806 19.6491 17.3597C16.4373 16.095 12.9344 16.1951 10.8782 16.4226Z" fill="#4C8EC5"/>
                            <path d="M36.8996 21.1401C34.9207 21.4949 31.9865 23.9515 30.7718 27.0495C29.9757 29.0739 30.5444 31.2939 32.2139 32.6996C33.1374 33.4775 34.252 33.8733 35.3756 33.8733C36.2491 33.8733 37.1316 33.6322 37.9323 33.1454L38.0232 33.0863C39.57 32.1082 40.1477 30.1339 40.357 28.6463C40.6891 26.3125 40.3661 23.2145 39.1059 21.7997C38.8148 21.4585 38.1233 20.9217 36.8996 21.1401ZM37.7275 28.2732C37.5456 29.547 37.127 30.5069 36.6084 30.8344L36.5538 30.8663C35.5394 31.485 34.534 31.162 33.929 30.657C33.3057 30.1338 32.8008 29.1694 33.2511 28.023C34.211 25.5801 36.4219 23.9924 37.2635 23.7786C37.6593 24.511 37.9959 26.3762 37.7275 28.2732Z" fill="#4C8EC5"/>
                        </svg>
                    </span>
                    Мясные компоненты
                </h3>
                <div className="table">
                    <div className="table_header">
                        <div className="table_header_el">Сырьевой компонент рациона</div>
                        <div className="table_header_el">ФУНКЦИЯ</div>
                    </div>
                    <div className="table_row table_row_light">
                        <div className="table_row_el">Курица</div>
                        <div className="table_row_el"><p>Источник <b>животного белка</b> с высокой усвояемостью.</p></div>
                    </div>
                    <div className="table_row table_row_dark">
                        <div className="table_row_el">Говядина</div>
                        <div className="table_row_el"><p>Богатый источник <b>белка и незаменимых аминокислот.</b></p></div>
                    </div>
                    <div className="table_row table_row_light">
                        <div className="table_row_el">Индейка</div>
                        <div className="table_row_el"><p>Источник <b>высокоусвояемых белков.</b></p></div>
                    </div>
                    <div className="table_row table_row_dark">
                        <div className="table_row_el">Лосось</div>
                        <div className="table_row_el"><p>Богатый источник <b>белка и Омега-3</b> жирных кислот.</p></div>
                    </div>
                    <div className="table_row table_row_light">
                        <div className="table_row_box">
                            <div className="table_row_box_el">
                                Мясная мука
                                <p className={'table_row_box_subtext'}>(куриная, говяжья и др.)</p>
                            </div>
                            <div className="table_row_box_el">
                                Дегидратированное мясо
                                <p className={'table_row_box_subtext'}>(птицы, говядины и др.)</p>
                            </div>
                            <div className="table_row_box_el">
                                Сухое мясо
                                <p className={'table_row_box_subtext'}>(птицы, говядины и др.)</p>
                            </div>
                        </div>
                        <div className="table_row_common">
                            <p><b>Сушеные мясные компоненты,</b> доведенные технологическим образом <b>до мукообразного состояния.</b></p>
                            <p>Такое высушивание необходимо, чтобы сделать исходное сырье подходящим для производственного процесса сухих кормов.</p>
                        </div>
                    </div>
                    <div className="table_row table_row_dark">
                        <div className="table_row_el">Гидролизат животного происхождения</div>
                        <div className="table_row_el"><p><b>Приготовленное из мясных компонентов сырье,</b> богатое <b>белком, аминокислотами, жирными кислотами и витаминами, и имеющее привлекательный естественный запах</b> для кошек и собак. Применяется в виде бульона (спрея), которым покрываются гранулы сухого корма в процессе производства.</p></div>
                    </div>
                    <div className="table_row table_row_light">
                        <div className="table_row_el">Субпродукты</div>
                        <div className="table_row_el"><p><b>Внутренние органы животных, используемые в производстве кормов.</b> К ним относятся, например, печень, почки, селезенка. Являются богатым источником животного белка, незаменимых аминокислот и витаминов. Имеют привлекательный вкус и запах для кошек и собак.</p></div>
                    </div>
                    <div className="table_row table_row_dark">
                        <div className="table_row_el">Яйцо</div>
                        <div className="table_row_el"><p>Источник <b>животного белка с очень высокой усвояемостью.</b> Также содержит высокий уровень некоторых витаминов,например, витамина D.</p></div>
                    </div>
                    <div className="table_row table_row_light">
                        <div className="table_row_el">Животный жир</div>
                        <div className="table_row_el"><p>Богатый источник <b>энергии, Омега-3 и Омега-6</b> жирных кислот, <b>жирорастворимых витаминов (A, D, E, K).</b></p></div>
                    </div>
                    <div className="table_row table_row_dark">
                        <div className="table_row_el">Рыбий жир</div>
                        <div className="table_row_el"><p>Богатый источник <b>жирных кислот,</b> прежде всего ДГК (докозагексаеновой кислоты), которая способствует развитию мозговой деятельности щенков и котят. Также содержит высокий уровень витаминов A, D и E.</p></div>
                    </div>
                </div>
            </div>
        )
    }
}
