import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import Popup from '../../../Components/Popup';

import img1 from '../../../image/lessons/lesson14/step8-1.png';
import img2 from '../../../image/lessons/lesson14/step8-2.png';
import img3 from '../../../image/lessons/lesson14/step8-3.png';
import img4 from '../../../image/lessons/lesson14/step8-4.png';
import img4xs from '../../../image/lessons/lesson14/step8-4xs.png';
import img5 from '../../../image/lessons/lesson14/step8-5.png';
import img5xs from '../../../image/lessons/lesson14/step8-5xs.png';


export default class step8 extends React.Component{

    state = {
        pointActive: 0,
        showPopup: false,
        points: [],
    };

    showPopup = (pointActive) => {
        const {points, showPopup} = this.state;
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        if (!showPopup) {
            this.setState({
                pointActive: pointActive,
                showPopup: true,
            })
        } else if (pointActive >= 2) {
            this.setState({showPopup: false, pointActive: 0})
        } else {
            this.setState({
                pointActive: pointActive +1,
                showPopup: true,
            })
        }

        if (points.length === 2) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    popupData = {
        0: {
            head: '',
            text: '',
            img: '',
            imgXs: ''
        },
        1: {
            head: `Оптимальный уровень кальция и фосфора`,
            text: `<b>Укрепление эмали зубов</b> достигается за счет оптимального уровня кальция и фосфора в составе корма, которые являются <b>основными</b> строительными материалами зубной эмали.`,
            img: img4,
            imgXs: img4xs
        },
        2: {
            head: `Пористая текстура сухого корма`,
            text: `Пористая текстура корма обеспечивает механическое <b>очищение зубов</b> от остатков пищи. Благодаря этому <b>сокращается</b> отложение зубного налета.`,
            img: img5,
            imgXs: img5xs,
            text2: `Таким образом, исходя из графика можно сделать вывод о том, что у собак, которые питаются сухим кормом с пористой структурой, индекс зубного налёта значительно меньше, чем у собак, питающихся рационами с плотной текстурой.`
        },
    }

    render() {
        const {showPopup, pointActive} = this.state
        return (
            <div className="step8">
                <TextBlock
                    text={`Собакам необходимо чистить зубы, так как ежедневно на их поверхности образуется налет, избыток которого может стать причиной образования зубного камня или кровоточивости десен. Давайте рассмотрим, какие компоненты в составе корма являются критически важными для здоровых зубов.`}
                />
                <div className="digestion">
                    Здоровые зубы
                </div>
                <div className="block">
                    <div className="block_el">
                        <span className="block_el_arrow">
                            <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.29289 18.7071C7.68342 19.0976 8.31658 19.0976 8.70711 18.7071L15.0711 12.3431C15.4616 11.9526 15.4616 11.3195 15.0711 10.9289C14.6805 10.5384 14.0474 10.5384 13.6569 10.9289L8 16.5858L2.34315 10.9289C1.95262 10.5384 1.31946 10.5384 0.928932 10.9289C0.538407 11.3195 0.538407 11.9526 0.928932 12.3431L7.29289 18.7071ZM7 -4.3711e-08L7 18L9 18L9 4.3711e-08L7 -4.3711e-08Z" fill="#005EAD"/>
                            </svg>
                        </span>
                        <h3 className="block_el_head">
                            Оптимальный уровень кальция и фосфора
                        </h3>
                        <div className="block_el_box">
                            <span className="block_el_icon">
                                <img src={img2} alt="" className="img_icon"/>
                            </span>
                        </div>
                        <div
                            className="point"
                            onClick={() => {this.showPopup(1)}}
                        >
                            ?
                        </div>
                    </div>
                    <div className="block_el">
                        <span className="block_el_arrow">
                            <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.29289 18.7071C7.68342 19.0976 8.31658 19.0976 8.70711 18.7071L15.0711 12.3431C15.4616 11.9526 15.4616 11.3195 15.0711 10.9289C14.6805 10.5384 14.0474 10.5384 13.6569 10.9289L8 16.5858L2.34315 10.9289C1.95262 10.5384 1.31946 10.5384 0.928932 10.9289C0.538407 11.3195 0.538407 11.9526 0.928932 12.3431L7.29289 18.7071ZM7 -4.3711e-08L7 18L9 18L9 4.3711e-08L7 -4.3711e-08Z" fill="#005EAD"/>
                            </svg>
                        </span>
                        <h3 className="block_el_head">
                            Пористая текстура сухого корма
                        </h3>
                        <div className="block_el_box">
                            <span className="block_el_icon">
                                <img src={img3} alt="" className="img_icon"/>
                            </span>
                        </div>
                        <div
                            className="point"
                            onClick={() => {this.showPopup(2)}}
                        >
                            ?
                        </div>
                    </div>
                </div>
                <div className="img-box">
                    <img src={img1} alt="" className="img"/>
                </div>
                {showPopup &&
                <Popup
                    closePopup={this.closePopup}
                >
                    <div className="head">
                        {this.popupData[pointActive].head}
                    </div>
                    <div className="text"
                         dangerouslySetInnerHTML={{ __html: this.popupData[pointActive].text }}
                    />
                    <div className="img-box">
                        <picture>
                            <source srcSet={this.popupData[pointActive].imgXs} media="(max-width: 767px)"/>
                            <img src={this.popupData[pointActive].img} alt="" className="img-popup"/>
                        </picture>
                    </div>
                    <div className="text">
                        {this.popupData[pointActive].text2}
                    </div>
                    <button
                        className="btn btn_blue"
                        onClick={() => {this.showPopup(pointActive)}}
                    >
                        Продолжить
                    </button>
                </Popup>
                }
            </div>
        )
    }
}
