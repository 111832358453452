import React from 'react';
import './style.sass'
import VideoBlock from '../../Components/LessonAndTestComponents/VideoBlock'

import img1 from '../../image/sert.png'

export default class GiftsWhiskas extends React.Component {

    render() {
        return (
            <div className="GiftsWhiskas">
                <div className="GiftsWhiskas_head">
                    Подарки от Whiskas®
                </div>
                <div className="GiftsWhiskas_box">
                    <div className="GiftsWhiskas_box_el video">
                        <div className="GiftsWhiskas_box_head">
                            Доступные видео
                        </div>
                        <div className="flex">
                            <div className="flex_el">
                                <div className="flex_el_wrp">
                                    <VideoBlock
                                        src="https://www.youtube.com/embed/FbZI4bxUGI0"
                                        title="https://www.youtube.com/watch?v=FbZI4bxUGI0&feature=emb_logo"
                                    />
                                    <div className="flex_el_head">
                                        Атанасова С.Г. - Стресс у кошек. Как помочь кошкам в ОРИТ?
                                    </div>
                                    <div className="flex_el_text">
                                        Клинические проявления и методы контроля стресса у кошек. Возможности применения психотропных средств у кошек.
                                    </div>
                                </div>
                            </div>
                            <div className="flex_el">
                                <div className="flex_el_wrp">
                                    <VideoBlock
                                        src="https://www.youtube.com/embed/LQFTil2S-L0"
                                        title="https://www.youtube.com/watch?v=LQFTil2S-L0&feature=emb_logo"
                                    />
                                    <div className="flex_el_head">
                                        Беленсон М.М. - Когда действительно стоит подозревать "что-то эндокринное" у кошек?
                                    </div>
                                    <div className="flex_el_text">
                                        Рассмотрены ситуации, в которых "чего-то эндокринного" нет и "что-то эндокринное" очень вероятно: основные "маячки". А также типичные ошибки в диагностике эндокринопатий кошек.
                                    </div>
                                </div>
                            </div>
                            <div className="flex_el">
                                <div className="flex_el_wrp">
                                    <VideoBlock
                                        src="https://www.youtube.com/embed/qUlqMDqY6nI"
                                        title="https://www.youtube.com/watch?v=qUlqMDqY6nI&feature=emb_logo"
                                    />
                                    <div className="flex_el_head">
                                        Мендоса-Кузнецова Е.С. - Кошкина кожа и кошкина шерсть. Что особенного?
                                    </div>
                                    <div className="flex_el_text">
                                        В лекции разобраны наиболее часто встречающиеся отличия в диагностике и лечение дерматологических заболеваний у кошек от собак.
                                    </div>
                                </div>
                            </div>
                            <div className="flex_el">
                                <div className="flex_el_wrp">
                                    <VideoBlock
                                        src="https://www.youtube.com/embed/WbHSJP1uuYU"
                                        title="https://www.youtube.com/watch?v=WbHSJP1uuYU&feature=emb_logo"
                                    />
                                    <div className="flex_el_head">
                                        Спирина А.С. - Стоматология кошек. От педиатрии до гериатрии. Просто о сложном
                                    </div>
                                    <div className="flex_el_text">
                                        Когда питомцу нужен стоматолог? Болезни ротовой полости и зубов у кошек (гингивит и стоматит, пародонтит, резорбция, перелом зубов, новообразования). Профилактика, диагностика и лечение в домашних условиях. Особенности лечения в ветеринарной клинике.
                                    </div>
                                </div>
                            </div>
                            <div className="flex_el">
                                <div className="flex_el_wrp">
                                    <VideoBlock
                                        src="https://www.youtube.com/embed/FLPfZ2O7BiY"
                                        title="https://www.youtube.com/watch?v=FLPfZ2O7BiY&feature=emb_logo"
                                    />
                                    <div className="flex_el_head">
                                        Сятковская О.С. - Казнить нельзя помиловать. Или почему кот мочится мимо лотка
                                    </div>
                                    <div className="flex_el_text">
                                        Как разобраться в проблеме, понять, медицинская причина или же поведенческая. Разобраться не только, как врачу, но, и помочь владельцу с лечением и профилактикой таких ситуаций. Возможные диагнозы и лечение медицинских проблем. Методы коррекции поведенческих причин.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="GiftsWhiskas_box_el certificate">
                        <div className="GiftsWhiskas_box_head">
                            Ваш сертификат
                        </div>
                        <img src={img1} alt="" className="GiftsWhiskas_box_img"/>
                        <a href="/web/sert.pdf" className="btn btn_blue btn_center" target="_blank" rel="noopener noreferrer">
                            Выгрузить в PDF
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}
