import React from 'react'

import MainHeader from "../../Components/LessonAndTestComponents/MainHeader";
import TextBlock from "../../Components/LessonAndTestComponents/TextBlock";
import InfoBlock from "../../Components/LessonAndTestComponents/InfoBlock";
import AnswersBlock from "../../Components/LessonAndTestComponents/AnswersBlock";
import AnswersDnd from "../../Components/LessonAndTestComponents/AnswersDnd";
import QuestionCorrect from "../../Components/LessonAndTestComponents/QuestionCorrect";
import MainButton from "../../Components/LessonAndTestComponents/MainButton";
import PopapAfterFinalTest from './popapAfterFinalTest'

import Api from '../../Service/Api'


export default class Test extends React.Component {
    Api = new Api();

    state = {
        MainHeader_header: 'Финальный тест', // Первая линия заголовка шага
        MainHeader_subheader: 'Как выбрать щенка', // Вторая линия заголовка шага
        MainHeader_class: 'bigHeader noBB', // Большой или маленький Заголовок
        typeMainBtn: 'check answer', // тип кнопок (take a lesson - Пройти урок, '' - стандартно медлу щагами)
        questionName: '', // Название вопроса
        questionCount: '', // Количество вопросов
        answerCorrect: 0, // Ответов верных
        count: 1, //Номер вопроса
        countNextQuestion: 1, //Номер следующего вопроса для тестов внутри шагов
        qId: '',
        question: '',
        answers: {},
        compareAnswer: {},
        showQuestionCorrect: false,
        questionCorrect: '',
        headCorrect: '',
        textCorrect: '',
        answerActive: [],
        nextQuestionName: '',
        nextQuestion: '',
        nextAnswers: '',
        nextTextCorrect: '',
        nextqId: '',
        showPopap: false,
        disabled: false,
        multi_answer: '',
        testDone: 1,
        order: '',
    }

    componentDidMount() {
        const {order} = this.state
        this.Api.getFinalTest(order)
            .then((res) => {
                if (!res.done) {
                    this.setState({
                        qId: res.question.id,
                        questionName: res.question.title,
                        question: res.question.description,
                        questionCount: res.totalQuestions,
                        answers: res.answers,
                        textCorrect: res.question.caption,
                        multi_answer: res.question.multi_answer,
                        order: res.order,
                    })
                }
            })

    }

    checkAnswer = () => {
        const {qId, answerActive, order} = this.state
        this.setState({disabled: false})
        let answers = [];
        // eslint-disable-next-line
        answerActive.map(item => {
            answers.push(item)
        })
        this.Api.compareFinalTestQuestion(qId, answers)
            .then((res) => {
                if (!res.done) {
                    this.setState({
                        compareAnswer: res.compare.answers,
                        questionCorrect: res.compare.correct === 1 ? 'correct' : 'inCorrect',
                        typeMainBtn: 'next question',
                        showQuestionCorrect: true,
                        headCorrect: res.compare.correct === 1 ? 'все верно!' : 'К сожалению, ваш ответ неверен.',
                        answerCorrect: this.state.answerCorrect + res.compare.correct,
                        testDone: this.state.answerCorrect/this.state.questionCount
                    })
                }
            })
        this.Api.getFinalTestStep(order)
            .then((res) => {
                if (res.final) {
                    this.setState({
                        nextqId: res.question.id,
                        nextQuestionName: res.question.title,
                        nextQuestion: res.question.description,
                        questionCount: res.totalQuestions,
                        nextAnswers: res.answers,
                        nextTextCorrect: res.question.caption,
                        multi_answer: res.question.multi_answer,
                        order: res.order,
                    })
                } else {
                    this.setState({
                        typeMainBtn: 'end test'
                    })
                }
            })
    }

    nextQuestion = () => {
        this.setState({disabled: true})
        const {nextQuestionName, nextAnswers, nextQuestion, nextqId, nextTextCorrect} = this.state
        if (nextQuestionName === '' && nextAnswers === '' && nextQuestion === '' && nextqId === '' && nextTextCorrect === '') {
            this.setState({
                showPopap: true
            })
        } else {
            this.setState({
                compareAnswer: {},
                questionCorrect: '',
                typeMainBtn: 'check answer',
                showQuestionCorrect: false,
                headCorrect: '',
                questionName: nextQuestionName,
                question: nextQuestion,
                answers: nextAnswers,
                qId: nextqId,
                textCorrect: nextTextCorrect,
                nextQuestionName: '',
                nextQuestion: '',
                nextAnswers: '',
                nextqId: '',
                nextTextCorrect: '',
                answerActive: [],
                count: this.props.stepId ? parseInt(this.state.countNextQuestion.match(/\d+/), 10) : this.state.count + 1,
                disabled: false,
            })
        }
    }


    setAnswer = id => {
        const {answerActive} = this.state;
        answerActive.indexOf(id) > -1 ?
            delete answerActive[answerActive.indexOf(id)]
            :
            answerActive.push(id)
        this.setState({answerActive})
    }

    sendAnswer = answerActive => {
        this.setState({answerActive})
    }

    render() {
        const {
            MainHeader_header,
            MainHeader_subheader,
            MainHeader_class,
            answerCorrect,
            questionCount,
            questionName,
            count,
            question,
            answers,
            compareAnswer,
            typeMainBtn,
            questionCorrect,
            headCorrect,
            textCorrect,
            showQuestionCorrect,
            showPopap,
            disabled,
            multi_answer,
            testDone
        } = this.state;
        return (
            <div>
                <div className={"content_test finalTest " + (this.props.stepId && 'content_test_step')}>
                    <div className="test">
                        {/* Основной заголовок шага */}
                        <MainHeader
                            header={MainHeader_header}
                            subheader={MainHeader_subheader}
                            headerClass={MainHeader_class}
                            questionName={questionName}
                            count={count}
                            answerCorrect={answerCorrect}
                            questionCount={questionCount}

                        />
                        <InfoBlock
                            text={`Выберите вариант ответа и нажмите на него`}
                            hidden={showQuestionCorrect ? ' hidden' : ''}
                        />
                        <MainHeader
                            headerClass="MainHeader_mobile"
                            questionName={questionName}
                            count={count}
                            answerCorrect={answerCorrect}
                            questionCount={questionCount}
                            testHeadClass="testHeadMobile"

                        />
                        {/* Текст вопроса */}
                        <TextBlock text={question}/>
                        {/* Варианты ответов */}
                        {multi_answer === 2
                            ?
                            <AnswersDnd answers={answers} compareAnswers={compareAnswer} setAnswer={this.setAnswer} sendAnswer={this.sendAnswer}/>
                            :
                            <AnswersBlock answers={answers} compareAnswers={compareAnswer} setAnswer={this.setAnswer}/>}
                        {/*<AnswersBlock answers={answers} compareAnswers={compareAnswer} setAnswer={this.setAnswer}/>*/}
                        {/* Верный или нет ответ */}
                        {showQuestionCorrect &&
                        <QuestionCorrect
                            type={questionCorrect} // correct - верный, inCorrect - не выерный
                            head={headCorrect} // Заголовок блока
                            text={textCorrect} // Текст блока
                        />
                        }

                        <MainButton
                            type={typeMainBtn}
                            disabled={disabled}
                            checkAnswer={this.checkAnswer}
                            nextQuestion={this.nextQuestion}
                            showPrevBtn={this.props.stepId !== undefined}
                            showNextStep={this.props.showNextStep}
                            showPrevStep={this.props.showPrevStep}

                        />
                    </div>
                </div>
                {showPopap &&
                <PopapAfterFinalTest
                    testDone={testDone}
                    history={this.props.history}
                />
                }
            </div>


        )
    }
}
