import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import img1 from '../../../image/lessons/lesson3/step7-1.png';
import img2 from '../../../image/lessons/lesson3/step7-2.png';


export default class step7 extends React.Component {
    state = {
        flexActive: 0,
        flexs: [1],
        flexHidden: false,
    };

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }
        this.setState({flexActive, flexs, flexHidden: true})
        if (flexs.length === 4) {
            this.props.showNextBtn()
        }
    }

    closeImgDiv = () => {this.setState({flexHidden: false, flexActive: 0})}


    render() {
        const {flexActive, flexHidden} = this.state
        return (
            <div className="step7">
                <InfoBlock
                    text={`Нажмите на этапы для дополнительной информации.`}
                />
                <div className="wrap">
                    <div className={'flex ' + (flexHidden === true && 'hidden')}>
                        <div className="flex_wrp">
                            <div
                                className={'flex_el ' + (flexActive === -1 && 'active')}
                                onClick={() => {this.changeFlex(-1)}}
                            >
                                Животное употребляет белок в пищу
                            </div>
                            <img src={img2} alt="" className="flex_el_arrow"/>
                        </div>
                        <div className="flex_wrp">
                            <div
                                className={'flex_el ' + (flexActive === 2 && 'active')}
                                onClick={() => {this.changeFlex(2)}}
                            >
                                Ферменты расщепляют белок на аминокислоты
                            </div>
                            <div className={'flex_el_content ' + (flexActive === 2 && 'active')}>
                                <TextBlock
                                    text={`Аминокислоты всасываются в кровь через стенку пищеварительного тракта.`}
                                />
                            </div>
                            <img src={img2} alt="" className="flex_el_arrow"/>
                        </div>
                        <div className="flex_wrp">
                            <div
                                className={'flex_el ' + (flexActive === -2 && 'active')}
                                onClick={() => {this.changeFlex(-2)}}
                            >
                                Аминокислоты всасываются в кровь
                            </div>
                            <img src={img2} alt="" className="flex_el_arrow"/>
                        </div>
                        <div className="flex_wrp">
                            <div
                                className={'flex_el ' + (flexActive === 4 && 'active')}
                                onClick={() => {this.changeFlex(4)}}
                            >
                                Аминокислоты собираются в новые белковые структуры
                            </div>
                            <div className={'flex_el_content ' + (flexActive === 4 && 'active')}>
                                <TextBlock
                                    text={`Аминокислоты различных белков связаны между собой в разной последовательности, каждая комбинация которых образует белок со специальными характеристиками и функциями. От структуры зависят функции белка.`}
                                />
                            </div>
                            <img src={img2} alt="" className="flex_el_arrow"/>
                        </div>
                        <div className="flex_wrp">
                            <div
                                className={'flex_el ' + (flexActive === 5 && 'active')}
                                onClick={() => {this.changeFlex(5)}}
                            >
                                Избыточные аминокислоты разрушаются в печени
                            </div>
                            <div className={'flex_el_content ' + (flexActive === 5 && 'active')}>
                                <TextBlock
                                    text={`На этом этапе высвобождается энергия и вырабатывается мочевина, которая выводится из организма через мочу.`}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div className={'imgDiv ' + (flexActive > 0 && 'active')}>
                            <div className={'imgDiv_wrap ' + (flexActive === 2 && 'active')}>
                                <TextBlock
                                    text={`Аминокислоты всасываются в кровь через стенку пищеварительного тракта.`}
                                />
                            </div>
                            <div className={'imgDiv_wrap ' + (flexActive === 4 && 'active')}>
                                <TextBlock
                                    text={`Аминокислоты различных белков связаны между собой в разной последовательности, каждая комбинация которых образует белок со специальными характеристиками и функциями. От структуры зависят функции белка.`}
                                />
                            </div>
                            <div className={'imgDiv_wrap ' + (flexActive === 5 && 'active')}>
                                <TextBlock
                                    text={`На этом этапе высвобождается энергия и вырабатывается мочевина, которая выводится из организма через мочу.`}
                                />
                            </div>
                        </div>
                        <div className={'imgBox ' + (flexActive > 0 && 'active')}>
                            <img className={'img ' + (flexActive > 0 && 'active')} src={img1} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
