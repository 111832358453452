import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';

export default class step1 extends React.Component {

    planData1 = [
        `Научная основа рационов WHISKAS<sup>®</sup>`,
        `Особенности питания кошек на разных стадиях жизни`,
    ]

    planData2 = [
        `Преимущества сочетания сухих и влажных кормов`,
    ]

    render() {
        return (
            <div className="step1">
                <div className="head">План урока</div>

                <TextBlock
                    text={`В этом уроке рассматриваются следующие вопросы:`}
                />

                <PlanInFirstStep
                    planData={this.planData1}
                    type={'purple'}
                />
                <div className="flex">
                    <div className="flex_el">
                        Котенок
                    </div>
                    <div className="flex_el">
                        Взрослая кошка
                    </div>
                    <div className="flex_el">
                        Кошка старше 7 лет
                    </div>
                </div>
                <PlanInFirstStep
                    planData={this.planData2}
                    type={'purple'}
                />
            </div>
        )
    }
}
