import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson16/step9-1.png'
import img1xs from '../../../image/lessons/lesson16/step9-1xs.png'


export default class step9 extends React.Component {


    render() {
        return (
            <div className="step9">
                <TextBlock
                    text={`Покупатель нашей категории совершает покупки <span class="text_blue">чаще и тратит за один раз больше</span>, чем покупатели в среднем.`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} className="imgCenter" alt=""/>
                </picture>
            </div>
        )
    }
}
