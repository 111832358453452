import React from 'react'
import { Link, animateScroll as scroll } from "react-scroll";
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";

import img1 from "../../../image/arrow-up.png";

export default class step1 extends React.Component {

    state = {
        scrollTop: window.pageYOffset
    }

    //Отлов события scroll
    handlerScroll = () => {
        this.setState({scrollTop: window.pageYOffset})
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handlerScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handlerScroll);
    }

    videoData = {
        1: {
            question: `Как отучить кошку царапать мебель?`,
            src: 'https://www.youtube.com/embed/iAUTgBMMykI',
            title: 'https://www.youtube.com/watch?v=iAUTgBMMykI&feature=emb_logo',
        },
        2: {
            question: `Почему кошка кусает руки?`,
            src: 'https://www.youtube.com/embed/0aTgnyl8_nQ',
            title: 'https://www.youtube.com/watch?v=0aTgnyl8_nQ&feature=emb_logo',
        },
        3: {
            question: `Как отучить кошку ходить по столу?`,
            src: 'https://www.youtube.com/embed/XNhZj1hgaHY',
            title: 'https://www.youtube.com/watch?v=XNhZj1hgaHY&feature=emb_logo',
        },
        4: {
            question: `Почему кошка ходит мимо лотка?`,
            src: 'https://www.youtube.com/embed/IRvxCOe1sbQ',
            title: 'https://www.youtube.com/watch?v=IRvxCOe1sbQ&feature=emb_logo',
        },
        5: {
            question: `Почему кошка не дает спать и будит ночью?`,
            src: 'https://www.youtube.com/embed/H3WJR0KtgVU',
            title: 'https://www.youtube.com/watch?v=H3WJR0KtgVU&feature=emb_logo',
        },
        6: {
            question: `Почему кошка много ест?`,
            src: 'https://www.youtube.com/embed/4antR1cf3Sg',
            title: 'https://www.youtube.com/watch?v=4antR1cf3Sg&feature=emb_logo',
        },
        7: {
            question: `Почему кошка мало ест?`,
            src: 'https://www.youtube.com/embed/HFVbfNo-_Ko',
            title: 'https://www.youtube.com/watch?v=HFVbfNo-_Ko&feature=emb_logo',
        },
        8: {
            question: `Почему кошка ходит и мяукает?`,
            src: 'https://www.youtube.com/embed/FhBZdrrSDIU',
            title: 'https://www.youtube.com/watch?v=N9jDm5Il4dk&feature=emb_logo',
        },
        9: {
            question: `Какие игрушки выбрать для кошки?`,
            src: 'https://www.youtube.com/embed/N9jDm5Il4dk',
            title: 'https://www.youtube.com/watch?v=XNhZj1hgaHY&feature=emb_logo',
        },
        10: {
            question: `Должно ли быть у кошки свое место?`,
            src: 'https://www.youtube.com/embed/8Tq25l-DFKs',
            title: 'https://www.youtube.com/watch?v=8Tq25l-DFKs&feature=emb_logo',
        },
        11: {
            question: `Как подружить кошку с собакой?`,
            src: 'https://www.youtube.com/embed/1f23GO1Iodg',
            title: 'https://www.youtube.com/watch?v=1f23GO1Iodg&feature=emb_logo',
        },
        12: {
            question: `Стоит ли к старой кошке заводить котенка?`,
            src: 'https://www.youtube.com/embed/jZam516q7Bg',
            title: 'https://www.youtube.com/watch?v=jZam516q7Bg&feature=emb_logo',
        },
        13: {
            question: `Почему кошка мало пьет?`,
            src: 'https://www.youtube.com/embed/8th_NdBuWX8',
            title: 'https://www.youtube.com/watch?v=8th_NdBuWX8&feature=emb_logo',
        },
        14: {
            question: `Почему моя кошка постоянно себя вылизывает?`,
            src: 'https://www.youtube.com/embed/IzXigIHzbzk',
            title: 'https://www.youtube.com/watch?v=IzXigIHzbzk&feature=emb_logo',
        },
    }

    render() {
        const {scrollTop} = this.state
        return (
            <div className="step1">
                <ul className="list">
                    {Object.keys(this.videoData).map(item => {
                        return (
                            <li
                                key={item}
                                className="list_item"
                            >
                                <Link
                                    activeClass="active"
                                    className="list_item_link"
                                    to={'catTips' + item}
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                >
                                    {this.videoData[item].question}
                                </Link>
                            </li>
                        )
                    })}
                </ul>
                {Object.keys(this.videoData).map(item => {
                    return (
                        <VideoBlock
                            key={item}
                            id={'catTips' + item}
                            src={this.videoData[item].src}
                            title={this.videoData[item].title}
                        />
                    )
                })}
                {/*eslint-disable-next-line*/}
                <a onClick={() => scroll.scrollToTop()}><img src={img1} alt="" className={'img_arrow-up ' + (scrollTop > 700 && 'active')}/></a>
            </div>
        )
    }
}
