import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson7/step12-1.png";
import img2 from "../../../image/lessons/lesson7/step12-2.png";

export default class step12 extends React.Component {

    render() {
        return (
            <div className="step12">
                <TextBlock
                    text={`Одним из важнейших аспектов правильного кормления является оптимальная температура корма. Она влияет на ее вкусовое восприятие и усвояемость. Оптимальная температура зависит от формата корма и от животного, которое его ест.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Сухой корм
                        </div>
                        <div className="flex_el_box">
                            <TextBlock
                                text={`Исследования показали, что вкусовые свойства сухого корма <b>не зависят от температуры</b>. Это было подтверждено многочисленными исследованиями.`}
                            />
                            <img src={img1} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Влажный корм
                        </div>
                        <div className="flex_el_box">
                            <TextBlock
                                text={`Вкусовые свойства влажного корма <b>изменяются в зависимости от температуры</b>. При этом предпочтения по температуре у кошек и собак отличаются. Далее вы узнаете о том, какая температура корма считается оптимальной для домашних животных.`}
                            />
                            <img src={img2} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
