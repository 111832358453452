import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';

export default class step1 extends React.Component {

    planData = [
        `Разновидности минералов`,
        `Функции минералов в организме`,
        `Источники минералов`,
        `Симптомы дефицита и избытка минералов у животных`,
    ]

    render() {
        return (
            <div className="step1">
                <div className="head">План урока</div>

                <TextBlock
                    text={`Этот урок посвящен изучению минералов и пониманию их роли в организме.
                    
                    В этом уроке вы узнаете:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'blue'}
                />

            </div>
        )
    }
}
