import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";

import img1 from '../../../image/lessons/lesson8/step3-1.png'
import img2 from '../../../image/lessons/lesson8/step3-2.png'


export default class step3 extends React.Component {
    state = {
        pointActive: 0,
        points: [],
        showPopup: false
    };

    showPopup = (pointActive) => {
        const {points, showPopup} = this.state;
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        if (!showPopup) {
            this.setState({
                pointActive: pointActive,
                showPopup: true,
            })
        } else if (pointActive >= 2) {
            this.setState({showPopup: false, pointActive: 0})
        } else {
            this.setState({
                pointActive: pointActive +1,
                showPopup: true,
            })
        }

        if (points.length === 2) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    popupWrp = {
        1: {
            text: `Вспомните материал предыдущих уроков!

<span class="textBlue">Формула расчета энергетической потребности:</span>

для взрослой кошки <span class="textBlue textBlue--big"><b>Э = 77.6 * M <sup>0,711</sup></b></span>

для взрослой собаки <span class="textBlue textBlue--big"><b>Э = 95 * M <sup>0,75</sup></b></span> 

<small>Э — энергетическая потребность;
М — масса тела в килограммах.</small>`
        },
        2: {
            text: `Энергетическая ценность корма пишется на обратной стороне упаковки. Например, энергетическая ценность WHISKAS<sup>®</sup> с говядиной и ягнёнком в рагу составляет 70 ккал / 100 г./ 100 г.`,
            img: img2
        },

    }

    render() {
        const {pointActive, showPopup} = this.state;
        return (
            <div className="step3">
                <TextBlock
                    text={`Суточная норма кормления рассчитывается путем деления суточной энергетической потребности питомца на энергетическую ценность корма.`}
                />
                <div className="ul">
                    <div className="liHead">Расчет делается в три шага:</div>
                    <div className="li">
                        <div className="number">1.</div>
                        <div className="text">Рассчитать <b>энергетическую потребность</b> питомца, исходя из его массы. Измеряется в килокалориях (ккал).</div>
                        <div className="quest" onClick={() => {this.showPopup(1)}}/>
                    </div>
                    <div className="li">
                        <div className="number">2.</div>
                        <div className="text">Узнать <b>энергетическую ценность</b> корма. Эта информация есть на каждой упаковке. Измеряется в ккал / 100 г.</div>
                        <div className="quest" onClick={() => {this.showPopup(2)}}/>
                    </div>
                    <div className="li">
                        <div className="number">3.</div>
                        <div className="text">
                            <div className="formula">
                                <div className="formula_box">
                                    <div className="formula_numerator">
                                        энергетическая потребность
                                    </div>
                                    <div className="formula_denominator">
                                        энергетическая ценность
                                    </div>
                                </div>
                                <div className="formula_multiplier">
                                    X 100 = cуточный рацион
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <img src={img1} alt="" className="imgCenter"/>

                {showPopup &&
                    <PopupInStep
                        closePopup={this.closePopup}
                        popupText={this.popupWrp[pointActive].text}
                        popupImg2={this.popupWrp[pointActive].img}
                        changePoint={() => this.showPopup(pointActive)}
                    />
                }
            </div>
        )
    }
}
