import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";

import img1 from '../../../image/lessons/lesson23/step5-1.png';
import img2 from '../../../image/lessons/lesson23/step5-2.png';
import img3 from '../../../image/lessons/lesson23/step5-3.png';
import img4 from '../../../image/lessons/lesson23/step5-4.png';
import img5 from '../../../image/lessons/lesson23/step5-5.png';
import img6 from '../../../image/lessons/lesson23/step5-6.png';

export default class step5 extends React.Component {

    state = {
        pointActive: 0,
        points: [],
    }

    showInfo = (pointActive) => {
        const {points} = this.state;
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        this.setState({points: points, pointActive: pointActive})

        if (points.length === 5) {
            this.props.showNextBtn();
        }
    }

    render() {
        const {points} = this.state
        return (
            <div className="step5">
                <div className="head">
                    Каждый ингредиент NATURE’S TABLE™ несет пользу для здоровья питомцев
                </div>
                <TextBlock
                    text={`Сбалансированное питание на основе натуральных ингредиентов из проверенных источников.`}
                />
                <InfoBlock
                    text={`Нажмите на активные элементы, чтобы узнать больше о составе NATURE’S TABLE™.`}
                />
                <div className="block">
                    <div className="block_box">
                        <div className="point" onClick={() => {this.showInfo(1)}}>
                            <img className={'point_img ' + (points.indexOf(1) >= 0 && 'active')} src={img2} alt=""/>
                        </div>
                        <div className="point" onClick={() => {this.showInfo(2)}}>
                            <img className={'point_img ' + (points.indexOf(2) >= 0 && 'active')} src={img3} alt=""/>
                        </div>
                        <div className="point" onClick={() => {this.showInfo(3)}}>
                            <img className={'point_img ' + (points.indexOf(3) >= 0 && 'active')} src={img4} alt=""/>
                        </div>
                        <div className="point" onClick={() => {this.showInfo(4)}}>
                            <img className={'point_img ' + (points.indexOf(4) >= 0 && 'active')} src={img5} alt=""/>
                        </div>
                        <div className="point" onClick={() => {this.showInfo(5)}}>
                            <img className={'point_img ' + (points.indexOf(5) >= 0 && 'active')} src={img6} alt=""/>
                        </div>
                        <img className="imgCenter" src={img1} alt=""/>
                    </div>
                </div>
            </div>
        )
    }
}
