import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from "../../../image/lessons/lesson10/step8-1.png";


export default class step8 extends React.Component{

    render(){
        return (
            <div className="step8">
                <div className="flex">
                    <div className="flex_el">
                        <TextBlock text={`Корм для котят обязательно <span class="text_blue">должен содержать</span> необходимое количество следующих элементов:`}/>
                        <ul className="list">
                            <li className="list_item">белок;</li>
                            <li className="list_item">таурин;</li>
                            <li className="list_item">кальций и фосфор;</li>
                            <li className="list_item">витамины и микроэлементы;</li>
                            <li className="list_item">Омега-3 жирные кислоты.</li>
                        </ul>
                    </div>
                    <div className="flex_el">
                        <img className="flex_el_img" src={img1} alt=""/>
                    </div>
                </div>
                <TextBlock
                    text={`Корм должен обладать <b>высокой калорийностью</b>, чтобы обеспечить котенка необходимым <b>запасом энергии.</b>
                        
                        Лучшее решение — специализированный корм для котят. Например, корм <b>WHISKAS<sup>®</sup> для котят</b> от 1 до 12 месяцев или <b>PERFECT FIT™ для котят.</b>`}/>
            </div>
        )
    }

}
