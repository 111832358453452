import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson3/step2-1.png'
import img2 from '../../../image/lessons/lesson3/step2-2.png'
import img3 from '../../../image/lessons/lesson3/step2-3.png'
import img4 from '../../../image/lessons/lesson3/step2-4.png'
import img5 from '../../../image/lessons/lesson3/step2-5.png'
import img6 from '../../../image/lessons/lesson3/step2-6.png'
import img7 from '../../../image/lessons/lesson3/step2-7.png'



export default class step2 extends React.Component {


    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step2">
                <TextBlock
                    text={`Белки являются неотъемлемой частью организма и выполняют множество важнейших функций. Они участвуют практически в каждом процессе на клеточном уровне.`}
                />
                <div className="head">
                    4 главные функции белка
                </div>
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_border">
                            <img className="flex_el_img" src={img1} alt=""/>
                        </div>
                        <p className="flex_el_text">строительная</p>
                        <p className="flex_el_little-text">(компонент клеток, обеспечивают рост организма)</p>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_border">
                            <img className="flex_el_img" src={img2} alt=""/>
                        </div>
                        <p className="flex_el_text">двигательная</p>
                        <p className="flex_el_little-text">(выполняют все виды движения)</p>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_border">
                            <img className="flex_el_img" src={img3} alt=""/>
                        </div>
                        <p className="flex_el_text">транспортная</p>
                        <p className="flex_el_little-text">(перенос различных веществ)</p>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_border">
                            <img className="flex_el_img" src={img4} alt=""/>
                        </div>
                        <p className="flex_el_text">защитная</p>
                        <p className="flex_el_little-text">(распознают и уничтожают опасные для организма вещества)</p>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_border">
                            <img className="flex_el_img" src={img5} alt=""/>
                        </div>
                        <p className="flex_el_text">ферментативная</p>
                        <p className="flex_el_little-text">(ускоряют течение всех химических реакций)</p>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_border">
                            <img className="flex_el_img" src={img6} alt=""/>
                        </div>
                        <p className="flex_el_text">регуляторная</p>
                        <p className="flex_el_little-text">(участвуют в обмене веществ)</p>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_border">
                            <img className="flex_el_img" src={img7} alt=""/>
                        </div>
                        <p className="flex_el_text">энергетическая</p>
                        <p className="flex_el_little-text">(источник энергии)</p>
                    </div>
                </div>
                <TextBlock
                    text={`В определенные периоды жизни животным необходимо повышенное количество белка:`}
                />
                <ul className="list">
                    <li className="list_item">период активного роста;</li>
                    <li className="list_item">беременность;</li>
                    <li className="list_item">лактация;</li>
                    <li className="list_item">повышенная физическая активность;</li>
                    <li className="list_item">восстановление поврежденных тканей.</li>
                </ul>
            </div>
        )
    }
}
