import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson21/step19-1.png'
import img2 from '../../../image/lessons/lesson21/step19-2.png'


export default class step18 extends React.Component {


    render() {
        return (
            <div className="step19">
                <TextBlock
                    text={`В Mars Centre of Pet Nutrition проводятся тесты вкусовой привлекательности.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
                <div className="info">
                    <div className="info_el">
                        <div className="info_el_number">1</div>
                        <div className="info_el_text">
                            <span className="info_purple">Система кормления</span> позволяет индивидуально кормить каждую кошку и отслеживать количество съеденного корма.
                        </div>
                    </div>
                    <div className="info_el">
                        <div className="info_el_number">2</div>
                        <div className="info_el_text">
                            Система позволяет определять какой корм кошке понравился больше.
                        </div>
                    </div>
                    <div className="info_el">
                        <div className="info_el_number">3</div>
                        <div className="info_el_text">
                            Система открывает возможность применения различных методик изучения не только вкусовых предпочтений животных, но и научных исследований пищевого поведения животных.
                        </div>
                    </div>
                    <div className="info_el">
                        <div className="info_el_number">4</div>
                        <div className="info_el_text">
                            Каждая кормовая станция оборудована электронным считывающим устройством, позволяющим ограничить доступ не авторизованных кошек к кормушке. Каждой кошке назначается своя кормовая станция.
                        </div>
                    </div>
                </div>
                <div className="cat">
                    <img src={img2} alt="" className="cat_img"/>
                    <TextBlock
                        text={`Изучение пищевого поведения животных, кормов и их переваримости базируется на гуманном отношении к животным. Все проводимые тесты не наносят вред здоровью и благополучию кошек (в научном центре не собирается кровь у животных и не проводятся какие-либо другие мероприятия, которые могли бы причинить боль животным).`}
                    />
                </div>
            </div>
        )
    }
}
