import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from "../../../image/lessons/lesson24/step10-1.png";
import img2 from "../../../image/lessons/lesson24/step10-2.png";
import img3 from "../../../image/lessons/lesson24/step10-3.png";
import img4 from "../../../image/lessons/lesson24/step10-4.png";
import img5 from "../../../image/lessons/lesson24/step10-5.png";
import img6 from "../../../image/lessons/lesson24/step10-6.png";

export default class step10 extends React.Component {

    render() {
        return (
            <div className="step10">
                <TextBlock
                    text={`Блюда Sheba представлены <span class="text_sheba">6 различными</span> текстурами, в каждой из которых – изысканные сочетания вкусов.`}
                />
                <div className="flex">
                    <img src={img1} alt="" className="flex_img"/>
                    <img src={img2} alt="" className="flex_img"/>
                    <img src={img3} alt="" className="flex_img"/>
                    <img src={img4} alt="" className="flex_img"/>
                    <img src={img5} alt="" className="flex_img"/>
                    <img src={img6} alt="" className="flex_img"/>
                </div>
            </div>
        )
    }
}
