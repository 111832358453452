import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson21/step13-1.png";
import DefinitionBlock from "../../../Components/LessonAndTestComponents/DefinitionBlock";

export default class step13 extends React.Component {

    render() {
        return (
            <div className="step13">
                <TextBlock
                    text={`Однако не все хищники одинаковы. В отличие от тех, кто может питаться как мясом животных, так и растительной пищей, так как обладают способностью переваривать и извлекать необходимые питательные вещества, включая белки, из бобовых, злаков и овощей, кошки - исключительные (облигатные*) хищники и истинные плотоядные.`}
                />
                <div className="flex">
                    <img src={img1} alt="" className="flex_img"/>
                    <DefinitionBlock
                        text={`<span class="text_pink">*Облигатный —</span> термин, используемый для описания организмов, которые ограничиваются каким-либо одним характерным для них способом существования.`}
                    />
                </div>
            </div>
        )
    }
}
