import React from 'react'
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";

export default class step1 extends React.Component {

    render() {
        return (
            <div className="step1">
                <div className="box">
                    <VideoBlock
                        src={'https://www.youtube.com/embed/21rV-8Pr3Rs'}
                        title={'https://www.youtube.com/watch?v=21rV-8Pr3Rs&feature=emb_logo'}
                    />
                </div>
            </div>
        )
    }
}
