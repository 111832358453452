import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';

export default class step1 extends React.Component {

    planData = [
        `Как определить энергетические потребности собак и кошек`,
        `Что такое энергетический баланс`,
        `Как определить энергетическую ценность корма`,
        `Как оценить физическое состояние кошек и собак`,
    ]

    render() {
        return (
            <div className="step1">
                <div className="head">План урока</div>

                <TextBlock
                    text={`В этом уроке рассматриваются основные понятия, связанные с потреблением энергии у животных.
                    
                    Вы узнаете:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'blue'}
                />

            </div>
        )
    }
}
