import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson7/step6-1.png";

export default class step6 extends React.Component {

    render() {
        return (
            <div className="step6">
                <TextBlock
                    text={`Рацион кошек в дикой природе составляют мыши, кролики, птицы и иногда рептилии. Все эти животные достаточно маленького размера, поэтому кошки охотятся и едят так часто. Из жертвы кошки получают необходимые питательные вещества, а также воду.
                    
                    Питательный состав влажных кормов очень близок к составу рациона кошек в природе. Для примера рассмотрим самый популярный корм для кошек — <span class="text_whiskas">влажный корм WHISKAS<sup>®</sup></span>.`}
                />
                <img src={img1} alt="" className="img"/>
                <div className="advice">
                    Из сравнения видно, что готовый влажный корм для кошек максимально похож по составу на пищу кошек в дикой природе и удовлетворяет их пищевые потребности.
                </div>
            </div>
        )
    }
}
