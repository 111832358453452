import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import img1 from "../../../image/lessons/lesson8/step19-1.png";

export default class step19 extends React.Component {

    render() {
        return (
            <div className="step19">
                <TextBlock
                    text={`У кошек и собак, как и у людей, ожирение принято связывать с угрозой здоровью, и в большинстве случаев оно действительно представляет такую угрозу — от элементарных неудобств до тяжелых патологических состояний, сопряженных с риском для жизни.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
                <InfoBlock
                    text={`Рассмотрите схему. На ней показаны риски, появляющиеся у животных в связи с ожирением.`}
                />
            </div>
        )
    }
}
