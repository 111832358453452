import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickTest from '../../../Components/LessonAndTestComponents/OnClickTest';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import img1 from '../../../image/lessons/lesson1/step2-1.png'
import img2 from '../../../image/lessons/lesson1/step2-2.png'
import img3 from '../../../image/lessons/lesson1/step2-3.png'
import img4 from '../../../image/lessons/lesson1/step2-4.png'
import img5 from '../../../image/lessons/lesson1/step2-5.png'
import img6 from '../../../image/lessons/lesson1/step2-6.png'
import img7 from '../../../image/lessons/lesson1/step2-7.png'



export default class step2 extends React.Component {

    state = {
        answersData: {
            1: {
                img: img1,
                text: `Помощь в охоте`
            },
            2: {
                img: img2,
                text: `Помощь в выпасе и защите скота`
            },
            3: {
                img: img3,
                text: `Охрана жилища`
            },
            4: {
                img: img4,
                text: `Средство передвижения`
            },
            5: {
                img: img5,
                text: `Спорт и активный отдых`
            },
            6: {
                img: img6,
                text: `Теплые отношения`
            },
            7: {
                img: img7,
                text: `Терапия с участием животных`
            },
        }

    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step2">
                <TextBlock
                    text={`Собаки всегда помогали людям и облегчали их жизнь. Люди выводили новые породы для определенных целей. Что же хорошего собаки делают для нас?`}
                />
                <InfoBlock
                    text={`Выберите вариант ответа и нажмите «Подтвердить».`}
                />
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'img'}
                    rightAnswers={[1, 2, 3, 4, 5, 6, 7]}
                    showNextBtn={this.showNextBtn}
                    correctText={`Домашние собаки выполняют все эти функции, собаки начали приносить пользу людям сразу после того, как были одомашнены.
                    
                    Далее вы узнаете, как происходил процесс одомашнивания.`}
                />
            </div>
        )
    }
}
