import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson13/step3-1.png';
import img2 from '../../../image/lessons/lesson13/step3-2.jpg';


export default class step3 extends React.Component {
    state = {
    };

    render() {
        return (
            <div className="step3">
                <TextBlock
                    text={`Ответственные производители кормов не просто занимаются их производством, но и постоянно <b>отслеживают его качество.</b> Они следят за тем, чтобы продукт был <b>сбалансированным</b> и соответствовал <b>современным научным стандартам.</b>`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <img className="flex_img" src={img2} alt=""/>
                    </div>
                    <div className="flex_el">
                        <img className="img_block" src={img1} alt=""/>
                        <TextBlock
                            text={`Крупные производители кормов имеют собственные научные центры по изучению потребностей животных, чтобы гарантировать максимальную пользу своих продуктов.
                            
                            Например, компания Mars осуществляет научные разработки <span class="text_dark-blue">в научно-исследовательском институте Waltham</span> - мировом лидере в области ухода за домашними животными.`}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
