import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson16/step20-1.png';
import img1xs from "../../../image/lessons/lesson16/step20-1xs.png";
import img2 from '../../../image/lessons/lesson16/step20-2.png';
import img2xs from '../../../image/lessons/lesson16/step20-2xs.png';

export default class step20 extends React.Component {

    render() {
        return (
            <div className="step20">
                <TextBlock
                    text={`Категория кормов уже является привычной для многих владельцев животных, поэтому <span class="text_blue">корма для животных часто находятся в одной корзине вместе с продуктами питания:</span>`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} className="imgCenter" alt=""/>
                </picture>
                <TextBlock
                    text={`И, в целом, доля чеков с кормами может занимать <span class="text_blue">≈10% от общего количества чеков в магазине:</span>`}
                />
                <picture>
                    <source srcSet={img2xs} media="(max-width: 767px)"/>
                    <img src={img2} className="imgCenter" alt=""/>
                </picture>
            </div>
        )
    }
}
