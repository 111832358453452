import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

export default class step17 extends React.Component {

    state = {
        flexActive: 0,
    };

    changeFlex = flexActive => {
        this.setState({flexActive})
        this.props.showNextBtn()
    }

    render() {
        const {flexActive} = this.state
        return (
            <div className="step17">
                <div className="head">
                    Шаг №2: Рассчитать калорийность кормов, проданных на территории России в год.
                </div>
                <InfoBlock
                    text={`Нажмите на ячейку, чтобы узнать ответ.`}
                />
                <div className={'flex'}>
                    <div className="flex_el flex_el_inactive">
                        Калорийность 1 гр корма разных технологий (сухого, влажного, лакомств)
                    </div>
                    <div className="flex_el_sign">x</div>
                    <div className={'flex_el flex_el_inactive'}>
                        Годовой объем рынка каждой технологии (в тоннах)
                    </div>
                    <div className="flex_el_sign">=</div>
                    <div className={'flex_el total ' + (flexActive === 1 && 'hidden')} onClick={() => {this.changeFlex(1)}}>
                        Фактический объем калорий, который животные употребили в год
                        <p className={'flex_el_click left'}>
                            Нажмите
                        </p>
                        <p className={'flex_el_click right'}>
                            на ячейку
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}
