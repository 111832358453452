import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from "../../../image/lessons/lesson8/step25-1.png";

export default class step25 extends React.Component {

    render() {
        return (
            <div className="step25">
                <TextBlock
                    text={`Во избежание пищевых расстройств и отказа от нового корма, переводить питомца на новый корм нужно постепенно.
                    
                    <b>Перед вами образец перевода животного на новый корм:</b>`}
                />
                <img src={img1} alt="" className="imgCenter"/>
                <TextBlock
                    text={`<span class="text_blue">Оптимальное решение:</span> в течение 4-7 дней увеличивать соотношение нового корма к старому.`}
                />
            </div>
        )
    }
}
