import React from 'react'

import img1 from '../../../image/lessons/lesson24/step2-1.png'

export default class step2 extends React.Component {


    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step2">
                <div className="flex">
                    <div className="flex_el">
                        <ul className="list">
                            <li className="list_item">
                                Кошка обладает удивительной способностью получать от вас все, что ей нужно.
                            </li>
                            <li className="list_item">
                                Ей достаточно просто посмотреть на вас, и вы уже во власти ее чар. Вы сделаете все, что она пожелает.
                            </li>
                            <li className="list_item">
                                Когда кошка хочет Sheba<sup>®</sup> , сопротивление бесполезно.
                            </li>
                        </ul>
                    </div>
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                </div>

            </div>
        )
    }
}
