import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import DefinitionBlock from '../../../Components/LessonAndTestComponents/DefinitionBlock';

import img1 from "../../../image/lessons/lesson8/step16-1.png";

export default class step16 extends React.Component {

    render() {
        return (
            <div className="step16">
                <div className="text">
                    Как вы уже знаете, рекомендации по кормлению, указанные на упаковке корма, являются лишь ориентиром. Хозяин должен постоянно следить за общим состоянием питомца путем оценки упитанности.
                    <img src={img1} alt="" className="text_img"/>
                </div>
                <DefinitionBlock
                    text={`<span class="text_blue">Количественная оценка упитанности</span> — очень полезный технический прием, позволяющий определить кондицию тела и отследить ее изменения с течением времени.`}
                />
                <TextBlock
                    text={`Чтобы оценить упитанность животного, нужно посмотреть на него с двух ракурсов:`}
                />
                <div className="list">
                    <div className="list_item">вид сверху;</div>
                    <div className="list_item">вид сбоку в профиль.</div>
                </div>
                <TextBlock
                    text={`Стоит обратить внимание, насколько сильно выступают грудная клетка и позвоночник и насколько легко их почувствовать наощупь. Осмотр питомца позволит сделать полезные выводы и скорректировать его питание.`}
                />
            </div>
        )
    }
}
