import React from 'react'
import './style.sass'

export default props => {
    return (
        <div
            className={'TextBlock ' + props.hidden}
            dangerouslySetInnerHTML={{ __html: props.text }}
        />
    )
}
