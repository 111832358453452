import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson9/step21-1.png'


export default class step21 extends React.Component {


    render() {
        return (
            <div className="step21">
                <TextBlock
                    text={`Перед вами рекомендуемая схема кормления щенков рационами PEDIGREE<sup>®</sup>. Изучите её для того, чтобы правильно подбирать рационы для щенков с учетом их возраста и размерной группы.`}
                />
                <img className="img" src={img1} alt=""/>
            </div>
        )
    }
}
