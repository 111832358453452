import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson7/step23-1.png";
import img2 from "../../../image/lessons/lesson7/step23-2.png";

export default class step23 extends React.Component {

    render() {
        return (
            <div className="step23">
                <TextBlock
                    text={`Каждый из видов корма имеет свои преимущества. И сухой, и влажный корм являются полноценными сбалансированными рационами.
                    
                    Сравните особенности сухого и влажного корма.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Сухой корм
                        </div>
                        <div className="flex_el_box">
                            <ul className="list">
                                <li className="list_item">Содержит меньше влаги.</li>
                                <li className="list_item">Содержит клетчатку для пищеварения.</li>
                                <li className="list_item">Высокая энергетическая и питательная ценность.</li>
                                <li className="list_item">Долго и удобно хранится.</li>
                                <li className="list_item">В одной упаковке содержится несколько порций, поэтому нужно измерять размер порции в соответствии с рекомендациями.</li>
                            </ul>
                            <img src={img1} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Влажный корм
                        </div>
                        <div className="flex_el_box">
                            <ul className="list">
                                <li className="list_item">Содержит большое количество жидкости.</li>
                                <li className="list_item">Сниженная энергетическая ценность в одном пауче.</li>
                                <li className="list_item">Разнообразие текстур и вкусов.</li>
                                <li className="list_item">Порционный формат.</li>
                            </ul>
                            <img src={img2} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
