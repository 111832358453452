import React from 'react'

import img1 from '../../../image/lessons/lesson15/step9-1.png'
import img2 from '../../../image/lessons/lesson15/step9-2.png'
import img3 from '../../../image/lessons/lesson15/step6-6.png'
import img4 from '../../../image/lessons/lesson15/step9-3.png'
import img5 from '../../../image/lessons/lesson15/step6-5.png'
import img6 from '../../../image/lessons/lesson15/step7-4.png'


export default class step9 extends React.Component {

    render() {
        return (
            <div className="step9">
                <div className="flex">
                    <div className="flex_el left">
                        <img src={img1} alt="" className="flex_el_img"/>
                        <div className="flex_el_town">
                            Характерно для следующих городов\округов:
                        </div>
                        <div className="flex_el_box">
                            <img src={img3} alt="" className="flex_el_box_img"/>
                            <div className="flex_el_box_text">
                                Города с населением более 1 млн человек
                            </div>
                        </div>
                        <div className="flex_el_box">
                            <img src={img4} alt="" className="flex_el_box_img"/>
                            <div className="flex_el_box_text">
                                Сельские поселения
                            </div>
                        </div>
                        <div className="flex_el_box">
                            <img src={img5} alt="" className="flex_el_box_img"/>
                            <div className="flex_el_box_text">
                                Юг\Кавказ, Приволжье
                            </div>
                        </div>
                    </div>
                    <div className="flex_el right">
                        <img src={img2} alt="" className="flex_el_img"/>
                        <div className="flex_el_description">
                            <div className="flex_el_description_point yellow"/>
                            <div className="flex_el_description_text">Москва / Санкт-Петербург</div>
                        </div>
                        <div className="flex_el_description">
                            <div className="flex_el_description_point blue"/>
                            <div className="flex_el_description_text">Города с населением более 1 млн человек</div>
                        </div>
                        <div className="flex_el_description">
                            <div className="flex_el_description_point green"/>
                            <div className="flex_el_description_text">500-999 тыс. человек</div>
                        </div>
                        <div className="flex_el_description">
                            <div className="flex_el_description_point black"/>
                            <div className="flex_el_description_text">100-499 тыс. человек</div>
                        </div>
                        <div className="flex_el_description">
                            <div className="flex_el_description_point dark-blue"/>
                            <div className="flex_el_description_text">10-99 тыс. человек</div>
                        </div>
                        <div className="flex_el_description">
                            <div className="flex_el_description_point blue"/>
                            <div className="flex_el_description_text">Сельские поселения</div>
                        </div>
                        <div className="flex_el_description">
                            <div className="flex_el_description_point green">
                                <img src={img6} alt="" className="block_box_el_point_img"/>
                            </div>
                            <div className="flex_el_description_text">Самый активный рост популяции</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
