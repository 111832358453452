import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import img1 from '../../../image/lessons/lesson19/step7-1.png';
import img2 from '../../../image/lessons/lesson19/step8-1.png';
import img3 from '../../../image/lessons/lesson19/step8-2.png';
import img3xs from '../../../image/lessons/lesson19/step8-2xs.png';
import img4 from '../../../image/lessons/lesson19/step8-3.png';
import img4xs from '../../../image/lessons/lesson19/step8-3xs.png';
import img5 from '../../../image/lessons/lesson19/step8-4.png';
import img5xs from '../../../image/lessons/lesson19/step8-4xs.png';
import img6 from '../../../image/lessons/lesson19/step8-5.png';
import img6xs from '../../../image/lessons/lesson19/step8-5xs.png';

export default class step8 extends React.Component {
    state = {
        flexActive: 0,
        flexs: [1],
        flexHidden: false,
    };

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }
        this.setState({flexActive, flexs, flexHidden: true})
        if (flexs.length === 4) {
            this.props.showNextBtn()
        }
    }

    render() {
        const {flexActive, flexHidden} = this.state
        return (
            <div className="step8">
                <InfoBlock
                    text={`Нажмите на каждое из пяти слагаемых здоровья и узнайте благодаря каким особенностям состава корма Perfect Fit™ они достигаются.`}
                    hidden={flexHidden === true ? 'hidden' : ''}
                />
                <div className="box">
                    <div className="img-box">
                        <img src={img1} alt="" className="img_active"/>
                        <div className={'flex ' + (flexHidden === true && 'hidden')}>
                            <div className={'flex_el pink ' + (flexActive === 1 && 'active')} onClick={() => {
                                this.changeFlex(1)
                            }}></div>
                            <div className={'flex_el orange ' + (flexActive === 2 && 'active')} onClick={() => {
                                this.changeFlex(2)
                            }}></div>
                            <div className={'flex_el green ' + (flexActive === 3 && 'active')} onClick={() => {
                                this.changeFlex(3)
                            }}></div>
                            <div className={'flex_el yellow ' + (flexActive === 4 && 'active')} onClick={() => {
                                this.changeFlex(4)
                            }}></div>
                            <div className={'flex_el blue ' + (flexActive === 5 && 'active')} onClick={() => {
                                this.changeFlex(5)
                            }}></div>
                        </div>
                    </div>
                    {flexActive === 0 &&
                    <img className="imgBg" src={img2} alt=""/>
                    }
                    {flexActive !== 0 &&
                    <div className={'imgDiv ' + (flexActive === 2 ? 'imgDiv--2' : flexActive === 3 ? 'imgDiv--3': flexActive === 4 ? 'imgDiv--4' : '')}>
                        {flexActive === 1 &&
                        <div className="imgDiv_wrap">
                            <div className="imgDiv_head pink">Поддержание иммунитета</div>
                            <TextBlock
                                text={`Повышенное содержание <b>витамина Е</b>, марганца и цинка для поддержания сильной естественной защиты`}
                            />
                            <picture>
                                <source srcSet={img3xs} media="(max-width: 767px)"/>
                                <img src={img3} alt="" className="img_popup"/>
                            </picture>
                        </div>
                        }
                        {flexActive === 2 &&
                        <div className="imgDiv_wrap">
                            <div className="imgDiv_head orange">Здоровье зубов</div>
                            <TextBlock
                                text={`Сухая текстура корма и специальный компонент <b>(STTP)</b> способствуют снижению образования зубного камня и поддержания здоровья зубов.`}
                            />
                        </div>
                        }
                        {flexActive === 3 &&
                        <div className="imgDiv_wrap">
                            <div className="imgDiv_head green">Здоровое пищеварение</div>
                            <TextBlock
                                text={`Содержит высококачественные белки, которые легко усваиваются, и пребиотики (экстракт цикория) для поддержания здоровой микрофлоры кишечника.`}
                            />
                            <picture>
                                <source srcSet={img4xs} media="(max-width: 767px)"/>
                                <img src={img4} alt="" className="img_popup"/>
                            </picture>
                        </div>
                        }
                        {flexActive === 4 &&
                        <div className="imgDiv_wrap">
                            <div className="imgDiv_head yellow">Здоровая кожа и шерсть</div>
                            <TextBlock
                                text={`Содержит цинк и подсолнечное масло – натуральный источник <b>Омега 6 жирных кислот</b>, способствующих поддержанию здоровья кожи и шерсти`}
                            />
                            <picture>
                                <source srcSet={img5xs} media="(max-width: 767px)"/>
                                <img src={img5} alt="" className="img_popup"/>
                            </picture>
                        </div>
                        }
                        {flexActive === 5 &&
                        <div className="imgDiv_wrap">
                            <div className="imgDiv_head blue">Контроль веса</div>
                            <TextBlock
                                text={`Формула содержит все необходимые <b>нутриенты</b> для поддержания активности и энергии собаки, что способствует сохранению оптимального веса и хорошей физической формы <b>(согласно рекомендациям WALTHAM™)</b>`}
                            />
                            <picture>
                                <source srcSet={img6xs} media="(max-width: 767px)"/>
                                <img src={img6} alt="" className="img_popup"/>
                            </picture>
                        </div>
                        }
                    </div>
                    }
                </div>
            </div>
        )
    }
}
