import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson15/step25-1.png'

export default class step5 extends React.Component {

    render() {
        return (
            <div className="step25">
                <TextBlock
                    text={`Источники роста категории кормов для животных отличаются как внутри сегментов (кошки-собаки), так и по географическим сегментам.`}
                />
                <div className="flex">
                    <img className="flex_img" src={img1} alt=""/>
                    <div className="flex_text">
                        Давайте сыграем в игру, чтобы лучше разобраться
                    </div>
                </div>
            </div>
        )
    }
}
