import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson32/step49-1.png';
import img2 from '../../../image/lessons/lesson32/step49-2.png';


export default class step49 extends React.Component {

    render() {
        return (
            <div className="step49">
                <TextBlock
                    text={`В основе эффективности лежит сочетание <span class="text_red">механического действия и благоприятного воздействия активных компонентов.</span>`}
                />
                <div className="head">
                    Механическое воздействие
                </div>
                <TextBlock
                    text={`Эффективность механической чистки зубов заключается в особой текстуре, которая способствует чистке зубов и массажу десен, а также стимулирует выделение слюны и удаление с её помощью остатков пищи.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_img-box">
                            <img src={img1} alt="" className="flex_el_img"/>
                        </div>
                        {/*<div className="flex_el_head">*/}
                        {/*    ТРИПОЛИФОСФАТ НАТРИЯ*/}
                        {/*</div>*/}
                        <div className="flex_el_text">
                            <b>Х-образная форма</b>, созданная с учетом особенности строения ротовой полости собаки, <b>и специальная текстура</b> способствуют <b>механической чистке зубов и массажу дёсен.</b>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_img-box">
                            <img src={img2} alt="" className="flex_el_img"/>
                        </div>
                        {/*<div className="flex_el_head">*/}
                        {/*    СУЛЬФАТ ЦИНКА*/}
                        {/*</div>*/}
                        <div className="flex_el_text">
                            Разгрызание в течение долгого времени PEDIGREE<sup>®</sup> DENTASTIX™ <b>стимулирует выделение слюны и удаление остаткой пищи.</b>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
