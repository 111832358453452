import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import ArrowBack from '../../../Components/LessonAndTestComponents/ArrowBack';

import img1 from '../../../image/lessons/lesson1/step18-1.png';
import img1_1 from '../../../image/lessons/lesson1/step18-1-1.png';
import img2 from '../../../image/lessons/lesson1/step18-2.png';
import img2_1 from '../../../image/lessons/lesson1/step18-2-1.png';
import img3 from '../../../image/lessons/lesson1/step18-3.png';
import img3_1 from '../../../image/lessons/lesson1/step18-3-1.png';
import img4 from '../../../image/lessons/lesson1/step18-4.png';
import img4_1 from '../../../image/lessons/lesson1/step18-4-1.png';
import img5 from '../../../image/lessons/lesson1/step18-5.png';
import img5_1 from '../../../image/lessons/lesson1/step18-5-1.png';
import img6 from '../../../image/lessons/lesson1/step18-6.png';
import img6_1 from '../../../image/lessons/lesson1/step18-6-1.png';
import img7 from '../../../image/lessons/lesson1/step18-7.png';
import img7_1 from '../../../image/lessons/lesson1/step18-7-1.png';
import img8 from '../../../image/lessons/lesson1/step18-8.png';
import img8_1 from '../../../image/lessons/lesson1/step18-8-1.png';
import img9 from '../../../image/lessons/lesson1/step18-9.png';
import img9_1 from '../../../image/lessons/lesson1/step18-9-1.png';
import img10 from '../../../image/lessons/lesson1/step18-10.png';
import img10_1 from '../../../image/lessons/lesson1/step18-10-1.png';


export default class step18 extends React.Component {
    state = {
        flexActive: 0,
        flexs: [1],
        flexHidden: false,
    };

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }
        this.setState({flexActive, flexs, flexHidden: true})
        if (flexs.length === 11) {
            this.props.showNextBtn()
        }
    }

    closeImgDiv = () => {this.setState({flexHidden: false, flexActive: 0})}

    breedData = {
        0: {
            img: '',
            breed: '',
            divHead: ``,
            divList: [''],
            divImg: '',
        },
        1: {
            img: img1,
            breed: 'Немецкая овчарка',
            divHead: `55–68 см, 25–40 кг`,
            divList: [
                `Умная и внимательная`,
                `Легко обучается`,
                `Спокойная собака`,
            ],
            divImg: img1_1,
        },
        2: {
            img: img2,
            breed: 'Той-терьер',
            divHead: `20–28 см, 2–3 кг`,
            divList: [
                `Веселый и дружелюбный`,
                `Покладистый и послушный`,
                `Преданный, отважный и выносливый`,
            ],
            divImg: img2_1,
        },
        3: {
            img: img3,
            breed: 'Йоркширский терьер',
            divHead: `15–20 см, 1,5–3,2 кг`,
            divList: [
                `Любопытный и активный`,
                `Подвержен мании величия`,
                `Требует тщательного ухода за шерстью`,
            ],
            divImg: img3_1,
        },
        4: {
            img: img4,
            breed: 'Чихуахуа',
            divHead: `15–20 см, 1–3 кг`,
            divList: [
                `Воображает себя огромным псом`,
                `Своенравный, но привязчивый`,
                `Ему необходимо участвовать во всех делах семьи`,
            ],
            divImg: img4_1,
        },
        5: {
            img: img5,
            breed: 'Кавказская овчарка',
            divHead: `66–75 см, 45–75 кг`,
            divList: [
                `Преданная и бесстрашная`,
                `Выносливая, самостоятельная`,
                `Может быть агрессивна к людям и собакам`,
            ],
            divImg: img5_1,
        },
        6: {
            img: img6,
            breed: 'Такса',
            divHead: `15–20 см, 4,5–12 кг`,
            divList: [
                `Умная и хитрая`,
                `Привязчива и забавна`,
                `Дрессировку необходимо начинать в раннем возрасте`,
            ],
            divImg: img6_1,
        },
        7: {
            img: img7,
            breed: 'Западно-сибирская лайка',
            divHead: `55–62 см, 18–23 кг`,
            divList: [
                `Отлично развиты охотничьи инстинкты`,
                `Красивая и очень выносливая`,
                `Предана хозяину на все 100%`,
            ],
            divImg: img7_1,
        },
        8: {
            img: img8,
            breed: 'Пекинес',
            divHead: `20–30 см, 3–5 кг`,
            divList: [
                `Самоуверен и настойчив в достижении желаемого`,
                `Дрессируется трудно`,
            ],
            divImg: img8_1,
        },
        9: {
            img: img9,
            breed: 'Среднеазиатская овчарка',
            divHead: `62–65 см, 40–80 кг`,
            divList: [
                `Самостоятельная, независимая и упрямая`,
                `Сильная и бесстрашная`,
                `Выносливая и неприхотливая`,
            ],
            divImg: img9_1,
        },
        10: {
            img: img10,
            breed: 'Лабрадор-ретривер',
            divHead: `55–57 см, 30–45 кг`,
            divList: [
                `Добродушный и общительный`,
                `Обожает плавать`,
                `Хорошо дрессируется, необходимо ограничивать питание`,
            ],
            divImg: img10_1,
        },
    }

    render() {
        const {flexActive, flexHidden} = this.state
        return (
            <div className="step18">
                <InfoBlock
                    text={`Нажмите на название породы, чтобы узнать о ней подробнее.
                    
                    Для продолжения, пожалуйста, ознакомьтесь со всей представленной информацией в этом шаге урока.`}
                    hidden={flexHidden === true ? ' hidden' : ''}
                />
                <div className={'flex ' + (flexActive !== 0 && 'active ') +  (flexHidden === true && ' hidden')}>
                    {Object.keys(this.breedData).filter(id => id > 0).map((item) => {
                        return (
                            <div
                                key={item}
                                className={'flex_el ' + (flexActive === (item) && 'active')}
                                onClick={() => {this.changeFlex(item)}}
                            >
                                <img className="flex_el_img" src={this.breedData[item].img} alt=""/>
                                <p className="flex_el_text">
                                    {this.breedData[item].breed}
                                </p>
                                <p className="flex_el_number">{item}</p>
                            </div>
                        )
                    })}
                </div>
                <div className={'imgDiv ' + (flexActive !== 0 && 'active')}>
                    <ArrowBack
                        className={flexActive === 0 ? 'hidden' : ''}
                        returnMenu={this.closeImgDiv}
                    />
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_flex">
                            <img className="imgDiv_img" src={this.breedData[flexActive].divImg} alt=""/>
                            <div className="imgDiv_box">
                                <p className="imgDiv_head">
                                    {this.breedData[flexActive].divHead}
                                </p>
                                <ul className="list">
                                    {this.breedData[flexActive].divList.map((item, index) => {
                                        return (
                                            <li
                                                key={index}
                                                className="list_item"
                                            >
                                                {item}
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
