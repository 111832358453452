import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from "../../../image/lessons/lesson10/step6-1.png";



export default class step6 extends React.Component {

    render() {
        return (
            <div className="step6">
                <TextBlock
                    text={`После отучения от материнского молока энергетические потребности котят превышают потребности взрослых кошек в 3–4 раза и <b>достигают пика в 10-недельном возрасте</b> котенка. Затем энергетические потребности начинают постепенно понижаться, хотя остаются относительно высокими.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
                <TextBlock
                    text={`На графике видно, что котята мужского пола растут быстрее, и к коменту взросления их вес значительно больше, чем у котят женского пола.
                    
                    В годовалом возрасте коты могут весить на 45% больше кошек, при прочих равных условиях.`}
                />
            </div>
        )
    }
}
