import React from 'react'
import OnClickTest from "../../../Components/LessonAndTestComponents/OnClickTest";
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

export default class step13 extends React.Component {

    state = {
        answersData: [
            `В каждой 2-й семье`,
            `В каждой 4-й семье`,
            `В каждой 5-й семье`,
        ],
    }

    showNextBtn = () => {
        this.props.showNextBtn()
        this.setState({showImg: true})
    }

    render() {
        return (
            <div className="step13">
                <TextBlock
                    text={`Как Вы думаете, в скольких семьях России есть домашние животные?`}
                />
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'line'}
                    rightAnswers={[1]}
                    showNextBtn={this.showNextBtn}
                    correctText={`Домашний питомец есть в каждой второй семье.`}
                />
            </div>
        )
    }
}
