import React from 'react'
import Resume from "../../../Components/LessonAndTestComponents/Resume";

import img1 from "../../../image/lessons/lesson7/step14-1.png";

export default class step14 extends React.Component {
    state = {
        resumeData: [
            `Собаки — <b>факультативные плотоядные.</b>`,
            `Так как предки собак охотились стаями и съедали за раз <b>большое количество пищи</b>, такое же пищевое поведение является характерным и для домашних собак.`,
            `Оптимальный режим питания для собак — <b>2 раза в день.</b>`,
        ]
    }

    render() {
        return (
            <div className="step14">
                <Resume
                    resumeData={this.state.resumeData}
                    img={img1}
                />
            </div>
        )
    }
}
