import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import DefinitionBlock from '../../../Components/LessonAndTestComponents/DefinitionBlock';

import img1 from '../../../image/lessons/lesson10/step4-1.jpg'

export default class step4 extends React.Component {

    render() {
        return (
            <div className="step4">
                <DefinitionBlock
                    text={`<span class="text_blue">Лактация</span> — самый энергозатратный период жизни кошки. Она должна достаточно есть, чтобы обеспечить энергией не только себя, но и своих котят.`}
                />
                <TextBlock
                    text={`<span class="text_blue">Энергетические потребности кошки во время лактации зависят от возраста и количества котят.</span>`}
                />
                <div className="flex">
                    <TextBlock
                        text={`Первые четыре недели жизни котята полностью зависят от материнского молока. Поэтому энергетические потребности кормящей кошки определяются в зависимости от размера помета и возраста котят. В пиковый период лактации потребности кошки могут в четыре раза превысить стандартную норму взрослой кошки.`}
                    />
                    <img src={img1} alt="" className="flex_img"/>
                </div>
            </div>
        )
    }
}
