import React from 'react'
import OnClickTest from "../../../Components/LessonAndTestComponents/OnClickTest";
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";

import img1 from "../../../image/lessons/lesson8/step5-1.png";


export default class step5 extends React.Component {

    state = {
        answersData: [`425г`, `360г`, `372г`, `нет верного ответа`]
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step5">
                <InfoBlock
                    text={`Сделайте расчеты и выберите ответ, который у вас получился.`}
                />
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'block'}
                    rightAnswers={[3]}
                    showNextBtn={this.showNextBtn}
                    img={img1}
                    correctText={`Убедитесь, что ваш процесс расчета был последовательным и правильным, посмотрев на верное решение.`}
                />
            </div>
        )
    }
}
