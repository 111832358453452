import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import List from '../../../Components/LessonAndTestComponents/List';

import img1 from '../../../image/lessons/lesson21/step18-1.png';
import img1pink from '../../../image/lessons/lesson21/step18-1pink.png';
import img2 from '../../../image/lessons/lesson21/step18-2.png';
import img2pink from '../../../image/lessons/lesson21/step18-2pink.png';
import img3 from '../../../image/lessons/lesson21/step18-3.png';
import img3pink from '../../../image/lessons/lesson21/step18-3pink.png';
import img4 from '../../../image/lessons/lesson21/step18-4.png';
import img4pink from '../../../image/lessons/lesson21/step18-4pink.png';
import img5 from '../../../image/lessons/lesson21/step18-5.png';
import img5pink from '../../../image/lessons/lesson21/step18-5pink.png';
import img6 from '../../../image/lessons/lesson21/step18-6.png';
import img7 from '../../../image/lessons/lesson21/step18-7.png';
import img8 from '../../../image/lessons/lesson21/step18-8.png';
import img9 from '../../../image/lessons/lesson21/step18-9.png';
import img10 from '../../../image/lessons/lesson21/step18-10.png';


export default class step18 extends React.Component {
    state = {
        flexActive: 0,
        flexs: [],
    }

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }
        this.setState({flexActive, flexs})
        if (flexs.length === 5) {
            this.props.showNextBtn()
        }
    }

    textActive = {
        1: {
            head: `Окружающая среда:`,
            img: img6,
            text: false,
            list: [
                `Музыкальная система,`,
                `Контролируемый микроклимат (температура и вентиляция)`,
            ]
        },
        2: {
            head: `Архитектурные и планировочные решения:`,
            img: img7,
            text: false,
            list: [
                `Ветеринарный блок,`,
                `Контролируемый микроклимат (температура и вентиляция)`,
            ]
        },
        3: {
            head: `Тренировки и упражнения:`,
            img: img8,
            text: `Сотрудники центра регулярно занимаются со своими питомцами, для того чтобы животные были готовы и умели себя вести при взаимодействии с людьми, при проведении ветеринарных манипуляций, знали, как кушать в кормовых станциях и в какой лоток ходить в туалет.`,
            list: false
        },
        4: {
            head: `Игры:`,
            img: img9,
            text: `Значительную часть рабочего дня наших сотрудников занимают игры с животными, наши кошки любят играть как с традиционными игрушками (такими как лазерная указка, дразнилки, шуршалки, мячики и пр), так и с современными (компьютерные игры, спиннеры). Игры проводятся как с группой животных, так и индивидуально.`,
            list: false
        },
        5: {
            head: `Общение:`,
            img: img10,
            text: false,
            list: [
                `сотрудники питомников;`,
                `экскурсии;`,
                `социально направленные мероприятия.`,
            ]
        },
    }


    render() {
        const {flexActive} = this.state
        return (
            <div className="step18">
                {/*<TextBlock*/}
                {/*    text={`Стабильный обмен веществ и крепкий иммунитет бывают только у тех собак, которые:`}*/}
                {/*/>*/}
                <InfoBlock text={`Нажмите на активные элементы, чтобы узнать больше о Центре.`}/>

                <div className="flex">
                    <div className={'flex_el ' + (flexActive === 1 && 'active')} onClick={() => {
                        this.changeFlex(1)
                    }}>
                        <div className="flex_wrp">
                            <div className="flex_img">
                                <img src={img1} className="flex_img_white" alt=""/>
                                <img src={img1pink} className="flex_img_pink" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className={'flex_el ' + (flexActive === 2 && 'active')} onClick={() => {
                        this.changeFlex(2)
                    }}>
                        <div className="flex_wrp">
                            <div className="flex_img">
                                <img src={img2} className="flex_img_white" alt=""/>
                                <img src={img2pink} className="flex_img_pink" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className={'flex_el ' + (flexActive === 3 && 'active')} onClick={() => {
                        this.changeFlex(3)
                    }}>
                        <div className="flex_wrp">
                            <div className="flex_img">
                                <img src={img3} className="flex_img_white" alt=""/>
                                <img src={img3pink} className="flex_img_pink" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className={'flex_el ' + (flexActive === 4 && 'active')} onClick={() => {
                        this.changeFlex(4)
                    }}>
                        <div className="flex_wrp">
                            <div className="flex_img">
                                <img src={img4} className="flex_img_white" alt=""/>
                                <img src={img4pink} className="flex_img_pink" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className={'flex_el ' + (flexActive === 5 && 'active')} onClick={() => {
                        this.changeFlex(5)
                    }}>
                        <div className="flex_wrp">
                            <div className="flex_img">
                                <img src={img5} className="flex_img_white" alt=""/>
                                <img src={img5pink} className="flex_img_pink" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
                {flexActive !== 0 &&
                <div className={'textDiv ' + (flexActive !== 1 ? `textDiv--${flexActive}` : '')}>
                    <div className="textDiv_head">
                        {this.textActive[flexActive].head}
                    </div>
                    <div className="textDiv_flex">
                        <div className="textDiv_flex_el">
                            <img src={this.textActive[flexActive].img} alt="" className="textDiv_flex_img"/>
                        </div>
                        <div className="textDiv_flex_el">
                            {this.textActive[flexActive].text &&
                                <TextBlock
                                    text={this.textActive[flexActive].text}
                                />
                            }
                            {this.textActive[flexActive].list &&
                                <List
                                    ListData={this.textActive[flexActive].list}
                                />
                            }
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }
}