import React from 'react'
import Resume from "../../../Components/LessonAndTestComponents/Resume";

import img1 from "../../../image/lessons/lesson1/step9-1.png";


export default class step9 extends React.Component {


    state = {
        resumeData: [
            `Кошки были одомашнены человеком <b>около 9500 лет назад.</b>`,
            `Предок домашней кошки – <b>африканская степная кошка.</b>`,
            `<b>На генетическом уровне кошки изменились менее значительно,</b> чем собаки, поэтому они имеют меньше различий между собой.`,
        ]
    }

    render() {
        return (
            <div className="step9">
                <Resume
                    resumeData={this.state.resumeData}
                    img={img1}
                />
            </div>
        )
    }
}
