import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson8/step11-1.png";

export default class step11 extends React.Component {

    render() {
        return (
            <div className="step11">
                <TextBlock
                    text={`Рассмотрите таблицу. На ней показано, каким образом кормление животного пищей для людей влияет на суточное потребление калорий животным. Некоторые из этих популярных угощений составляют более половины суточного рациона животного!`}
                />
                <img src={img1} alt="" className="imgCenter"/>
                <TextBlock
                    text={`<span class="text_blue">Можно сделать вывод, что частое угощение животных едой со стола ведет к ожирению и последующим проблемам со здоровьем.</span>`}
                />
            </div>
        )
    }
}
