import React from 'react';
import { withRouter } from 'react-router-dom';


import Main from './Navigation/main';
import Entrance from './Navigation/Entrance';

import './Page/pageStyle.sass'

import LeftSideBar from "./Components/LeftSideBar";
import Api from './Service/Api'
import GuruPanel from "./Components/GuruPanel";
import SiteFooter from "./Components/SiteFooter";
import Preloader from "./Components/Preloader";

class App extends React.Component {
    state = {
        isGuest: undefined,
        showGuru: true,
        user: undefined,
        status: '',
        preloader: true,
    };
    Api = new Api()


    componentDidMount() {
        if (process.env.NODE_ENV !== 'development') {
            this.Api.isGuest()
                .then(isGuest => {
                    this.setState({isGuest}, () => {
                        if (!isGuest) {
                            this.updateUserDate()
                        }
                    })
                })
        }else{
            this.setState({
                // preloader: false,
                isGuest: true
            }, () => {
                this.updateUserDate()
            })

            // this.props.history.push('/PPClubVideo');
            //this.props.history.push('/startPage/perekrestok');
            //this.props.history.push('/startPage/ppclub');
            this.props.history.push('/startPage');
            // this.props.history.push(this.props.location.pathname);

        }
        this.unlisten = this.props.history.listen( location =>  {
            this.updateUserDate()
        });
        // this.setState({preloader: false})
    }
    componentWillUnmount() {
        this.unlisten();

    }

    updateUserDate = () =>{
        const {isGuest} = this.state
        let route = this.props.history.location.pathname
        if(isGuest === false) {
            if (route.indexOf('/PPCVideo') >= 0 || route.indexOf('/PPClubVideo') >= 0 || route.indexOf('/myTraining') >= 0 || route.indexOf('/profileCard') >= 0 || route.indexOf('/myTesting') >= 0 || (route.indexOf('/') >= 0  && route.length === 1)) {
                this.Api.getUserData()
                    .then(user => {
                        this.setState({user})
                        this.setState({status: user.user.is_vip})
                        this.setState({preloader: false})
                    })

            }
        }
    }

    changeType = (newPage) => {
        this.Api.isGuest()
            .then(isGuest => {
                this.setState({isGuest})
                if(isGuest /* && this.props.history.location.pathname.indexOf('startPage') === -1*/){
                    if (newPage) {
                        newPage === 'Blue' ?this.props.history.push('/startPage/ppclub') : newPage === 'pyaterochka' ? this.props.history.push('/startPage/Pyaterochka') : newPage === 'perekrestok' ? this.props.history.push('/startPage/Perekrestok') : this.props.history.push('/startPage');
                    } else {
                        this.props.history.push('/startPage')
                    }
                }else{
                    newPage === 'Blue' ? this.props.history.push('/PPClubVideo') : this.props.history.push('/myTraining');
                }
            })
    }

    hidePreloader = () => {
        this.setState({preloader: false})
    }



    render() {
        const {isGuest, status, preloader} = this.state
        const shortBg = document.location.pathname === '/PPClubVideo' ? 'short_bg' : ''
        if(isGuest === undefined) {
            return(<div/>)
        }
        let route = this.props.history.location.pathname
        let showGuru = true;
        if(route.indexOf('/faq') >= 0 || route.indexOf('/lesson') >= 0 || route.indexOf('/test') >=0 || route.indexOf('/PPClubVideo') >= 0){
            showGuru = false
        }
        return (
            <div>
                <Preloader hidePreloader={!preloader}/>
                {/*Проверка*/}
                {isGuest ?
                    <Entrance changeType={this.changeType} hidePreloader={this.hidePreloader}/>
                    : <div>
                        <LeftSideBar user={this.state.user} status={status} changeType={this.changeType}/>
                        <div className={`wrp ${status} ${shortBg}`}>
                            <div className={status ? "content content_withLeftSideBar blue" : "content content_withLeftSideBar"}>
                                {showGuru &&
                                <GuruPanel user={this.state.user} status={status}/>
                                }
                                <div className={'content_wrp ' + (showGuru && 'content_wrpGuru')} style={status ? {backgroundColor: 'transparent'} : {backgroundColor: '#E6EFF7'}}>
                                    <Main status={status} hidePreloader={this.hidePreloader}/>
                                </div>
                                <SiteFooter/>
                            </div>
                        </div>
                    </div>

                }
            </div>   
        )
    }
}

export default withRouter(App);

