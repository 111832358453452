import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson32/step6-1.png';
import img2 from '../../../image/lessons/lesson32/step6-2.png';
import img3 from '../../../image/lessons/lesson32/step6-3.png';
import img4 from '../../../image/lessons/lesson32/step6-4.png';


export default class step6 extends React.Component {

    state = {
        pointActive: 0,
        points: [0],
    };


    clickPoint = pointActive => {
        const {points} = this.state
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }
        this.setState({pointActive, points})

        if (points.length === 5) {
            this.props.showNextBtn();
        }
    }

    render() {
        const {points} = this.state
        return (
            <div className="step6">
                <TextBlock
                    text={`Рационы Pedigree поддерживают здоровье собак по 4 основным показателям здоровья:`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <img src={img1} alt="" className={'flex_el_img ' + (points.indexOf(1) >= 0 && 'active')} onClick={() => {this.clickPoint(1)}}/>
                        <div className={'flex_el_block ' + (points.indexOf(1) >= 0 && 'active')}>
                            <div className="flex_el_head">
                                Оптимальное пищеварение
                            </div>
                            <div className="flex_el_description">
                                Клетчатка, витамины и минералы, высококачественный белок
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <img src={img2} alt="" className={'flex_el_img ' + (points.indexOf(2) >= 0 && 'active')} onClick={() => {this.clickPoint(2)}}/>
                        <div className={'flex_el_block ' + (points.indexOf(2) >= 0 && 'active')}>
                            <div className="flex_el_head">
                                Здоровье кожи и шерсти
                            </div>
                            <div className="flex_el_description">
                                Омега-6 и цинк, высококачественный белок, витамины группы В
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <img src={img3} alt="" className={'flex_el_img ' + (points.indexOf(3) >= 0 && 'active')} onClick={() => {this.clickPoint(3)}}/>
                        <div className={'flex_el_block ' + (points.indexOf(3) >= 0 && 'active')}>
                            <div className="flex_el_head">
                                Крепкие зубы
                            </div>
                            <div className="flex_el_description">
                                Оптимальный уровень кальция для укрепления зубов
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <img src={img4} alt="" className={'flex_el_img ' + (points.indexOf(4) >= 0 && 'active')} onClick={() => {this.clickPoint(4)}}/>
                        <div className={'flex_el_block ' + (points.indexOf(4) >= 0 && 'active')}>
                            <div className="flex_el_head">
                                Поддержка иммунной системы
                            </div>
                            <div className="flex_el_description">
                                Витемин Е и цинк поддерживают иммунную систему
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
