import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson7/step19-1.png'


export default class step18 extends React.Component {


    render() {
        return (
            <div className="step19">
                <TextBlock
                    text={`Как кошки, так и собаки обладают уникальными пищевыми потребностями. Поэтому <b>кошку нельзя кормить кормом для собаки и наоборот</b>. Например, уникальные особенности пищевых потребностей для кошки следующие:`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <ul className="list">
                            <li className="list_item">Высокая потребность в белке.</li>
                            <li className="list_item">Наличие в рационе таурина. Кошки не способны синтезировать таурин самостоятельно, как это происходит у собак.</li>
                            <li className="list_item">Регулярное потребление аргинина.</li>
                            <li className="list_item">Наличие в рационе предварительно обработанной арахидоновой кислоты.</li>
                            <li className="list_item">Наличие в рационе витаминов В3 и А.</li>
                        </ul>
                        <TextBlock
                            text={`Этот перечень пищевых потребностей кошек учитывается при производстве готовых кормов.`}
                        />
                    </div>
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                </div>
            </div>
        )
    }
}
