import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson7/step34-1.png'
import img2 from '../../../image/lessons/lesson7/step31-2.png'


export default class step34 extends React.Component {


    render() {
        return (
            <div className="step34">
                <TextBlock
                    text={`У кошек, которых кормили кормом с большим содержанием жидкости, увеличилось также общее количество выпитой ими за день воды. Большое количество потребляемой воды способствует профилактике заболевания мочевыводящих путей.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                    <div className="flex_el">
                        <img src={img2} alt="" className="flex_el_img second"/>
                        <TextBlock
                            text={`Таким образом, польза влажного корма для кошек и собак научно доказана. Рацион с большим содержанием влаги способствует поддержанию водного баланса в организме животного.`}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
