import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';

export default class step1 extends React.Component {

    planData = [
        `Виды лакомств для собак`,
        `Заболевания зубов у собак и способы их профилактики`,
        `Эффективность PEDIGREE<sup>®</sup> DENTASTIX™`,
        `Распространенные вопросы по уходу за зубами у собак`,
    ]

    render() {
        return (
            <div className="step1">
                <div className="head">План урока</div>

                <TextBlock
                    text={`Вы изучите:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'yellow'}
                />

            </div>
        )
    }
}
