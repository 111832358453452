import React from 'react'
import './style.sass'

export default props => {

    return (
        <div
            className={'ArrowBack ' + props.className}
            onClick={props.returnMenu}
        >
            <div></div>
        </div>
    )
}
