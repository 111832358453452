import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'
import Answer from './dnd/Answer';
import Questions from './dnd/Questions';

import img1 from '../../../image/lessons/lesson32/step14-1.png'
import img1xs from '../../../image/lessons/lesson32/step14-1xs.png'
import img2 from '../../../image/lessons/lesson32/step14-2.png'

export default class step14 extends React.Component {

    state = {
        // Ресайз
        windowWidth: window.innerWidth,

        // Работа десктопной версии
        answers: [],
        questions: [],
        correct: '',
        answerCorrect: [],

        //Работа мобильной версии
        answerActive: 0,
        questionActive: 0,
        answersMobile: [],
        questionsMobile: [],
        answersSelected: ['?', '?', '?'],
        correctMobile: '',
        answerCorrectMobile: [],
        showPopup: false
    }

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    //Работа десктопной версии
    setAnswer = (answer_el, question_el) => {
        const {answers} = this.state

        if (answers.indexOf(question_el) === -1) {
            let answer = []
            answers[answer_el] = question_el
            answers.push(answer)
        }
        this.setState({answers})

        let questions = [];
        // eslint-disable-next-line
        answers.map(answer => {

            if (answer.length) {
                questions.push(answer)
            }

        });
        this.setState({questions})
    };

    checkAnswer = () => {
        const {answerCorrect, questions} = this.state
        // eslint-disable-next-line
        questions.map((answer, index) => {
            if (answer === String(index + 1)) {
                answerCorrect.push('correct')
            } else {
                answerCorrect.push('inCorrect')
            }
        })
        this.setState({
            answerCorrect: answerCorrect,
        })
        if (questions.length === 3 && answerCorrect.indexOf('inCorrect') === -1) {
            this.setState({correct: '1'})
        } else {
            this.setState({correct: '0'})
        }
        this.props.showNextBtn()
    };

    _renderTestDesktop = () => {
        const {answerCorrect, correct, questions} = this.state
        return (
            <div className="test_desktop">
                <div className="questions">
                    <div className={'question_el ' + (questions.indexOf('1') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'1'} label={'Эпидермис'} text={'Эпидермис'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('2') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'2'} label={'Базальная мембрана'} text={'Базальная мембрана'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('3') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'3'} label={'Дерма'} text={'Дерма'}/>
                    </div>
                </div>
                <div className="flex">
                    <div className="flex_img">
                        <img className={'img ' + (correct === '' ? 'active' : '')} src={img1} alt=""/>
                        <img className={'img right ' + (correct !== '' ? 'active' : '')} src={img2} alt=""/>
                    </div>
                    <div className="answers">
                        <div className="answers_item">
                            <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'1'} text={'?'} count={'?'} answerCorrect={answerCorrect[0]}/>
                            <span className={'answers_number ' + answerCorrect[0]}>1</span>
                        </div>
                        <div className="answers_item">
                            <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'2'} text={'1'} count={'?'} answerCorrect={answerCorrect[1]}/>
                            <span className={'answers_number ' + answerCorrect[1]}>2</span>
                        </div>
                        <div className="answers_item">
                            <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'3'} text={'2'} count={'?'} answerCorrect={answerCorrect[2]}/>
                            <span className={'answers_number ' + answerCorrect[2]}>3</span>
                        </div>
                    </div>
                </div>
                {correct !== '' ?
                    <div className={'info ' + (correct === '0' && 'error')}>
                        <div className="info_head">
                            {correct === '1' ? 'все верно!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                    </div>
                    :
                    <button className="btn btn_blue" onClick={() => this.checkAnswer()}>
                        ПРОВЕРИТЬ
                    </button>
                }
            </div>
        )
    }

    //Работа мобильной версии
    questions = [
        `Эпидермис`,
        `Базальная мембрана`,
        `Дерма`,
    ]

    selectAnswer = (answerActive) => {
        document.body.scrollIntoView( true);
        document.body.style.overflow = "hidden";
        this.setState({
            showPopup: true,
            answerActive: answerActive
        })
    }

    selectQuestion = (questionActive) => {
        const {answerActive, answersMobile, questionsMobile, answersSelected} = this.state

        if (answersMobile.indexOf(answerActive) === -1) {
            answersMobile.push(answerActive)
        }
        this.setState({answersMobile})

        if (questionsMobile.indexOf(questionsMobile) === -1) {
            questionsMobile.push(questionActive)
        }
        this.setState({questionsMobile})

        answersSelected.splice(answerActive - 1, 1, this.questions[questionActive - 1])

        document.body.style.overflow = "";
        this.setState({
            showPopup: false,
            questionActive: questionActive,
            answersSelected: answersSelected
        })
    }

    checkAnswerMobile = () => {
        const {answerCorrectMobile, answersSelected} = this.state
        // eslint-disable-next-line
        answersSelected.map((answer, index) => {
            if (answer === this.questions[index]) {
                answerCorrectMobile.push('correct')
            } else {
                answerCorrectMobile.push('inCorrect')
            }
        })
        this.setState({
            answerCorrect: answerCorrectMobile,
        })
        if (answerCorrectMobile.indexOf('?') === -1 && answerCorrectMobile.indexOf('inCorrect') === -1) {
            this.setState({correctMobile: '1'})
        } else {
            this.setState({correctMobile: '0'})
        }
        this.props.showNextBtn()
    }

    closePopup = () => {
        document.body.style.overflow = "";
        this.setState({showPopup: false})
    }

    _renderTestMobile = () => {
        const {showPopup, answerActive, answersMobile, questionsMobile, answersSelected, correctMobile, answerCorrectMobile} = this.state
        return (
            <div className="test_mobile">
                {correctMobile !== '' ?
                    <div className={'info ' + (correctMobile === '0' && 'error')}>
                        <div className="info_head">
                            {correctMobile === '1' ? 'все верно!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                    </div>
                    :
                    <button className="btn btn_blue" onClick={() => this.checkAnswerMobile()}>
                        ПРОВЕРИТЬ
                    </button>
                }
                <div className="flex">
                    <div className="flex_img">
                        <img className="img" src={img1xs} alt=""/>
                    </div>
                    <div className="answers">
                        {this.questions.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={'answers_item ' + (answersMobile.indexOf(index + 1) >=0 ? 'selected ' : '') + answerCorrectMobile[index]}
                                    onClick={() => {this.selectAnswer(index + 1)}}
                                >
                                    {answersSelected[index]}
                                    <span className={'answers_number ' + (answersMobile.indexOf(index + 1) >=0 ? 'selected ' : '') + answerCorrectMobile[index]}>{index + 1}</span>
                                </div>
                            )
                        })

                        }
                    </div>
                </div>
                {showPopup &&
                <div className="popup">
                    <div className="popup_wrp">
                        <div
                            className="popup_closed"
                            onClick={() => {this.closePopup()}}
                        />
                        <div className="popup_flex">
                            <div className="point">
                                {answerActive}
                            </div>
                            <p className="popup_text">
                                Выберите вариант ответа:
                            </p>
                            <div className="questions">
                                {this.questions.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={'questions_item ' + (questionsMobile.indexOf(index + 1) >=0 ? 'selected ' : '')}
                                            onClick={() => {this.selectQuestion(index + 1)}}
                                        >
                                            {item}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }

    render() {
        const {windowWidth} = this.state
        return (
            <div className="step14">
                <InfoBlock
                    text={`Самостоятельно соотнесите название элемента с его положением на изображении`}
                />
                {windowWidth >= 768 ?
                    <div className="test">
                        {this._renderTestDesktop()}
                    </div>
                    :
                    <div className="test">
                        {this._renderTestMobile()}
                    </div>
                }
            </div>
        )
    }
}
