import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson13/step4-1.png';
import img2 from '../../../image/lessons/lesson13/step4-2.png';
import img3 from '../../../image/lessons/lesson13/step4-3.png';


export default class step4 extends React.Component {
    state = {
    };

    render() {
        return (
            <div className="step4">

                <TextBlock
                    text={`<b>Научно-исследовательский институт Waltham</b> — это центр экспертизы и фундаментальных научных знаний о домашних животных, устанавливающий внутренние производственные нормативы и рекомендации для компании Mars. Научно-исследовательский институт Waltham располагается в Англии.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <img className="img" src={img1} alt=""/>
                    </div>
                    <div className="flex_el">
                        <img className="img" src={img2} alt=""/>
                    </div>
                    <div className="flex_el">
                        <img className="img" src={img3} alt=""/>
                    </div>
                </div>
            </div>
        )
    }
}
