import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from "../../../image/lessons/lesson3/step22-1.png";


export default class step22 extends React.Component {

    render() {
        return (
            <div className="step22">
                <TextBlock
                    text={`Белок выполняет множество важных функций в организме. Для поддержания оптимального состояния здоровья кошкам и собакам необходимо получать из пищи 10 аминокислот (11 для кошек), которые являются незаменимыми, и дефицит хотя бы одной из них может вызвать серьезные нарушения в организме.
                    
                    После того, как белок употребляется в пищу, ферменты расщепляют его на аминокислоты, которые потом формируются в другом порядке и образуют белковые структуры, необходимые организму.
                    
                    Наличие определенных аминокислот в белке и его перевариваемость определяют биологическую ценность белка.`}
                />
                <img src={img1} alt="" className="img"/>
            </div>
        )
    }
}
