import React from 'react';
import DropTarget from './src/DropTarget';

export default class Answer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {text: '', img: ''};
    }

    dropped = (e) => {
        const {text} = this.state;
        // e.containerElem.style.visibility="hidden";
        if(text !== '') {
        }
        this.setState({text: e.dragData.label, img: e.dragData.img})
        this.props.setAnswer(this.props.answer_el, e.dragData.question_el)

        console.log({'onHit event passed to target animal:':e.dragData});
    };

    render() {
        return (
        <DropTarget
            onHit={this.dropped}
            targetKey={this.props.targetKey}
            dropData={{name: this.props.name}}
        >
            <div className={'answer ' + this.props.answerCorrect}>
                <span className={'icon'}><img src={this.state.img} alt=""/></span>
                <span className="question-mark">{this.state.text ? '' : this.props.count}</span>
                {this.props.children}
            </div>
        </DropTarget>
        );
    }
}
