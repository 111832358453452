import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import DefinitionBlock from '../../../Components/LessonAndTestComponents/DefinitionBlock';

import img1 from "../../../image/lessons/lesson21/step7-1.png";

export default class step7 extends React.Component {
    render() {
        return (
            <div className="step7">
                <DefinitionBlock
                    text={`<span class="text_pink">Вкус —</span> одно из самых сложных чувств.`}
                />
                <TextBlock
                    text={`Вкусовые ощущения принято разделять на четыре основных: сладкое, солёное, кислое, горькое. А в последнее время выделяют также вкус глютамата натрия (umami) и вкус воды.
                    
                    Вкусовые рецепторы разных видов группируются во вкусовые почки, локализованные в эпителии вкусовых сосочков на поверхности языка.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                    <div className="flex_el_img">
                        <div className="info">
                            <div className="info_el">
                                <div className="info_el_number">1</div>
                                <div className="info_el_text">
                                    <span className="info_purple">Грибовидные сосочки</span> разбросаны по всей поверхности языка среди нитевидных, несут рецепторы солёного вкуса
                                </div>
                            </div>
                            <div className="info_el">
                                <div className="info_el_number">2</div>
                                <div className="info_el_text">
                                    <span className="info_purple">Валиковидные</span> сосочки погружены в поверхность языка и несколько отделены углублением от остальных, содержат рецепторы кислого и горького вкуса.
                                </div>
                            </div>
                            <div className="info_el">
                                <div className="info_el_number">3</div>
                                <div className="info_el_text">
                                    <span className="info_purple">Нитевидные сосочки</span> выполняют механическую функцию. Кошки используют свой язык, чтобы удалить перья или шерсть с добычи, отделить мясо от костей. Кроме того, они выполняет роль щётки.
                                </div>
                            </div>
                            <div className="info_el">
                                <div className="info_el_number">4</div>
                                <div className="info_el_text">
                                    <span className="info_purple">Листовидные</span> сосочки располагаются в параллельных складках у корня языка, данный тип сосочков несёт рецепторы кислого вкуса.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
