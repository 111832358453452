import React from 'react'
import OnClickBlock from "../../../Components/LessonAndTestComponents/OnClickBlock";
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from "../../../image/lessons/lesson3/step16-1.png";
import img2 from "../../../image/lessons/lesson3/step16-2.png";

export default class step16 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step16">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Таурин необходим для обеспечения нормальной сердечной деятельности, хорошего слуха и зрения, и является важным природным антиоксидантом.</b>
                           
                            Необходим только для кошек, так как они, в отличие от собак, не могут синтезировать его самостоятельно.
                            
                            Таурин был обнаружен в 1826 году в желчи крупного рогатого скота. Эта сульфоаминокислота содержится в большинстве животных тканей.

                            Чтобы кошка получала необходимое количество таурина, во влажном корме его содержание должно быть в два раза выше, чем в сухом.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Таурин выполняет важные функции в организме:`}
                        />
                        <ul className="list">
                            <li className="list_item">синтез желчных солей в печени;</li>
                            <li className="list_item">регулирует поступление и выведение кальция из клеток;</li>
                            <li className="list_item">отвечает за процесс воспроизводства потомства;</li>
                            <li className="list_item">обеспечивает нормальную сердечную деятельность;</li>
                            <li className="list_item">способствует поддержанию хорошего зрения и слуха;</li>
                            <li className="list_item">является важным антиоксидантом;</li>
                            <li className="list_item">играет роль предшественника в процессе синтеза сложных жиров (гликосфинголипидов).</li>
                        </ul>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Главные природные источники таурина — животные жиры. Самое высокое содержание таурина в составе субпродуктов, таких как сердце, печень и почки.
                            
                            В диких условиях кошки получают таурин из мяса мелких животных, на которых они охотятся. В домашней среде у них нет такой возможности, поэтому необходимое количество таурина содержится во всех кормах для кошек.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img2} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Дефицит таурина приводит к серьезным последствиям:`}
                        />
                        <ul className="list">
                            <li className="list_item">дегенерация сетчатки у кошек с последующей слепотой;</li>
                            <li className="list_item">неадекватная иммунная реакция;</li>
                            <li className="list_item">замедленный рост;</li>
                            <li className="list_item">нарушение репродуктивной функции;</li>
                            <li className="list_item">рождение нежизнеспособного потомства или врожденные пороки развития.</li>
                        </ul>
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
