import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step3 extends React.Component {

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step3">
                <TextBlock
                    text={`Все минералы делятся на две группы: <b>макроэлементы</b> и <b>микроэлементы</b>. Макроэлементы — это те минералы, суточное потребление которых определяется в граммах, в то время как потребление микроэлементов принято определять в милиграммах или микрограммах.`}
                />
                <div className="data">
                    <div className={'data_el'}>
                        <div className="data_el_head">Макроэлементы</div>
                        <div className="data_tablet">
                            <div className="data_tablet_column left">
                                <div className="data_tablet_item">Кальций</div>
                                <div className="data_tablet_item">Фосфор</div>
                                <div className="data_tablet_item">Калий</div>
                                <div className="data_tablet_item">Натрий</div>
                                <div className="data_tablet_item">Магний</div>
                                <div className="data_tablet_item">Хлор</div>
                            </div>
                            <div className="data_tablet_column right">
                                <div className="data_tablet_item">Прочность костей</div>
                                <div className="data_tablet_item">Передача энергии</div>
                                <div className="data_tablet_item">Ионный баланс клеток</div>
                                <div className="data_tablet_item">Ионный баланс клеток</div>
                                <div className="data_tablet_item">Нервные импульсы</div>
                                <div className="data_tablet_item">Кислотно-щелочной баланс</div>
                            </div>
                        </div>
                    </div>
                    <div className={'data_el'}>
                        <div className="data_el_head">Микроэлементы</div>
                        <div className="data_tablet">
                            <div className="data_tablet_column left">
                                <div className="data_tablet_item">Железо</div>
                                <div className="data_tablet_item">Цинк</div>
                                <div className="data_tablet_item">Марганец</div>
                                <div className="data_tablet_item">Медь</div>
                                <div className="data_tablet_item">Йод</div>
                                <div className="data_tablet_item">Селен</div>
                            </div>
                            <div className="data_tablet_column right">
                                <div className="data_tablet_item">Синтез гемоглобина в эритроцитах</div>
                                <div className="data_tablet_item">Здоровье кожи и шерсти</div>
                                <div className="data_tablet_item">Формирование хрящей и кожи</div>
                                <div className="data_tablet_item">Синтез пигментов кожи и шерсти</div>
                                <div className="data_tablet_item">Работа щитовидной железы</div>
                                <div className="data_tablet_item">Кожа, шерсть</div>
                            </div>
                        </div>
                    </div>
                </div>
                <TextBlock
                    text={`В этом уроке вы ознакомитесь с каждым из перечисленных минералов более подробно.`}
                />
            </div>
        )
    }
}
