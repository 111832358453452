import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson7/step20-1.png'


export default class step20 extends React.Component {


    render() {
        return (
            <div className="step20">
                <TextBlock
                    text={`Существует несколько научных организаций, занимающихся исследованием вопросов, связанных с питанием домашних животных.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="head">NRC</div>
                        <TextBlock text={`Национальный научно-исследовательский совет.`}/>
                    </div>
                    <div className="flex_el">
                        <div className="head">AAFCO</div>
                        <TextBlock text={`Американская ассоциация контроля качества пищевых продуктов.`}/>
                    </div>
                    <div className="flex_el">
                        <div className="head">FEDIAF</div>
                        <TextBlock text={`Европейская федерация производителей кормов для домашних животных.`}/>
                    </div>
                    <div className="flex_el">
                        <div className="head">WALTHAM</div>
                        <TextBlock text={`Центр питания домашних животных. Устанавливает внутренние стандарты MARS.`}/>
                    </div>
                </div>

                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
