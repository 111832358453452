import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import img1 from '../../../image/lessons/lesson9/step19-1.png'


export default class step6 extends React.Component {


    render() {
        return (
            <div className="step19">
                <TextBlock
                    text={`Отучать щенка от материнского молока нужно постепенно. В этой таблице показано, как часто нужно кормить щенка на разных стадиях жизни.`}
                />
                <img src={img1} alt="" className="img"/>
                <div className="attention">
                    <p className="attention_head">Важно!</p>
                    <p className="attention_text">Щенок растет примерно в 12 раз быстрее ребенка. Поэтому необходимо следить за его питанием и соблюдать режим кормления. Первый год жизни собаки — самый важный для ее дальнейшего развития!</p>
                </div>
            </div>
        )
    }
}
