import React from 'react'
import Resume from "../../../Components/LessonAndTestComponents/Resume";

import img1 from "../../../image/lessons/lesson5/step20-1.png";

export default class step20 extends React.Component {
    state = {
        resumeData: [
            `Витамины относятся к классу <b>микронутриентов.</b>`,
            `Витамины делятся на <b>водорастворимые</b> и <b>жирорастворимые.</b>`,
            `Большинство витаминов <b>должно поступать в организм с пищей</b> на регулярной основе.`,
            `Жирорастворимые витамины обладают способностью накапливаться в организме, поэтому их избыточное потребление может иметь <b>токсический эффект.</b>`,
            `Несмотря на то, что водорастворимые витамины в большинстве своем не накапливаются в организме, передозировка некоторых из них всё же возможна. Поэтому все витамины следует употреблять <b>в рамках нормы.</b>`,
        ]
    }

    render() {
        return (
            <div className="step20">
                <Resume
                    resumeData={this.state.resumeData}
                    img={img1}
                />
            </div>
        )
    }
}
