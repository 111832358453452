import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import StatisticsBlock from '../../../Components/LessonAndTestComponents/StatisticsBlock';

import img1 from "../../../image/lessons/lesson1/step11-1.png";
import img2 from "../../../image/lessons/lesson1/step11-2.png";
import img3 from "../../../image/lessons/lesson1/step11-3.png";
import img4 from "../../../image/lessons/lesson1/step20-1.png";



export default class step20 extends React.Component {

    render() {
        return (
            <div className="step20">
                <TextBlock
                    text={`В России живет почти 40,8 млн. кошек! При этом в городах и сельской местности количество домохозяйств, содержащих кошку, примерно совпадает.`}
                />
                <div className="table">
                    <div className="table_flex rows">
                        <div className="table_flex_row hidden"></div>
                        <div className="table_flex_row">Процент домохозяйств, в которых есть кошка</div>
                        <div className="table_flex_row">Популяция кошек (в млн.)</div>
                    </div>
                    <div className="table_flex">
                        <div className="table_flex_row head">
                            <img className="table_flex_head_img" src={img1} alt=""/>
                            <p className="table_flex_head_text">Общая</p>
                        </div>
                        <div className="table_flex_row data">
                            <p className="table_flex_row_text">48%</p>
                            <div className="table_flex_mobile">
                                <div className="table_flex_mobile_row">
                                    <p className="table_flex_mobile_name">Процент домохозяйств, в которых есть кошка</p>
                                    <p className="table_flex_mobile_data">48%</p>
                                </div>
                                <div className="table_flex_mobile_row">
                                    <p className="table_flex_mobile_name">Популяция кошек (в млн.)</p>
                                    <p className="table_flex_mobile_data">40,8</p>
                                </div>
                            </div>
                        </div>
                        <div className="table_flex_row block">
                            40,8
                        </div>
                    </div>
                    <div className="table_flex">
                        <div className="table_flex_row head">
                            <img className="table_flex_head_img" src={img2} alt=""/>
                            <p className="table_flex_head_text">Городская</p>
                        </div>
                        <div className="table_flex_row data">
                            <p className="table_flex_row_text">43%</p>
                            <div className="table_flex_mobile">
                                <div className="table_flex_mobile_row">
                                    <p className="table_flex_mobile_name">Процент домохозяйств, в которых есть кошка</p>
                                    <p className="table_flex_mobile_data">43%</p>
                                </div>
                                <div className="table_flex_mobile_row">
                                    <p className="table_flex_mobile_name">Популяция кошек (в млн.)</p>
                                    <p className="table_flex_mobile_data">26</p>
                                </div>
                            </div>
                        </div>
                        <div className="table_flex_row block">
                            26
                        </div>
                    </div>
                    <div className="table_flex">
                        <div className="table_flex_row head">
                            <img className="table_flex_head_img" src={img3} alt=""/>
                            <p className="table_flex_head_text">Деревенская</p>
                        </div>
                        <div className="table_flex_row data">
                            <p className="table_flex_row_text">64%</p>
                            <div className="table_flex_mobile">
                                <div className="table_flex_mobile_row">
                                    <p className="table_flex_mobile_name">Процент домохозяйств, в которых есть кошка</p>
                                    <p className="table_flex_mobile_data">64%</p>
                                </div>
                                <div className="table_flex_mobile_row">
                                    <p className="table_flex_mobile_name">Популяция кошек (в млн.)</p>
                                    <p className="table_flex_mobile_data">15</p>
                                </div>
                            </div>
                        </div>
                        <div className="table_flex_row block">
                            15
                        </div>
                    </div>
                </div>
                <StatisticsBlock
                    img={img4}
                    text={'Популяция котов по России примерно совпадает с популяцией кошек.'}
                />
            </div>
        )
    }
}
