import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson7/step21-1.png'


export default class step21 extends React.Component {


    render() {
        return (
            <div className="step21">
                <TextBlock
                    text={`Центр WALTHAM™ Research — мировой научный центр, обеспечивающий научную базу и опыт, лежащий в основе производства кормов MARS.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_head">
                            <span className="flex_el_blue">WALTHAM™ Research —</span> это:
                        </div>
                        <ul className="list">
                            <li className="list_item">более чем 50-летнее наследие инновационных исследований;</li>
                            <li className="list_item">мировой лидер в области ухода за домашними животными и их питания;</li>
                            <li className="list_item">инициатор многих важных научных открытий;</li>
                            <li className="list_item">сотрудничество с ведущими научными институтами по всему миру.</li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
