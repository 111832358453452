import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from "../../../image/lessons/lesson1/step11-1.png";
import img2 from "../../../image/lessons/lesson1/step11-2.png";
import img3 from "../../../image/lessons/lesson1/step11-3.png";
import img4 from "../../../image/lessons/lesson1/step22-1.png";
import img5 from "../../../image/lessons/lesson1/step22-2.png";
import img6 from "../../../image/lessons/lesson1/step22-3.png";
import img7 from "../../../image/lessons/lesson1/step22-4.png";

export default class step22 extends React.Component {

    render() {
        return (
            <div className="step22">
                <TextBlock
                    text={`Рассмотрите эти диаграммы. Из них понятно, что в городах большая часть кошек живет исключительно в квартире, не выходя на улицу. В то время как в сельской местности, наоборот, кошки проводят много времени на улице.`}
                />
                <div className="table">
                    <div className="table_flex">
                        <div className="table_flex_el head">
                            <img className="table_flex_head_img" src={img1} alt=""/>
                            <p className="table_flex_head_text">Общая</p>
                        </div>
                        <div className="table_flex_el data">
                            <img className="table_flex_data_img" src={img4} alt=""/>
                        </div>
                    </div>
                    <div className="table_flex">
                        <div className="table_flex_el head">
                            <img className="table_flex_head_img" src={img2} alt=""/>
                            <p className="table_flex_head_text">Городская</p>
                        </div>
                        <div className="table_flex_el data">
                            <img className="table_flex_data_img" src={img5} alt=""/>
                        </div>
                    </div>
                    <div className="table_flex">
                        <div className="table_flex_el head">
                            <img className="table_flex_head_img" src={img3} alt=""/>
                            <p className="table_flex_head_text">Деревенская</p>
                        </div>
                        <div className="table_flex_el data">
                            <img className="table_flex_data_img" src={img6} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="footnote">
                    <div className="footnote_el">
                        <span className="footnote_point"></span><span className="footnote_text">Гуляет самостоятельно</span>
                    </div>
                    <div className="footnote_el">
                        <span className="footnote_point"></span><span className="footnote_text">Большую часть времени дома, но иногда гуляет</span>
                    </div>
                    <div className="footnote_el">
                        <span className="footnote_point"></span><span className="footnote_text">Не выходит из дома</span>
                    </div>
                </div>
                <div className="flex">
                    <div className="flex_el">
                        <img className="flex_el_img" src={img7} alt=""/>
                    </div>
                    <div className="flex_el">
                        <TextBlock
                            text={`<b>Малоактивные кошки склонны к набору лишнего веса, поэтому необходимо тщательно следить за калорийностью их рациона.</b>
                            
                            Для домашних кошек, которые практически не бывают на улице, производят специальные корма, например, <span class="text_yellow">PERFECT FIT™ Indoor</span>. Такие корма не только помогают поддерживать оптимальный вес, но и способствуют уменьшению запаха кошачьего туалета благодаря содержанию специальных компонентов (в кормах <span class="text_yellow">PERFECT FIT™ Indoor</span> это растительный экстракт Юкки Шидигера)`}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
