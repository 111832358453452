import React from 'react'
import img1 from '../../../image/lessons/lesson9/step4-1.png';
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";


export default class step4 extends React.Component {
    state = {
    };

    render() {
        return (
            <div className="step4">

                <TextBlock
                    text={`Сравните <span class="text_blue">сухие корма</span> для взрослых собак и корма для щенков, чтобы понять, почему последние лучше подходят для беременных собак.`}
                />
                <img src={img1} alt=""/>
                <TextBlock
                    text={`Мы видим, что <span class="text_blue">сухие рационы для щенков обладают более высокой энергетической ценностью и содержат большее количество белка по сравнению с сухими рационами для взрослых собак.</span> Таким образом, кормление беременных собак рационами для щенков удовлетворит повышенные требования их организма в питательных веществах и энергии, и окажет положительное влияние на их здоровье.`}
                />

            </div>
        )
    }
}
