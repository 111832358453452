import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson24/step14-1.png";
import img2 from "../../../image/lessons/lesson24/step14-2.png";
import img3 from "../../../image/lessons/lesson24/step14-3.png";
import img4 from "../../../image/lessons/lesson24/step14-4.png";

export default class step14 extends React.Component {

    render() {
        return (
            <div className="step14">
                <div className="head">
                    Sheba<sup>®</sup> Mini
                </div>
                <TextBlock
                    text={`Мини-порция от Sheba<sup>®</sup> — это всегда свежее угощение для вашей любимицы. Она точно не устоит перед таким соблазнительным блюдом.
                    
                    <span class="text_sheba">Ключевые особенности:</span>`}
                />
                <div className="advantages">
                    <div className="advantages_el">
                        Особая мелкая и тонкая нарезка
                        мясных ломтиков полнее
                        раскрывает вкус мяса
                    </div>
                    <div className="advantages_plus">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.22939 13.425V8.925H0.704395V5.475H5.22939V0.975H8.7794V5.475H13.3044V8.925H8.7794V13.425H5.22939Z" fill="#AC8748"/>
                        </svg>
                    </div>
                    <div className="advantages_el">
                        максимальная свежесть каждого
                        кормления благодаря мини-порции
                        50 г
                    </div>
                </div>
                <div className="flex">
                    <img src={img1} alt="" className="flex_img"/>
                    <img src={img2} alt="" className="flex_img"/>
                    <img src={img3} alt="" className="flex_img"/>
                    <img src={img4} alt="" className="flex_img"/>
                </div>
            </div>
        )
    }
}
