import React from 'react'
import Resume from "../../../Components/LessonAndTestComponents/Resume";

import img1 from "../../../image/lessons/lesson8/step8-1.png";

export default class step8 extends React.Component {
    state = {
        resumeData: [
            `Рассчитать <b>суточный объем</b> рациона, <b>количество дней</b>, на которые хватит одной упаковки, а также <b>стоимость одной порции</b> можно с помощью специальных формул.`,
            `Чтобы поддерживать здоровый вес питомца, нужно кормить его полноценным и сбалансированным кормом, удовлетворяющим его <b>энергетическую потребность.</b>`,
            `Размер порции рассчитывается, исходя из <b>желаемого веса</b> питомца и <b>энергетической ценности</b> корма.`,
        ]
    }

    render() {
        return (
            <div className="step8">
                <Resume
                    resumeData={this.state.resumeData}
                    img={img1}
                />
            </div>
        )
    }
}
