import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from "../../../image/lessons/lesson8/step22-1.png";

export default class step22 extends React.Component {

    render() {
        return (
            <div className="step22">
                <div className="flex">
                    <TextBlock
                        text={`У кошек и собак бывают индивидуальные случаи расстройства пищеварения, которые могут выражаться в виде диареи. В большинстве случаев владельцы считают, что причиной диареи является рацион. Однако это не всегда так.
                        
                        Узнайте больше о причинах возникновения пищевых расстройств, чтобы давать владельцам кошек и собак правильные рекомендации.`}
                    />
                    <img src={img1} alt="" className="flex_img"/>
                </div>
            </div>
        )
    }
}
