import React from 'react'

export default class step1 extends React.Component {
    render() {
        return (
            <div className="step1">
                <div className="head">план урока</div>

                <div className="text">
                    Каждая жизненная стадия имеет свои особенности, которые нужно учитывать при кормлении собаки.
                </div>
                <div className="text">
                    В этом уроке рассматриваются:
                </div>

                <div className="lessonSteps">
                    <div className="lessonSteps_el">Беременность и лактация</div>
                    <div className="lessonSteps_el">Рост и развитие щенков</div>
                    <div className="lessonSteps_el">Подбор рациона для щенков</div>
                    <div className="lessonSteps_el">Питание взрослых и старых собак</div>
                    <div className="lessonSteps_el">Итоги урока</div>
                    <div className="lessonSteps_el">Тест</div>
                </div>

            </div>
        )
    }
}
