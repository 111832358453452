import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson6/step14-1.png";
import img2 from "../../../image/lessons/lesson6/step14-2.png";
import img3 from "../../../image/lessons/lesson6/step14-3.png";

export default class step14 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит и избыток'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step14">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Медь (Cu) — профилактика и лечение анемии, пигментация шерсти.</b>
                           
                            Организм собак и кошек содержит очень малое количество меди.

                            В 1984 году Мейер вычислил, что содержание меди у молодых собак составляет 7,3 мг на килограмм массы тела.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_flex">
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img2} alt=""/>
                            </div>
                            <div className="imgDiv_wrap_flex_box">
                                <TextBlock
                                    text={`<b>Функции меди:</b>`}
                                />
                                <ul className="list">
                                    <li className="list_item">способствует кишечному всасыванию железа и его включению в состав гемоглобина;</li>
                                    <li className="list_item">является активным элементом во многих ферментах;</li>
                                    <li className="list_item">играет важную роль в сокращении клеточных повреждений, вызванных воздействием свободных радикалов;</li>
                                    <li className="list_item">участвует в синтезе коллагена в сухожилиях и миелина в нервной системе, а также меланина.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`К продуктам с высоким содержанием меди относятся:`}
                        />
                        <ul className="list">
                            <li className="list_item">баранина;</li>
                            <li className="list_item">утка;</li>
                            <li className="list_item">горох;</li>
                            <li className="list_item">чечевица;</li>
                            <li className="list_item">соя;</li>
                            <li className="list_item">минеральные соли.</li>
                        </ul>
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img3} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Дефицит</b> меди может привести к:`}
                        />
                        <ul className="list">
                            <li className="list_item">анемии;</li>
                            <li className="list_item">потере пигментации шерсти.</li>
                        </ul>
                        <TextBlock
                            text={`Медь концентрируется в печени, и хотя случаи интоксикации редки, некоторые породы предрасположены к развитию болезни накопления меди.`}
                        />
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
