import React from "react";
import "./style.scss"

const Question = (props) => {
    return (
        <div className="login__pet-question">
            <p className="login__question">{props.question}</p>
            {props.answers.map((answer, index) => {
                return(
                    <div className="login__answer" onClick={(e) => {props.onClick(e)}} key={index}>
                        <input className="radio-main__input" type="radio" name={props.name} id={props.answersId[index]} value={index + 1}/>
                        <label className="radio-main__label" htmlFor={props.answersId[index]}>{answer}</label>
                    </div>
                )
            })}
        </div>
    )
}

export default Question