import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import Popup from "../../../Components/Popup";

import img1 from '../../../image/lessons/lesson17/step8-1.png'
import img2 from '../../../image/lessons/lesson17/step8-2.png'
import img3 from '../../../image/lessons/lesson17/step8-3.png'
import img4 from '../../../image/lessons/lesson17/step8-4.png'

export default class step8 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        pointActive: 0,
        showPopup: false,
        points: [],
    };

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    showPopup = (pointActive) => {
        const {points, showPopup, windowWidth} = this.state;
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        if (windowWidth < 768) {
            if (!showPopup) {
                this.setState({
                    pointActive: pointActive,
                    showPopup: true,
                })
            } else if (pointActive >= 2) {
                this.setState({showPopup: false, pointActive: 0})
            } else {
                this.setState({
                    pointActive: pointActive +1,
                    showPopup: true,
                })
            }
        } else {
            this.setState({pointActive: pointActive})
        }
        if (points.length === 2) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false, pointActive: 0})
    }

    popupData = {
        0: {
            img: '',
        },
        1: {
            img: img3
        },
        2: {
            img: img4
        },
    }

    render() {
        const {showPopup, pointActive} = this.state
        return (
            <div className="step8">
                <TextBlock
                    text={`<b>Из чего же состоит категория кормов для животных?</b> Давайте разберемся, какие корма присутствуют на полках в магазинах.

                    Прежде всего, готовые корма делятся на корма для собак и корма для кошек.`}
                />
                <InfoBlock
                    text={`Нажмите на картинку с собаками, а потом на картинку с кошками и рассмотрите, какие корма входят в категорию для них.`}
                />
                <div className="box">
                    <div className="box_img">
                        <img src={img1} className="img" alt="" onClick={() => {this.showPopup(1)}}/>
                        <img src={img2} className="img" alt="" onClick={() => {this.showPopup(2)}}/>
                        <div className={'block green ' + (pointActive === 1 && 'active')}>
                            <div className="block_close" onClick={this.closePopup}/>
                            <img src={img3} alt="" className="img-popup"/>
                        </div>
                        <div className={'block red ' + (pointActive === 2 && 'active')}>
                            <div className="block_close" onClick={this.closePopup}/>
                            <img src={img4} alt="" className="img-popup"/>
                        </div>
                    </div>
                </div>
                <ul className="list">
                    <li className="list_item">Такое деление обосновано тем, что с научной точки зрения обмен веществ собак и кошек отличается между собой, а значит и питание у них должно быть разное.</li>
                    <li className="list_item">Например, кошка требует больше белка и аминокислот, поэтому в составе корма для кошки всегда больше белка, чем в корме для собак.</li>
                    <li className="list_item">А это означает, что нельзя кормить собаку кормом для кошки, а кошку — кормом для собаки.</li>
                    <li className="list_item">Именно поэтому ответственные производители всегда выпускают отдельно корм для кошек, отдельно для собак.</li>
                </ul>
                {showPopup &&
                <Popup
                    closePopup={this.closePopup}
                >
                    <img src={this.popupData[pointActive].img} alt="" className="Popup_img"/>
                    <button
                        className="btn btn_blue"
                        onClick={() => {this.showPopup(pointActive)}}
                    >
                        Продолжить
                    </button>
                </Popup>
                }
            </div>
        )
    }
}
