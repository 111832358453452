import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'
import List from '../../../Components/LessonAndTestComponents/List'
import Popup from '../../../Components/Popup'


import img1 from '../../../image/lessons/lesson32/step7-1.png'

export default class step7 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        pointActive: 0,
        points: [0],
        showPopup: false,
    }

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    clickPoint = pointActive => {
        const {points, windowWidth} = this.state
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        if (windowWidth < 767) {

            if (pointActive > 3) {
                this.setState({
                    pointActive: 0,
                    showPopup: false,
                })
            } else {
                this.setState({
                    pointActive: pointActive,
                    showPopup: true,
                    points: points
                })
            }

        } else {
            this.setState({pointActive, points})
        }

        if (points.length === 4) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    infoData = {
        1: {
            head: `Желудок`,
            text: `Очень растяжимый желудок, способный переваривать болшое количество пищи. Уровень рН желудочного сока более кислый, чем у человека.`,
            list: false
        },
        2: {
            head: `Тонкий кишечник`,
            text: 'Время кишечного транзита составляет 12-30 часов, что значительно меньше по сравнению с аналогичными показателями человека (от 30 часов до 5 дней).',
            list: false
            // list: [
            //     `Переваривание крахмала и дисахаридов`,
            //     `Абсорбция моносахаридов`,
            //     `Переваривание протеинов`,
            //     `Абсорбция аминокислот и пептидов`,
            //     `Абсорбция витаминов`,
            //     `Эмульгирование жиров желчью`,
            //     `Переваривание жиров`,
            //     `Абсорбция жирных кислот`,
            //     `Абсорбция минералов`,
            // ]
        },
        3: {
            head: `Толстый кишечник`,
            text: 'Бактериальная ферментация происходит в толстом кишечнике.',
            list: false
            // list: [
            //     `Всасывание воды и минералов`,
            //     `Бактериальная ферментация`,
            //     `Образование фекальных масс`,
            // ]
        },
    }

    render() {
        const {points, pointActive, showPopup} = this.state
        return (
            <div className="step7">
                <InfoBlock
                    text={`Нажмите на активные элементы, чтобы узнать больше о пищеварении собак`}
                />
                <div className="block">
                    <div className="img_box">
                        <div className={'point '  + (points.indexOf(1) >= 0 && 'active')} onClick={() => {this.clickPoint(1)}}>1</div>
                        <div className={'point '  + (points.indexOf(2) >= 0 && 'active')} onClick={() => {this.clickPoint(2)}}>2</div>
                        <div className={'point '  + (points.indexOf(3) >= 0 && 'active')} onClick={() => {this.clickPoint(3)}}>3</div>
                        <div className={'string ' + (points.indexOf(1) >= 0 && 'active')}/>
                        <div className={'string ' + (points.indexOf(2) >= 0 && 'active')}/>
                        <div className={'string ' + (points.indexOf(3) >= 0 && 'active')}/>
                        <img src={img1} alt="" className="block_img"/>
                    </div>
                    {Object.keys(this.infoData).map(item => {
                        return (
                            <div key={item} className={'info  ' + (item === '1' ? 'top ' : item === '2' ? 'left ' : 'bottom ') + (points.indexOf(Number(item)) >= 0 && 'active')}>
                                <div className="info_head">
                                    {this.infoData[item].head}
                                </div>
                                {this.infoData[item].text &&
                                <div className="info_description">
                                    {this.infoData[item].text}
                                </div>
                                }
                                {this.infoData[item].list &&
                                <List ListData={this.infoData[item].list}/>
                                }
                            </div>
                        )
                    })}
                    {showPopup &&
                        <Popup
                            closePopup={this.closePopup}
                        >
                            <div className="Popup_number">
                                {pointActive}
                            </div>
                            <div className="Popup_head">
                                {this.infoData[pointActive].head}
                            </div>
                            {this.infoData[pointActive].text &&
                            <div className="Popup_description">
                                {this.infoData[pointActive].text}
                            </div>
                            }
                            {this.infoData[pointActive].list &&
                                <ul>
                                    {this.infoData[pointActive].list.map((item, index) => {
                                        return (
                                            <li key={index}>{item}</li>
                                        )
                                    })}
                                </ul>
                            /*<List ListData={this.infoData[pointActive].list}/>*/
                            }
                            <button
                                className="btn btn_blue"
                                onClick={() => {this.clickPoint(pointActive + 1)}}
                            >
                                продолжить
                            </button>
                        </Popup>
                    }
                </div>
            </div>
        )
    }
}
