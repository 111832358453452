import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import img1 from '../../../image/lessons/lesson32/step16-1.png'
import img2 from '../../../image/lessons/lesson32/step16-2.png'
import img3 from '../../../image/lessons/lesson32/step16-3.png'
import img4 from '../../../image/lessons/lesson32/step16-4.png'
import Popup from "../../../Components/Popup";


export default class step6 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        pointActive: 0,
        points: [0],
        showPopup: false,
    };

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }


    clickPoint = pointActive => {
        const {points, windowWidth, showPopup} = this.state
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }
        if (windowWidth < 767) {

            if (showPopup) {
                this.setState({
                    pointActive: 0,
                    showPopup: false,
                })
            } else {
                this.setState({
                    pointActive: pointActive,
                    showPopup: true,
                    points: points
                })
            }

        } else {
            this.setState({pointActive, points})
        }

        this.props.showNextBtn();
    }

    closePopup = () => {
        this.setState({pointActive: 0, showPopup: false})
    }

    render() {
        const {showPopup, points} = this.state;
        return (
            <div className="step16">
                <InfoBlock
                    text={`Нажмите на активные элементы, чтобы узнать больше о роли питания в здоровье кожи и шерсти у собаки.`}
                />
                <TextBlock
                    text={`На рост и выпадение волос влияют следующие факторы, действие которых ведет к снижению активности волосяных фолликулов:`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <ul>
                            <li>гормональный фон</li>
                            <li>продолжительность светового дня</li>
                            <li>температура</li>
                            <li>питание</li>
                            <li>стресс</li>
                            <li>генетические особенности</li>
                        </ul>
                        <img src={img4} alt="" className="flex_el_img"/>
                    </div>
                    <div className="flex_el">
                        <div className={'flex_el_block ' + (points.indexOf(1) >= 0 && 'active')}>
                            <div className="flex_el_block_head">
                                Признаки здоровья кожи и шерсти
                            </div>
                            <ul>
                                <li>Упругая кожа</li>
                                <li>Насыщенный выраженный цвет шерсти</li>
                                <li>Блеск шерсти</li>
                                <li>Отсутствие перхоти</li>
                            </ul>
                            <img src={img3} alt="" className="flex_el_block_img"/>
                        </div>
                        <img src={img2} alt="" className="flex_el_img-button" onClick={() => {this.clickPoint(1)}}/>
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                </div>
                {showPopup &&
                <Popup
                    closePopup={this.closePopup}
                >
                    <img src={img2} alt="" className="Popup_img"/>
                    <div className="Popup_head">
                        Признаки здоровья кожи и шерсти
                    </div>
                    <ul>
                        <li>Упругая кожа</li>
                        <li>Насыщенный выраженный цвет шерсти</li>
                        <li>Блеск шерсти</li>
                        <li>Отсутствие перхоти</li>
                    </ul>
                    <img src={img3} alt="" className="Popup_img bottom"/>
                    <button
                        className="btn btn_blue"
                        onClick={() => {this.clickPoint(0)}}
                    >
                        продолжить
                    </button>
                </Popup>
                }
            </div>
        )
    }
}
