import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson6/step4-1.png";
import img2 from "../../../image/lessons/lesson6/step4-2.png";
import img3 from "../../../image/lessons/lesson6/step4-3.png";

export default class step4 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит и избыток'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step4">
               <OnClickBlock
                   flexData={flexData}
                   hideElement={this.hideElement}
                   showNextBtn={this.showNextBtn}
               >
                   <div className="imgDiv_wrap">
                       <TextBlock
                           text={`<b>Кальций (Са) — необходим для здоровья костей и зубов.</b>
                           
                            Пятый по распространенности элемент в земной коре и морской воде.
                           
                            Для обеспечения здоровья и роста костей потребление этого минерала должно быть сбалансировано с фосфором.`}
                       />
                       <div className="imgBox">
                           <img className="imgDiv_wrap_img" src={img1} alt=""/>
                       </div>
                   </div>
                   <div className="imgDiv_wrap">
                       <div className="imgDiv_wrap_flex">
                           <div className="imgBox">
                               <img className="imgDiv_wrap_img" src={img2} alt=""/>
                           </div>
                           <div className="imgDiv_wrap_flex_box">
                               <TextBlock
                                   text={`Кальций выполняет две основные функции:`}
                               />
                               <ul className="list">
                                   <li className="list_item">обеспечивает структурную прочность костей и зубов;</li>
                                   <li className="list_item">участвует в передаче нервных импульсов и информации между клетками.</li>
                               </ul>
                           </div>
                       </div>
                   </div>
                   <div className="imgDiv_wrap">
                       <TextBlock
                           text={`Кальций содержится в:`}
                       />
                       <div className="imgDiv_wrap_flex">
                           <ul className="list">
                               <li className="list_item">костях млекопитающих, птиц и рыб;</li>
                               <li className="list_item">молочных продуктах;</li>
                               <li className="list_item">брокколи;</li>
                               <li className="list_item">белокочанной капусте;</li>
                               <li className="list_item">минеральных солях (карбонат, сульфат и фосфат кальция).</li>
                           </ul>
                           <div className="imgBox">
                               <img className="imgDiv_wrap_img" src={img3} alt=""/>
                           </div>
                       </div>
                   </div>
                   <div className="imgDiv_wrap">
                       <TextBlock
                           text={`Недостаточный или избыточный уровень кальция в рационе приводит к неправильному развитию скелета. В период лактации и роста требуется повышенное количество кальция.

                                 К симптомам <b>дефицита</b> кальция относится нарушение роста, а его <b>избыток</b> вызывает патологии костей и остеохондроз.`}
                       />
                   </div>
               </OnClickBlock>
            </div>
        )
    }
}
