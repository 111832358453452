import React from 'react'
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";
import img1 from "../../../image/lessons/betterWorld.png"

export default class step1 extends React.Component {

    render() {
        return (
            <div className="step1">
                <VideoBlock
                    src={'https://www.youtube.com/embed/VXV8yjHfJy8'}
                    title={'https://www.youtube.com/watch?v=VXV8yjHfJy8'}
                />
                <div className="step1_img_box">
                    <img src={img1} alt="better World"/>
                </div>
            </div>
        )
    }
}
