import React from 'react'
import './style.sass'

export default props => {
    return (
        <div className={'DefinitionBlock ' + props.hidden}>
            {props.head &&
            <div className="DefinitionBlock_head">
                {props.head}
            </div>
            }
            <div className="DefinitionBlock_box">
                <div className="DefinitionBlock_item"
                     dangerouslySetInnerHTML={{ __html: props.text }}
                />
                {props.text2 &&
                <div className="DefinitionBlock_item"
                     dangerouslySetInnerHTML={{ __html: props.text2 }}
                />
                }
                {props.text3 &&
                <div className="DefinitionBlock_item"
                     dangerouslySetInnerHTML={{ __html: props.text3 }}
                />
                }
            </div>
        </div>
    )
}
