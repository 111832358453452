import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from "../../../Components/LessonAndTestComponents/OnClickBlock";

import img1 from '../../../image/lessons/lesson4/step13-1.png';
import img2 from "../../../image/lessons/lesson4/step13-2.png";

export default class step13 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит и избыток'],
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step13">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Крахмал — это перевариваемый углевод</b>, состоящий из тысяч молекул глюкозы, соединенных химическими связями.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Крахмал можно использовать в качестве источника энергии.
                            
                            Молекулы крахмала под действием пищеварительных ферментов распадаются на молекулы глюкозы, всасываемые в тонком кишечнике. Крахмал, прошедший тепловую обработку, более быстро и полно переваривается и усваивается в ЖКТ.`}
                        />
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Крахмал имеет растительное происхождение и используется как источник энергии.
                            
                            Распространенные источники крахмала:`}
                        />
                        <div className="imgDiv_wrap_flex">
                            <ul className="list">
                                <li className="list_item">рис;</li>
                                <li className="list_item">кукуруза;</li>
                                <li className="list_item">пшеница;</li>
                                <li className="list_item">ячмень;</li>
                                <li className="list_item">картофель.</li>
                            </ul>
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img2} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Поскольку крахмал не является жизненно необходимым элементом для кошек и собак, его дефицит невозможен. Избыток крахмала или недостаточная тепловая обработка может привести к диарее.`}
                        />
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
