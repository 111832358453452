import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson32/step3-1.png';
// import img1xs from '../../../image/lessons/lesson41/step3-1xs.png';
import img1xxs from '../../../image/lessons/lesson32/step3-1xxs.png';


export default class step3 extends React.Component {
    state = {
        storyActive: 0,
        stories: []
    };

    showStory = (storyActive) => {
        const {stories} = this.state
        if (stories.indexOf(storyActive) === -1) {
            stories.push(storyActive)
        }
        this.setState({storyActive: storyActive})

        if (stories.length === 3) {
            this.props.showNextBtn();
        }
    }

    render() {
        const {stories} = this.state
        return (
            <div className="step3">
                <div className="fact">
                    <div className="fact_el">
                        <picture>
                            <source srcSet={img1xxs} media="(max-width: 767px)"/>
                            <img src={img1} alt="" className="fact_el_img"/>
                        </picture>
                        <TextBlock
                            text={`<b>Самый крупный бренд кормов</b> для собак в мире с годовым объемом продаж более 3 миллиардов долларов!`}
                        />
                    </div>
                    <div className="fact_el">
                        <picture>
                            <source srcSet={img1xxs} media="(max-width: 767px)"/>
                            <img src={img1} alt="" className="fact_el_img"/>
                        </picture>
                        <TextBlock
                            text={`<b>Миллионы собаковладельцев</b> в России кормят своих собак рационами <b>Pedigree</b>`}
                        />
                    </div>
                </div>
                <div className="history">
                    <div className={'history_el ' + (stories.indexOf(1) >= 0 && 'active')} onClick={() => {this.showStory(1)}}>
                        <picture className="history_el_img-box">
                            <source srcSet={img1xxs} media="(max-width: 767px)"/>
                            <img src={img1} alt="" className="history_el_img"/>
                        </picture>
                        <TextBlock
                            text={`История <b>Pedigree<sup>®</sup></b> начинается в 1960 году в Великобритании`}
                        />
                    </div>
                    <div className={'history_el ' + (stories.indexOf(2) >= 0 && 'active')} onClick={() => {this.showStory(2)}}>
                        <picture className="history_el_img-box">
                            <source srcSet={img1xxs} media="(max-width: 767px)"/>
                            <img src={img1} alt="" className="history_el_img"/>
                        </picture>
                        <TextBlock
                            text={`Уже в 1964 году впервые собака, которую кормили <b>Pedigree<sup>®</sup></b>, выиграла <b>титул “лучшая из лучших” на престижной выставке CRAFTS</b>`}
                        />
                    </div>
                    <div className={'history_el ' + (stories.indexOf(3) >= 0 && 'active')} onClick={() => {this.showStory(3)}}>
                        <picture className="history_el_img-box">
                            <source srcSet={img1xxs} media="(max-width: 767px)"/>
                            <img src={img1} alt="" className="history_el_img"/>
                        </picture>
                        <TextBlock
                            text={`С 1992 года <b>Pedigree<sup>®</sup> представлен на рынке России</b> (производится в России с 1996 года)`}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
