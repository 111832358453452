import React from 'react'
import './style.sass'

export default props => {
    return (
        <div className={'StatisticsBlock ' + props.className}>
            <img className="img" src={props.img} alt=""/>
            <span dangerouslySetInnerHTML={{ __html: props.text }}/>
        </div>
    )
}
