import React from 'react'

import img1 from '../../../image/lessons/lesson4/step3-1.png'
import img2 from "../../../image/lessons/lesson4/step3-2.png";

export default class step3 extends React.Component {

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step3">
                <div className="wrap">
                    <div className={'flex'}>
                        <div className="flex_wrp">
                            <div className={'flex_el'}>
                                В кишечнике жиры превращаются в эмульсию под действием желчной кислоты
                            </div>
                            <img src={img2} alt="" className="flex_el_arrow"/>
                        </div>
                        <div className="flex_wrp">
                            <div className={'flex_el'}>
                                Перевариваются с помощью ферментов поджелудочной железы (липазы)
                            </div>
                            <img src={img2} alt="" className="flex_el_arrow"/>
                        </div>
                        <div className="flex_wrp">
                            <div className={'flex_el'}>
                                Жирные кислоты всасываются в кровоток и лимфатическую систему
                            </div>
                        </div>
                    </div>
                    <div className={'imgBox'}>
                        <img className={'img'} src={img1} alt=""/>
                    </div>
                </div>
            </div>
        )
    }
}
