import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson8/step14-1.png";


export default class step14 extends React.Component {

    render() {
        return (
            <div className="step14">
                <TextBlock
                    text={`Эти статистические данные показывают процент кошек с избыточным, нормальным и недостаточным весом в городах России разной численности населения.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
                <TextBlock
                    text={`Как видно из диаграммы, в России большинство кошек не страдает избыточным весом. Однако в Москве и Санкт-Петербурге количество кошек с лишним весом больше, чем в небольших городах. Это объясняется пониженной активностью домашних кошек в мегаполисах.`}
                />
            </div>
        )
    }
}
