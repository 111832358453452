import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickTest from "../../../Components/LessonAndTestComponents/OnClickTest";
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";

import img1 from "../../../image/lessons/lesson7/step10-1.png";


export default class step10 extends React.Component {

    state = {
        answersData: [`1-2 раза в день`, `4-6 раз в день`, `7-20 раз в день`]
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step10">
                <TextBlock
                    text={`Частота кормления собак отличается от кошек. Знаете ли вы, какая частота приемов пищи оптимальна для взрослых собак?`}
                />
                <InfoBlock
                    text={`Выберите подходящий вариант и нажмите «Проверить».`}
                />
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'block'}
                    rightAnswers={[1]}
                    showNextBtn={this.showNextBtn}
                    img={img1}
                    correctText={`У собак хорошо растягивается желудок. Поэтому оптимальная частота их кормления — 2 раза в день.`}
                />
            </div>
        )
    }
}
