import React from "react";
import './style.scss'

const RadioGroup = (props) => {
    let id = props.idNumber
    return(
        <div className="radio-group">
            <p className="radio-group__label">{props.label}</p>
                {props.items.map((el, index) => {
                    return(
                        <div className="radio-group__item" key={index}>
                            <input 
                                className="radio__input"
                                type="radio"
                                name={props.name}
                                defaultChecked={+props.indexChecked === +index ? true : false}
                                id={id[index]}
                                onChange={props.onChange}
                                value={props.values[index]}
                            />
                            <label className="radio__label">{el}</label>
                        </div>
                    )
                })}
        </div>
    )
}

export default RadioGroup