import React from 'react'
import './style.sass'

export default class popapAfterFinalTest extends React.Component {

    componentDidMount() {
        document.body.scrollIntoView( true);
        document.body.style.overflow = "hidden";
    }

    componentWillUnmount() {
        document.body.style.overflow = "";
    }

    render() {
        return (

            <div className="popapAfterFinalTest">
                <div className="popapAfterFinalTest_wrp">
                    <div
                        className="popapAfterFinalTest_close"
                        onClick={() => {
                            this.props.closeEndPopap !== undefined ? this.props.closeEndPopap() : this.props.history.push('/MyTraining', {showPopapEnd: false})
                        }}
                    />
                    {this.props.testDone <= 0.7 ?
                        <div className="popapAfterFinalTest_head">
                            К сожаления Вы не прошли тест.<br/>
                            Попробуйте снова
                        </div>
                        :
                        <div className="popapAfterFinalTest_head">
                            Вы успешно прошли тест.<br/> Поздравляем!
                        </div>
                    }
                    {/*<div className="popapAfterFinalTest_text">
                        Вы можете ознакомится с другими уроками, <br/>
                        просто нажмите кнопку.
                    </div>*/}
                    <div className="popapAfterFinalTest_btn">
                        <button
                            className="btn btn_blue"
                            onClick={() => {
                                this.props.closeEndPopap !== undefined ? this.props.closeEndPopap() : this.props.history.push('/myTraining', {showPopapEnd: false})
                            }}
                        >продолжить
                        </button>
                    </div>

                </div>
            </div>

        )
    }
}
