import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson24/step5-1.png'

export default class step5 extends React.Component {

    render() {
        return (
            <div className="step5">
                <TextBlock
                    text={`Блюда Sheba<sup>®</sup> имеют привлекательный внешний вид* для владельцев кошек:`}
                />
                <img src={img1} alt="" className="imgCenter"/>
                <div className="info">
                    71% владельцев кошек называют
                    соотношение ломтиков и соуса ОПТИМАЛЬНЫМ
                </div>
            </div>
        )
    }
}
