import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from "../../../image/lessons/lesson18/step8-1.png";


export default class step8 extends React.Component{

    render(){
        return (
            <div className="step8">
                <div className="flex">
                    <div className="flex_el">
                        <TextBlock text={`Лакомство по уходу за зубами <span class="text_dark-blue">PEDIGREE<sup>®</sup> DENTASTIX™</span> является наиболее удобным и эффективным способом ухода за зубами собаки.
                        
                        <span class="text_dark-blue">Благодаря тройному действию PEDIGREE<sup>®</sup> DENTASTIX™:</span>
                        1) Уменьшает образование зубного камня до 80%;
                        
                        2) Очищает труднодоступные зубы;
                        
                        3) Поддерживает здоровье десен.`}/>
                    </div>
                    <div className="flex_el">
                        <img className="flex_el_img" src={img1} alt=""/>
                    </div>
                </div>
            </div>
        )
    }

}
