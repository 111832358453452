import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson16/step13-1.png';

export default class step13 extends React.Component {

    render() {
        return (
            <div className="step13">
                <TextBlock
                    text={`В течение года российский покупатель приобретает корм для своего питомца примерно <span class="text_blue">в 8 разных магазинах.</span>`}
                />
                <img src={img1} className="imgCenter" alt=""/>
            </div>
        )
    }
}
