import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';

export default class step1 extends React.Component {

    planData = [
        `Предки кошек и собак и процесс их одомашнивания`,
        `Распределение кошек и собак по России`,
        `Самые популярные породы кошек и собак`,
    ]

    render() {
        return (
            <div className="step1">
                <div className="head">План урока</div>

                <TextBlock
                    text={`Вы научитесь различать принципы питания собак и кошек, основываясь на их происхождении и породе.
                    
                    В этом уроке рассматриваются следующие вопросы:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'blue'}
                />

            </div>
        )
    }
}
