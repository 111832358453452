import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson24/step12-1.png";
import img2 from "../../../image/lessons/lesson24/step12-2.png";

export default class step12 extends React.Component {

    render() {
        return (
            <div className="step12">
                <div className="head">
                    Sheba<sup>®</sup> Ломтики в желе
                </div>
                <TextBlock
                    text={`Sheba<sup>®</sup> — это незабываемый вкус сочных мясных ломтиков и нежнейшего желе. Поверьте, такое аппетитное сочетание точно заставит замереть в предвкушении даже самых притязательных кошек. Пикантная курочка в желе или нежнейший кролик в желе? Вам остается только выбрать блюдо для своей кошки на сегодня.
                    
                    <span class="text_sheba">Ключевые особенности:</span>`}
                />
                <div className="advantages">
                    <div className="advantages_el">
                        Классические мясные ломтики Sheba<sup>®</sup>
                    </div>
                    <div className="advantages_plus">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.22939 13.425V8.925H0.704395V5.475H5.22939V0.975H8.7794V5.475H13.3044V8.925H8.7794V13.425H5.22939Z" fill="#AC8748"/>
                        </svg>
                    </div>
                    <div className="advantages_el">
                        Насыщенное аппетитное желе
                    </div>
                </div>
                <div className="flex">
                    <img src={img1} alt="" className="flex_img"/>
                    <img src={img2} alt="" className="flex_img"/>
                </div>
            </div>
        )
    }
}
