import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import img1 from '../../../image/lessons/lesson32/step18-1.png';
import img2 from '../../../image/lessons/lesson32/step18-2.png';
import img3 from '../../../image/lessons/lesson32/step18-3.png';


export default class step18 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        pointActive: 0,
        points: [0],
        showPopup: false,
    };

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }


    clickPoint = pointActive => {
        const {points} = this.state
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        this.setState({pointActive, points})

        if (points.length === 4) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    infoData = {
        1: {
            img: img1,
            list: [
                `Является <b>компонентом клеточных мембран</b>, способствует <b>насыщению клеток кислородом</b>`,
                `В составе кожного секрета способствует <b>защите кожи от излишнего испарения влаги и препятствует ломкости и сухости волоса</b>`,
                `Кожный секрет проникает в межклеточное пространство рогового слоя кожи, обеспечивая <b>эластичность кожи</b>`,
                `<b>Подавляет активность ферментов</b>, участвующих в процессе кератинизации клеток кожи`,
            ],
        },
        2: {
            img: img1,
            list: [
                `Витамины группы B являются <b>ко-ферментами в реакциях клеточного метаболизма</b>, участвуют <b>в синтезе жирных кислот и связывании аминокислотных цепей</b>`,
                `Ускоряют процесс объединения аминокислот в белковые цепи, <b>способствуя регенерация тканей</b>`,
                `Наряду с пиридоксином(B6) и ниацином(В3), <b>принимают участие в синтезе коллагена – основного белка кожи</b>`,
                `Обеспечивают <b>синтез кератина</b> – базового вещества волоса и всех производных кожи`,
            ],
        },
        3: {
            img: img1,
            list: [
                `Регулирует <b>процесс формирования волоса</b>`,
                `<b>Участвует в синтезе коллагена – основного белка кожи</b>`,
                `<b>Стимулирует обменные процессы</b> в коже`,
                `Необходим для <b>синтеза жирных кислот</b>`,
                `Участвует в процессе <b>обмена витамина А</b>`,
                `Действует как <b>необходимый кофактор более чем в 10 биохимических реакциях</b>`,
                `<b>Регулирует</b> содержание <b>кальция и меди</b>`,
            ],
        },
    }

    render() {
        const {pointActive, windowWidth, points} = this.state
        return (
            <div className="step18">
                <InfoBlock
                    text={`Нажмите на активные элементы, чтобы узнать больше о роли питания в поддержании здоровья кожи и шерсти у собаки.`}
                />
                <TextBlock
                    text={`Рационы PEDIGREE<sup>®</sup> cодержат высокоусвояемые ингредиенты:`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_box">
                            <div className="flex_el_head">
                                Линолевая кислота
                            </div>
                            <img
                                src={img1} alt=""
                                className={'flex_el_img ' + (pointActive === 1 && windowWidth > 767 && 'active ') + (points.indexOf(1) >= 0 && windowWidth <= 767 && ' active')}
                                onClick={() => {this.clickPoint(1)}}
                            />
                        </div>
                        <div className={'flex_el_block ' + (pointActive === 1 && windowWidth > 767 && 'active ') + (points.indexOf(1) >= 0 && windowWidth <= 767 && ' active')}>
                            <ul>
                                {this.infoData[1].list.map((item, index) => {
                                    return (
                                        <li
                                            key={index}
                                            dangerouslySetInnerHTML={{ __html: item }}
                                        />
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_box">
                            <div className="flex_el_head">
                                Витамины группы В
                            </div>
                            <img
                                src={img2} alt=""
                                className={'flex_el_img ' + (pointActive === 2 && windowWidth > 767 && 'active ') + (points.indexOf(2) >= 0 && windowWidth <= 767 && ' active')}
                                onClick={() => {this.clickPoint(2)}}
                            />
                        </div>
                        <div className={'flex_el_block ' + (pointActive === 2 && windowWidth > 767 && 'active ') + (points.indexOf(2) >= 0 && windowWidth <= 767 && ' active')}>
                            <ul>
                                {this.infoData[2].list.map((item, index) => {
                                    return (
                                        <li
                                            key={index}
                                            dangerouslySetInnerHTML={{ __html: item }}
                                        />
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_box">
                            <div className="flex_el_head">
                                Цинк
                            </div>
                            <img
                                src={img3} alt=""
                                className={'flex_el_img ' + (pointActive === 3 && windowWidth > 767 && 'active ') + (points.indexOf(3) >= 0 && windowWidth <= 767 && ' active')}
                                onClick={() => {this.clickPoint(3)}}/>
                        </div>
                        <div className={'flex_el_block ' + (pointActive === 3 && windowWidth > 767 && 'active ') + (points.indexOf(3) >= 0 && windowWidth <= 767 && ' active')}>
                            <ul>
                                {this.infoData[3].list.map((item, index) => {
                                    return (
                                        <li
                                            key={index}
                                            dangerouslySetInnerHTML={{ __html: item }}
                                        />
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
