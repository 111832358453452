import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson22/step5-1.png';

export default class step5 extends React.Component {

    render() {
        return (
            <div className="step5">
                <div className="head">
                    Каждая кошка может выбрать свой любимый DREAMIES™ из 8 разных вкусов
                </div>
                <TextBlock
                    text={`Известно, что кошки по своей природе избирательны в еде и питаются исключительно тем, что нравится только им. Поэтому DREAMIES™ предлагает <b>широкое многообразие вкусов</b>, которые можно чередовать между собой. Таким образом, DREAMIES™ <b>учитывает пожелания даже самых прихотливых</b> питомцев.
                    
                    Перед вами расположена палитра вкусов DREAMIES™:`}
                />
                <img className="imgCenter" src={img1} alt=""/>
            </div>
        )
    }
}
