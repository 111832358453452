import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step6 extends React.Component {

    render() {
        return (
            <div className="step6">
               <div className="head">Зачем кошкам орган Якобсона?</div>
                <TextBlock
                    text={`Часть обонятельной области носа у кошек обособилась в так называемый орган Якобсона. Вы можете понять, что она использует его, по характерной позе: она останавливается как вкопанная, открывает пасть и вдыхает воздух, втягивая губы и собирая кожу на голове в своеобразную «насмешливую» гримасу (улыбку Флемена). Существуют три основных гипотезы о его назначении:`}
                />
                <div className="info">
                    <div className="info_el">
                        <div className="info_el_number">1</div>
                        <div className="info_el_text">
                            Роль информатора, специализирующегося на восприятии феромонов и других социальных запахов.
                        </div>
                    </div>
                    <div className="info_el">
                        <div className="info_el_number">2</div>
                        <div className="info_el_text">
                            Этот орган воспринимает запах пищи, поступившей в ротовую полость, как бы дополняя информацию о ней, полученную органом обоняния. Сторонники этой гипотезы считают, что животные, таким образом, обладают ротовым обонянием.
                        </div>
                    </div>
                    <div className="info_el">
                        <div className="info_el_number">3</div>
                        <div className="info_el_text">
                            Сторонники третьей гипотезы называют этот орган «шестым чувством», позволяющим кошкам предсказывать катастрофические события (извержение вулкана, землетрясение, приближение лесного пожара и т.д.), благодаря анализу воздушных потоков атмосферы, отражающих незначительные изменения химического состава воздуха.
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
