import React from 'react'
import Resume from "../../../Components/LessonAndTestComponents/Resume";

import img1 from "../../../image/lessons/lesson4/step15-1.png";

export default class step15 extends React.Component {
    state = {
        resumeData: [
            `Углеводы не являются незаменимыми питательными веществами, но они используются во многих кормах как <b>источник энергии.</b>`,
            `<b>Клетчатка хорошо влияет на пищеварение,</b> стимулируя моторику желудочно-кишечного тракта.`,
            `Все углеводы делятся на три группы: <b>сахара, крахмал и клетчатку.</b>`,
            `<b>Пребиотики</b> — виды клетчатки, которые способствуют размножению полезных бактерий в кишечнике.`,
        ]
    }

    render() {
        return (
            <div className="step15">
                <Resume
                    resumeData={this.state.resumeData}
                    img={img1}
                />
            </div>
        )
    }
}
