import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson8/step24-1.png";
import img2 from "../../../image/lessons/lesson8/step24-2.png";
import img3 from "../../../image/lessons/lesson8/step24-3.png";

export default class step24 extends React.Component {

    state = {
        flexData: ['острая диарея', 'хроническая диарея'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: true})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData, flexHidden} = this.state
        return (
            <div className="step24">
                <TextBlock
                    text={`Существует два основных вида диареи.`}
                />
                <InfoBlock
                    text={`Нажмите на каждый из видов, чтобы узнать симптомы и методы лечения.`}
                />
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_flex">
                            <div className="imgDiv_wrap_flex_box">
                                <TextBlock
                                    text={`<b>Причины:</b>`}
                                />
                                <ul className="list">
                                    <li className="list_item">резкая смена рациона;</li>
                                    <li className="list_item">переедание;</li>
                                    <li className="list_item">употребление некоторых продуктов (например, молока, которое не нужно взрослым кошкам и собакам).</li>
                                </ul>
                                <TextBlock
                                    text={`<b>Симптомы:</b>`}
                                />
                                <ul className="list">
                                    <li className="list_item">боль в животе;</li>
                                    <li className="list_item">жидкий стул.</li>
                                </ul>
                                <TextBlock
                                    text={`<b>Лечение:</b>`}
                                />
                                <ul className="list">
                                    <li className="list_item">посещение ветеринарного врача;</li>
                                    <li className="list_item">соблюдение рекомендаций врача.</li>
                                </ul>
                            </div>
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img2} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_flex">
                            <TextBlock
                                text={`Хроническая диарея продолжается длительное время и периодически повторяется. Во избежание ее обострения <b>необходима диагностика ветеринарного врача</b> и специально назначенное лечение.
                                
                                При составлении истории болезни выявляется продолжительность и частота диареи, характер кала, аппетит животного, изменения в весе и другие факторы.`}
                            />
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img3} alt=""/>
                            </div>
                        </div>
                    </div>
                </OnClickBlock>
                <img src={img1} alt="" className={'imgCenter ' + (flexHidden && 'hidden')}/>
            </div>
        )
    }
}
