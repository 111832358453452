import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson7/step17-1.png'


export default class step17 extends React.Component {


    render() {
        return (
            <div className="step17">
                <TextBlock
                    text={`У каждого необходимого питательного вещества есть свой минимум и максимум потребления. Эти значения определялись путем длительных исследований, некоторые из которых продолжаются до сих пор.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
                <div className="advice">
                    Избыток питательных веществ так же вредит здоровью питомца, как их дефицит. Поэтому так важно не перекармливать животное и грамотно составлять его дневной рацион.
                </div>
            </div>
        )
    }
}
