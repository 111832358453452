import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson32/step38-1.png'
import img1xs from '../../../image/lessons/lesson32/step38-1xs.png'


export default class step6 extends React.Component {


    render() {
        return (
            <div className="step38">
                <div className="head">
                    2. Склонность к заболеваниям мочевыделительной системы
                </div>
                <TextBlock
                    text={`<b>Предрасположенность собак мелких пород к МКБ обусловлена небольшим объемом образуемой мочи, редким мочеиспусканием и, как следствие, повышенной концентрацией неорганических веществ в моче<b>`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} alt="" className="imgCenter"/>
                </picture>
                <TextBlock
                    text={`<b>Породы собак, наиболее часто подверженные мочекаменной болезни:</b>`}
                />
                <div className="flex">
                    <ul>
                        <li>Бишон-фризе</li>
                        <li>Такса</li>
                        <li>Лхасский апсо</li>
                        <li>Карликовый пудель</li>
                    </ul>
                    <ul>
                        <li>Цвергшнауцер</li>
                        <li>Ши-тцу</li>
                        <li>Йоркширский терьер</li>
                    </ul>
                </div>
            </div>
        )
    }
}
