import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";
import PlanInFirstStep from "../../../Components/LessonAndTestComponents/PlanInFirstStep";

export default class step1 extends React.Component {
    planData = [
        `Особенности покупательского профиля`,
        `Стимулы и барьеры к совершению покупки`,
        `Покупательское поведение`,
    ]

    render() {
        return (
            <div className="step1">
                <div className="head">План урока</div>

                <TextBlock
                    text={`Вы изучите:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'blue'}
                />
            </div>
        )
    }
}
