import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";
import Popup from "../../../Components/Popup";

import img1 from '../../../image/lessons/lesson32/step4-1.png';
import img1xs from '../../../image/lessons/lesson32/step4-1xs.png';


export default class step4 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        pointActive: window.innerWidth < 767 ? 1 : 0,
        points: [0],
        showPopup: false,
    };

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    changePoint = pointActive => {
        const {points, windowWidth} = this.state
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }
        if (windowWidth < 767) {

            if (pointActive > 10) {
                this.setState({
                    pointActive: 10,
                    showPopup: false,
                })
            } else {
                this.setState({
                    pointActive: pointActive,
                    showPopup: true,
                    points: points
                })
            }

        } else {
            document.querySelector('#progress').style.setProperty('--left', (this.infoData[pointActive].cursor) + '%')
            this.setState({pointActive, points})
        }

        if (points.length === 11) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    infoData = {
        1: {
            year: `1975`,
            text: `Участие в определении потребностей взрослых собак в аминокислотах`,
            cursor: 1, // Положение курсора
        },
        2: {
            year: `1985`,
            text: `Участие в публикации книги WALTHAM™ Research о питании домашних животных`,
            cursor: 11.8,
        },
        3: {
            year: `1997`,
            text: `Патент на уровень цинка и линолевой кислоты, определяющих состояние кожи и шерсти`,
            cursor: 22.4,
        },
        4: {
            year: `1999`,
            text: `Создание рациона для собак на основе исследования о применении зеленогубого моллюска для поддержания суставов`,
            cursor: 33,
        },
        5: {
            year: `2000`,
            text: `Демонстрация положительного влияния уровня антиоксидантов в рационе щенков на здоровье иммунной системы`,
            cursor: 43.6,
        },
        6: {
            year: `2005`,
            text: `Первый Lactobacillus пробиотик, принятый к использованию в рационых для собак`,
            cursor: 54.4,
        },
        7: {
            year: `2007`,
            text: `Открытие гена, обуславливающего размер собак`,
            cursor: 65,
        },
        8: {
            year: `2008`,
            text: `Определение генетического влияния на стереотипические характеристики собак`,
            cursor: 75.6,
        },
        9: {
            year: `2009`,
            text: `Совместно с FEDIAF и ключевыми учёными, установлен новый безопасный верхний уровень витамина А в питании щенков на ранних стадиях развития `,
            cursor: 86.3,
        },
        10: {
            year: `2012`,
            text: `Показано, что снижение массы тела является значимым фактором в повышении качества жизни животных при ожирении`,
            cursor: 97,
        },
    }

    render() {
        const {points, pointActive, showPopup} = this.state
        return (
            <div className="step4">

                <TextBlock
                    text={`В основе рационов PEDIGREE<sup>®</sup> лежат научные исследования научно-исследовательского института Waltham. Научно-исследовательский институт Waltham изучает вопросы питания домашних животных с 1963 года. За это время научно-исследовательский институт Waltham совершил ряд важных и ценных научных открытий.`}
                />
                <InfoBlock
                    text={`Познакомьтесь с наиболее значимыми открытиями научно-исследовательского института Waltham, нажимая на точки, либо кнопку “Продолжить”`}
                />
                <picture className="img-box">
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} alt="" className="img imgCenter"/>
                </picture>
                <div className="info">
                    {Object.keys(this.infoData).map(item => {
                        return (
                            <div
                                key={item}
                                className={'info_item ' + (pointActive === Number(item) && 'info_item_show')}
                            >
                                <p className="info_year">
                                    {this.infoData[item].year}
                                </p>
                                <p className="info_text">
                                    {this.infoData[item].text}
                                </p>
                            </div>
                        )
                    })}
                </div>
                <div id="progress" className="progress">
                    <div className={'progress_cursor ' + (pointActive > 0 && 'show')}>
                        <svg width="16" height="29" viewBox="0 0 16 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 19.5652L9.58721 27.9297C8.78667 28.9739 7.21333 28.9739 6.41279 27.9297L9.12238e-07 19.5652L16 19.5652Z" fill="#BA0E0E"/>
                            <path d="M16 19.5652L8.75491 22.5184C8.27097 22.7156 7.72903 22.7156 7.24509 22.5184L9.12238e-07 19.5652L2.44784e-06 1.99999C2.5444e-06 0.895426 0.895433 -3.22783e-06 2 -3.13127e-06L14 -2.08219e-06C15.1046 -1.98563e-06 16 0.89543 16 2L16 19.5652Z" fill="#BA0E0E"/>
                        </svg>
                    </div>
                    <div className="progress_big">
                        {Object.keys(this.infoData).slice(1).map(item => {
                            return (
                                <div key={item} className={'progress_big_item ' + (points.indexOf(Number(item)) >= 0 && 'active')}/>
                            )
                        })}
                    </div>
                    <div className="progress_small">
                        {Object.keys(this.infoData).map(item => {
                            return (
                                <div
                                    key={item}
                                    className={'progress_small_item ' + (points.indexOf(Number(item)) >= 0 && item === '1' ? 'active-first' : points.indexOf(Number(item)) >= 0 ? 'active' : '')}
                                />
                            )
                        })}
                    </div>
                    <div className="progress_points">
                        {Object.keys(this.infoData).map(item => {
                            return (
                                <div
                                    key={item}
                                    className={'progress_points_item ' + (points.indexOf(Number(item)) >= 0 && 'active')}
                                    onClick={() => {this.changePoint(Number(item))}}
                                >
                                    <div
                                        className={'progress_points_item_red ' + (points.indexOf(Number(item)) >= 0 && 'active')}
                                        onClick={() => {this.changePoint(Number(item))}}
                                    >
                                        {item}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="progress-phone">
                    {Object.keys(this.infoData).map(item => {
                        return (
                            <div
                                key={item}
                                className={'progress-phone_box ' + (pointActive === 2 &&  Number(item) === 1 && points.length < 3 ? 'active' : pointActive === 2 &&  Number(item) === 2 && points.length < 3 ? '' : pointActive === Number(item) ? 'active' : '')}
                            >
                                <div className="progress-phone_el first">
                                    {Number(item) > 1 &&
                                    <div className="progress-phone_el_big active">
                                        <div className="progress-phone_el_small active"/>
                                    </div>
                                    }
                                </div>
                                <div className="progress-phone_el second">
                                    <div className={'progress-phone_el_point ' + (points.indexOf(Number(item)) >= 0 && 'active')} onClick={() => {this.changePoint(Number(item))}}>
                                        <div className={'progress-phone_el_small ' + (points.indexOf(Number(item)) >= 0 && 'active')}/>
                                        <div className={'progress-phone_el_point_red ' + (points.indexOf(Number(item)) >= 0 && 'active')}>
                                            {item}
                                        </div>
                                    </div>
                                    {Number(item) < 10 &&
                                    <div className={'progress-phone_el_big ' + (points.indexOf(Number(item)) >= 0 && 'active')}>
                                        <div className={'progress-phone_el_small ' + (points.indexOf(Number(item)) >= 0 && 'active')}/>
                                    </div>
                                    }
                                </div>
                                {Number(item) < 10 &&
                                <div className="progress-phone_el third">
                                    <div className={'progress-phone_el_point ' + (pointActive === 2 && points.length < 3 && 'active')} onClick={() => {this.changePoint(Number(item) + 1)}}>
                                        <div className={'progress-phone_el_small ' + (pointActive === 2 && points.length < 3 && 'active')}/>
                                        <div className={'progress-phone_el_point_red ' + (pointActive === 2 && points.length < 3 && 'active')}>
                                            {Number(item) + 1}
                                        </div>
                                    </div>
                                    <div className={'progress-phone_el_big ' + (pointActive === 2 && points.length < 3 && 'active')}>
                                        <div className={'progress-phone_el_small ' + (pointActive === 2 && points.length < 3 && 'active')}/>
                                    </div>
                                </div>
                                }
                            </div>
                        )
                    })}
                </div>
                {showPopup &&
                <Popup
                    closePopup={this.closePopup}
                >
                    <div className="Popup_year">
                        {this.infoData[pointActive].year}
                    </div>
                    <TextBlock
                        text={this.infoData[pointActive].text}
                    />
                    <button
                        className="btn btn_blue"
                        onClick={() => {this.changePoint(pointActive + 1)}}
                    >
                        продолжить
                    </button>
                </Popup>
                }
            </div>
        )
    }
}
