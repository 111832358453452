import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson2/step2-1.png'
import img2 from '../../../image/lessons/lesson2/step2-2.png'
import img3 from '../../../image/lessons/lesson2/step2-3.png'
import img4 from '../../../image/lessons/lesson2/step2-4.png'



export default class step2 extends React.Component {


    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step2">
                <TextBlock
                    text={`Как и всем живым существам, кошкам и собакам необходима пища — субстанция, содержащая в себе необходимые питательные вещества, которые усваиваются в организме после поглощения и перерабатываются в энергию.`}
                />
                <div className="head">
                    4 главные функции питательных веществ
                </div>
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_border">
                            <img className="flex_el_img" src={img1} alt=""/>
                        </div>
                        <p className="flex_el_text">Способствуют движению мышц</p>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_border">
                            <img className="flex_el_img" src={img2} alt=""/>
                        </div>
                        <p className="flex_el_text">Обеспечивают рост организма</p>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_border">
                            <img className="flex_el_img" src={img3} alt=""/>
                        </div>
                        <p className="flex_el_text">Обеспечивают восстановительные процессы в организме</p>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_border">
                            <img className="flex_el_img" src={img4} alt=""/>
                        </div>
                        <p className="flex_el_text">Поддерживают температуру тела</p>
                    </div>
                </div>
            </div>
        )
    }
}
