import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson16/step2-1.png';

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <TextBlock
                    text={`Кто он — покупатель нашей категории?`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
