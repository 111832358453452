import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson24/step7-1.png'

export default class step7 extends React.Component {

    render() {
        return (
            <div className="step7">
                <TextBlock
                    text={`В основе блюд Sheba<sup>®</sup> – высокое содержание мясных ингредиентов с простым и понятным составом:`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
