import React from 'react'
import './style.sass'

export default props => {
    return (
        <div className="VideoBlock" id={props.id ? props.id : ''}>
            <iframe title={props.title} width="560" height="315" src={props.src} frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
            />
        </div>
    )
}
