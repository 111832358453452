import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson12/step18-1.png'
import img1xs from "../../../image/lessons/lesson12/step18-1xs.png";

export default class step18 extends React.Component {


    render() {
        return (
            <div className="step18">
                <TextBlock
                    text={`Третий критерий, который отличает корма для домашних животных — <b>их специализация,</b> то есть наличие внутри ассортимента разнообразных рецептур для различных групп животных.
                    
                    <b>Чем выше класс корма, тем выше специализация под конкретную группу животных,</b> например:`}
                />
                <div className="flex">
                    {/*<div className="flex_el">*/}
                    {/*    <ul>*/}
                    {/*        <li>корм эконом класса KITEKAT™ — это корм для взрослых кошек без учёта образа жизни животного и других особенностей;</li>*/}
                    {/*        <li>корм премиум класса имеет ещё большую специализацию, он учитывает возраст животного, его образ жизни, особенности пищеварения, а также может учитывать породные и другие особенности;</li>*/}
                    {/*        <li>корм мейн-стрим класса WHISKAS<sup>®</sup> предлагает рационы с учётом возраста животного и его образа жизни (корм для котят, для кошек старше 7 лет, для стерилизованных кошек).</li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}
                    <picture>
                        <source srcSet={img1xs} media="(max-width: 767px)"/>
                        <img src={img1} alt=""/>
                    </picture>
                </div>
            </div>
        )
    }
}
