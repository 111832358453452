import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";

import img1 from '../../../image/lessons/lesson13/step13-1.png'
import img1_1 from '../../../image/lessons/lesson13/step13-1-1.png'
import img2_1 from '../../../image/lessons/lesson13/step13-2-1.png'
import img2_2 from '../../../image/lessons/lesson13/step13-2-2.png'
import img3_1 from '../../../image/lessons/lesson13/step13-3-1.png'
import img3_2 from '../../../image/lessons/lesson13/step13-3-2.png'
import img4_1 from '../../../image/lessons/lesson13/step13-4-1.png'
import img4_2 from '../../../image/lessons/lesson13/step13-4-2.png'
import img5_1 from '../../../image/lessons/lesson13/step13-5-1.png'
import img5_2 from '../../../image/lessons/lesson13/step13-5-2.png'


export default class step13 extends React.Component {

    state = {
        popupActive: 0,
        popupShow: false,
        flexHidden: false,
        points: [],
    };

    showPopup = popupActive => {
        const {points} = this.state;
        if (points.indexOf(popupActive) === -1) {
            points.push(popupActive)
        }

        if (points.length === 5) {
            this.setState({popupActive: 0, popupShow: false})
        }

        this.setState({
            popupShow: true,
            popupActive: popupActive,
            points: points
        })

        if (popupActive > 5) {
            this.setState({popupActive: 0, popupShow: false})
        }

        if (points.length === 5) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({popupShow: false})
    }

    popupWrp = {
        1: {
            img_head: img1_1,
            head: 'Сырьё',
            text: `Прежде чем поступить в производственный процесс, сырье для сухого корма <b>подвергается высушиванию.</b> То есть из курицы производится куриная мука, из говядины — говяжья мука и т.д.

            Каждая партия сырья <b>проверяется на качество и безопасность</b> по нескольким показателям, а также <b>определяются его окислительные характеристики</b> с целью выяснить способность сырья к длительному хранению.`,
            img: false
        },
        2: {
            img_head: img2_1,
            head: 'Перемешивание и измельчение',
            text: `Первый этап производства сухого корма <b>— соединение всех сырьевых компонентов согласно рецептуре</b> (мясные муки, растительные муки, масла и жиры, витамины и минералы), их перемешивание и измельчение.
            
            Этот этап очень важен, так как <b>чем лучше будет проведено измельчение сырья, тем лучше корм будет усваиваться</b> в желудочно-кишечном тракте животного.`,
            img: img2_2
        },
        3: {
            img_head: img3_1,
            head: 'Формирование гранул методом экструзии',
            text: `<b>Экструзия</b> — это процесс, при котором сырье из сухого муко образного состояния <b>превращается в пористые гранулы под воздействием высокой температуры и давления.</b> В процессе такого воздействия происходит расщепление сложных углеводов на более простые, что обеспечивает существенное <b>улучшение усвояемости корма.</b>`,
            img: img3_2,
            text2: `Преимущества этого метода:`,
            ListData: [
                `создается продукт с заданными внешними свойствами;`,
                `обеспечивается микробиологическая безопасность продукта;`,
                `сохраняется постоянство характеристик продукта;`,
                `повышается усвояемость продукта за счет изменения структуры сложных углеводов и повышения пористости гранул.`
            ],
        },
        4: {
            img_head: img4_1,
            head: 'Высушивание гранул',
            text: `Результатом экструзии является <b>формирование пористой гранулы.</b> Однако создание гранулы еще не означает, что продукт готов.
            
            Очень важно <b>высушить</b> гранулы после экструзии, то есть под действием температуры убрать из корма лишнюю влагу.
            
            Важно сделать так, чтобы в готовом сухом корме <b>содержание влаги не превышало 10%,</b> так как такое высушивание позволяет защитить продукт от воздействия микроорганизмов в окружающей среде и обеспечить ему большой срок годности <b>без дополнительных консервантов.</b>`,
            img: img4_2
        },
        5: {
            img_head: img5_1,
            head: 'Покрытие гранул бульоном',
            text: `Наконец, финальный этап производства сухого корма — покрытие сухой гранулы специальным бульоном, который также называется спреем. Такой бульон <b>варится из субпродуктов</b> (например, печени), <b>а также содержит масла и жиры.</b>
            
            Гранулы корма покрываются бульоном для того, чтобы корм стал более <b>привлекательным</b> для собак и кошек, ведь они являются хищниками, а значит, им очень нравится вкус и запах мясных ингредиентов, богатых белком и жиром.
            
            Еще одна важная задача бульона — быть «защитной оболочкой» для гранулы, то есть <b>защищать ее от проникновения внутрь влаги.</b>`,
            img: img5_2
        },
    }

    render() {
        const {popupShow, popupActive} = this.state
        return (
            <div className="step13">
                <TextBlock
                    text={`После проверки и контроля качества сырья запускается производственный процесс. На схеме ниже показан процесс производства <b>сухого корма.</b>`}
                />
                <InfoBlock
                    text={`Нажмите на вопросительные знаки, чтобы посмотреть описания производственных процессов.`}
                />
                <div className="imgDiv">
                    {Object.keys(this.popupWrp).map(item => {
                        return (
                            <div
                                key={item}
                                className="point"
                                onClick={() => {this.showPopup(Number(item))}}
                            >
                                ?
                            </div>
                        )
                    })
                    }
                    <img src={img1} alt="" className="img"/>
                </div>
                {popupShow &&
                <PopupInStep
                    closePopup={this.closePopup}
                    popupImg={this.popupWrp[popupActive].img_head}
                    popupHead={this.popupWrp[popupActive].head}
                    popupText={this.popupWrp[popupActive].text}
                    popupImg2={this.popupWrp[popupActive].img}
                    popupText2={this.popupWrp[popupActive].text2}
                    ListData={this.popupWrp[popupActive].ListData}
                    changePoint={() => this.showPopup(popupActive + 1)}
                />
                }
            </div>
        )
    }
}
