import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import img1 from '../../../image/lessons/lesson9/step8-1.png'


export default class step8 extends React.Component {
    state = {
        clicked: [],
        correct: ''
    };

    onClick = (el) => {
        const {clicked, correct} = this.state
        if(correct === '') {
            if (clicked.indexOf(el) === -1) {
                clicked.push(el)
            } else {
                clicked.splice(clicked.indexOf(el), 1)
            }
            this.setState({clicked});
        }
    }

    checkClick = () => {
        const {clicked, correct} = this.state
        if (clicked.length === 1 && clicked[0] === 4 && correct === '') {
            this.setState({correct: '1'})
        } else {
            this.setState({correct: '0'})
        }
        this.props.showNextBtn()
    }

    render() {
        const {clicked, correct} = this.state
        return (
            <div className="step8">

                <InfoBlock
                    text={
                        `Сделайте расчеты и выберите ответ, который у вас получился.`
                    }
                />
                <div className="flex">
                    <div className={'flex_el ' + (clicked.indexOf(1) >= 0 && ' active ') + (correct !== '' && ' inCorrect') } onClick={() => {
                        this.onClick(1)
                    }}>
                        400г
                    </div>
                    <div className={'flex_el ' + (clicked.indexOf(2) >= 0 && ' active ') + (correct !== '' && ' inCorrect')} onClick={() => {
                        this.onClick(2)
                    }}>
                        525г
                    </div>
                    <div className={'flex_el ' + (clicked.indexOf(3) >= 0 && ' active ') + (correct !== '' && ' inCorrect')} onClick={() => {
                        this.onClick(3)
                    }}>
                        475г
                    </div>
                    <div className={'flex_el ' + (clicked.indexOf(4) >= 0 && ' active ') + (correct !== '' && ' correct')} onClick={() => {
                        this.onClick(4)
                    }}>
                        486г
                    </div>
                </div>
                <img src={img1} alt="" className="imgCenter"/>
                {correct !== '' ?
                    <div className={'info ' + (correct === '0' && 'error')}>
                        <div className="info_head">
                            {correct === '1' ? 'все верно!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                        <div className="infoText">
                            {correct === '1' ?
                                'Вы отлично справились. Убедитесь, что ваш процесс расчета был последовательным и правильным, посмотрев на верное решение.'
                                :
                                'Посмотрите верное решение.'}

                        </div>
                    </div>
                    :
                    <button className="btn btn_blue" onClick={() => this.checkClick()}>
                        ПРОВЕРИТЬ
                    </button>
                }

            </div>
        )
    }
}
