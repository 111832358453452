import React from 'react'
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";

import img1 from '../../../image/lessons/lesson27/step1-1.png'

export default class step1 extends React.Component {

    render() {
        return (
            <div className="step1">
                <div className="title">
                    Вебинар по работе с мифами о готовых кормах для домашних животных
                </div>
                <div className="box">
                    <VideoBlock
                        src={'https://www.youtube.com/embed/cdiBcEEEvEU'}
                        title={'https://www.youtube.com/watch?v=cdiBcEEEvEU&feature=emb_logo'}
                    />
                </div>
                <img src={img1} alt="" className="img"/>
            </div>
        )
    }
}
