import React from 'react'
import Resume from "../../../Components/LessonAndTestComponents/Resume";

import img1 from "../../../image/lessons/lesson8/step21-1.png";

export default class step21 extends React.Component {

    state = {
        resumeData: [
            `В России количество кошек и собак, имеющих лишний вес, <b>больше в крупных городах</b>, чем в небольших населённых пунктах.`,
            `<b>Ожирение</b> у кошек и собак ведет к серьезным проблемам со здоровьем.`,
            `<b>Контроль калорийности</b> рациона и <b>регулярная физическая активность</b> — важнейшие аспекты поддержания нормального веса питомца.`,
        ]
    }

    render() {
        return (
            <div className="step21">
                <Resume
                    resumeData={this.state.resumeData}
                    img={img1}
                />
            </div>
        )
    }
}
