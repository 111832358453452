import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson17/step2-1.png'



export default class step2 extends React.Component {


    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step2">
                <TextBlock
                    text={`<b>Категория кормов для животных построена на заботе и любви, и это является залогом ее успешного роста!</b>
                    
                    Эти факты красноречиво говорят о том, что люди любят своих питомцев и хотят заботиться о них.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
