import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson2/step17-1.png';

export default class step17 extends React.Component {

    render() {
        return (
            <div className="step17">
                <div className="flex">
                    <div className="flex_el">
                        <div className="head">
                            Пример нарушения баланса:
                        </div>
                        <TextBlock
                            text={`Допустим, собака весит 35 кг и ее суточная норма энергии составляет 1600 ккал.
                            
                            Если ежедневно ей дают на 25 г сухого корма больше, то она потребляет на 100 ккал больше нормы. Из-за этого ее вес может увеличиться на 2-3 кг в течение года!`}
                        />
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_imgBox">
                            <img src={img1} alt="" className="img"/>
                        </div>

                    </div>
                </div>
                <div className="advice">
                    Таким образом, даже небольшие отклонения от суточной нормы энергии в течение длительного времени могут вызвать заметные изменения в физическом состоянии питомца и негативно повлиять на его здоровье.
                </div>
            </div>
        )
    }
}
