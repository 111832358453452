import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson23/step2-1.png'

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <TextBlock
                    text={`Мы верим, что каждая кошка и собака достойна питания самого высокого качества.
                    Именно поэтому мы создали NATURE’S TABLE™ — премиальный натуральный корм, в состав которого вошло лучшее, что может предложить природа.
                    
                    Качественные натуральные ингредиенты, высокое содержание белка и никаких вредных добавок — всё для того, чтобы ваш питомец был здоров и счастлив.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
