import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import ArrowBack from '../../../Components/LessonAndTestComponents/ArrowBack';

import img1 from '../../../image/lessons/lesson3/step9-1.png';
import img2 from '../../../image/lessons/lesson3/step9-2.png';
import img3 from '../../../image/lessons/lesson3/step9-3.png';


export default class step9 extends React.Component {
    state = {
        flexActive: 0,
        flexs: [1],
        flexHidden: false,
    };

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }
        this.setState({flexActive, flexs, flexHidden: true})
        if (flexs.length === 2) {
            this.props.showNextBtn()
        }
    }

    closeImgDiv = () => {this.setState({flexHidden: false, flexActive: 0})}


    render() {
        const {flexActive, flexHidden} = this.state
        return (
            <div className="step9">
                <TextBlock
                    text={`Из-за различий в структуре аминокислот не все белки имеют одинаковые питательные качества. Чтобы различать белки между собой, нужно знать их происхождение.`}
                    hidden={flexHidden === true ? ' hidden' : ''}
                />
                <InfoBlock
                    text={`Нажмите на блок, чтобы получить дополнительную информацию.
                    
                    Для продолжения, пожалуйста, ознакомьтесь со всей представленной информацией в этом шаге урока.`}
                    hidden={flexHidden === true ? ' hidden' : ''}
                />

                <div className={'flex ' + (flexHidden === true && 'hidden')}>
                    <div className="flex_el flex_el_inactive">
                        Биологическая ценность белка
                    </div>
                    <div className="flex_el_sign">=</div>
                    <div className={'flex_el ' + (flexActive === 1 && 'active')} onClick={() => {
                        this.changeFlex(1)
                    }}>Набор аминокислот
                    </div>
                    <div className="flex_el_sign">+</div>
                    <div className={'flex_el ' + (flexActive === 2 && 'active')} onClick={() => {
                        this.changeFlex(2)
                    }}>Усвояемость организмом
                    </div>
                </div>
                {flexActive !== 0 &&
                <div className={'imgDiv ' + (flexActive === 2 ? 'center' : flexActive === 3 ? 'right' : '')}>
                    <ArrowBack
                        returnMenu={this.closeImgDiv}
                    />
                    {flexActive === 1 &&
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Аминокислоты, получаемые из белка, используются организмом для различных целей, главная из которых — производство новых белков организмом.
                            
                            На данной схеме из двух источников белка получилось два разных белка.
                            
                            Для создания белка А организму понадобились аминокислоты только из мясного белка, в то время как для белка Б потребовались аминокислоты как взятые из мяса, так и из рыбы.`}
                        />
                        <img src={img1} alt=""/>
                    </div>
                    }
                    {flexActive === 2 &&
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Усвояемость — это количество белка, которое переваривается в пищеварительной системе и усваивается организмом.
                            
                            Большое количество белка в пище не всегда является лучшим решением.
                            
                            Качественный белок совмещает в себе хорошую усвояемость и большое количество незаменимых аминокислот. К таким белкам относятся: яйца, мясо (в том числе субпродукты), птица, рыба и зерновой глютен.`}
                        />
                        <img src={img2} alt=""/>
                    </div>
                    }
                </div>
                }
                <TextBlock
                    text={`При выборе корма нужно обращать внимание на источник белка и делать выводы о его биологической ценности.`}
                    hidden={flexHidden === true ? ' hidden' : ''}
                />
                <div className={'flexBottom ' + (flexHidden === true ? 'hidden' : '')}>
                    <div className="flexBottom_el text">
                        Важно не только количество белка в рационе, но и его качество!
                    </div>
                    <div className="flexBottom_el img">
                        <div className="flexBottom_imgBox">
                            <img src={img3} alt="" className="flexBottom_img"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
