import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'
import PopupInStep from '../../../Components/LessonAndTestComponents/PopupInStep'

import img1 from '../../../image/lessons/lesson9/step7-1.jpg'
import img2 from '../../../image/lessons/lesson9/step7-2.png'
import img3 from '../../../image/lessons/lesson9/step7-3.png'
import img4 from '../../../image/lessons/lesson9/step7-4.png'


export default class step7 extends React.Component {
    state = {
        pointActive: 0,
        points: [],
    };

    changePoint = pointActive => {
        this.setState({pointActive}, () => {
            this.checkPoint(pointActive)
        })
    }

    checkPoint = (point) => {
        const {points} = this.state
        if (points.indexOf(point) === -1) {
            points.push(point)
        }
        if (points.length === 2) {
            this.props.showNextBtn()
        }
    }

    closePopap = () => {
        this.setState({pointActive: 0})
    }

    popapWrp = {
        1: {
            icon: img3,
            head: 'Используйте график для определения текущих энергетических потребностей Молли, а формулу — для расчета суточной нормы рациона.',
            text: img4
        },
    }

    render() {
        const {pointActive} = this.state;

        return (
            <div className="step7">
                <TextBlock
                    text={`На основе полученной информации, рассчитайте размер суточной порции для этой собаки.`}
                />

                <div className="flex">
                    <div className="left">
                        <img src={img1} alt=""/>
                    </div>
                    <div className="right">
                        <div className="right_row">
                            <div className="row_block">
                                <div className="row_img_mobile">
                                    <img className='row_img_mobile_dog' src={img1} alt=""/>
                                </div>
                                <TextBlock
                                    text={`Это лабрадор Молли, она находится на 8-й неделе беременности и скоро родит щенков.

                                    Ее энергетические потребности до беременности составляли <b>1 200 ккал / сутки.</b>>`}
                                />
                            </div>
                        </div>
                        <div className="right_row">
                            <div className="row_block">
                                <div className="row_img_mobile">
                                    <img className='row_img_mobile_eat' src={img2} alt=""/>
                                </div>
                                <TextBlock
                                    text={`Хозяин кормит Молли кормом для щенков всех пород PEDIGREE<sup>®</sup>.
                                    
                                        Его энергетическая ценность составляет <b>370 ккал / 100 г.</b>`}
                                />
                            </div>
                            <img className="right_row_img" src={img2} alt=""/>
                        </div>
                    </div>
                </div>

                <InfoBlock
                    text={`Используйте подсказку для правильного расчета. Посчитайте с помощью калькулятора, а затем перейдите далее, чтобы выбрать ответ.`}
                />

                {/*<div className="textDown">
                    Используйте шпаргалку, чтобы посмотреть нужные вам формулы. <br/>
                    Просто нажмите на эту кнопку.
                </div>*/}

                <button className="btn btn_blue btn_shpora" onClick={() => {this.changePoint(1)}}>
                    ШПАРГАЛКА
                </button>

                {pointActive !== 0 &&
                /*<div className={'infoPopap'}>
                    <div className="infoPopap_wrp">
                        <div className="popap_close" onClick={() => {
                            this.changePoint(0)
                        }}/>

                        <div className="head"
                             dangerouslySetInnerHTML={{__html: this.popapWrp[pointActive].head}}
                        />
                        <div className="image">
                            <div className="imageBg">
                                <img src={this.popapWrp[pointActive].icon} alt=""/>
                            </div>
                        </div>
                        <TextBlock
                            text={this.popapWrp[pointActive].text}
                            fontSize={16}
                        />
                        {this.popapWrp[pointActive].img && <img src={this.popapWrp[pointActive].img} alt="" className={'img'}/>}
                        <button className="btn btn_blue" onClick={() => {
                            this.changePoint(0)
                        }}>Продолжить</button>
                    </div>
                </div>*/
                <PopupInStep
                    closePopup={this.closePopap}
                    popupText={this.popapWrp[pointActive].head}
                    popupImg2={this.popapWrp[pointActive].icon}
                    popupImg3={this.popapWrp[pointActive].text}
                    changePoint={this.closePopap}
                />
                }
            </div>
        )
    }
}
