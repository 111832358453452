import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step18 extends React.Component {

    render() {
        return (
            <div className="step18">
                <div className="head">
                    Шаг №3: Рассчитать покрытие калорий
                </div>
                <TextBlock
                    text={`Чтобы понять, что такое «покрытие калорий», необходимо прибегнуть к нескольким математическим вычислениям. Их результат наглядно продемонстрирует смысл и важность данного показателя.`}
                />
                <div className={'flex'}>
                    <div className="flex_el flex_el_white">
                        <p className="flex_el_value">
                            Потребляемые калории
                        </p>
                        <hr className="flex_el_hr"/>
                        <p className="flex_el_value">
                            Необходимые калории
                        </p>
                    </div>
                    <div className="flex_el_sign">=</div>
                    <div className={'flex_el flex_el_inactive'}>
                        Покрытие калорий
                    </div>
                </div>
            </div>
        )
    }
}
