import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson5/step11-1.png";
import img2 from "../../../image/lessons/lesson5/step11-2.png";

export default class step11 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит и избыток'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step11">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Витамин В3 (ниацин) — важен для здоровья.</b>
                           
                            Ниацин также известен как витамин PP и никотиновая кислота. В человеческом организме он помогает предотвратить пеллагру — серьезную болезнь, которая проявляется кожными, пищеварительными, неврологическими и гематологическими расстройствами.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Ниацин выполняет такие функции, как:`}
                        />
                        <ul className="list">
                            <li className="list_item">защита кожи;</li>
                            <li className="list_item">синтез кожного жира;</li>
                            <li className="list_item">синтез церамидов (ограничивают обезвоживание кожи);</li>
                            <li className="list_item">образование энергии.</li>
                        </ul>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Ниацин содержится в большинстве продуктов:`}
                        />
                        <div className="imgDiv_wrap_flex">
                            <div className="box">
                                <ul className="list">
                                    <li className="list_item">хлебных злаках;</li>
                                    <li className="list_item">мясе;</li>
                                    <li className="list_item">рыбе.</li>
                                </ul>
                                <TextBlock
                                    text={`Собаки и кошки способны синтезировать ниацин очень ограниченно. Потому ниацин необходимо включать в рацион питания.`}
                                />
                            </div>
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img2} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`При дефиците витамина В3 у собак и кошек может наблюдаться воспаление и изъязвление слизистой оболочки ротовой полости, появлением неприятного запаха. Синдром недостатка ниацина появляется в виде «черного языка» у собак. Также у собак нехватка данного элемента может быть причиной дерматита.`}
                        />
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
