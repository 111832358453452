import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from "../../../Components/LessonAndTestComponents/OnClickBlock";

import img1 from '../../../image/lessons/lesson4/step14-1.png';
import img2 from "../../../image/lessons/lesson4/step14-2.png";
import img3 from "../../../image/lessons/lesson4/step14-3.png";

export default class step14 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит и избыток'],
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step14">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Клетчатка — улучшает работу кишечника. Некоторые ее виды (пребиотики) способствуют росту нормальной микрофлоры в кишечнике.</b>

                            Растворимые волокна клетчатки поддаются ферментации бактериями в кишечнике, а большинство нерастворимых волокон не поддается ферментации. Исключением является псиллиум — неферментируемая растворимая клетчатка.`}
                        />
                        <div className="imgDiv_wrap_flex">
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img1} alt=""/>
                                <TextBlock
                                    text={`Пищевые волокна помогают оптимизировать время кишечного транзита для обеспечения всасывания питательных веществ и предупреждения запоров.`}
                                />
                            </div>
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img2} alt=""/>
                                <TextBlock
                                    text={`ФОС способствуют развитию полезных бактерий, что положительно сказывается на работе пищеварительного тракта.`}
                                />
                                <div className="text_italic">
                                    <span className="text_italic_blue">ФОС (фрукто-олиго-сахариды)</span> — особый вид ферментируемой клетчатки, который благоприятно влияет на установление правильного баланса микрофлоры в кишечнике.
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Роль клетчатки зависит от ее вида.
                            
                            <span class="text_blue">Неферментируемая клетчатка:</span>`}
                        />
                        <ul className="list">
                            <li className="list_item">увеличивает массу содержимого пищеварительного тракта, регулируя прохождение пищи;</li>
                            <li className="list_item">помогает оптимизировать время кишечного транзита.</li>
                        </ul>
                        <TextBlock
                            text={`<span class="text_blue">Ферментируемая клетчатка:</span> улучшает работу пищеварительного тракта, так как является пищей для полезных бактерий. Эти особые волокна называются пребиотиками. Однако не вся ферментируемая клетчатка оказывает пребиотическое действие.`}
                        />
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Практически вся клетчатка имеет растительное происхождение.
                            
                            Среди распространенных источников можно отметить:`}
                        />
                        <div className="imgDiv_wrap_flex">
                            <ul className="list">
                                <li className="list_item">пульпу сахарной свеклы;</li>
                                <li className="list_item">целлюлозу;</li>
                                <li className="list_item">люцерну;</li>
                                <li className="list_item">растительные смолы;</li>
                                <li className="list_item">пектин.</li>
                            </ul>
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img3} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Поскольку клетчатка не является жизненно необходимым элементом рациона кошек и собак, ее дефицит невозможен.
                            
                            Однако слишком малое или большое ее количество может отрицательно сказаться на качестве кала.`}
                        />
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
