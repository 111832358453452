import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from "../../../image/lessons/lesson8/step9-1.png";
import img2 from "../../../image/lessons/lesson8/step9-2.png";


export default class step9 extends React.Component {

    render() {
        return (
            <div className="step9">
                <TextBlock
                    text={`Использование лакомств важно для укрепления связи между животным и его владельцем, а также для решения таких функциональных задач, как, например, чистка зубов.
                    
                    Для сохранения здоровья питомца нужно ответственно подходить к выбору угощений. Перекармливание лакомствами может привести к проблемам с лишним весом. Существуют специализированные лакомства для животных, которые положительно влияют на здоровье.`}
                />
                <div className="flex">
                    <div className="flex_img-box">
                        <img src={img1} alt="" className="img"/>
                    </div>
                    {/*<div className="flex_img-box">*/}
                    {/*    <img src={img2} alt="" className="img"/>*/}
                    {/*</div>*/}
                </div>
            </div>
        )
    }
}
