import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson5/step2-1.png'


export default class step2 extends React.Component {


    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step2">
                <TextBlock
                    text={`Витамины поступают в организм двумя способами: в составе продуктов питания и в виде специальных добавок.
                    
                    При приготовлении корма и его последующем хранении следует учитывать, что витамины могут частично разрушаться под воздействием внешних факторов.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_wrp">
                            Свет
                            <img src={img1} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_wrp">
                            Тепло
                            <img src={img1} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_wrp">
                            Кислород
                            <img src={img1} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                    <div className="flex_el big">
                        <div className="flex_el_wrp">
                            Разрушение витаминов
                            <img src={img1} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                </div>
                <TextBlock
                    text={`Поэтому при изготовлении промышленных кормов в первоначальную рецептуру закладывается большее количество витаминов, чтобы после термической обработки и разрушения части витаминов в корме осталось необходимое количество.`}
                />
            </div>
        )
    }
}
