import React from 'react'
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson15/step23-1.png";
import img2 from "../../../image/lessons/lesson15/step23-2.png";
import img3 from "../../../image/lessons/lesson15/step23-3.png";
import img4 from "../../../image/lessons/lesson15/step23-4.png";
import img5 from "../../../image/lessons/lesson15/step23-5.png";
import img6 from "../../../image/lessons/lesson15/step23-6.png";
import img7 from "../../../image/lessons/lesson15/step23-7.png";

export default class step23 extends React.Component {

    state = {
        flexData: ['Источник 1', 'Источник 2', 'Источник 3'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step23">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_flex">
                            <div className="imgDiv_wrap_flex_el">
                                <div className="info">
                                    <img src={img1} alt="" className="info_img"/>
                                    <div className="info_box">
                                        <div className="info_box_head">
                                            Для рынка
                                        </div>
                                        <div className="info_box_text">
                                            привлечение новых потребителей
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="imgDiv_wrap_flex_el arrow">
                                <img src={img7} alt="" className="arrowImg"/>
                            </div>
                            <div className="imgDiv_wrap_flex_el">
                                <div className="info">
                                    <img src={img2} alt="" className="info_img"/>
                                    <div className="info_box">
                                        <div className="info_box_head">
                                            Для магазина
                                        </div>
                                        <div className="info_box_text">
                                            увеличение количества покупателей
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_flex">
                            <div className="imgDiv_wrap_flex_el">
                                <div className="info">
                                    <img src={img3} alt="" className="info_img"/>
                                    <div className="info_box">
                                        <div className="info_box_head">
                                            Для рынка
                                        </div>
                                        <div className="info_box_text">
                                            увеличение потребления
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="imgDiv_wrap_flex_el arrow">
                                <img src={img7} alt="" className="arrowImg"/>
                            </div>
                            <div className="imgDiv_wrap_flex_el">
                                <div className="info">
                                    <img src={img4} alt="" className="info_img"/>
                                    <div className="info_box">
                                        <div className="info_box_head">
                                            Для магазина
                                        </div>
                                        <div className="info_box_text">
                                            увеличение объема и частоты покупки
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_flex">
                            <div className="imgDiv_wrap_flex_el">
                                <div className="info">
                                    <img src={img5} alt="" className="info_img"/>
                                    <div className="info_box">
                                        <div className="info_box_head">
                                            Для рынка
                                        </div>
                                        <div className="info_box_text">
                                            увеличение стоимости калорий
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="imgDiv_wrap_flex_el arrow">
                                <img src={img7} alt="" className="arrowImg"/>
                            </div>
                            <div className="imgDiv_wrap_flex_el">
                                <div className="info">
                                    <img src={img6} alt="" className="info_img"/>
                                    <div className="info_box">
                                        <div className="info_box_head">
                                            Для магазина
                                        </div>
                                        <div className="info_box_text">
                                            увеличение цены за кг
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
