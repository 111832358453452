import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";
import OnClickTest from "../../../Components/LessonAndTestComponents/OnClickTest";

import img1 from "../../../image/lessons/lesson1/step10-1.png";
import img2 from "../../../image/lessons/lesson1/step10-2.png";

export default class step6 extends React.Component {

    state = {
        answersData: {
            1: {
                img: img1,
            },
            2: {
                img: img2,
            },
        }

    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step10">
                <TextBlock
                    text={`Как вы думаете, какие домашние животные более популярны в России – собаки или кошки?`}
                />
                <InfoBlock
                    text={`Выберите ответ, а затем нажмите «подтвердить».`}
                />
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'img'}
                    rightAnswers={[2]}
                    showNextBtn={this.showNextBtn}
                    correctText={`Популяция кошек в России: 40,8 млн.
                    
                    Популяция собак: 22,6 млн.`}
                />
            </div>
        )
    }
}
