import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from "../../../image/lessons/lesson18/step7-1.png";
import img2 from "../../../image/lessons/lesson18/step7-2.png";

export default class step7 extends React.Component{

    render(){
        return (
            <div className="step7">
                <TextBlock
                    text={`Существует 2 способа чистки зубного налёта: с помощью зубной щётки и пасты для собак, либо с применением <span class="text_dark-blue">PEDIGREE<sup>®</sup> DENTASTIX™.</span>`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                    <div className="flex_el">
                        <img className="flex_el_img" src={img2} alt=""/>
                    </div>
                </div>
            </div>
        )
    }

}
