import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson9/step15-1.jpg'


export default class step15 extends React.Component {


    render() {
        return (
            <div className="step15">
                <div className="flex">
                    <div className="ul">
                        <div className="li">
                            <div className="number">1</div>
                            <div className="text">
                                Развитие щенков делится на <b>4 периода: неонатальный период, переходный период, период социализации, подростковый период.</b>
                            </div>
                        </div>
                        <div className="li">
                            <div className="number">2</div>
                            <div className="text">
                                Новорожденные щенки <b>полностью зависят от своей матери</b> и не могут ничего делать самостоятельно.
                            </div>
                        </div>
                        <div className="li">
                            <div className="number">3</div>
                            <div className="text">
                                Во время переходного периода формируются <b>физические способности</b> щенка.
                            </div>
                        </div>
                        <div className="li">
                            <div className="number">4</div>
                            <div className="text">
                                В период социализации формируется <b>характер щенка</b> и его <b>поведенческие особенности.</b>
                            </div>
                        </div>
                        <div className="li">
                            <div className="number">5</div>
                            <div className="text">
                                В подростковый период щенок <b>совершенствует свои навыки</b> и легко поддается дрессировке.
                            </div>
                        </div>
                    </div>
                    <img src={img1} alt=""/>
                </div>
                <TextBlock
                    text={`В следующей теме вы узнаете о том, как подобрать рацион для щенка.`}
                />
            </div>
        )
    }
}
