import React from 'react'
import OnClickTest from "../../../Components/LessonAndTestComponents/OnClickTest";
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

export default class step24 extends React.Component {

    state = {
        answersData: [
            `Спонтанно, без логического обоснования`,
            `Исходя из того, что приобретают другие покупатели категории`,
            `В соответствии с его приоритетами в покупке`,
        ],
        showImg: false
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step24">
                <TextBlock
                    text={`Как Вы думаете, находясь у стеллажа с кормами, как покупатель принимает решение о покупке?`}
                />
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'line'}
                    rightAnswers={[3]}
                    showNextBtn={this.showNextBtn}
                    correctText={`Покупатель принимает решение о покупке в соответствии с его приоритетами в покупке.`}
                />
            </div>
        )
    }
}
