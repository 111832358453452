import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson10/step17-1.png';

export default class step17 extends React.Component {

    render() {
        return (
            <div className="step17">
                <div className="flex">
                    <div className="flex_el">
                        <TextBlock
                            text={`В возрасте старше 7 лет кошкам нужен не только <b>хорошо усвояемый</b>, но и <b>вкусный</b> корм, который они будут есть с удовольствием. С возрастом кошки становятся более разборчивы и могут перестать есть свой обычный рацион.`}
                        />
                    </div>
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                </div>
                <TextBlock
                    text={`Существуют специальные корма для кошек старше 7 лет. Например, сухие и влажные рационы <b>WHISKAS<sup>®</sup> для кошек старше 7 лет</b> или <b>PERFECT FIT™ для стерилизованных кошек старше 7 лет.</b>
                    
                    <span class="text_blue">Особенности корма для кошек старше 7 лет:</span>`}
                />
                <div className="info">
                    <div className="info_el">
                        <div className="info_el_wrp">
                            омега-6 и цинк <span className="info_el_wrp_blue">для здоровья кожи и шерсти</span>
                        </div>
                    </div>
                    <div className="info_el">
                        <div className="info_el_wrp">
                            источник глюкозамина <span className="info_el_wrp_blue">для здоровья суставов</span>
                        </div>
                    </div>
                    <div className="info_el">
                        <div className="info_el_wrp">
                            баланс белков и жиров <span className="info_el_wrp_blue">для жизненной энергии</span>
                        </div>
                    </div>
                    <div className="info_el">
                        <div className="info_el_wrp">
                            витамин Е <span className="info_el_wrp_blue">для поддержания иммунитета</span>
                        </div>
                    </div>
                    <div className="info_el">
                        <div className="info_el_wrp">
                            специальная формула вкуса <span className="info_el_wrp_blue">для здорового аппетита</span>
                        </div>
                    </div>
                    <div className="info_el">
                        <div className="info_el_wrp">
                            высокоусвояемые ингредиенты <span className="info_el_wrp_blue">для здорового пищеварения</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
