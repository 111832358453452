import React from 'react'
import Resume from '../../../Components/LessonAndTestComponents/Resume'

import img1 from '../../../image/lessons/lesson1/step5-1.png'


export default class step5 extends React.Component{

    state = {
        resumeData: [
            `Собаки были одомашнены человеком около <b>12000 лет назад.</b>`,
            `Предок домашней собаки – <b>азиатский волк.</b>`,
            `С момента одомашнивания волка было выведено около <b>400 пород собак.</b>`,
            `Изначально собаки использовались <b>человеком для помощи в хозяйстве.</b>`,
        ]
    }

    render() {
        return (
            <div className="step5">
                <Resume
                    resumeData={this.state.resumeData}
                    img={img1}
                />
            </div>
        )
    }
}
