import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson18/step13-1.gif'
import img2 from '../../../image/lessons/lesson18/step13-2.gif'
import img3 from '../../../image/lessons/lesson18/step13-3.gif'
import img4 from '../../../image/lessons/lesson18/step13-1.png'
import img5 from '../../../image/lessons/lesson18/step13-2.png'
import img6 from '../../../image/lessons/lesson18/step13-3.png'

export default class step13 extends React.Component {

    render() {
        return (
            <div className="step13">
                <TextBlock
                    text={`Практикующие ветеринарные врачи в России подтверждают эффективность PEDIGREE<sup>®</sup> DENTASTIX™ на основании клинических исследований.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        {/*<img src={img1} className="flex_el_img" alt=""/>*/}
                        <div className="flex_el_img_box">
                            <img src={img4} className="flex_el_img" alt=""/>
                            <TextBlock
                                text={`3 палочки - 45 г`}
                            />
                            <TextBlock
                                text={`7 палочек - 110 г`}
                            />
                        </div>
                        <div className="flex_el_text">
                            для собак мелких пород от 5 до 10 кг (подходит для щенков с 4 месяцев)
                        </div>
                    </div>
                    <div className="flex_el">
                        {/*<img src={img2} className="flex_el_img" alt=""/>*/}
                        <div className="flex_el_img_box">
                            <img src={img5} className="flex_el_img" alt=""/>
                            <TextBlock
                                text={`3 палочки - 77 г`}
                            />
                            <TextBlock
                                text={`7 палочек - 180 г`}
                            />
                        </div>
                        <div className="flex_el_text">
                            для собак средних пород от 10 до 25 кг
                        </div>
                    </div>
                    <div className="flex_el">
                        {/*<img src={img3} className="flex_el_img" alt=""/>*/}
                        <div className="flex_el_img_box">
                            <img src={img6} className="flex_el_img" alt=""/>
                            <TextBlock
                                text={`7 палочек - 270 г`}
                            />
                        </div>
                        <div className="flex_el_text">
                            для собак крупных пород > 25 кг
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
