import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson17/step9-1.png'

export default class step9 extends React.Component {


    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step9">
                <TextBlock
                    text={`Также, выпускаются отдельно <b>корма для животных разных возрастов.</b>
                    
                    Это связано с тем, что <span class="text_blue">в разные периоды жизни домашних животных им необходимы различные количества питательных веществ.</span> Поэтому, например, состав корма для котят существенно отличается от состава корма для взрослых кошек.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
                <TextBlock
                    text={`Вот некоторые <b>особенности питательных потребностей</b> животных разных возрастов:`}
                />
                <ul className="list">
                    <li className="list_item">Растущий котенок должен потреблять как минимум на 10% больше белка и аминокислот, чем взрослая кошка.</li>
                    <li className="list_item">Щенки нуждаются в 5.8 раз большем количестве кальция и в 6.4 раз большем количестве фосфора, чем взрослые собаки.</li>
                    <li className="list_item">Котята требуют двукратного уровня меди в рационе, по сравнению со взрослыми кошками.</li>
                    <li className="list_item">Потребности стареющих собак и кошек в витаминах группы В, меди и цинке увеличиваются более, чем в 2 раза по сравнению со взрослыми животными.</li>
                </ul>
                <TextBlock
                    text={`Вот почему на полке в магазинах присутствуют корма для животных разных возрастов!`}
                />
            </div>
        )
    }
}
