import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import img1 from '../../../image/lessons/lesson10/step2-1.jpg'


export default class step2 extends React.Component {

    state = {
        pointActive: 0,
        points: [],
    };

    showPoint = pointActive => {
        const {points} = this.state;
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        this.setState({
            pointActive: pointActive,
            points: points
        })

        if (points.length === 4) {
            this.props.showNextBtn();
        }
    }

    render() {
        const {points} = this.state
        return (
            <div className="step2">
                <TextBlock
                    text={`Беременность кошек имеет свои особенности, и их обязательно нужно учитывать при подборе рациона.`}
                />
                <InfoBlock
                    text={`Нажмите на вопросительные знаки, чтобы узнать больше.`}
                />
                <div className="imgDiv">
                    <div
                        className={'point ' + (points.indexOf(1) >= 0 ? 'point_out' : '')}
                        onClick={() => {this.showPoint(1)}}
                    >
                        ?
                    </div>
                    <div
                        className={'point ' + (points.indexOf(2) >= 0 ? 'point_out' : '')}
                        onClick={() => {this.showPoint(2)}}
                    >
                        ?
                    </div>
                    <div
                        className={'point ' + (points.indexOf(3) >= 0 ? 'point_out' : '')}
                        onClick={() => {this.showPoint(3)}}
                    >
                        ?
                    </div>
                    <div
                        className={'point ' + (points.indexOf(4) >= 0 ? 'point_out' : '')}
                        onClick={() => {this.showPoint(4)}}
                    >
                        ?
                    </div>
                    <img src={img1} alt="" className="imgCenter"/>
                    <div className="imgDiv_answers">
                        <div
                            className={'answer ' + (points.indexOf(1) >= 0 ? 'answer_in' : '')}
                        >
                            <p className="answer_text">Средняя продолжительность беременности — <b>64 дня</b> (9–9,5 недель).</p>
                        </div>
                        <div
                            className={'answer ' + (points.indexOf(2) >= 0 ? 'answer_in' : '')}
                        >
                            <p className="answer_text">Пик набора веса приходится <b>на второй триместр</b>, затем он начинает медленно <b>снижаться.</b></p>
                        </div>
                        <div
                            className={'answer ' + (points.indexOf(3) >= 0 ? 'answer_in' : '')}
                        >
                            <p className="answer_text">Беременные кошки <b>набирают вес</b> уже <b>на первой неделе беременности.</b></p>
                        </div>
                        <div
                            className={'answer ' + (points.indexOf(4) >= 0 ? 'answer_in' : '')}
                        >
                            <p className="answer_text">Наибольшее количество еды кошка употребляет <b>на 7–8 неделе</b> беременности.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
