import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import Popup from "../../../Components/Popup";

import img1 from "../../../image/lessons/lesson20/step11-1.png";
import img2 from "../../../image/lessons/lesson20/step11-2.png";
import img3 from "../../../image/lessons/lesson20/step17-1.png";
import img4 from "../../../image/lessons/lesson20/step17-2.png";

export default class step17 extends React.Component {

    state = {
        popupActive: 0,
        showPopup: false,
        points: [],
    }

    showPopup = (popupActive) => {
        const {points} = this.state;
        if (points.indexOf(popupActive) === -1) {
            points.push(popupActive)
        }

        this.setState({
            popupActive: popupActive,
            showPopup: true,
            points: points
        })

        if (points.length === 2) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    render() {
        const {showPopup, popupActive} = this.state
        return (
            <div className="step17">
                <TextBlock
                    text={`Теперь Вам предстоит знакомство с текстурами WHISKAS<sup>®</sup> для кошек старше 7 лет, а также с индивидуальными особенностями каждого продукта.`}
                />
                <InfoBlock
                    text={`Нажмите на изображения с кормом и узнайте о нем больше.`}
                />
                <div className="flex">
                    <div className="flex_el" onClick={() => {this.showPopup(1)}}>
                        <img src={img1} alt="" className="flex_el_img"/>
                        <p className="flex_el_name">
                            Сухой корм
                        </p>
                    </div>
                    <div className="flex_el" onClick={() => {this.showPopup(2)}}>
                        <img src={img2} alt="" className="flex_el_img"/>
                        <p className="flex_el_name">
                            Рагу
                        </p>
                    </div>
                </div>
                {showPopup &&
                <Popup
                    closePopup={this.closePopup}
                >
                    <div className="head">
                        Разнообразие в питании – репертуар вкусов и текстур
                    </div>
                    <div className="box">
                        {popupActive === 1
                            ?
                            <div className="box_el">
                                <div className="box_el_head">
                                    Сухой корм WHISKAS<sup>®</sup> «Подушечки с паштетом. Ассорти с курицей и индейкой» для кошек старше 7 лет
                                </div>
                                <div className="box_el_flex">
                                    <div className="box_el_flex_wrap">
                                        <div className="box_el_flex_head low">
                                            Cодержит все необходимое, чтобы еда Вашей кошки была не только
                                            вкусной, но и полезной:
                                        </div>
                                        <ul className="list">
                                            <li className="list_item">Витамин Е для поддержания иммунитета</li>
                                            <li className="list_item">Высокоусвояемые ингредиенты для здорового пищеварения</li>
                                            <li className="list_item">Таурин для здоровья сердца</li>
                                            <li className="list_item">Жирные кислоты Омега-6 и цинк для здоровья кожи и шерсти</li>
                                            <li className="list_item">Источник глюкозамина для здоровья суставов</li>
                                            <li className="list_item">Специальная формула для хорошего аппетита</li>
                                            <li className="list_item">Сбалансированный рацион для поддержания веса</li>
                                        </ul>
                                    </div>
                                    <img src={img3} alt="" className="box_el_flex_img"/>
                                </div>
                            </div>
                            : popupActive === 2 ?
                                <div className="box_el">
                                    <div className="box_el_head">
                                        Рагу WHISKAS<sup>®</sup> для кошек старше 7 лет
                                    </div>
                                    <div className="box_el_flex">
                                        <div className="box_el_flex_wrap">
                                            <div className="box_el_flex_head">
                                                Cодержит:
                                            </div>
                                            <ul className="list">
                                                <li className="list_item">Оптимальный баланс белков и жиров для жизненной энергии;</li>
                                                <li className="list_item">Витамин Е для поддержания иммунитета;</li>
                                                <li className="list_item">Высокоусвояемые ингредиенты для здорового пищеварения;</li>
                                                <li className="list_item">Источник глюкозамина для здоровья суставов;</li>
                                                <li className="list_item">Жирные кислоты Омега-6 и цинк для здоровья кожи и шерсти;</li>
                                                <li className="list_item">Специальная формула для хорошего аппетита;</li>
                                                <li className="list_item">Витамин D и минералы.</li>
                                            </ul>
                                        </div>
                                        <img src={img4} alt="" className="box_el_flex_img"/>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </div>
                </Popup>
                }
            </div>
        )
    }
}
