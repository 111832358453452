import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson6/step15-1.png";
import img2 from "../../../image/lessons/lesson6/step15-2.png";
import img3 from "../../../image/lessons/lesson6/step15-3.png";

export default class step15 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит и избыток'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step15">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Йод (I) — синтез тиреоидных гормонов (гормонов щитовидной железы).</b>
                           
                            Минерал, необходимый кошкам и собакам в очень малых количествах.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_flex">
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img2} alt=""/>
                            </div>
                            <TextBlock
                                text={`Йод — это неотъемлемый элемент тиреоидных гормонов, которые необходимы для роста, развития и регуляции интенсивности метаболизма.`}
                            />
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Источниками йода являются:`}
                        />
                        <div className="imgDiv_wrap_flex">
                            <ul className="list">
                                <li className="list_item">морская соль;</li>
                                <li className="list_item">морские водоросли;</li>
                                <li className="list_item">рыба.</li>
                            </ul>
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img3} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Симптомы <b>дефицита</b> включают:`}
                        />
                        <ul className="list">
                            <li className="list_item">увеличение щитовидной железы;</li>
                            <li className="list_item">сухость кожного покрова;</li>
                            <li className="list_item">потерю шерсти;</li>
                            <li className="list_item">увеличение веса из-за изменения функционирования щитовидной железы.</li>
                        </ul>
                        <TextBlock
                            text={`У кошек <b>избыток</b> йода снижает уровень содержания тироксина, что приводит к тем же симптомам, что и при дефиците.`}
                        />
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
