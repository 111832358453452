import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson7/step16-1.png'
import img2 from '../../../image/lessons/lesson7/step16-2.png'
import img3 from '../../../image/lessons/lesson7/step16-3.png'


export default class step16 extends React.Component {


    render() {
        return (
            <div className="step16">
                <div className="flex">
                    <div className="flex_el">
                        <img src={img2} alt="" className="icon"/>
                        Как можно больше разных питательных веществ.
                    </div>
                    <div className="flex_el">
                        <img src={img3} alt="" className="icon"/>
                        Правильное соотношение необходимых животному питательных веществ.
                    </div>
                </div>
                <TextBlock
                    text={`Для того чтобы понять, является ли корм сбалансированным, необходимо внимательно изучить упаковку. На упаковке сбалансированного корма всегда есть фраза <b>«полнорационный корм»</b> или <b>«рацион сбалансирован»</b>.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
