import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson32/step12-1.png'
import img1xs from "../../../image/lessons/lesson32/step12-1xs.png";
import img2 from '../../../image/lessons/lesson32/step12-2.png'


export default class step12 extends React.Component {

    render() {
        return (
            <div className="step12">
                <TextBlock
                    text={`По данным клинических исследований, проводимых в 2013 году ветеринарными врачами в России, после перевода собак с домашней еды на Pedigree<sup>®</sup> у 93 % собак улучшилось состояние желудочно-кишечного тракта.`}
                />
                <picture className="img-box">
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img className="imgCenter" src={img1} alt=""/>
                </picture>
                <div className="confirmation">
                    <img src={img2} alt="" className="confirmation_img"/>
                    <p className="confirmation_text">
                        Ассоциация практикующих ветеринарных врачей подтверждает, что применение сухих рационов Pedigree для регулярного кормления собак в течение 14 дней гарантирует отличное пищеварение
                    </p>
                </div>
            </div>
        )
    }
}
