import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson3/step14-1.png";
import img1xs from "../../../image/lessons/lesson3/step14-1xs.png";


export default class step14 extends React.Component {

    render() {
        return (
            <div className="step14">
                <TextBlock
                    text={`Некоторые аминокислоты, заменимые для человека, являются незаменимыми для кошек и собак. Поэтому им нужно сбалансированное питание, содержащее все жизненно важные аминокислоты.`}
                />
                <div className="shema">
                    <picture>
                        <source srcSet={img1xs} media="(max-width: 500px)"/>
                        <img src={img1} alt="" className="shema_img"/>
                    </picture>
                </div>
                <div className="footnote">
                    <div className="footnote_el">
                        <span className="footnote_point"></span><span className="footnote_text">человек, кошки и собаки</span>
                    </div>
                    <div className="footnote_el">
                        <span className="footnote_point"></span><span className="footnote_text">кошки и собаки</span>
                    </div>
                    <div className="footnote_el">
                        <span className="footnote_point"></span><span className="footnote_text">только кошки</span>
                    </div>
                </div>
                <div className="footnote">
                    <div className="footnote_el">
                        <span className="footnote_star">*</span><span className="footnote_text">Две аминокислоты - тирозин и цистеин - являются условно заменимыми, так как при дефиците их поступления с пищей могут синтезироваться в организме из других аминокислот.</span>
                    </div>
                </div>
                <TextBlock
                    text={`На схеме показаны незаменимые аминокислоты для людей, кошек и собак. Таким образом, в отличие от людей, собаки нуждаются в гистидине и аргинине, а кошки, помимо этого, в таурине.`}
                />
            </div>
        )
    }
}
