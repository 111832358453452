import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson32/step10-1.png'

export default class step10 extends React.Component {

    render() {
        return (
            <div className="step10">
                <TextBlock
                    text={`Pedigree<sup>®</sup> и Ассоциация практикующих ветеринарных врачей в 2013 г провели исследование рационов Pedigree для взрослых собак.`}
                />
                <div className="text">
                    Результаты исследования подтверждают:
                </div>
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
