import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson2/step13-1.png";
import img2 from "../../../image/lessons/lesson2/step13-2.png";

export default class step13 extends React.Component {

    state = {
        activeBlock: 1
    }

    changeDataBlock = (activeBlock) => {
        this.setState({activeBlock: activeBlock})
    }

    render() {
        const {activeBlock} = this.state
        return (
            <div className="step13">
                <div className="product">
                    <div className="product_el">
                        <img src={img1} alt="" className="product_el_img"
                            onClick={() => {this.changeDataBlock(1)}}
                        />
                    </div>
                    <div className="product_el">
                        <img src={img2} alt="" className="product_el_img"
                             onClick={() => {this.changeDataBlock(2)}}
                        />
                    </div>
                </div>
                <div className="data">
                    <div className={'data_el ' + (activeBlock === 1 && 'active')}>
                        <div className="data_el_head">100 г свежего мяса:</div>
                        <div className="data_tablet">
                            <div className="data_tablet_column left">
                                <div className="data_tablet_item">Белки</div>
                                <div className="data_tablet_item">Жиры</div>
                                <div className="data_tablet_item">Углеводы</div>
                            </div>
                            <div className="data_tablet_column right">
                                <div className="data_tablet_item">20.3г</div>
                                <div className="data_tablet_item">4.6г</div>
                                <div className="data_tablet_item">0.5г</div>
                            </div>
                        </div>
                        <TextBlock
                            text={`
                            Энергетическая ценность: <span class="text_blue">111.7 ккал</span> содержатся в 100 г свежего мяса.`}
                        />
                    </div>
                    <div className={'data_el ' + (activeBlock === 2 && 'active')}>
                        <div className="data_el_head">100 г сухого корма WHISKAS<sup>®</sup>:</div>
                        <div className="data_tablet">
                            <div className="data_tablet_column left">
                                <div className="data_tablet_item">Белки</div>
                                <div className="data_tablet_item">Жиры</div>
                                <div className="data_tablet_item">Углеводы</div>
                            </div>
                            <div className="data_tablet_column right">
                                <div className="data_tablet_item">35г</div>
                                <div className="data_tablet_item">11.5г</div>
                                <div className="data_tablet_item">33.5г</div>
                            </div>
                        </div>
                        <TextBlock
                            text={`
                            Энергетическая ценность: <span class="text_blue">365 ккал</span> содержат 100 г WHISKAS<sup>®</sup>.`}
                        />
                    </div>
                </div>
                <div className="advice">
                    Энергетическая ценность сухого корма более, чем в 3 раза превышает энергетическую ценность мяса.
                </div>
            </div>
        )
    }
}
