import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from "../../../Components/LessonAndTestComponents/OnClickBlock";

import img1 from '../../../image/lessons/lesson4/step12-1.png';
import img2 from "../../../image/lessons/lesson4/step12-2.png";

export default class step12 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит и избыток'],
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step12">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Сахара — источник энергии.</b>
                           
                            Термин «сахара» относится к некоторым абсорбируемым (например, <b>глюкоза и фруктоза</b>) и перевариваемым углеводам (например, <b>лактоза и сахароза</b>). В рационе сахара являются источником энергии.
                            
                            Во время приготовления корма сахара, добавляемые в готовые рационы для домашних животных, могут вступать в реакцию с лизином, усиливая вкус и аромат кормов.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Хотя сахара и не являются жизненно необходимыми, их можно использовать в качестве источника энергии.
                            
                            Лактоза, содержащаяся в молоке матери, является источником энергии для щенков или котят, но для ее биологической доступности необходим пищеварительный фермент — лактаза. После отъема от матери в организме котят и щенков постепенно снижается выработка лактазы. У взрослых кошек и собак дефицит лактазы считается обычным явлением.`}
                        />
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Сахара в естественном виде содержатся:`}
                        />
                        <div className="imgDiv_wrap_flex">
                            <ul className="list">
                                <li className="list_item">в большинстве фруктов;</li>
                                <li className="list_item">корнеплодах;</li>
                                <li className="list_item">зерновых культурах.</li>
                            </ul>
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img2} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Избыток сахаров может привести к диарее и избыточному росту бактерий в кишечнике.`}
                        />
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
