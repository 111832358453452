import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";
import PlanInFirstStep from "../../../Components/LessonAndTestComponents/PlanInFirstStep";

export default class step1 extends React.Component {
    planData = [
        `По каким критериям классифицируются готовые корма для домашних животных`,
        `Какие бывают классы кормов, и чем они отличаются`,
        `Почему важно подбирать корм, учитывая возрастные и физиологические особенности питомца`,
    ]

    render() {
        return (
            <div className="step1">
                <div className="head">План урока</div>

                <TextBlock
                    text={`В этом уроке вы узнаете об основных различиях между кормами и особенностях выбора кормов.
                    
                    Вы изучите:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'blue'}
                />

            </div>
        )
    }
}
