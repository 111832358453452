import React from 'react'
import Resume from "../../../Components/LessonAndTestComponents/Resume";

import img1 from "../../../image/lessons/lesson6/step18-1.png";

export default class step18 extends React.Component {
    state = {
        resumeData: [
            `Общее количество минералов в корме называется <b>золой</b> и указывается на упаковке.`,
            `Все минералы делятся на <b>макро-</b> и <b>микроэлементы</b>. Макроэлементы — это минералы, суточное потребление которых определяется <b>в граммах</b>, а потребление микроэлементов принято определять <b>в милиграммах или микрограммах.</b>`,
            `Дефицит или избыток минералов могут привести к нарушению роста, проблемам опорно-двигательного аппарата, репродуктивной функции, а также ухудшению состояния кожи и шерсти.`,
            `При производстве готовых рационов контролируют содержание минералов в ингредиентах и дополнительно вводят минеральные добавки с учетом их взаимного влияния, биологической доступности и потребностей животных.`,
        ]
    }

    render() {
        return (
            <div className="step20">
                <Resume
                    resumeData={this.state.resumeData}
                    img={img1}
                />
            </div>
        )
    }
}
