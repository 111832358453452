import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson2/step16-1.png';

export default class step16 extends React.Component {

    render() {
        return (
            <div className="step16">
                <div className="formula">
                    УПОТРЕБЛЯЕМАЯ ЭНЕРГИЯ = ЗАТРАЧИВАЕМАЯ ЭНЕРГИЯ
                </div>
                <img src={img1} alt="" className="img"/>
                <TextBlock
                    text={`Важно, чтобы потребление и расходование энергии находились в равновесии.
                    
                    Потребление энергии в большем, чем требуется, количестве обуславливает увеличение веса, и, напротив, потребление ее в количестве меньше необходимого приводит к потере веса.`}
                />
            </div>
        )
    }
}
