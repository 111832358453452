import React from 'react'
import './style.sass'
import InputMask from 'react-input-mask';


export default class Inputs extends React.Component{
    render() {
        return (
            <div className={'inputBlock ' + (this.props.option_show && 'show ') + (this.props.required && ' required')}>
                {this.props.label &&
                    <label htmlFor={this.props.id}>{this.props.label}</label>
                }
                <span className="star">&#42;</span>
                {this.props.options &&
                    <span className="arrowSelect"></span>
                }
                <InputMask
                    type={this.props.type ? this.props.type : 'text'}
                    className={'input ' + this.props.className}
                    style={this.props.style}
                    name={this.props.name}
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    id={this.props.id}
                    onChange={(e) => {this.props.onChange(this.props.name, e.target.value, this.props.index)}}
                    onClick={() => {this.props.options && this.props.onClick()}}
                    readOnly={this.props.options}
                    required={this.props.required}
                    disabled={this.props.disabled}
                    mask={this.props.mask}
                />
                {this.props.options &&
                    <div className={"options " + (this.props.option_show && 'showOptions ')}>
                        {Object.keys(this.props.options).map((key) => {
                            return (
                                <div
                                    key={key}
                                    className={"option " + (this.props.option_active === key && 'active')}
                                    onClick={() => {this.props.option_click(key)}}
                                >
                                    {this.props.options[key]}
                                </div>
                            )
                        })}
                    </div>
                }
                {this.props.errorText &&
                    <div className="errorText">{this.props.errorText}</div>
                }
            </div>
        )
    }
}
