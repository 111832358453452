import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson6/step6-1.png";
import img2 from "../../../image/lessons/lesson6/step6-2.png";
import img3 from "../../../image/lessons/lesson6/step6-3.png";

export default class step6 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит и избыток'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step6">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Калий (K) — клеточная функция и энергетический обмен.</b>
                           
                            Это щелочной минерал, быстро окисляющийся на воздухе и активно взаимодействующий с водой.
                            
                            Он был впервые выделен из углекислого калия. Это восьмой по содержанию в организме минерал.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_flex">
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img2} alt=""/>
                            </div>
                            <div className="imgDiv_wrap_flex_box">
                                <ul className="list">
                                    <li className="list_item">Калий — наиболее широко представленный катион в клетке, необходимый для ее правильного функционирования;</li>
                                    <li className="list_item">Отвечает за поддержание кислотно-щелочного баланса (наряду с натрием);</li>
                                    <li className="list_item">Отвечает за передачу нервных импульсов;</li>
                                    <li className="list_item">Играет важную роль в энергетическом обмене.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Калий содержится в:`}
                        />
                        <ul className="list">
                            <li className="list_item">овощах;</li>
                            <li className="list_item">мясе;</li>
                            <li className="list_item">рыбе;</li>
                            <li className="list_item">яйцах.</li>
                        </ul>
                        <TextBlock
                            text={`Минеральные соли содержат гидрокарбонат калия, хлорид калия и сульфат калия.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img3} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Дефицит калия встречается редко. У щенков недостаток калия может вызывать беспокойное состояние и мышечный паралич.
                            
                            Длительная диарея может вызвать значительную потерю калия. У кошек с хронической болезнью почек может развиться гипокалиемия в результате сниженного потребления этого минерала с кормом и увеличения диуреза.`}
                        />
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
