import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step11 extends React.Component{

    render() {
        return (
            <div className="step11">
                <TextBlock
                    text={`<span class="text_blue">Возможен ли дефицит кормов компании MARS?</span>`}
                />
                <ul className="list">
                    <li className="list_item">Сейчас спрос на корма вырос, но мы увеличили объемы производства, чтобы у
                        всех домашних животных всегда был их любимый корм.
                    </li>
                    <li className="list_item">Однако, в текущих условиях могут возникнуть перебои с доставкой. Поэтому
                        мы советуем вам, во-первых, убедиться, что у питомца есть всё необходимое с запасом (корм,
                        лакомства, средства по уходу, наполнитель для туалета, лекарства и т.д.). Во‑вторых, делать
                        покупки заранее, чтобы проблемы с доставкой не стали неприятной неожиданностью, и тоже с
                        запасом.
                    </li>
                    <li className="list_item">Так же мы советуем вам соблюдать все меры карантина, по возможности, воспользоваться бесконтактной онлайн‑доставкой.</li>
                </ul>
            </div>
        )
    }
}
