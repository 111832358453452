import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson10/step15-1.jpg";

export default class step15 extends React.Component {

    state = {
        flexData: ['Потребление энергии', 'Пищеварение'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: true})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData, flexHidden} = this.state
        return (
            <div className="step15">
                <TextBlock
                    text={`В научно-исследовательском институте Waltham проводились исследования поведения пожилых кошек. Изучалась их физическая активность, аппетит, особенности пищеварения.`}
                />
                <InfoBlock
                    text={`Нажмите на кнопки, чтобы узнать результаты исследований.`}
                />
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_flex">
                            <TextBlock
                                text={`Исследование научно-исследовательского института Waltham показало, что в отличие от людей и собак, у кошек в процессе старения <span class="text_blue">не уменьшаются</span> энергетические потребности и мышечная масса. Это связано с тем, что <b>уровень активности</b> взрослых и пожилых кошек примерно <b>одинаковый</b>, поэтому потребление энергии не изменяется. Однако этот эффект зависит от индивидуальных особенностей каждой кошки.
                                
                                В пожилом возрасте необходимо продолжать следить за упитанностью кошки и подбирать рацион, исходя из <b>индивидуальных потребностей.</b>`}
                            />
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Другое исследование показало, что многие пожилые кошки испытывают <span class="text_blue">проблемы с перевариванием жиров и белков.</span>
                            
                            Кошки, относящиеся к очень пожилой возрастной группе (11+ лет) больше склонны к потере веса и мышечной массы из-за <b>ухудшения усвояемости питательных веществ</b>. Кошкам с пищеварительными проблемами <b>увеличивают</b> суточную норму потребления пищи для компенсации этого эффекта.`}
                        />
                    </div>
                </OnClickBlock>
                <img src={img1} alt="" className={'imgCenter ' + (flexHidden && 'hidden')}/>
            </div>
        )
    }
}
