import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson22/step3-1.png'

export default class step3 extends React.Component {

    render() {
        return (
            <div className="step3">
                <TextBlock
                    text={`Сырое мясо и сырая рыба могут быть <b>небезопасны</b> (бактерии, паразиты).

                    Еда со стола, как правило, чрезмерно <b>калорийна</b> для кошек. Например:`}
                />
                <ul className="list">
                    <li className="list_item">
                        угостив кошку сосиской, она сразу получает 2/3 своей суточной нормы калорий;
                    </li>
                    <li className="list_item">
                        угостив маленьким кусочком сыра – 1/3 суточной нормы калорий.
                    </li>
                </ul>
                <img src={img1} alt="" className="imgCenter"/>
                <TextBlock
                    text={`<span class="text_light-blue">Лакомства Dreamies™ специально разработаны для кошек и содержат всего 2 Ккал в 1 подушечке, т.е. около 1% суточной нормы калорий для кошки!</span>`}
                />
            </div>
        )
    }
}
