import React from 'react'

import img1 from '../../../image/lessons/lesson10/step13-1.png'
import img2 from '../../../image/lessons/lesson10/step13-2.png'
import img3 from '../../../image/lessons/lesson10/step13-3.png'
import img4 from '../../../image/lessons/lesson10/step13-4.png'
import img5 from '../../../image/lessons/lesson10/step13-5.png'


export default class step13 extends React.Component {
    state = {
    };

    render() {
        return (
            <div className="step13">
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_wrp">
                            <span className="flex_status">
                                <img src={img4} alt=""/>
                            </span>
                            <p className="flex_text">
                                <span className="flex_red">Нельзя</span> кормить кошку рационом для собак! В нем не хватает необходимых кошке питательных веществ.
                            </p>
                            <img className="img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_wrp">
                            <span className="flex_status">
                                <img src={img4} alt=""/>
                            </span>
                            <p className="flex_text">
                                <span className="flex_red">Нельзя</span> кормить взрослую кошку кормом для котят! Это несет в себе риски несбалансированного питания и избытка калорий.
                            </p>
                            <img className="img img_2" src={img2} alt=""/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_wrp flex_wrp--green">
                            <span className="flex_status">
                                <img src={img5} alt=""/>
                            </span>
                            <p className="flex_text">
                                <span className="flex_green">Рекомендуется</span> давать кошке корм, соответствующий ее возрасту.
                            </p>
                            <img className="img img_3" src={img3} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
