import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson7/step24-1.png";

export default class step24 extends React.Component {

    render() {
        return (
            <div className="step24">
                <div className="flex">
                    <div className="flex_el">
                        <TextBlock
                            text={`Энергетическая ценность сухого корма практически <b>в 4 раза</b> превышает энергетическую ценность влажного из-за высокого содержания влаги во влажном корме.
                            
                            Таким образом, 100 г сухого корма дает приблизительно столько же энергии, сколько 400 г влажного корма.
                            
                            Соответственно, если питомец ест сухой корм, порции должны быть меньше, чем при кормлении влажным кормом.`}
                        />
                    </div>
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                </div>
            </div>
        )
    }
}
