import React from 'react'
import Resume from "../../../Components/LessonAndTestComponents/Resume";

import img1 from "../../../image/lessons/lesson8/step27-1.png";

export default class step27 extends React.Component {

    state = {
        resumeData: [
            `Мы выяснили, что при расчете суточной нормы рациона нужно использовать <b>рекомендованные нормы как стартовую точку</b>, а затем корректировать их в зависимости от индивидуальных особенностей и потребностей питомца.`,
            `В дополнение к основному рациону, многие хозяева любят угощать своих животных лакомствами по разному поводу. Лакомства позволяют <b>укрепить отношения</b> между владельцем и питомцем, <b>помочь в процессе дрессировки</b> или <b>улучшить здоровье</b> питомца (функциональный уход).`,
            `На вес животных влияют различные факторы. <b>Лишний вес</b> — это всегда угроза здоровью питомца, ведущая <b>к сокращению</b> продолжительности жизни. Во избежание этого фактора необходимо контролировать <b>питание</b> и <b>уровень активности</b> своего питомца.`,
        ]
    }

    render() {
        return (
            <div className="step27">
                <Resume
                    resumeData={this.state.resumeData}
                    img={img1}
                />
            </div>
        )
    }
}
