import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson19/step7-1.png'

export default class step7 extends React.Component {

    render() {
        return (
            <div className="step7">
                <TextBlock
                    text={`PERFECT FIT™ — это <span class="text_dark-blue">система профессионального питания</span>, которая специально создана для поддержания активности и жизненной энергии собаки на протяжении всей жизни.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_box-img">
                            <img src={img1} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <TextBlock
                            text={`Разработанная совместно с диетологами и ветеринарными врачами, <span class="text_pink">уникальная формула PERFECT FIT™ «TOTAL 5»</span> заботится о важнейших слагаемых здоровья в соответствии с размером и возрастом собаки.`}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
