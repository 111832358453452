import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson23/step3-1.png'
import img2 from '../../../image/lessons/lesson23/step3-2.png'
import img3 from '../../../image/lessons/lesson23/step3-3.png'

export default class step3 extends React.Component {

    render() {
        return (
            <div className="step3">
                <div className="head">
                    Что такое натуральные ингредиенты?
                </div>
                <TextBlock
                    text={`Для того, чтобы ингредиент считался натуральным, он должен сооветствовать одновременно трем основным критериям согласно требованиям FEDIAF*`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                    <div className="flex_el">
                        <ul className="flex_el_list">
                            <li className="flex_el_item">
                                <img src={img2} alt="" className="flex_el_icon"/>
                                Компоненты питания природного происхождения (полученные из растений, животных, микроорганизмов и минералов).
                            </li>
                            <li className="flex_el_item">
                                <img src={img2} alt="" className="flex_el_icon"/>
                                К которым не было ничего добавлено в процессе производства и хранения.
                            </li>
                            <li className="flex_el_item">
                                <img src={img2} alt="" className="flex_el_icon"/>
                                И которые были подвергнуты только физической обработке, чтобы сделать их пригодными для еды при сохранении природного состава.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="info">
                    <img src={img3} alt="" className="info_img"/>
                    <div className="info_text">
                        Требования Mars Petcare к натуральности ингредиентов полностью соответствуют требованиям FEDIAF*
                    </div>
                </div>
            </div>
        )
    }
}
