import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson18/step11-1.png'
import img2 from '../../../image/lessons/lesson18/step11-2.png'

export default class step11 extends React.Component{

    render() {
        return (
            <div className="step11">
                <TextBlock
                    text={`Активные компоненты, триполифосфат натрия и сульфат цинка, способствуют сдерживанию роста налёта и предотвращают отложение зубного камня.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                    <div className="flex_el">
                        <img src={img2} alt="" className="flex_el_img"/>
                    </div>
                </div>
                <TextBlock
                    text={`<span class="text_dark-blue">Зубной налёт продолжает оставаться в мягком состоянии и может быть удалён в процессе жевания собакой.</span>`}
                />
            </div>
        )
    }
}
