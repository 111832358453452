import React from 'react'
import Resume from "../../../Components/LessonAndTestComponents/Resume";

import img1 from '../../../image/lessons/lesson3/step5-1.png';

export default class step5 extends React.Component {
    state = {
        resumeData: [
            `Белки состоят из <b>20 аминокислот, 10 из которых являются незаменимыми</b> для кошек и собак.`,
            `Помимо этого кошкам также необходим <b>таурин</b> — особая серосодержащая аминокислота.`,
            `Незаменимые аминокислоты <b>должны содержаться в пище,</b> так как они не могут синтезироваться организмом.`,
        ]
    }

    render() {
        return (
            <div className="step5">
                <Resume
                    resumeData={this.state.resumeData}
                    img={img1}
                />
            </div>
        )
    }
}
