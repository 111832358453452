import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import ArrowBack from '../../../Components/LessonAndTestComponents/ArrowBack';

import img1 from '../../../image/lessons/lesson21/step4-1.png'
import img2 from '../../../image/lessons/lesson21/step4-2.png'


export default class step4 extends React.Component {
    state = {
        flexActive: 0,
        flexs: [1],
        flexHidden: false,
    };

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }
        this.setState({flexActive, flexs, flexHidden: true})
        this.props.showNextBtn()
    }

    closeImgDiv = () => {this.setState({flexHidden: false, flexActive: 0})}


    render() {
        const {flexActive, flexHidden} = this.state
        return (
            <div className="step4">
                <InfoBlock
                    text={`Выберите правильный вариант цветового восприятия кошек.`}
                    hidden={flexHidden === true ? ' hidden' : ''}
                />

                <div className={'flex ' + (flexHidden === true && 'hidden')}>
                    <div className={'flex_el error ' + (flexActive === 1 && 'active')} onClick={() => {
                        this.changeFlex(1)
                    }}>
                        <img src={img1} className="flex_el_img" alt=""/>
                    </div>
                    <div className={'flex_el ' + (flexActive === 2 && 'active')} onClick={() => {
                        this.changeFlex(2)
                    }}>
                        <img src={img2} className="flex_el_img" alt=""/>
                    </div>
                </div>
                {flexActive !== 0 &&
                <div className={'info ' + (flexActive === 1 && 'error')}>
                    <ArrowBack
                        returnMenu={this.closeImgDiv}
                    />
                    {flexActive === 1 &&
                    <div className="info_wrp">
                        <div className="info_head">
                            Неверно. Так человек видит мир.
                        </div>
                        <div className="info_el">
                            <div className="info_el_number">1</div>
                            <div className="info_el_text">
                                Фоторецепторы различаются по форме и делятся на два типа: палочки и колбочки. В палочках содержится только один вид пигмента, они обеспечивают сумеречное бесцветное зрение. В колбочках — два вида пигмента, и они составляют основу двухцветного цветового дневного зрения кошек.
                            </div>
                        </div>
                        <div className="info_el">
                            <div className="info_el_number">2</div>
                            <div className="info_el_text">
                                У человека в колбочках — три вида пигмента, и поэтому зрение трёхцветное. У кошки, как у сумеречного животного, на сетчатке глаза преобладают палочки, и только в центральной части сконцентрированы колбочки; это область острого зрения. Соотношение палочек и колбочек у кошек 25:1, а у человека — 4:1.
                            </div>
                        </div>
                        <div className="info_el">
                            <div className="info_el_number">3</div>
                            <div className="info_el_text">
                                Кошка гораздо хуже человека различает оттенки цвета. Они отчётливо видят только в синих и зелено-жёлтых частях спектра. Но при этом кошки способны различать большое количество оттенков серого цвета. Такую особенность зрения можно объяснить окраской основных жертв кошек — мышей, цвет которых варьируется от светло-серого до буровато-серого.
                            </div>
                        </div>
                    </div>
                    }
                    {flexActive === 2 &&
                    <div className="info_wrp">
                        <div className="info_head">
                            Правильно! Так кошка видит мир.
                        </div>
                        <div className="info_el">
                            <div className="info_el_number">1</div>
                            <div className="info_el_text">
                                Кошка хуже человека различает оттенки цвета. Они отчётливо видят только в синих и зелено-жёлтых частях спектра, но при этом кошки способны различать большое количество оттенков серого цвета. Такую особенность зрения можно объяснить окраской основных жертв кошек — мышей, цвет которых варьируется от светло-серого до буровато-серого.
                            </div>
                        </div>
                        <div className="info_el">
                            <div className="info_el_number">2</div>
                            <div className="info_el_text">
                                Фоторецепторы различаются по форме и делятся на два типа: палочки и колбочки. В палочках содержится только один вид пигмента, они обеспечивают сумеречное бесцветное зрение. В колбочках — два вида пигмента, и они составляют основу двухцветного цветового дневного зрения кошек.
                            </div>
                        </div>
                        <div className="info_el">
                            <div className="info_el_number">3</div>
                            <div className="info_el_text">
                                У человека в колбочках три вида пигмента, и поэтому зрение трёхцветное. У кошки, как у сумеречного животного, на сетчатке глаза преобладают палочки, и только в центральной части сконцентрированы колбочки; это область острого зрения. Соотношение палочек и колбочек у кошек 25:1, а у человека — 4:1.
                            </div>
                        </div>
                    </div>
                    }
                </div>
                }
            </div>
        )
    }
}
