import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from "../../../image/lessons/lesson20/step21-1.png";

export default class step32 extends React.Component {

    render() {
        return (
            <div className="step32">
                <TextBlock
                    text={`<span class="text_whiskas">Сочетание сухого и влажного корма</span> — лучшее решение при составлении рациона питомца, причем не обязательно сочетать разные виды кормов в рамках одного бренда. Можно использовать разные!
                    
                    Польза от чередования видов кормов доказана клиническими исследованиями научно-исследовательского института Waltham. Такой способ кормления соединяет <span class="text_whiskas">преимущества обоих видов кормов.</span>`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_box">
                            <ul className="list">
                                <li className="list_item">Улучшает гигиену полости рта.</li>
                                <li className="list_item">Содержит больше клетчатки и хорошо влияет на моторику ЖКТ.</li>
                                <li className="list_item">Может длительно храниться в миске.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_box">
                            <ul className="list">
                                <li className="list_item">Позволяет разнообразить рацион питомца различными вкусами и текстурами.</li>
                                <li className="list_item">Способствует соблюдению водного баланса.</li>
                                <li className="list_item">Позволяет избежать переедания.</li>
                                <li className="list_item">Профилактика мочекаменной болезни за счет большего содержания влаги.</li>
                            </ul>
                        </div>
                    </div>
                    <img src={img1} alt="" className="flex_el_img"/>
                </div>
            </div>
        )
    }
}
