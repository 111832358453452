import React from "react";
import Auth from "./_auth";
import Forgot from "./_forgot";
import Registration from "./_registration";
import {withRouter} from 'react-router-dom';

import './style.sass'

// import Info from "./_info";
import Api from "../../Service/Api";

class Login extends React.Component{

    Api = new Api();

    state = {
        link: this.props.match.params.link || 'auth'
    }

    changePage = () => {
        this.setState({link: 'forgot'})
    }

    changePageOnAuth = () => {
        this.setState({link: 'auth'})
    }

    _renderType = (link) => {
        if (link === 'auth') {
            return <Auth changePage={this.changePage} changeType={this.props.changeType}/>
        } else if (link === 'forgot') {
            return <Forgot changeType={this.props.changeType} changePageOnAuth={this.changePageOnAuth}/>
        } else {
            return <Registration changeType={this.props.changeType}/>
        }
    }

    render() {
        const {link} = this.state

        return (
            <div className={'login'}>
                {this._renderType(link)}
            </div>
        )
    }

}

export default withRouter(Login);
