import React, {useEffect, useState} from "react";
import Wrapper from "../../Coomponents/Wrapper";
import LoginLogo from "../../Images/login__logo.png"
import "./style.scss"
import Select from "../../Coomponents/Select";
import TextInput from "../../Coomponents/TextInput";
import RadioGroup from "../../Coomponents/RadioGroup";
import CheckboxInput from "../../Coomponents/CheckboxInput";
import Question from "../../Coomponents/Question";
import Button from "../../Coomponents/Button";
import Api from "../../Service/Api";
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import ru from 'date-fns/locale/ru';
import DatePicker from 'react-datepicker'
import {Link} from "react-router-dom";
import iconOk from "../../image/icon-ok.png";

const Restore = (props) => {

    const [isError, setIsError] = useState(false)
    const [errors, setErrors] = useState([])
    const [emailSend, setEmailSend] = useState(false)


    const api = new Api



    const login = () => {
        let inputCollection = [...document.querySelector('.login__tabs-body.active').querySelectorAll('.input')]
        inputCollection.map(el => {
            if(el.value.length === 0 && el.getAttribute('data-required') === 'true'){
                el.classList.add('error')
            }
        })
        let errorInputCollection = [...document.querySelector('.login__tabs-body.active').querySelectorAll('input.error')]
        if(errorInputCollection.length === 0){
            let email = document.querySelector('.login__tabs-body.active').querySelector('input[name="email"]').value
            api.requestPasswordReset(email)
                .then((res) => {
                    if(res.status === 200){
                        setEmailSend(true)
                    } else {
                        setIsError(true)
                        setErrors(Object.values(res.error))
                    }
                });
        }
    }


    return(
        <div className="login">
            <Wrapper>
                <div className="login__container">
                    <div className="login__logo">
                        <img className="login__image" src={LoginLogo} alt='Логотип'/>
                    </div>
                    <div className="login__title">Онлайн колледж</div>
                    <div className="login__form">
                        <div className="login__tabs">
                            {emailSend === false ?
                                <form className="login__tabs-body active" data-tab="login">
                                    <TextInput
                                        placeholder = 'E-MAIL'
                                        required = 'true'
                                        name = 'email'
                                        type = "email"
                                    />
                                    <Button
                                        title = "ОТправить запрос"
                                        onClick={login}
                                    />
                                </form> :
                                <div className="login__email_send">
                                    <div className="login_send_icon">
                                        {/* <img src={iconOk} alt=""/> */}
                                        <svg width="63" height="45" viewBox="0 0 63 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6 0C2.70194 0 0 2.70194 0 6V39C0 42.298 2.70194 45 6 45H57C60.2981 45 63 42.298 63 39V6C63 2.70194 60.2981 0 57 0H6ZM6 3H57C57.422 3 57.8115 3.085 58.1719 3.23438L33.6562 24.539C32.291 25.7245 30.6598 25.7246 29.2969 24.539L4.82812 3.23438C5.1885 3.085 5.57801 3 6 3ZM3.04688 5.67188L27.3281 26.789C29.716 28.8664 33.2365 28.8631 35.625 26.789L59.9531 5.67188C59.9648 5.78212 60 5.88603 60 6V39C60 40.688 58.688 42 57 42H6C4.31205 42 3 40.688 3 39V6C3 5.88603 3.03518 5.78212 3.04688 5.67188Z" fill="white"/>
                                        </svg>
                                    </div>
                                    Письмо с новым паролем придёт в течение суток. Спасибо!
                                    <p>
                                        <Link
                                            className="login__toStartPage"
                                            to={'/StartPage/ppclub'}
                                            // onClick={this.props.changePageOnAuth}
                                        >
                                            Перейти на главную
                                        </Link>
                                    </p>
                                </div>
                            }
                            {
                                isError &&
                                <div className="login__error-block">
                                    {errors.join('<br>')}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Wrapper>
        </div>
    )
}

export default Restore