import React from 'react'
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

export default class step5 extends React.Component {

    render() {
        return (
            <div className="step5">
                <TextBlock
                    text={`Предлагаем ознакомиться с обучающими видео`}
                />
                <VideoBlock
                    src={'https://www.youtube.com/embed/TFf-BpxER2g'}
                    title={'https://www.youtube.com/watch?v=TFf-BpxER2g&feature=emb_logo'}
                />
                <TextBlock
                    text={`<span class="text_blue">Как научить собаку команде «Ко мне!»</span>`}
                />
            </div>
        )
    }
}
