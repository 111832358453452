import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import DefinitionBlock from '../../../Components/LessonAndTestComponents/DefinitionBlock';

import img1 from '../../../image/lessons/lesson2/step3-1.png'

export default class step3 extends React.Component {

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step3">
                <DefinitionBlock
                    text={`<span class="text_blue">Питательное вещество</span> — это компонент пищи, который поддерживает жизненные процессы организма.`}
                    text2={`<span class="text_blue">Незаменимое питательное вещество</span> — это вещество, которое не может быть выработано организмом и должно быть получено из сбалансированного питания.`}
                    text3={`<span class="text_blue">Рацион</span> — это комплекс питательных веществ, употребляемых животным.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_head">
                            НЕДОСТАТОК ИЛИ ИЗБЫТОК ПИТАТЕЛЬНЫХ ВЕЩЕСТВ = НЕСБАЛАНСИРОВАННОЕ ПИТАНИЕ
                        </div>
                        <TextBlock
                            text={`Несбалансированное питание несет в себе риск возникновения различных заболеваний. Во избежание этого хозяева должны понимать значимость здорового питания и правильно подбирать рацион своим питомцам.`}
                        />
                    </div>
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                </div>
            </div>
        )
    }
}
