import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';

export default class step1 extends React.Component {

    planData = [
        `Метод расчета оптимального размера порции`,
        `Виды лакомств для питомцев`,
        `Способы поддержания здорового веса животного`,
        `Причины возникновения расстройств пищеварения и способы борьбы с ними`,
        `Метод перевода питомца на новый рацион`,
    ]

    render() {
        return (
            <div className="step1">
                <div className="head">План урока</div>

                <TextBlock
                    text={`В этом уроке рассматривается питание животных с практической точки зрения.
                    
                    Вы узнаете:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'blue'}
                />

            </div>
        )
    }
}
