import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson15/step6-1.png'
import img2 from '../../../image/lessons/lesson15/step6-2.png'
import img3 from '../../../image/lessons/lesson15/step6-3.png'
import img4 from '../../../image/lessons/lesson15/step6-4.png'
import img5 from '../../../image/lessons/lesson15/step6-5.png'
import img6 from '../../../image/lessons/lesson15/step6-6.png'


export default class step6 extends React.Component {

    render() {
        return (
            <div className="step6">
                <div className="flex">
                    <div className="flex_el left">
                        <TextBlock
                            text={`1. Кошек заводит большее количество семей`}
                        />
                        <img src={img1} alt="" className="flex_el_img"/>
                        <div className="flex_el_town">
                            Характерно для следующих городов\округов:
                        </div>
                        <div className="flex_el_box">
                            <img src={img3} alt="" className="flex_el_box_img"/>
                            <div className="flex_el_box_text">
                                Москва, Санкт-Петербург
                            </div>
                        </div>
                        <div className="flex_el_box">
                            <img src={img4} alt="" className="flex_el_box_img"/>
                            <div className="flex_el_box_text">
                                Города с населением менее 100 тыс. человек
                            </div>
                        </div>
                        <div className="flex_el_box">
                            <img src={img5} alt="" className="flex_el_box_img"/>
                            <div className="flex_el_box_text">
                                Урал, Дальний Восток
                            </div>
                        </div>
                    </div>
                    <div className="flex_el right">
                        <TextBlock
                            text={`2. В семьях появляется больше питомцев`}
                        />
                        <img src={img2} alt="" className="flex_el_img"/>
                        <div className="flex_el_town">
                            Характерно для следующих городов\округов:
                        </div>
                        <div className="flex_el_box">
                            <img src={img6} alt="" className="flex_el_box_img"/>
                            <div className="flex_el_box_text">
                                100-500 тыс. человек
                            </div>
                        </div>
                        <div className="flex_el_box">
                            <img src={img4} alt="" className="flex_el_box_img"/>
                            <div className="flex_el_box_text">
                                Города с населением менее 100 тыс. человек
                            </div>
                        </div>
                        <div className="flex_el_box">
                            <img src={img5} alt="" className="flex_el_box_img"/>
                            <div className="flex_el_box_text">
                                Москва, Приволжье, Урал
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
