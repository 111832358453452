import React from 'react'

import img1 from '../../../image/lessons/lesson15/step28-1.png'
import img1xs from '../../../image/lessons/lesson15/step28-1xs.png'


export default class step28 extends React.Component {


    render() {
        return (
            <div className="step28">
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} className="imgCenter" alt=""/>
                </picture>
            </div>
        )
    }
}
