import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson6/step13-1.png";
import img2 from "../../../image/lessons/lesson6/step13-2.png";
import img3 from "../../../image/lessons/lesson6/step13-3.png";

export default class step13 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит и избыток'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step13">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Марганец (Mn) — кости и хрящи.</b>
                           
                            Cодержится в организме животных в небольших количествах.

                            Общее содержание марганца в организме взрослой собаки может достигать всего 3–15 мг.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_flex">
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img2} alt=""/>
                            </div>
                            <div className="imgDiv_wrap_flex_box">
                                <TextBlock
                                    text={`Марганец необходим для:`}
                                />
                                <ul className="list">
                                    <li className="list_item">роста;</li>
                                    <li className="list_item">воспроизводства;</li>
                                    <li className="list_item">заживления ран;</li>
                                    <li className="list_item">эффективной работы мозга;</li>
                                    <li className="list_item">правильного метаболизма сахаров, инсулина и холестерина.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Злаковые культуры и минеральные соли — хороший источник марганца.
                            
                            В несколько меньших количествах он содержится в мясе.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img3} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Дефицит</b> марганца может привести к:`}
                        />
                        <ul className="list">
                            <li className="list_item">дефектам передних лап в период роста;</li>
                            <li className="list_item">хромоте;</li>
                            <li className="list_item">увеличению суставов;</li>
                            <li className="list_item">ухудшению способности к передвижению;</li>
                            <li className="list_item">задержке течки;</li>
                            <li className="list_item">плохим показателям оплодотворения и рождаемости.</li>
                        </ul>
                        <TextBlock
                            text={`Длительный <b>избыток</b> марганца может привести к дефициту железа.`}
                        />
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
