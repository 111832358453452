import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson20/step5-1.png'


export default class step21 extends React.Component {

    render() {
        return (
            <div className="step21">
                <div className="flex">
                    <div className="flex_el">
                        <TextBlock
                            text={`В основе влажных рационов WHISKAS<sup>®</sup> - <b>100% натуральные высококачественные ингредиенты*</b> (мясо и субпродукты, а также злаки), которые соответствуют критериям натуральности <b>FEDIAF</b> (Европейская федерация производителей кормов для домашних животных).<br>* Натуральные ингредиенты: мясо и субпродукты`}
                        />
                    </div>
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                </div>
                <div className={'block'}>
                    <div className="block_head">
                        FEDIAF считает ингредиент натуральным, если он одновременно соответствует трём критериям:
                    </div>
                    <ul className="list">
                        <li className="list_item">Имеет природное происхождение (получен из растений, животных, микроорганизмов и минералов);</li>
                        <li className="list_item">К нему ничего не было добавлено в процессе хранения, т.е. в его природный состав ничего не было привнесено;</li>
                        <li className="list_item">В процессе производства он был подвергнут только физическим методам обработки (например, помол, высушивание, температурная обработка).</li>
                    </ul>
                    <div className="block_head bottom">
                        Натуральные ингредиенты WHISKAS<sup>®</sup> соответствуют всем требованиям FEDIAF
                    </div>
                </div>
            </div>
        )
    }
}
