import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson9/step13-1.png'


export default class step13 extends React.Component {

    render() {
        return (
            <div className="step13">
                <TextBlock
                    text={`Щенки разных размерных групп растут с разной скоростью и становятся взрослыми в разное время. <b>Чем меньше собака, тем быстрее проходит ее период роста и развития.</b>`}
                />
                <img src={img1} alt=""/>
                <TextBlock
                    text={`На графике показано различие в темпах роста щенков разных размерных групп. Как вы видите, йоркширский терьер вырастает до размеров взрослой собаки уже в 9 месяцев, в том время как ньюфаундленд достигает этого показателя лишь к 2 годам.`}
                />
            </div>
        )
    }
}
