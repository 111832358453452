import React from 'react'

import img1 from '../../../image/lessons/lesson32/step36-1.png'
import img1xs from "../../../image/lessons/lesson32/step36-1xs.png";

export default class step36 extends React.Component {

    render() {
        return (
            <div className="step36">
                <div className="head">
                    Особенности миниатюрных и мелких собак
                </div>
                <ul>
                    <li>Большая продолжительность жизни</li>
                    <li>Высокая частота заболеваний ротовой полости</li>
                    <li>Склонность к ожирению</li>
                    <li>Склонность к заболеванию нижних мочевыводящих путей</li>
                    <li>Привередливость в еде</li>
                </ul>
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} alt="" className="imgCenter"/>
                </picture>
            </div>
        )
    }
}
