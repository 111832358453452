import React from 'react'
import OnClickTest from "../../../Components/LessonAndTestComponents/OnClickTest";
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from "../../../image/lessons/lesson17/step11-1.png";
import img2 from "../../../image/lessons/lesson17/step11-2.png";
import img3 from "../../../image/lessons/lesson17/step11-3.png";

export default class step11 extends React.Component {

    state = {
        answersData: [
            `Сухие`,
            `Влажные`,
        ],
        showImg: false
    }

    showNextBtn = () => {
        this.props.showNextBtn()
        this.setState({showImg: true})
    }

    render() {
        const {showImg} = this.state
        return (
            <div className="step11">
                <TextBlock
                    text={`Готовые корма для собак и кошек представлены в сухом и влажном виде. Как Вы думаете, что полезнее для собак и кошек — сухие, влажные корма или полезными являются оба вида корма?`}
                />
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'line'}
                    rightAnswers={[1, 2]}
                    showNextBtn={this.showNextBtn}
                    correctText={`И сухие, и влажные корма будут полезны для животного, если на их упаковке написано <b>«полнорационный корм»</b> или <b>«рацион сбалансирован»</b>. Обязательно найдите на упаковке эти слова прежде, чем покупать корм. Это означает, что в корме есть все необходимые для животного питательные вещества в нужных количествах (белки, жиры, углеводы, витамины и минералы).

                    Тем не менее, специалисты рекомендуют кормить собак и кошек <b>ежедневно и сухим, и влажным кормом</b>, потому что каждый вид корма имеет свои дополнительные преимущества для здоровья питомцев`}
                    img={img1}
                />
                <div className={'block ' + (showImg && 'show')}>
                    <div className="block_el">
                        <img src={img2} alt="" className="block_el_img"/>
                        <div className="block_el_head">
                            Преимущества влажного корма
                        </div>
                        <div className="block_el_box">
                            <ul className="list">
                                <li className="list_item">Cпособствует <b>поддержанию здорового веса</b> питомца за счет меньшей калорийности;</li>
                                <li className="list_item">Способствует <b>профилактике мочекаменной болезни</b> за счет высокого содержания влаги;</li>
                                <li className="list_item">Предлагает больше <b>вариаций вкусов и текстур</b>, чтобы разнообразить питание (кусочки в соусе, кусочки в желе, паштеты и другие варианты).</li>
                            </ul>
                        </div>
                    </div>
                    <div className="block_el">
                        <img src={img3} alt="" className="block_el_img"/>
                        <div className="block_el_head">
                            Преимущества сухого корма
                        </div>
                        <div className="block_el_box">
                            <ul className="list">
                                <li className="list_item">Обеспечивает <b>механическое очищение зубов</b> при поедании;</li>
                                <li className="list_item">Содержит <b>большее количество полезной клетчатки</b> для поддержания правильного пищеварения.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={'advice ' + (showImg && 'show')}>
                    Оптимальное питание — <span className="advice_bold">ежедневное сочетание сухого и влажного рационов</span>
                </div>
            </div>
        )
    }
}
