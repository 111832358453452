import React from "react";
import './style.scss'

const Preloader = (props) => {
    return(
        // <div className="preloader">
        //     <svg width="256px" height="64px" viewBox="0 0 128 32">
        //         <circle fill="#001299" cx="0" cy="0" r="11" transform="translate(16 16)">
        //             <animateTransform attributeName="transform" type="scale" additive="sum" values="1;1.42;1;1;1;1;1;1;1;1" dur="1350ms" repeatCount="indefinite"></animateTransform>
        //         </circle>
        //         <circle fill="#001299" cx="0" cy="0" r="11" transform="translate(64 16)">
        //             <animateTransform attributeName="transform" type="scale" additive="sum" values="1;1;1;1;1.42;1;1;1;1;1" dur="1350ms" repeatCount="indefinite"></animateTransform>
        //         </circle>
        //         <circle fill="#001299" cx="0" cy="0" r="11" transform="translate(112 16)">
        //             <animateTransform attributeName="transform" type="scale" additive="sum" values="1;1;1;1;1;1;1;1.42;1;1" dur="1350ms" repeatCount="indefinite"></animateTransform>
        //         </circle>
        //     </svg>
        // </div>
        <div className={'preloader ' + (props.hidePreloader && 'loaded')}>
            <div className="preloader__view">Загрузка...</div>
        </div>
    )
}

export default Preloader