import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson12/step11-1.png'
import img2 from '../../../image/lessons/lesson12/step11-2.png'
import img3 from '../../../image/lessons/lesson12/step11-3.png'
import img4 from '../../../image/lessons/lesson12/step11-4.png'


export default class step6 extends React.Component {
    state = {
    };

    render() {
        return (
            <div className="step11">
                <TextBlock
                    text={`В то время, как все сухие рационы имеют примерно одинаковую текстуру, у влажных рационов текстуры различаются. Существуют <b>разнообразные виды текстур,</b> например, WHISKAS<sup>®</sup> предлагает на выбор следующие виды:`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <h3 className="flex_el_head">
                            Рагу
                        </h3>
                        <img className="flex_el_img" src={img1} alt=""/>
                        <p className="flex_el_text">
                            мясные кусочки в соусе
                        </p>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_el_head">
                            Желе
                        </h3>
                        <img className="flex_el_img" src={img2} alt=""/>
                        <p className="flex_el_text">
                            мясные кусочки в желе
                        </p>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_el_head">
                            Паштет
                        </h3>
                        <img className="flex_el_img" src={img3} alt=""/>
                        <p className="flex_el_text">
                            мясной однородный паштет
                        </p>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_el_head">
                            Мини-Филе
                        </h3>
                        <img className="flex_el_img" src={img4} alt=""/>
                        <p className="flex_el_text">
                            кусочки, нарезанные особым образом, в желе
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}
