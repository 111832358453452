import React from 'react'
import OnClickTest from "../../../Components/LessonAndTestComponents/OnClickTest";
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

export default class step22 extends React.Component {

    state = {
        answersData: [
            `От 1 до 3 минут`,
            `Менее 1-ой минуты`,
            `Более 3 минут`,
        ],
        showImg: false
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step22">
                <TextBlock
                    text={`Теперь давайте поговорим, <span class="text_blue">как</span> покупатель принимает решение о покупке.
                    
                    Как Вы думаете, как много времени покупатель проводит в категории от момента входа до момента совершения покупки?`}
                />
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'line'}
                    rightAnswers={[2]}
                    showNextBtn={this.showNextBtn}
                    correctText={`Покупатель проводит в категории от момента входа до момента совершения покупки менее 1-ой минуты.`}
                />
            </div>
        )
    }
}
