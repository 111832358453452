import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson6/step7-1.png";
import img2 from "../../../image/lessons/lesson6/step7-2.png";
import img3 from "../../../image/lessons/lesson6/step7-3.png";

export default class step7 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит и избыток'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step7">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Натрий (Na) — клеточный баланс, регулирование жажды и диуреза.</b>
                           
                            Необходим для нормального функционирования клеток.
                            
                            Натрий представляет собой серебристо-белый реактивный минерал. Он был впервые выделен Хамфри Дейви в 1807 году.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_flex">
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img2} alt=""/>
                            </div>
                            <div className="imgDiv_wrap_flex_box">
                                <TextBlock
                                    text={`Натрий необходим для:`}
                                />
                                <ul className="list">
                                    <li className="list_item">здорового функционирования клеток;</li>
                                    <li className="list_item">поддержания кислотно-щелочного баланса вместе с калием;</li>
                                    <li className="list_item">поддержания давления между внутренней и внешней частями клетки;</li>
                                    <li className="list_item">клеточного энергетического обмена;</li>
                                    <li className="list_item">генерирования и передачи нервных импульсов;</li>
                                    <li className="list_item">регулирования водного баланса, чувства жажды и накопления мочи.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_flex">
                            <TextBlock
                                text={`Натрий обычно встречается в виде хлорида натрия (поваренная соль).
                                
                                Овощи содержат небольшое количество натрия, тогда как в сыром мясе его в три раза больше.
                                
                                В минеральных солях находится фосфат натрия, карбонат натрия, бикарбонат натрия и триполифосфат натрия.`}
                            />
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img3} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Дефицит</b> натрия у собак и кошек встречается редко. К симптомам дефицита относятся:`}
                        />
                        <ul className="list">
                            <li className="list_item">повышенная частота сердечных сокращений;</li>
                            <li className="list_item">сокращение потребления воды;</li>
                            <li className="list_item">повышенный диурез.</li>
                        </ul>
                        <TextBlock
                            text={`<b>Избыток</b> натрия вызывает:`}
                        />
                        <ul className="list">
                            <li className="list_item">рвоту;</li>
                            <li className="list_item">сухость слизистой оболочки.</li>
                        </ul>
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
