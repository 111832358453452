import React from 'react';
import './style.sass'

export default class LessonPlan extends React.Component {

    state = {
        planeActive: false,
        arrayStepsPlan: this.props.arrayStepsPlan
    }

    openPlan = () => {
        this.setState({
            planeActive: !this.state.planeActive
        })
    }

    render() {
        const {planeActive, arrayStepsPlan} = this.state
        return (
            <div className='LessonPlan'>

                <div className="image">
                    <img src={this.props.lessonImg} alt=""/>
                </div>
                <div className="name" dangerouslySetInnerHTML={{ __html: this.props.lessonName }}/>
                <div className="lessonNumber">
                    <p className="lessonNumeral" dangerouslySetInnerHTML={{ __html: this.props.lessonNumeral }}/>
                    <p className="lessonWord">Урок</p>
                </div>
                <div className={"steps" + (planeActive ? " disclosure" : "")}>
                    {Object.values(this.props.lessonSteps).map((item, index) => {
                        return (
                            <div
                                key={index}
                                className={(index === this.props.lessonStepsActive ? 'step active' : 'step') + (planeActive ? " opened" : "")}
                                onClick={arrayStepsPlan.length ? () => this.props.onClick(arrayStepsPlan.indexOf(item.order) + 1) : undefined}
                                dangerouslySetInnerHTML={{ __html: item.name }}
                            >
                                {/*{item.name}*/}
                            </div>
                        )
                    })}
                </div>
                <div className="buttonPlan" onClick={this.openPlan}>
                    <div className={"openArrow" + (planeActive ? " closeArrow" : "")}></div>
                </div>

            </div>
        )
    }
}
