import React from 'react'

import img1 from "../../../image/lessons/lesson10/step9-1.png";
import img2 from "../../../image/lessons/lesson10/step9-2.jpg";


export default class step9 extends React.Component {

    render() {
        return (
            <div className="step9">
                <img src={img1} alt="" className="imgCenter"/>
                <img src={img2} alt="" className="imgCenter"/>
            </div>
        )
    }
}
