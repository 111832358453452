import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson5/step9-1.png";
import img2 from "../../../image/lessons/lesson5/step9-2.png";

export default class step9 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит и избыток'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step9">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Витамин В1 (тиамин) — важен для работы нервной системы.</b>
                           
                            Тиамин — первый витамин, открытый наукой. Однако только в 1885 году было доказано его диетологическое происхождение, а в 1910 году причиной болезни был признан дефицит тиамина.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Функции тиамина:`}
                        />
                        <ul className="list">
                            <li className="list_item">играет важную роль в процессах метаболизма углеводов и жиров;</li>
                            <li className="list_item">необходим для протекания процессов роста и развития;</li>
                            <li className="list_item">поддерживает работу сердца;</li>
                            <li className="list_item">поддерживает нервную и пищеварительную системы.</li>
                        </ul>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Тиамин содержат следующие продукты:`}
                        />
                        <div className="imgDiv_wrap_flex">
                            <ul className="list">
                                <li className="list_item">дрожжи;</li>
                                <li className="list_item">ростки пшеницы;</li>
                                <li className="list_item">мясо;</li>
                                <li className="list_item">отруби;</li>
                                <li className="list_item">хлебные злаки.</li>
                            </ul>
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img2} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Дефицит тиамина может вызвать алиментарный полиневрит у людей и животных. Это заболевание проявляется в мышечной слабости, конвульсиях, проблемах опорно-двигательного аппарата, зрения, и даже может привести к смерти.`}
                        />
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
