import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson5/step6-1.png";
import img2 from "../../../image/lessons/lesson5/step6-2.png";

export default class step6 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит и избыток'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step6">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Витамин E (альфа-токоферол) — антиоксидант.</b>
                           
                            Витамин Е — это общее обозначение, которое включает в себя несколько веществ. Альфа-токоферол — наиболее распространенная форма, отличающаяся наибольшей биологической активностью.
                            
                            Витамин Е накапливается в жировой ткани, печени и мышцах. Диеты, богатые полиненасыщенными жирными кислотами, требуют большего количества витамина Е для предотвращения жировой дистрофии печени.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Витамин Е защищает клетки от свободных радикалов и укрепляет иммунную систему.
                            
                            Свободные радикалы повреждают и разрушают клетки, способствуют процессу старения. Они вырабатываются при нормальном метаболизме, а также в результате воздействия внешних факторов, таких как физическая нагрузка, загрязнение и солнечный свет.`}
                        />
                    </div>
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_flex">
                            <TextBlock
                                text={`Наиболее важные источники витамина Е — продукты растительного происхождения, содержащие масла, а также зерно и хлебные злаки.
                            
                                Витамин Е также находится в некоторых продуктах животного происхождения, например, в печени.`}
                            />
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img2} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Признаки дефицита у собак и кошек:`}
                        />
                        <ul className="list">
                            <li className="list_item">мышечная слабость;</li>
                            <li className="list_item">нарушение репродуктивной функции;</li>
                            <li className="list_item">нарушение зрения;</li>
                            <li className="list_item">патология нервной системы.</li>
                        </ul>
                        <TextBlock
                            text={`Избыток витамина Е у кошек увеличивает время свертывания крови.`}
                        />
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
