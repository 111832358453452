import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson18/step2-1.png'
import img2 from '../../../image/lessons/lesson18/step2-2.png'
import img3 from '../../../image/lessons/lesson18/step2-3.png'
import img4 from '../../../image/lessons/lesson18/step2-4.png'


export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <TextBlock
                    text={`Потребности собаковладельцев делятся на <span class="text_dark-blue">четыре основные группы</span>, для каждой из которых необходимо иметь соответствующее предложение.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                    <div className="flex_el">
                        <img src={img2} alt="" className="flex_el_img"/>
                    </div>
                    <div className="flex_el">
                        <img src={img3} alt="" className="flex_el_img"/>
                    </div>
                    <div className="flex_el">
                        <img src={img4} alt="" className="flex_el_img"/>
                    </div>
                </div>
            </div>
        )
    }
}
