import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import SwipeBlock from '../../../Components/LessonAndTestComponents/SwipeBlock';
import Slider from "react-slick";

import img1 from '../../../image/lessons/lesson16/step7-1.png';
import img1xs from '../../../image/lessons/lesson16/step7-1xs.png';
import img2 from '../../../image/lessons/lesson16/step7-2.png';
import img2xs from '../../../image/lessons/lesson16/step7-2xs.png';
import img3 from '../../../image/lessons/lesson16/step7-3.png';
import img4 from '../../../image/lessons/lesson16/step7-4.png';
import img4xs from '../../../image/lessons/lesson16/step7-4xs.png';
import img5 from '../../../image/lessons/lesson16/step7-5.png';
import img5xs from '../../../image/lessons/lesson16/step7-5xs.png';
import img6 from '../../../image/lessons/lesson16/step7-6.png';
import img6xs from '../../../image/lessons/lesson16/step7-6xs.png';
import img7 from '../../../image/lessons/lesson16/step7-7.png';

export default class step7 extends React.Component {

    changeSlide = (next) => {
        if(next === 5){
            this.props.showNextBtn();
        }
    }

    slider = {
        1: {
            head: `Частота кормления`,
            text: `Количество еды, которую ест кошка <b>ежедневно</b>, должно постепенно <b>увеличиваться уже с первого дня беременности</b>.

            Перед родами и некоторое время после можно предоставить кошке <b>свободный доступ к пище</b>, так как из-за лактации ей необходимо еще больше энергии, а значит, и еды.`,
            img: img1,
        },
        2: {
            head: `Усвояемость корма`,
            text: `В первую очередь, пища <b>должна быть хорошо усвояемой</b>. Это даст кошке необходимое количество <b>энергии</b>, и при этом желудок не будет сильно растягиваться от большого объема пищи. Особенно это важно, когда кошка вынашивает большой помет, так как в этом случае для желудка остается очень мало места и кошка физически не может потреблять достаточный объем пищи.`,
            img: img2
        },
        3: {
            head: `Энергетическая ценность корма`,
            text: `Чтобы обеспечить беременную кошку необходимым количеством энергии, при этом учитывая ее физиологические особенности, <b>рекомендуется давать беременным кошкам корм для котят</b>, например, сухой корм Whiskas<sup><sup>®</sup></sup> для котят от 2 до 12 месяцев и влажный корм Whiskas<sup><sup>®</sup></sup> для котят с 1 до 12 месяцев.
            
            Корм для котят имеет <b>высокую энергетическую плотность</b>, вкусовую <b>привлекательность</b> и <b>хорошо усваивается</b>. Именно эти качества корма необходимы кошкам во время беременности.`,
            img: img3
        },
    }

    render() {

        let settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            beforeChange: (current, next) => {
                this.changeSlide(next)
            },
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        dots: true,
                        arrows: false,
                        adaptiveHeight: true,
                    }
                },
            ]
        };
        return (
            <div className="step7">
                <TextBlock
                    text={`Мы стремимся усиливать стимулы к покупке и уменьшать влияние барьеров через:`}
                />
                <InfoBlock
                    text={`Используйте стрелки для перемещения.`}
                />
                <SwipeBlock
                    text={
                        `Используйте свайп для перемещения по шагам.`
                    }
                />

                <div className={"slider slider_noArrow show"}>
                    <Slider {...settings}>
                        <div className="slider_el">
                            <div className="imgMobile">
                                <img src={img1xs} className="slider_img-mobile" alt=""/>
                            </div>
                            <div className="head">
                                1. Высокое качество ингредиентов
                            </div>
                            <div className="slider_flex">
                                <TextBlock
                                    text={`Все полнорационные корма компании Mars разработаны согласно жестким требованиям по более 40 параметрам питательной ценности кормов для домашних животных, разработанным экспертами WALTHAM<sup>®</sup>.
                                    
                                    Перед запуском продуктов на рынок все корма проверяются на соответствие этим требованиям во внутренних и внешних аналитических и микробиологических лабораториях.`}
                                />
                                <img src={img1} className="slider_flex_img" alt=""/>
                            </div>
                        </div>
                        <div className="slider_el">
                            <div className="imgMobile">
                                <img src={img2xs} className="slider_img-mobile" alt=""/>
                            </div>
                            <div className="head">
                                1. Высокое качество ингредиентов
                            </div>
                            <div className="slider_flex">
                                <TextBlock
                                    text={`На заводах по производству кормов внедрены все современные стандарты и практики управления качеством выпускаемой продукции, включая ISO, НАССР и GMP.
                                    
                                    Чтобы корма были не только полезными и безопасными, но еще и действительно нравились питомцам, кошки и собаки в наших питомниках дегустируют разработанные корма, оценивая вкусовую привлекательность, а в свободное время окружены заботой и вниманием наших профессиональных ветеринарных врачей и тренеров.`}
                                />
                                <img src={img2} className="slider_flex_img" alt=""/>
                            </div>
                        </div>
                        <div className="slider_el">
                            <div className="imgMobile">
                                <img src={img3} className="slider_img-mobile" alt=""/>
                            </div>
                            <div className="head">
                                1. Высокое качество ингредиентов
                            </div>
                            <TextBlock
                                text={`Продукты Mars имеют очень высокие оценки в исследованиях с потребителями, что является основой успешных продаж на рынке.`}
                            />
                            <div className="slider_flex">
                                <div className="slider_flex_box">
                                    <TextBlock
                                        text={`<span class="text_blue big">95%*</span> владельцев домашних животных положительно оценивают продукты <span class="text_blue">Mars Petcare.</span>`}
                                    />
                                    <div className="slider_flex_note">
                                        * Среднее значение оценок «общее мнение покупателей:
                                        отличный продукт, очень хороший продукт, скорее хороший»
                                        о продуктах Mars Petcare: Whiskas<sup>®</sup> пауч рагу, Whiskas<sup>®</sup>
                                        сухой корм аппетитное ассорти, Perfect Fit™ пауч для
                                        стерилизованных кошек, Pedigree<sup>®</sup> пауч в соусе, Cesar<sup>®</sup>
                                        пауч в соусе, Dreamies<sup>®</sup> лакомство в рамках исследования
                                        ООО «ИПСОС» 2015-2016.
                                    </div>
                                </div>
                                <img src={img3} className="slider_flex_img" alt=""/>
                            </div>
                        </div>
                        <div className="slider_el">
                            <div className="head">
                                2. Непрерывная коммуникация натуральности продуктов
                            </div>
                            <ul className="list">
                                <li className="list_item">
                                    Мы подчеркиваем натуральный состав наших брендов на упаковке.
                                </li>
                                <li className="list_item">
                                    Транслируем на упаковке, что наши бренды рекомендованы ассоциацией практикующих ветеринарных врачей.
                                </li>
                                <li className="list_item">
                                    Также размещаем информацию про соответствие международным стандартам качества.
                                </li>
                            </ul>
                            <picture>
                                <source srcSet={img4xs} media="(max-width: 767px)"/>
                                <img src={img4} className="slider_img" alt=""/>
                            </picture>
                        </div>
                        <div className="slider_el">
                            <div className="head">
                                3. Развенчиваем мифы о вреде кормления кормами промышленного производства:
                            </div>
                            <div className="slider_flex">
                                <img src={img5} className="slider_flex_img left" alt=""/>
                                <div className="slider_flex_box mobile-hidden">
                                    <p className="slider_flex_box_text">
                                        Отслеживаются все упоминания о брендах Марс в интернете, по всем негативным случаям с потребителями связываются эксперты клиентского сервиса.
                                    </p>
                                    <p className="slider_flex_box_text">
                                        В блогах публикуются статьи, написанные ветеринарами и экспертами, объясняющие, пользу готовых кормов.
                                    </p>
                                    <p className="slider_flex_box_text">
                                        Взаимодействие с блогерами, отправка кормов приютам.
                                    </p>
                                </div>
                            </div>
                            <div className="imgMobile">
                                <img src={img5xs} className="slider_img-mobile" alt=""/>
                            </div>
                        </div>
                        <div className="slider_el">
                            <div className="head">
                                3. Развенчиваем мифы о вреде кормления кормами промышленного производства:
                            </div>
                            <div className="slider_flex">
                                <img src={img6} className="slider_flex_img left" alt=""/>
                                <div className="slider_flex_box mobile-hidden">
                                    <p className="slider_flex_box_text">
                                        вопросыокорме.рф — сайт разрушитель мифов о промышленных кормах.
                                    </p>
                                    <p className="slider_flex_box_text">
                                        хозяйкамечты.рф — социальный проект, помогающий питомцам из приютов найти ответственных хозяев
                                    </p>
                                </div>
                            </div>
                            <div className="imgMobile">
                                <img src={img6xs} className="slider_img-mobile" alt=""/>
                            </div>
                            <img src={img7} className="slider_img mobile-hidden" alt=""/>
                        </div>
                    </Slider>
                </div>
            </div>
        )
    }
}
