import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson7/step31-1.png'
import img2 from '../../../image/lessons/lesson7/step31-2.png'


export default class step31 extends React.Component {


    render() {
        return (
            <div className="step31">
                <TextBlock
                    text={`На этой диаграмме показана зависимость количества потребляемой воды от степени влажности корма.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                    <div className="flex_el">
                        <img src={img2} alt="" className="flex_el_img second"/>
                        <TextBlock
                            text={`Можно сделать вывод, что чем меньше влаги содержится в корме, тем больше воды выпьет животное. Это объясняется тем, что питомец стремится восполнять недостающее количество воды, что способствует <span class="text_blue">поддержанию оптимального водного баланса.</span>`}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
