import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import Popup from "../../../Components/Popup";

import img1 from "../../../image/lessons/lesson20/step11-1.png";
import img2 from "../../../image/lessons/lesson20/step11-2.png";
import img3 from "../../../image/lessons/lesson20/step11-3.png";
import img4 from "../../../image/lessons/lesson20/step11-4.png";
import img5 from "../../../image/lessons/lesson20/step13-1.png";
import img6 from "../../../image/lessons/lesson20/step13-2.png";
import img7 from "../../../image/lessons/lesson20/step13-3.png";
import img8 from "../../../image/lessons/lesson20/step13-4.png";
import img9 from "../../../image/lessons/lesson20/step13-5.png";
import img10 from "../../../image/lessons/lesson20/step13-6.png";
import img11 from "../../../image/lessons/lesson20/step13-7.png";
import img12 from "../../../image/lessons/lesson20/step13-8.png";
import img13 from "../../../image/lessons/lesson20/step13-9.png";
import img14 from "../../../image/lessons/lesson20/step13-11.png";
import img15 from "../../../image/lessons/lesson20/step13-12.png";
import img16 from "../../../image/lessons/lesson20/step13-13.png";
import img17 from "../../../image/lessons/lesson20/step13-14.png";

export default class step13 extends React.Component {

    state = {
        popupActive: 0,
        showPopup: false,
        points: [],
    }

    showPopup = (popupActive) => {
        const {points} = this.state;
        if (points.indexOf(popupActive) === -1) {
            points.push(popupActive)
        }

        this.setState({
            popupActive: popupActive,
            showPopup: true,
            points: points
        })

        if (points.length === 5) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    render() {
        const {showPopup, popupActive} = this.state
        return (
            <div className="step13">
                <TextBlock
                    text={`Теперь Вам предстоит знакомство с текстурами WHISKAS<sup>®</sup> для взрослых кошек, а также с индивидуальными особенностями каждого продукта.`}
                />
                <InfoBlock
                    text={`Нажмите на изображения с кормом и узнайте о них больше.`}
                />
                <div className="flex">
                    <div className="flex_el" onClick={() => {this.showPopup(1)}}>
                        <img src={img1} alt="" className="flex_el_img"/>
                        <p className="flex_el_name">
                            Сухой корм
                        </p>
                    </div>
                    <div className="flex_el" onClick={() => {this.showPopup(2)}}>
                        <img src={img2} alt="" className="flex_el_img"/>
                        <p className="flex_el_name">
                            Рагу
                        </p>
                    </div>
                    <div className="flex_el" onClick={() => {this.showPopup(3)}}>
                        <img src={img3} alt="" className="flex_el_img"/>
                        <p className="flex_el_name">
                            Желе
                        </p>
                    </div>
                    <div className="flex_el minus" onClick={() => {this.showPopup(7)}}>
                        <img src={img14} alt="" className="flex_el_img"/>
                        <p className="flex_el_name">
                            Рыбная коллекция
                        </p>
                    </div>
                    <div className="flex_el minus" onClick={() => {this.showPopup(4)}}>
                        <img src={img4} alt="" className="flex_el_img"/>
                        <p className="flex_el_name">
                            Паштет
                        </p>
                    </div>
                    <div className="flex_el minus" onClick={() => {this.showPopup(5)}}>
                        <img src={img5} alt="" className="flex_el_img"/>
                        <p className="flex_el_name">
                            Аппетитный микс
                        </p>
                    </div>
                    <div className="flex_el minus" onClick={() => {this.showPopup(6)}}>
                        <img src={img15} alt="" className="flex_el_img"/>
                        <p className="flex_el_name">
                            Мясная коллекция
                        </p>
                    </div>
                </div>
                {showPopup &&
                <Popup
                    closePopup={this.closePopup}
                >
                    <div className="head">
                        Разнообразие в питании – репертуар вкусов и текстур
                    </div>
                    <div className="box">
                        {popupActive === 1
                            ?
                            <div className="box_el">
                                <div className="box_el_head">
                                    WHISKAS<sup>®</sup> Вкусные подушечки с говядиной, с курицей и индейкой, с лососем
                                </div>
                                <div className="box_el_flex">
                                    <div className="box_el_flex_wrap">
                                        <div className="box_el_flex_head">
                                            Содержит:
                                        </div>
                                        <ul className="list">
                                            <li className="list_item">Оптимальное сочетание питательных веществ и
                                                нутриентов для поддержания обмена веществ;
                                            </li>
                                            <li className="list_item">Витамин Е и цинк для иммунитета;</li>
                                            <li className="list_item">Омега-6 и цинк для здоровья кожи и шерсти;</li>
                                            <li className="list_item">Витамин А и таурин для хорошего зрения;</li>
                                            <li className="list_item">Баланс кальция и фосфора для здоровья костей;</li>
                                            <li className="list_item">Сухая текстура корма для удаления зубного налета;</li>
                                            <li className="list_item">Высокоусвояемые ингредиенты и клетчатка для пищеварения.</li>
                                        </ul>
                                    </div>
                                    <img src={img6} alt="" className="box_el_flex_img"/>
                                </div>
                            </div>
                            : popupActive === 2 ?
                            <div className="box_el">
                                <div className="box_el_flex">
                                    <div className="box_el_flex_box left">
                                        <TextBlock
                                            text={`Рагу WHISKAS<sup>®</sup> с:`}
                                        />
                                        <ul className="list">
                                            <li className="list_item">Курицей;</li>
                                            <li className="list_item">Форелью и лососем;</li>
                                            <li className="list_item">Индейкой и кроликом;</li>
                                            <li className="list_item">Треской и лососем;</li>
                                            <li className="list_item">Телятиной;</li>
                                            <li className="list_item">Говядиной и ягненком.</li>
                                        </ul>
                                        <img src={img7} alt="" className="box_el_img"/>
                                    </div>
                                    <div className="box_el_flex_box right">
                                        <TextBlock
                                            text={`Оптимальный баланс питательных веществ для полноценной жизни.
                                            <b>Все необходимое для поддержания здоровья Вашей любимицы:</b>`}
                                        />
                                        <img src={img8} alt="" className="box_el_img"/>
                                    </div>
                                </div>
                            </div>
                            : popupActive === 3 ?
                                <div className="box_el">
                                    <div className="box_el_flex">
                                        <div className="box_el_flex_box left">
                                            <TextBlock
                                                text={`Желе WHISKAS<sup>®</sup> с:`}
                                            />
                                            <ul className="list">
                                                <li className="list_item">Курицей;</li>
                                                <li className="list_item">Говядиной и кроликом;</li>
                                                <li className="list_item">Лососем;</li>
                                                <li className="list_item">Говядиной и ягненком;</li>
                                                <li className="list_item">Курицей и индейкой.</li>
                                            </ul>
                                            <img src={img7} alt="" className="box_el_img"/>
                                        </div>
                                        <div className="box_el_flex_box right">
                                            <TextBlock
                                                text={`Оптимальный баланс питательных веществ для полноценной жизни.
                                                <b>Все необходимое для поддержания здоровья Вашей любимицы:</b>`}
                                            />
                                            <img src={img9} alt="" className="box_el_img"/>
                                        </div>
                                    </div>
                                </div>
                            : popupActive === 4 ?
                                <div className="box_el">
                                    <div className="box_el_flex">
                                        <div className="box_el_flex_box left">
                                            <TextBlock
                                                text={`Паштет WHISKAS<sup>®</sup> с:`}
                                            />
                                            <ul className="list">
                                                <li className="list_item">Уткой;</li>
                                                <li className="list_item">Говядиной и печенью;</li>
                                                <li className="list_item">Индейкой и кроликом;</li>
                                                <li className="list_item">Курицей и индейкой.</li>
                                            </ul>
                                            <img src={img10} alt="" className="box_el_img"/>
                                        </div>
                                        <div className="box_el_flex_box right">
                                            <TextBlock
                                                text={`Оптимальный баланс питательных веществ для полноценной жизни.
                                                <b>Все необходимое для поддержания здоровья Вашей любимицы:</b>`}
                                            />
                                            <img src={img11} alt="" className="box_el_img"/>
                                        </div>
                                    </div>
                                </div>
                                : popupActive === 5 ?
                                <div className="box_el">
                                    <TextBlock
                                        text={`<b>WHISKAS<sup>®</sup> Аппетитный Микс</b> — это коллекция сбалансированных блюд, приготовленных по особым рецептам. В каждом из них три основных ингредиента: разные виды мяса или морепродуктов смешиваются в нежнейшем соусе или желе, чтобы вы воплощали мечты ваших любимцев.`}
                                    />
                                    <img src={img12} alt="" className="box_el_img"/>
                                    <TextBlock
                                        text={`Оптимальный баланс питательных веществ для полноценной жизни.
                                        <b>Все необходимое для поддержания здоровья Вашей любимицы:</b>`}
                                    />
                                    <img src={img13} alt="" className="box_el_img"/>
                                </div>
                            : popupActive === 6 ?
                            <div className="box_el">
                            <div className="box_el_flex">
                            <div className="box_el_flex_box left">
                            <TextBlock
                            text={`Мясная коллекция WHISKAS<sup>®</sup> с:`}
                            />
                            <ul className="list">
                            <li className="list_item">Курицей;</li>
                            <li className="list_item">Говядиной;</li>
                            <li className="list_item">Кроликом.</li>
                            </ul>
                            {/*<img src={img7} alt="" className="box_el_img"/>*/}
                            </div>
                            <div className="box_el_flex_box right">
                            {/*<TextBlock*/}
                            {/*text={`Оптимальный баланс питательных веществ для полноценной жизни.*/}
                            {/*                    <b>Все необходимое для поддержания здоровья Вашей любимицы:</b>`}*/}
                            {/*/>*/}
                            <img src={img16} alt="" className="box_el_img"/>
                            </div>
                            </div>
                            </div>
                                                : popupActive === 7 ?
                                                    <div className="box_el">
                                                        <div className="box_el_flex">
                                                            <div className="box_el_flex_box left">
                                                                <TextBlock
                                                                    text={`Рыбная коллекция WHISKAS<sup>®</sup> с:`}
                                                                />
                                                                <ul className="list">
                                                                    <li className="list_item">Лососем.</li>
                                                                </ul>
                                                                {/*<img src={img7} alt="" className="box_el_img"/>*/}
                                                            </div>
                                                            <div className="box_el_flex_box right">
                                                                {/*<TextBlock*/}
                                                                {/*text={`Оптимальный баланс питательных веществ для полноценной жизни.*/}
                                                                {/*                    <b>Все необходимое для поддержания здоровья Вашей любимицы:</b>`}*/}
                                                                {/*/>*/}
                                                                <img src={img17} alt="" className="box_el_img"/>
                                                            </div>
                                                        </div>
                                                    </div> :
                                null
                        }
                    </div>
                </Popup>
                }
            </div>
        )
    }
}
