import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'
import DefinitionBlock from '../../../Components/LessonAndTestComponents/DefinitionBlock'
import Answer from './dnd/Answer';
import Questions from './dnd/Questions';

import img1 from '../../../image/lessons/lesson3/step10-1.png'
import img2 from '../../../image/lessons/lesson3/step10-2.png'
import img3 from '../../../image/lessons/lesson3/step10-3.png'
import img4 from '../../../image/lessons/lesson3/step10-4.png'
import img5 from '../../../image/lessons/lesson3/step10-5.png'

export default class step10 extends React.Component {

    state = {
        // Ресайз
        windowWidth: window.innerWidth,

        // Работа десктопной версии
        answers: ['4', '3', '1', '5', '2'], //Массив правильных ответов
        correct: '',
        answerCorrect: [],
        answersSelectedDesktop: ['?', '?', '?', '?', '?'], // Массив выбираемых значений

        //Работа мобильной версии
        answerActive: 0,
        questionActive: 0,
        answersMobile: [],
        questionsMobile: [],
        answersSelected: ['?', '?', '?', '?', '?'], // Массив выбираемых значений
        correctMobile: '',
        answerCorrectMobile: [],
        showPopup: false
    }

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    //Работа десктопной версии
    setAnswer = (answer_el, question_el) => {
        const {answersSelectedDesktop} = this.state

        answersSelectedDesktop.splice(answer_el - 1, 1, question_el)
        this.setState({answersSelectedDesktop})
    };

    checkAnswer = () => {
        const {answerCorrect, answersSelectedDesktop, answers} = this.state
        // eslint-disable-next-line
        answersSelectedDesktop.map((answer, index) => {
            if (answer === answers[index] ) {
                answerCorrect.push('correct')
            } else {
                answerCorrect.push('inCorrect')
            }
        })
        this.setState({
            answerCorrect: answerCorrect,
        })
        if (answersSelectedDesktop.indexOf('?') === -1 && answerCorrect.indexOf('inCorrect') === -1) {
            this.setState({correct: '1'})
        } else {
            this.setState({correct: '0'})
        }
        this.props.showNextBtn()
    };

    _renderTestDesktop = () => {
        const {answerCorrect, correct} = this.state
        return (
            <div className="test_desktop">
                <div className="test_wrp">
                    <div className="questions">
                        <div className={'question_el'}>
                            <Questions targetKey="1" question_el={'1'} label={'Белки'} text={''} img={img1}/>
                        </div>
                        <div className={'question_el'}>
                            <Questions targetKey="1" question_el={'2'} label={'Углеводы'} text={''} img={img2}/>
                        </div>
                        <div className={'question_el'}>
                            <Questions targetKey="1" question_el={'3'} label={'Минералы'} text={''} img={img3}/>
                        </div>
                        <div className={'question_el'}>
                            <Questions targetKey="1" question_el={'4'} label={'Жиры'} text={''} img={img4}/>
                        </div>
                        <div className={'question_el'}>
                            <Questions targetKey="1" question_el={'5'} label={'Витамины'} text={''} img={img5}/>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="answers">
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'1'} count={'?'} answerCorrect={answerCorrect[0]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'2'} count={'?'} answerCorrect={answerCorrect[1]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'3'} count={'?'} answerCorrect={answerCorrect[2]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'4'} count={'?'} answerCorrect={answerCorrect[3]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'5'} count={'?'} answerCorrect={answerCorrect[4]}/>
                            </div>
                        </div>
                    </div>
                </div>
                {correct !== '' ?
                    <div className={'info ' + (correct === '0' && 'error')}>
                        <div className="info_head">
                            {correct === '1' ? 'все верно!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                        <div className="infoText">
                            Из приведенных продуктов белок яйца усваивается лучше всех. Далее идет белок молока, мяса, сои и кукурузы (кукурузный глютен).
                        </div>
                    </div>
                    :
                    <button className="btn btn_blue" onClick={() => this.checkAnswer()}>
                        ПРОВЕРИТЬ
                    </button>
                }
            </div>
        )
    }

    //Работа мобильной версии
    questions = [
        img1,
        img2,
        img3,
        img4,
        img5,
    ]

    selectAnswer = (answerActive) => {
        document.body.scrollIntoView( true);
        document.body.style.overflow = "hidden";
        this.setState({
            showPopup: true,
            answerActive: answerActive
        })
    }

    selectQuestion = (questionActive) => {
        const {answerActive, answersMobile, questionsMobile, answersSelected} = this.state

        if (answersMobile.indexOf(answerActive) === -1) {
            answersMobile.push(answerActive)
        }
        this.setState({answersMobile})

        if (questionsMobile.indexOf(questionsMobile) === -1) {
            questionsMobile.push(questionActive)
        }
        this.setState({questionsMobile})

        answersSelected.splice(answerActive - 1, 1, this.questions[questionActive - 1])

        document.body.style.overflow = "";
        this.setState({
            showPopup: false,
            questionActive: questionActive,
            answersSelected: answersSelected
        })
        console.log(111, answersSelected)
    }

    checkAnswerMobile = () => {
        const {answerCorrectMobile, answersSelected} = this.state
        // eslint-disable-next-line
        answersSelected.map((answer, index) => {
            if (index === 0) {
                if (answer === this.questions[3]) {
                    answerCorrectMobile.push('correct')
                } else {
                    answerCorrectMobile.push('inCorrect')
                }
            } else if (index === 1) {
                if (answer === this.questions[2]) {
                    answerCorrectMobile.push('correct')
                } else {
                    answerCorrectMobile.push('inCorrect')
                }
            } else if (index === 2) {
                if (answer === this.questions[0]) {
                    answerCorrectMobile.push('correct')
                } else {
                    answerCorrectMobile.push('inCorrect')
                }
            } else if (index === 3) {
                if (answer === this.questions[4]) {
                    answerCorrectMobile.push('correct')
                } else {
                    answerCorrectMobile.push('inCorrect')
                }
            } else if (index === 4) {
                if (answer === this.questions[1]) {
                    answerCorrectMobile.push('correct')
                } else {
                    answerCorrectMobile.push('inCorrect')
                }
            }
        })
        this.setState({
            answerCorrect: answerCorrectMobile,
        })
        console.log(555, answerCorrectMobile)
        if (answerCorrectMobile.indexOf('?') === -1 && answerCorrectMobile.indexOf('inCorrect') === -1) {
            this.setState({correctMobile: '1'})
        } else {
            this.setState({correctMobile: '0'})
        }
        this.props.showNextBtn()
    }

    closePopup = () => {
        document.body.style.overflow = "";
        this.setState({showPopup: false})
    }

    _renderTestMobile = () => {
        const {showPopup, answerActive, answersMobile, answersSelected, correctMobile, answerCorrectMobile} = this.state
        return (
            <div className="test_mobile">
                {correctMobile !== '' ?
                    <div className={'info ' + (correctMobile === '0' && 'error')}>
                        <div className="info_head">
                            {correctMobile === '1' ? 'все верно!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                        <div className="infoText">
                            Из приведенных продуктов белок яйца усваивается лучше всех. Далее идет белок молока, мяса, сои и кукурузы (кукурузный глютен).
                        </div>
                    </div>
                    :
                    <button className="btn btn_blue" onClick={() => this.checkAnswerMobile()}>
                        ПРОВЕРИТЬ
                    </button>
                }
                <div className="answers">
                    <div className="flex">
                        <div className={'answers_item ' + (answersMobile.indexOf(1) >=0 ? 'selected ' : '') + answerCorrectMobile[0]} onClick={() => {this.selectAnswer(1)}}>
                            {answersSelected[0] === '?' ?
                                answersSelected[0]
                                :
                                <img src={answersSelected[0]} alt="" className="answers_item_img"/>
                            }
                        </div>
                        <div className={'answers_item ' + (answersMobile.indexOf(2) >=0 ? 'selected ' : '') + answerCorrectMobile[1]} onClick={() => {this.selectAnswer(2)}}>
                            {answersSelected[1] === '?' ?
                                answersSelected[1]
                                :
                                <img src={answersSelected[1]} alt="" className="answers_item_img"/>
                            }
                        </div>
                        <div className={'answers_item ' + (answersMobile.indexOf(3) >=0 ? 'selected ' : '') + answerCorrectMobile[2]} onClick={() => {this.selectAnswer(3)}}>
                            {answersSelected[2] === '?' ?
                                answersSelected[2]
                                :
                                <img src={answersSelected[2]} alt="" className="answers_item_img"/>
                            }
                        </div>
                        <div className={'answers_item ' + (answersMobile.indexOf(4) >=0 ? 'selected ' : '') + answerCorrectMobile[3]} onClick={() => {this.selectAnswer(4)}}>
                            {answersSelected[3] === '?' ?
                                answersSelected[3]
                                :
                                <img src={answersSelected[3]} alt="" className="answers_item_img"/>
                            }
                        </div>
                        <div className={'answers_item ' + (answersMobile.indexOf(5) >=0 ? 'selected ' : '') + answerCorrectMobile[4]} onClick={() => {this.selectAnswer(5)}}>
                            {answersSelected[4] === '?' ?
                                answersSelected[4]
                                :
                                <img src={answersSelected[4]} alt="" className="answers_item_img"/>
                            }
                        </div>
                    </div>
                </div>
                {showPopup &&
                <div className="popup">
                    <div className="popup_wrp">
                        <div
                            className="popup_closed"
                            onClick={() => {this.closePopup()}}
                        />
                        <div className="popup_flex">
                            <div className="point">
                                {answerActive}
                            </div>
                            <p className="popup_text">
                                Выберите вариант ответа:
                            </p>
                            <div className="questions">
                                {this.questions.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={'questions_item'}
                                            onClick={() => {this.selectQuestion(index + 1)}}
                                        >
                                            <img src={item} alt="" className="question_item_img"/>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }

    render() {
        const {windowWidth} = this.state
        return (
            <div className="step10">
                <DefinitionBlock
                    text={`<span class="text_blue">Биологическая ценность</span> — это шкала измерения, используемая для определения в процентах степени усвоения организмом данного источника питательных веществ.`}
                />
                <InfoBlock
                    text={`Как вы думаете, какие из этих продуктов имеют большую биологическую ценность? Расположите продукты по убыванию качества белка в них.`}
                />
                {windowWidth >= 768 ?
                    <div className="test">
                        {this._renderTestDesktop()}
                    </div>
                    :
                    <div className="test">
                        {this._renderTestMobile()}
                    </div>
                }
            </div>
        )
    }
}
