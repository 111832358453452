import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson7/step33-1.png'
import img2 from '../../../image/lessons/lesson7/step33-2.png'


export default class step33 extends React.Component {


    render() {
        return (
            <div className="step33">
                <TextBlock
                    text={`Исследование научно-исследовательского института Waltham в 2011 году показало, что содержание воды в корме влияет на состав мочи и общее потребление жидкости у взрослых кошек.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                    <div className="flex_el">
                        <TextBlock
                            text={`В ходе исследования кошек кормили кормом с различным содержанием жидкости.
                            
                            В результате у кошек, корм которых был наиболее влажным, плотность мочи и уровень ее перенасыщения были значительно меньше, чем в трех других случаях.`}
                        />
                        <img src={img2} alt="" className="flex_el_img second"/>
                    </div>
                </div>
                <TextBlock
                    text={`Перейдите далее, чтобы узнать выводы исследования.`}
                />
            </div>
        )
    }
}
