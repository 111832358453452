import React from 'react'
// import MainPage from "../Page/Main";
import MyTraining from "../Page/MyTraining";
import MyTesting from "../Page/MyTesting";
import PPClubVideo from "../Page/PPClubVideo";
import PPCVideo from "../Page/PPCVideo";
import Faq from "../Page/Faq";
import {Switch, Route, Redirect} from 'react-router-dom'
import TestPage from "../Page/Test";
import Lessons from "../Page/Lessons";
import ProfileCard from "../Components/ProfileCard";
import FinalTest from "../Page/FinalTest";
import GiftsWhiskas from "../Page/GiftsWhiskas";
import CoocieForm from "../Page/CoocieForm"
import Dostupnost from "../Page/dostupnost"
import Restore from "../Page/Restore";

export default class Main extends React.Component{

    // componentDidMount() {
    //     this.props.hidePreloader();
    // }

    render() {
        return (
            <main>
                <Switch>
                    <Route
                        exact
                        path='/'
                        render={() => {
                            return (
                                <Redirect to="/myTraining" />
                            )
                        }}
                    />
                    {/*<Route path='/myTraining' component={MyTraining}/>*/}
                    <Route path='/myTraining' render={(props)=><MyTraining status={this.props.status} {...props}/>}/>
                    <Route path='/myTesting' component={MyTesting}/>
                    <Route path='/PPClubVideo' component={PPClubVideo}/>
                    <Route path='/PPCVideo' component={PPCVideo}/>
                    <Route path='/faq' component={Faq}/>
                    <Route path='/test' component={TestPage}/>
                    <Route path='/lesson' component={Lessons}/>
                    <Route path='/profileCard' component={ProfileCard}/>
                    <Route path='/finalTest' component={FinalTest}/>
                    <Route path='/giftsWhiskas' component={GiftsWhiskas}/>
                    <Route path='/coociesform' component={CoocieForm}/>
                    <Route path='/dostupnost' component={Dostupnost}/>
                    <Route path='/restore' component={Restore}/>
                    <Route
                        path="/*"
                        render={() => {
                            return (
                                <Redirect to="/myTraining" />
                            )
                        }}
                    />

                </Switch>
            </main>
        )
    }
}
