import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import List from "../../../Components/LessonAndTestComponents/List";
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";

import img1 from '../../../image/lessons/lesson8/step20-1.png'


export default class step20 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        flexActive: 0,
        flexs: [1],
        showPopup: false
    };

    //Данные попапа
    imgDivData = {
        1: {
            text: `Некоторые породы <span class="text_blue">собак</span> предрасположены к увеличению веса.
            К ним относятся:`,
            list: [
                `бигль;`,
                `бернский зенненхунд;`,
                `колли;`,
                `лабрадор-ретривер;`,
                `керн-терьер;`,
                `кавалер-кинг-чарльз-спаниель.`,
            ],
            text2: `Что касается <span class="text_blue">кошек</span>, то метисы примерно вдвое более склонны к избыточному весу, чем чистопородные животные.`,
            class: 'first'
        },
        2: {
            text: `Генетические факторы обуславливают предрасположенность животного к избыточному весу, причем на это <b>влияют различные гены</b>.
            
            Животные одной и той же породы могут обладать <b>индивидуальными</b> особенностями. Эти не зависимые от породы вариации указывают на существование комплекса генетических элементов, который предопределяет способность животного к сохранению нормального веса.`,
            list: false,
            text2: false,
            class: 'second'
        },
        3: {
            text: `<b>С возрастом</b> склонность к набору избыточного веса <b>повышается</b>. Считается, что лишь 20% <span class="text_blue">собак</span> в возрасте до 4 лет склонны к избыточному весу. В возрастной категории 7-8 лет этот показатель составляет уже 50%.
            
            Исследования показывают, что <span class="text_blue">кошки</span> особенно <b>подвержены риску увеличения веса в среднем возрасте</b>. Каждая третья кошка в возрасте 6-8 лет страдает избыточным весом.`,
            list: false,
            text2: false,
            class: 'third'
        },
        4: {
            text: `У <span class="text_blue">собак</span> <b>суки более предрасположены</b> к ожирению, чем кобели. А после стерилизации у животных обоих полов эта предрасположенность становится еще выше.
            
            У <span class="text_blue">кошек</span> <b>стерилизация</b> представляет собой основной фактор риска развития ожирения <b>независимо от пола</b> животного.`,
            list: false,
            text2: false,
            class: 'fourth'
        },
        5: {
            text: `По данным исследований, владельцы животных, страдающих избыточным весом, <b>сами склонны к нему</b> и <b>отказываются признать</b> наличие у животного этой проблемы.
            
            Образ жизни владельца является важным фактором, определяющим возможность поддержания у животного нормального веса: когда владелец большую часть времени находится дома, для животного возникает повышенный риск набора избыточного веса, причиной которого является <b>недостаточная физическая активность владельца.</b>`,
            list: false,
            text2: false,
            class: 'fifth'
        },
        6: {
            text: `На возможность сохранения нормального веса влияет <b>режим кормления</b> питомца, а также <b>тип корма.</b>
            
            Кормление собак и кошек без ограничения приводит к перееданию и вызывает увеличение веса животных. Деление дневного рациона <b>на несколько порций</b> не приведет к такому результату.
            
            Основным фактором ожирения животных является <b>подкармливание</b> едой со стола. Большое количество лакомств также способствует увеличению веса.`,
            list: false,
            text2: false,
            class: 'sixth'
        },
        7: {
            text: `Регулярные <b>физические нагрузки</b> являются основным фактором профилактики ожирения.
            
            Для владельцев <span class="text_blue">собак</span> прогулки и игры с животными являются частью жизненного уклада. Однако большинство владельцев <span class="text_blue">кошек</span> не делают попыток инициировать игру со своими животными, которая удовлетворила бы потребность последних в движении.
            
            Домашние кошки, ведущие менее подвижный образ жизни, более склонны к увеличению веса, чем кошки, имеющие доступ на улицу. Однако последних также следует стимулировать к движению, чтобы помочь им поддерживать нормальный вес.`,
            list: false,
            text2: false,
            class: 'seventh'
        },
    }

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }

        this.setState({flexActive, flexs, showPopup: true})
        if (flexs.length === 7) {
            this.props.showNextBtn()
        }
    }

    nextPopup = (flexActive) => {
        const {flexs, showPopup} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }

        if (!showPopup) {
            this.setState({
                flexActive: flexActive,
                showPopup: true,
            })
        } else if (flexActive >= 7) {
            this.setState({showPopup: false, flexActive: 0})
        } else {
            this.setState({
                flexActive: flexActive +1,
                showPopup: true,
            })
        }

        if (flexs.length === 7) {
            this.props.showNextBtn()
        }
    }

    closeImgDiv = () => {this.setState({flexActive: 0})}


    render() {
        const {flexActive, windowWidth} = this.state
        return (
            <div className="step20">
                <TextBlock
                    text={`Избыток веса обусловлен потреблением животным калорий в большем количестве, чем ему требуется. Тем не менее, есть немало факторов, которые оказывают влияние на способность животного сохранять нормальный вес.`}
                />
                <div className="wrp">
                    <div className={'flex '}>
                        <div className={'flex_el ' + (flexActive === 1 && 'active')} onClick={() => {
                            this.changeFlex(1)
                        }}>Порода
                        </div>
                        <div className={'flex_el ' + (flexActive === 2 && 'active')} onClick={() => {
                            this.changeFlex(2)
                        }}>Генетика
                        </div>
                        <div className={'flex_el ' + (flexActive === 3 && 'active')} onClick={() => {
                            this.changeFlex(3)
                        }}>Возраст
                        </div>
                        <div className={'flex_el ' + (flexActive === 4 && 'active')} onClick={() => {
                            this.changeFlex(4)
                        }}>Пол и репродуктивный статус
                        </div>
                        <div className={'flex_el ' + (flexActive === 5 && 'active')} onClick={() => {
                            this.changeFlex(5)
                        }}>Влияние владельца
                        </div>
                        <div className={'flex_el ' + (flexActive === 6 && 'active')} onClick={() => {
                            this.changeFlex(6)
                        }}>Диетологические факторы
                        </div>
                        <div className={'flex_el ' + (flexActive === 7 && 'active')} onClick={() => {
                            this.changeFlex(7)
                        }}>Физическая активность
                        </div>
                    </div>
                    {windowWidth >= 768 ?
                        <div className="box">
                            {flexActive === 0 &&
                            <div className="wrp_right">
                                {/*<InfoBlock*/}
                                {/*    text={`Вы видите ключевые элементы, необходимые щенку. Нажмите на элемент, чтобы узнать, для чего он нужен растущему организму.`}*/}
                                {/*/>*/}
                                <img className="wrp_img" src={img1} alt=""/>
                            </div>
                            }
                            {flexActive !== 0 &&
                            <div className={'imgDiv ' + this.imgDivData[flexActive].class}>
                                <TextBlock text={this.imgDivData[flexActive].text}/>
                                {this.imgDivData[flexActive].list &&
                                <List ListData={this.imgDivData[flexActive].list}/>
                                }
                                {this.imgDivData[flexActive].text2 &&
                                <TextBlock text={this.imgDivData[flexActive].text2}/>
                                }
                            </div>
                            }
                        </div>
                        :
                        <div className="box">
                            {flexActive > 0 &&
                            <PopupInStep
                                closePopup={this.closeImgDiv}
                                popupText={this.imgDivData[flexActive].text}
                                ListData={this.imgDivData[flexActive].list}
                                popupText3={this.imgDivData[flexActive].text2}
                                changePoint={() => this.nextPopup(flexActive)}
                                popupBtnText={'Далее'}
                            />
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }
}
