import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson32/step37-1.png'
import img2 from '../../../image/lessons/lesson32/step37-2.png'
import img1xs from "../../../image/lessons/lesson32/step37-1xs.png";
import img2xs from "../../../image/lessons/lesson32/step37-2xs.png";

export default class step37 extends React.Component {

    render() {
        return (
            <div className="step37">
                <div className="head">
                    1. Привередливость в еде
                </div>
                <TextBlock
                    text={`Влажные рационы представлены <b>в 3 различных текстурах:</b>`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <ul>
                            <li>Паштет</li>
                            <li>Кусочки в соусе</li>
                        </ul>
                    </div>
                    <div className="flex_el">
                        <picture>
                            <source srcSet={img1xs} media="(max-width: 767px)"/>
                            <img src={img1} alt="" className="imgCenter"/>
                        </picture>
                    </div>
                </div>
                <TextBlock
                    text={`<b>В 5 различных вариантах вкусов:</b>`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <ul>
                            <li>Говядина</li>
                            <li>С говядиной и овощами в соусе</li>
                            <li>С ягненком и овощами в соусе</li>
                            <li>Паштет с курицей</li>
                            <li>Паштет с курицей</li>
                        </ul>
                    </div>
                    <div className="flex_el">
                        <picture>
                            <source srcSet={img2xs} media="(max-width: 767px)"/>
                            <img src={img2} alt="" className="imgCenter"/>
                        </picture>
                    </div>
                </div>
            </div>
        )
    }
}
