import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson32/step24-1.png'
import img1xs from "../../../image/lessons/lesson32/step24-1xs.png";
import img2 from "../../../image/lessons/lesson32/step24-2.png";


export default class step5 extends React.Component {

    render() {
        return (
            <div className="step24">
                <div className="flex">
                    <div className="flex_el">
                        <TextBlock
                            text={
                                `<b>Форма, размер и текстура гранул рационов PEDIGREE<sup>®</sup> специально разработаны, чтобы очищать зубы собаки от налета</b>`
                            }
                        />
                    </div>
                    <div className="flex_el">
                        <img src={img2} alt="" className="imgCenter"/>
                    </div>
                </div>
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} alt="" className="imgCenter"/>
                </picture>
            </div>
        )
    }
}
