import React from 'react'

import img1 from "../../../image/lessons/lesson21/step14-1.png";

export default class step14 extends React.Component {

    render() {
        return (
            <div className="step14">
                <img src={img1} alt="" className="imgCenter"/>
                <div className="info">
                    <div className="info_el">
                        <div className="info_el_number">1</div>
                        <div className="info_el_text">
                            <p className="info_el_head">Ротовая полость</p>
                            <p className="info_el_description">
                                В слюне кошки отсутствует фермент амилаза, который необходим травоядным животным для переваривания растительной пищи уже в процессе жевания.
                            </p>
                        </div>
                    </div>
                    <div className="info_el">
                        <div className="info_el_number">2</div>
                        <div className="info_el_text">
                            <p className="info_el_head">Желудок</p>
                            <p className="info_el_description">
                                Желудок кошек предназначен для многократного приёма небольших порций пищи в течение дня. Уровень pH желудочного сока кошек более кислый, чем у человека. Это способствует перевариванию костей и уничтожению патогенных бактерий.
                            </p>
                        </div>
                    </div>
                    <div className="info_el">
                        <div className="info_el_number">3</div>
                        <div className="info_el_text">
                            <p className="info_el_head">Тонкий кишечник</p>
                            <p className="info_el_description">
                                Тонкий кишечник хорошо приспособлен к перевариванию белков и жиров. У кошек отсутствует механизм регуляции активности ферментов для переваривания белков по типу отрицательной связи, поэтому они нуждаются в рационе, богатом белком.
                            </p>
                        </div>
                    </div>
                    <div className="info_el">
                        <div className="info_el_number">4</div>
                        <div className="info_el_text">
                            <p className="info_el_head">Толстый кишечник</p>
                            <p className="info_el_description">
                                Бактериальная ферментация происходит в толстом кишечнике. Время кишечного транзита составляет от 12 до 24 часов (для сравнения, у человека оно составляет от 30 часов до 5 дней).
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
