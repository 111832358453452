import React from 'react'
import Resume from '../../../Components/LessonAndTestComponents/Resume'

import img1 from '../../../image/lessons/lesson10/step18-1.jpg'


export default class step18 extends React.Component{

    state = {
        resumeData: [
            `Кошка считается пожилой, когда она достигает <b>последней трети</b> предполагаемой продолжительности жизни.`,
            `В отличие от собак, у кошек <b>не снижаются</b> энергетические потребности в старости.`,
            `Пожилым кошкам необходимо <b>особое кормление</b>, так как их организм может часто испытывать проблемы с извлечением энергии из пищи.`,
        ]
    }

    render() {
        return (
            <div className="step18">
                <Resume
                    resumeData={this.state.resumeData}
                    img={img1}
                />
            </div>
        )
    }
}
