import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson21/step20-1.png'
import img2 from '../../../image/lessons/lesson21/step20-2.png'

export default class step20 extends React.Component {

    render() {
        return (
            <div className="step20">
                <div className="flex">
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                    <div className="flex_el">
                        <TextBlock
                            text={`В Центре проводят различные физиологические тесты, в процессе которых собирается биоматериал (см.ниже).

                            Результаты исследований, полученные в лаборатории, позволяют с уверенностью говорить, что протестированный корм безопасен для здоровья кошек и полезен для них.
                            
                            На базе Центра осуществляется поддержка научных программ и инновационных разработок в области изучения вкусовой привлекательности продуктов питания для кошек и собак.`}
                        />
                    </div>
                </div>
                <div className="question">
                    <div className="question_el left">Есть ли зависимость консистенции фекалий от компонентов корма?</div>
                    <div className="question_el arrow">
                        <img src={img2} alt="" className="question_el_img"/>
                    </div>
                    <div className="question_el right">Как корм влияет на консистенцию фекалий?</div>
                </div>
                <div className="question">
                    <div className="question_el left">Оценка риска образования мочевых камней (анализ минералов)</div>
                    <div className="question_el arrow">
                        <img src={img2} alt="" className="question_el_img"/>
                    </div>
                    <div className="question_el right">Как корм влияет на растворение кристаллов?</div>
                </div>
                <div className="question">
                    <div className="question_el left">Оценка усваиваемости кормов</div>
                    <div className="question_el arrow">
                        <img src={img2} alt="" className="question_el_img"/>
                    </div>
                    <div className="question_el right">Какие вещества легко усваиваются?</div>
                </div>
            </div>
        )
    }
}
