import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'
import PopupInStep from '../../../Components/LessonAndTestComponents/PopupInStep'

import img1 from '../../../image/lessons/lesson8/step4-1.png'
import img2 from '../../../image/lessons/lesson8/step4-2.png'
import img3 from '../../../image/lessons/lesson8/step4-3.png'


export default class step4 extends React.Component {
    state = {
        pointActive: 0,
        points: [],
    };

    changePoint = pointActive => {
        this.setState({pointActive}, () => {
            this.checkPoint(pointActive)
        })
    }

    checkPoint = (point) => {
        const {points} = this.state
        if (points.indexOf(point) === -1) {
            points.push(point)
        }
        if (points.length === 1) {
            this.props.showNextBtn()
        }
    }

    closePopap = () => {
        this.setState({pointActive: 0})
    }

    popapWrp = {
        1: {
            text: `<span class="textBlue">Формула для расчета:</span>

Энергетическая потребность собаки: 
<span class="textBlue textBlue--big"><b>Э = 95 * M <sup>0,75</sup></b></span>`,
            img: img3
        },
    }

    render() {
        const {pointActive} = this.state;

        return (
            <div className="step4">
                <TextBlock
                    text={`Теперь попробуйте рассчитать суточную норму рациона самостоятельно.`}
                />

                <div className="flex">
                    <div className="left">
                        <img src={img1} alt=""/>
                    </div>
                    <div className="right">
                        <div className="right_row">
                            <div className="row_block">
                                <div className="row_img_mobile">
                                    <img className='row_img_mobile_dog' src={img1} alt=""/>
                                </div>
                                <TextBlock
                                    text={`Это - немецкая овчарка Рекс. Ему 5 лет, он весит 34 кг. Хозяин кормит его сухим кормом премиум класса PEDIGREE<sup>®</sup> для взрослых собак крупных пород.
                                    
                                    Рассчитайте, сколько граммов корма необходимо Рексу каждый день?`}
                                />
                            </div>
                        </div>
                        <div className="right_row">
                            <div className="row_block">
                                <div className="row_img_mobile">
                                    <img className='row_img_mobile_eat' src={img2} alt=""/>
                                </div>
                                <TextBlock
                                    text={`Энергетическая ценность корма: <br>360 ккал / 100 г.`}
                                />
                            </div>
                            <img className="right_row_img" src={img2} alt=""/>
                        </div>
                    </div>
                </div>

                <InfoBlock
                    text={`Эту задачу удобно решать на бумаге. Произведите необходимые расчеты и перейдите далее, чтобы выбрать вариант ответа и проверить свое решение.`}
                />

                <div className="textDown">
                    Используйте шпаргалку, чтобы посмотреть нужные вам формулы. <br/>
                    Просто нажмите на эту кнопку.
                </div>

                <button className="btn btn_blue btn_shpora" onClick={() => {this.changePoint(1)}}>
                    ШПАРГАЛКА
                </button>

                {pointActive !== 0 &&
                <PopupInStep
                    closePopup={this.closePopap}
                    popupText={this.popapWrp[pointActive].text}
                    popupImg3={this.popapWrp[pointActive].img}
                    changePoint={this.closePopap}
                />
                }
            </div>
        )
    }
}
