import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import Popup from "../../../Components/Popup";
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';
import List from "../../../Components/LessonAndTestComponents/List";

import img1 from "../../../image/lessons/lesson20/step12-1.png";
import img2 from "../../../image/lessons/lesson20/step12-2.png";
import img3 from "../../../image/lessons/lesson20/step12-3.png";
import img4 from "../../../image/lessons/lesson20/step12-4.png";
import img5 from "../../../image/lessons/lesson20/step12-5.png";

export default class step26 extends React.Component {

    state = {
        //OnClickBlock
        flexData: [
            'Обеспечение оптимального баланса питательных веществ',
            'Поддержка здоровья',
            'Поддержка разнообразия вкусов и текстур'
        ],
        flexHidden: false,

        // Popup
        popupActive: 0,
        showPopup: false,
        points: [],
    }

    //OnClickBlock
    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        const {points} = this.state
        if (points.length === 7) {
            this.props.showNextBtn()
        }
    }

    //Popup
    showPopup = (popupActive) => {
        const {points} = this.state;
        if (points.indexOf(popupActive) === -1) {
            points.push(popupActive)
        }

        this.setState({
            popupActive: popupActive,
            showPopup: true,
            points: points
        })
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    popupData = {
        1: {
            head: `Высокое содержание белка*`,
            list: [
                `кошки <b>нуждаются в высоком уровне</b> общего количества <b>белка</b> в рационе для удовлетворения своих потребностей;`,
                `<b>не способны регулировать</b> активность ферментов, контролирующих процессы распада белка, прежде всего при потреблении низкопротеинового рациона;`,
                `норма потребления - <b>не менее 15г/мДж.</b>`,
            ]
        },
        2: {
            head: `Витамин А*`,
            list: [
                `не могут превращать бета-каротин в витамин А;`,
                `<b>должны получать активные формы ретинола с рационом.</b>`,
            ]
        },
        3: {
            head: `Аргинин*`,
            list: [
                `без аргинина нарушается утилизация аммиака и образование мочевины, что приводит к развитию интоксикации и гибели животного;`,
                `<b>должны получать аргинин с рационом.</b>`,
            ]
        },
        4: {
            head: `Ниацин`,
            list: [
                `способны синтезировать очень ограниченное количество ниацина из триптофана из-за активного вовлечения последнего в другие биотрансформации;`,
                `<b>должны получать ниацин с рационом.</b>`,
            ]
        },
        5: {
            head: `Таурин*`,
            list: [
                `<b>не способны синтезировать таурин</b> для удовлетворения своих потребностей из серосодержащих аминокислот;`,
                `<b>должны получать таурин с рационом.</b>`,
            ]
        },
        6: {
            head: `Ограниченное количество углеводов`,
            list: [
                `обладают ограниченной способностью переваривать углеводы вследствие низкой активности ферментов в тонком кишечнике;`,
                `не способны переваривать углеводы в количестве, превышающем <b>5 г</b>;`,
                `необходимо ограничивать содержание клетчатки в рационе до <b>6 г/мДж.</b>`,
            ]
        },
        7: {
            head: `Арахидоновая кислота*`,
            list: [
                `<b>не способны синтезировать</b> в процессе трансформации жирных кислот арахидоновую кислоту из линолевой кислоты;`,
                `<b>должны получать арахидоновую кислоту с рационом.</b>`,
            ]
        },
    }

    _renderTestDesktop = () => {
        return (
            <div className="test_desktop">
                <div className="flex">
                    <div className="flex_el">
                        <div className="answers">
                            <div className="property">
                                <img src={img3} alt="" className="property_img"/>
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="answers">
                            <div className="answers_item">
                                <div className="answer">
                                    Поддержание обмена веществ
                                </div>
                            </div>
                            <div className="answers_item">
                                <div className="answer">
                                    Иммунитет
                                </div>
                            </div>
                            <div className="answers_item">
                                <div className="answer">
                                    Здоровье кожи и шерсти
                                </div>
                            </div>
                            <div className="answers_item">
                                <div className="answer">
                                    Здоровье костей
                                </div>
                            </div>
                            <div className="answers_item">
                                <div className="answer">
                                    Хорошее зрение
                                </div>
                            </div>
                            <div className="answers_item">
                                <div className="answer">
                                    Здоровое пищеварение
                                </div>
                            </div>
                            <div className="answers_item">
                                <div className="answer">
                                    Удаление зубного налета
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const {flexData, showPopup, popupActive} = this.state
        return (
            <div className="step26">
                <InfoBlock
                    text={`Нажмите на ключевые потребности взрослых кошек в питании и узнайте больше о каждой из них, а также Вы узнаете решения, которые предлагают рационы WHISKAS<sup>®</sup>.`}
                />
                <div className="head">
                    <div className="head_text">
                        Ключевые потребности кошек старше 7 лет в питании
                    </div>
                    <img src={img1} alt="" className="head_img"/>
                </div>
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <div className="features">
                            <span className="features_pink">7</span> ключевых ключевых особенностей рациона кошек как плотоядных
                        </div>
                        <InfoBlock
                            text={`Нажмите на ключевые особенности рациона кошек и узнайте, почему они им необходимы.`}
                        />
                        <div className="substances">
                            <div className="substances_el">
                                <div className="substances_el_block" onClick={() => {this.showPopup(1)}}>
                                    Высокое содержание белка*
                                </div>
                                <div className="substances_el_block" onClick={() => {this.showPopup(2)}}>
                                    Витамин А*
                                </div>
                                <div className="substances_el_block" onClick={() => {this.showPopup(3)}}>
                                    Аргинин*
                                </div>
                                <div className="substances_el_block" onClick={() => {this.showPopup(4)}}>
                                    Ниацин
                                </div>
                                <div className="substances_el_block" onClick={() => {this.showPopup(5)}}>
                                    Таурин*
                                </div>
                                <div className="substances_el_block" onClick={() => {this.showPopup(6)}}>
                                    Ограниченное количество углеводов
                                </div>
                                <div className="substances_el_block" onClick={() => {this.showPopup(7)}}>
                                    Арахидоновая кислота*
                                </div>
                            </div>
                            <div className="substances_el">
                                <img src={img2} alt="" className="substances_el_img"/>
                            </div>
                        </div>
                        <div className="note">
                            *Источник - только продукты животного происхождения.
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Рационы WHISKAS<sup>®</sup> способствуют улучшению здоровья кошек по следующим показателям:`}
                        />
                        <div className="test">
                            {this._renderTestDesktop()}
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_head">
                            Разнообразие в питании – репертуар вкусов и текстур
                        </div>
                        <img className="imgDiv_wrap_img imgCenter" src={img4} alt=""/>
                        <TextBlock
                            text={`Рационы WHISKAS<sup>®</sup> для взрослых кошек представлены во всех текстурах и вкусах.`}
                        />
                        {/*<InfoBlock*/}
                        {/*    text={`Нажмите на ключевые особенности рациона кошек и узнайте, почему они им необходимы.`}*/}
                        {/*/>*/}
                        <img className="imgDiv_wrap_img imgCenter" src={img5} alt=""/>
                    </div>
                </OnClickBlock>
                {showPopup &&
                <Popup
                    closePopup={this.closePopup}
                >
                    <div className="Popup_head">
                        {this.popupData[popupActive].head}
                    </div>
                    <List
                        ListData={this.popupData[popupActive].list}
                    />
                </Popup>
                }
            </div>
        )
    }
}
