import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson7/step8-1.png";

export default class step8 extends React.Component {

    render() {
        return (
            <div className="step8">
                <TextBlock
                    text={`Собаки также являются хищниками, но, в отличие от кошек, относятся к факультативным плотоядным. Это означает, что в дикой среде они питаются преимущественно мясом, но вместе с ним могут потреблять и растительную пищу.`}
                />
                <div className="text_blue">
                    Пищевое поведение собак имеет следующие особенности:
                </div>
                <ul className="list">
                    <li className="list_item">Поскольку волки и дикие собаки охотятся в стае, они привыкли есть добычу максимально быстро. Поэтому собака может съесть за один прием примерно 1/5 от веса своего тела.</li>
                    <li className="list_item">Практически все жертвы волков и диких собак являются растительноядными. Поэтому волков и собак можно назвать факультативными плотоядными, так как, съедая органы жертвы, они употребляют как животные, так и растительные компоненты.</li>
                    <li className="list_item">Собаки предпочитают пищу с повышенным содержанием жиров. Это помогает им оставаться сытыми долгое время.</li>
                </ul>
                <img src={img1} alt="" className="img"/>
            </div>
        )
    }
}
