import React, { useState } from "react";
import "./style.scss"

const Select = (props) => {
    let [selected, setSelected] = useState('')

    const showBody = (e) => {
        e.target.closest('.select').querySelector('.select__body').style.display === 'flex' ? e.target.closest('.select').querySelector('.select__body').style.display = 'none' : e.target.closest('.select').querySelector('.select__body').style.display = 'flex'
    }

    const bodyHandler = (e) => {
        e.target.closest('.select').querySelector('.select__title').textContent = e.target.textContent
        //e.target.closest('.select').querySelector('.select_hidden').value = e.target.textContent
        setSelected(e.target.textContent)
        e.target.closest('.select').querySelector('.select__body').style.display === 'flex' ? e.target.closest('.select').querySelector('.select__body').style.display = 'none' : e.target.closest('.select').querySelector('.select__body').style.display = 'flex'
        e.target.closest('.select').querySelector('.select__header').classList.remove('error')
        let numberElement = +e.target.closest('.select__body').getAttribute('data-element')
        if(numberElement === 1){
            props.setSelect1(false)
        }
        if(numberElement === 2){
            props.setSelect2(false)
        }
    }

    return(
        <div className="select">
            <div className="select__header" onClick={e => {showBody(e)}}>
                <p className="select__title">{props.title}</p>
                <input className="select_hidden" type="text" name={props.name} id={props.id} value={selected} data-required='true'/>
                <label htmlFor={props.id}></label>
                <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.29289 8.70711C7.68342 9.09763 8.31658 9.09763 8.70711 8.70711L15.0711 2.34315C15.4616 1.95262 15.4616 1.31946 15.0711 0.928932C14.6805 0.538408 14.0474 0.538408 13.6569 0.928932L8 6.58579L2.34315 0.928932C1.95262 0.538408 1.31946 0.538408 0.928932 0.928932C0.538408 1.31946 0.538408 1.95262 0.928932 2.34315L7.29289 8.70711ZM7 6L7 8L9 8L9 6L7 6Z" fill="#FEFAEF"/>
                </svg>
            </div>
            <div className="select__body" data-element={props.element} onClick={(e) => {
                bodyHandler(e)
            }}>
                {props.options.map(el => {
                    return(
                        <div className="select__item" onClick={props.selSet}>{el}</div>
                    )
                })}
            </div>
        </div>
    )
}

export default Select