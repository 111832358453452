import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from "../../../image/lessons/lesson10/step14-1.jpg";


export default class step14 extends React.Component{

    render(){
        return (
            <div className="step14">
                <TextBlock
                    text={`В возрасте 7-10 лет кошка считается стареющей, а при достижении последней трети предполагаемой продолжительности жизни (после 10 лет) – пожилой.
                    
                    Цель ухода за пожилой кошкой — <span class="text_blue">предотвратить или замедлить изменения, появляющиеся в процессе старения.</span>`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <TextBlock text={`В этом возрасте у кошек могут развиться болезни, такие как:`}/>
                        <ul className="list">
                            <li className="list_item">почечная недостаточность;</li>
                            <li className="list_item">гипертиреоз;</li>
                            <li className="list_item">болезни зубов.</li>
                        </ul>
                        <TextBlock text={`Для предотвращения этих заболеваний необходимо следить за здоровьем кошки.`}/>
                    </div>
                    <div className="flex_el">
                        <img className="flex_el_img" src={img1} alt=""/>
                    </div>
                </div>

            </div>
        )
    }

}
