import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson17/step4-1.png'

export default class step4 extends React.Component {


    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step4">
                <div className="block">
                    ... но при этом в России большинство питомцев не получают готовые корма.
                </div>
                <img src={img1} alt="" className="imgCenter"/>
                <TextBlock
                    text={`Это значит, что у категории кормов для животных есть огромный потенциал роста!`}
                />
                <div className="note">
                    Источник: внутренние данные Mars.
                </div>
            </div>
        )
    }
}
