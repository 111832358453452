import React from 'react'

import img1 from '../../../image/lessons/lesson9/step27-1.png'


export default class step27 extends React.Component {


    render() {
        return (
            <div className="step27">
                <div className="flex">
                    <div className="ul">
                        <div className="li">
                            <div className="number">1</div>
                            <div className="text">
                                Во взрослом возрасте нужно поддерживать оптимальное состояние здоровья и физической формы собаки с помощью правильного питания.
                            </div>
                        </div>
                        <div className="li">
                            <div className="number">2</div>
                            <div className="text">
                                Энергетические потребности пожилых собак сокращаются на 20% в связи с понижением уровня активности.
                            </div>
                        </div>
                    </div>
                    <img src={img1} alt=""/>
                </div>
            </div>
        )
    }
}
