import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from "../../../image/lessons/lesson20/step7-1.png";

export default class step7 extends React.Component {

    render() {
        return (
            <div className="step7">
                <div className="flex">
                    <div className="flex_el">
                        <TextBlock
                            text={`В этой части урока Вас ждет знакомство с особенностями питания:`}
                        />
                        <ul className="list">
                            <li className="list_item"><b>котят</b></li>
                            <li className="list_item"><b>взрослых кошек</b></li>
                            <li className="list_item"><b>кошек старше 7 лет</b></li>
                        </ul>
                        <TextBlock
                            text={`А также Вы познакомитесь с рационами WHISKAS<sup>®</sup> для кошек всех возрастов и узнаете об их индивидуальных особенностях!`}
                        />
                    </div>
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                </div>
            </div>
        )
    }
}
