import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson16/step4-1.png'
import img1xs from '../../../image/lessons/lesson16/step4-1xs.png'


export default class step4 extends React.Component {


    render() {
        return (
            <div className="step4">
                <TextBlock
                    text={`Домашний питомец есть <span class="text_blue">в каждой 2-ой семье</span> России.`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} className="imgCenter" alt=""/>
                </picture>
            </div>
        )
    }
}
