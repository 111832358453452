import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import List from '../../../Components/LessonAndTestComponents/List';
import img1 from '../../../image/lessons/lesson9/step3-1.png';


export default class step3 extends React.Component {
    state = {
        ListData: [
            `Такой вид корма содержит больше питательных веществ.`,
            `Он является более калорийным.`,
            `Обладает высокими вкусовыми качествами.`,
            `Хорошо усваивается.`
        ]
    };

    render() {
        const {ListData} = this.state
        return (
            <div className="step3">
                <TextBlock
                    text={`Обычный корм для взрослых собак не подходит беременным собакам, так как питательных веществ этого корма не хватает, чтобы обеспечить энергией собаку и ее помет. <span class="text_blue">Корм для беременных собак</span> должен обладать следующими качествами:`}
                />
                <img src={img1} alt=""/>
                <TextBlock
                    text={`<span class="text_blue">Для кормления беременных собак рекомендуют использовать корм для щенков.</span> Почему?`}
                />
                <List ListData={ListData}/>
                <TextBlock
                    text={`Например, сухие и влажные рационы PEDIGREE<sup>®</sup> для щенков всех пород отлично подойдут беременной собаке.`}
                />
            </div>
        )
    }
}
