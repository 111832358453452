import React from 'react'

import img1 from '../../../image/lessons/lesson32/step47-1.png';
import img2 from '../../../image/lessons/lesson32/step47-2.png';


export default class step47 extends React.Component {

    render() {
        return (
            <div className="step47">
                <div className="header">
                    Два способа чистки зубов
                </div>
                <div className="text">
                    Существует два способа чистки зубного налёта:
                </div>
                <div className="flex">
                    <div className="flex_el lie">
                        <div className="flex_el_head">
                            C помощью зубной щётки и пасты для собак
                        </div>
                        <div className="flex_el_img-box">
                            {/*<span className="flex_el_icon">*/}
                            {/*    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                            {/*        <circle cx="24" cy="24" r="24" fill="#CC3333"/>*/}
                            {/*        <rect x="15" y="22" width="18" height="4" rx="2" fill="white"/>*/}
                            {/*    </svg>*/}
                            {/*</span>*/}
                            <img src={img1} alt="" className="flex_el_img"/>
                        </div>
                        <div className="flex_el_description">
                            Сложная и неприятная процедура
                        </div>
                    </div>
                    <div className="flex_el right">
                        <div className="flex_el_head">
                            C применением PEDIGREE<sup>®</sup> DENTASTIX™
                        </div>
                        <div className="flex_el_img-box">
                            <span className="flex_el_icon">
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="24" cy="24" r="24" fill="#4085D8"/>
                                    <path d="M33 18L20.9898 29.25L15 23.6106" stroke="white" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </span>
                            <img src={img2} alt="" className="flex_el_img"/>
                        </div>
                        <div className="flex_el_description">
                            Естественное очищение зубов в процессе разгрызания лакомства
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
