import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson23/step4-1.png'
import img2 from '../../../image/lessons/lesson23/step4-2.png'
import img3 from '../../../image/lessons/lesson23/step4-3.png'
import img4 from '../../../image/lessons/lesson23/step4-4.png'

export default class step4 extends React.Component {

    render() {
        return (
            <div className="step4">
                <div className="head">
                    Животный белок – основа здорового рациона и ингредиент №1 в рецептурах NATURE’S TABLE™
                </div>
                <div className="flex">
                    <img src={img1} alt="" className="flex_img"/>
                    <img src={img2} alt="" className="flex_img"/>
                    <img src={img3} alt="" className="flex_img"/>
                    <img src={img4} alt="" className="flex_img"/>
                </div>
                <TextBlock
                    text={`Поэтому в NATURE’S TABLE™ такой высокий уровень белка:`}
                />
                <ul className="list">
                    <li className="list_item"><span className="list_red">41%</span> в сухом корме для кошек;</li>
                    <li className="list_item"><span className="list_red">27%</span> в сухом корме для собак;</li>
                    <li className="list_item"><span className="list_red">9%</span> во влажном корме для кошек.</li>
                </ul>
            </div>
        )
    }
}
