import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import Popup from "../../../Components/Popup";
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';
import List from "../../../Components/LessonAndTestComponents/List";
import Answer from "../Lesson16/dnd/Answer";
import Questions from "../Lesson16/dnd/Questions";

import img1 from "../../../image/lessons/lesson20/step12-1.png";
import img2 from "../../../image/lessons/lesson20/step12-2.png";
import img3 from "../../../image/lessons/lesson20/step12-3.png";
import img4 from "../../../image/lessons/lesson20/step12-4.png";
import img5 from "../../../image/lessons/lesson20/step12-5.png";

export default class step12 extends React.Component {

    state = {
        // Ресайз
        windowWidth: window.innerWidth,

        //OnClickBlock
        flexData: [
            'Обеспечение оптимального баланса питательных веществ',
            'Поддержка здоровья',
            'Поддержка разнообразия вкусов и текстур'
        ],
        flexHidden: false,

        // Popup
        popupActive: 0,
        showPopup: false,
        points: [],

        // Работа десктопной версии теста
        answers: [],
        questions: [],
        correct: '',
        answerCorrect: [],
    }

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    //OnClickBlock
    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        const {questions, points} = this.state
        if (questions.length === 7 && points.length === 7) {
            this.props.showNextBtn()
        }
    }

    //Popup
    showPopup = (popupActive) => {
        const {points} = this.state;
        if (points.indexOf(popupActive) === -1) {
            points.push(popupActive)
        }

        this.setState({
            popupActive: popupActive,
            showPopup: true,
            points: points
        })
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    popupData = {
        1: {
            head: `Высокое содержание белка*`,
            list: [
                `кошки <b>нуждаются в высоком уровне</b> общего количества <b>белка</b> в рационе для удовлетворения своих потребностей;`,
                `<b>не способны регулировать</b> активность ферментов, контролирующих процессы распада белка, прежде всего при потреблении низкопротеинового рациона;`,
                `норма потребления - <b>не менее 15г/мДж.</b>`,
            ]
        },
        2: {
            head: `Витамин А*`,
            list: [
                `не могут превращать бета-каротин в витамин А;`,
                `<b>должны получать активные формы ретинола с рационом.</b>`,
            ]
        },
        3: {
            head: `Аргинин*`,
            list: [
                `<b>без аргинина нарушается утилизация аммиака и образование мочевины, что приводит к развитию интоксикации и возможной последующей гибели животного;`,
                `<b>должны получать аргинин с рационом.</b>`,
            ]
        },
        4: {
            head: `Ниацин`,
            list: [
                `способны синтезировать очень ограниченное количество ниацина из триптофана из-за активного вовлечения последнего в другие биотрансформации;`,
                `<b>должны получать ниацин с рационом.</b>`,
            ]
        },
        5: {
            head: `Таурин*`,
            list: [
                `<b>не способны синтезировать таурин</b> для удовлетворения своих потребностей из серосодержащих аминокислот;`,
                `<b>должны получать таурин с рационом.</b>`,
            ]
        },
        6: {
            head: `Ограниченное количество углеводов`,
            list: [
                `обладают ограниченной способностью переваривать углеводы вследствие низкой активности ферментов в тонком кишечнике;`,
                `не способны переваривать углеводы в количестве, превышающем <b>5 г</b>;`,
                `необходимо ограничивать содержание клетчатки в рационе до <b>6 г/мДж.</b>`,
            ]
        },
        7: {
            head: `Арахидоновая кислота*`,
            list: [
                `<b>не способны синтезировать</b> в процессе трансформации жирных кислот арахидоновую кислоту из линолевой кислоты;`,
                `<b>должны получать арахидоновую кислоту с рационом.</b>`,
            ]
        },
    }

    //Работа десктопной версии теста
    setAnswer = (answer_el, question_el) => {
        const {answers} = this.state

        if (answers.indexOf(question_el) === -1) {
            let answer = []
            answers[answer_el] = question_el
            answers.push(answer)
        }
        this.setState({answers})

        let questions = [];
        // eslint-disable-next-line
        answers.map(answer => {

            if (answer.length) {
                questions.push(answer)
            }

        });
        this.setState({questions})
        if (questions.length === 7) {
            this.checkAnswer()
        }
    };

    checkAnswer = () => {
        const {answerCorrect, questions} = this.state
        // eslint-disable-next-line
        questions.map((answer, index) => {
            if (answer === String(index + 1)) {
                answerCorrect.push('correct')
            } else {
                answerCorrect.push('inCorrect')
            }
        })
        this.setState({
            answerCorrect: answerCorrect,
        })
        if (questions.length === 7 && answerCorrect.indexOf('inCorrect') === -1) {
            this.setState({correct: '1'})
        } else {
            this.setState({correct: '0'})
        }
    };

    _renderTestDesktop = () => {
        const {answerCorrect, correct, questions} = this.state
        return (
            <div className="test_desktop">
                {correct !== '' &&
                    <div className={'info ' + (correct === '0' && 'error')}>
                        <div className="info_head">
                            {correct === '1' ? 'все верно!' : 'ЕСТЬ ОШИБКИ...'}
                        </div>
                    </div>
                }
                <div className="flex">
                    <div className="flex_el">
                        <div className="answers">
                            <div className="property">
                                <img src={img3} alt="" className="property_img"/>
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="answers">
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'1'} text={'?'} count={'?'} answerCorrect={answerCorrect[0]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'2'} text={'1'} count={'?'} answerCorrect={answerCorrect[1]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'3'} text={'2'} count={'?'} answerCorrect={answerCorrect[2]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'4'} text={'3'} count={'?'} answerCorrect={answerCorrect[3]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'5'} text={'?'} count={'?'} answerCorrect={answerCorrect[4]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'6'} text={'?'} count={'?'} answerCorrect={answerCorrect[5]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'7'} text={'?'} count={'?'} answerCorrect={answerCorrect[6]}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="questions">
                    <div className={'question_el ' + (questions.indexOf('1') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'1'} label={'Поддержание обмена веществ'} text={'Поддержание обмена веществ'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('2') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'2'} label={'Иммунитет'} text={'Иммунитет'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('3') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'3'} label={'Здоровье кожи и шерсти'} text={'Здоровье кожи и шерсти'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('4') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'4'} label={'Здоровье костей'} text={'Здоровье костей'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('5') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'5'} label={'Хорошее зрение'} text={'Хорошее зрение'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('6') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'6'} label={'Здоровое пищеварение'} text={'Здоровое пищеварение'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('7') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'7'} label={'Удаление зубного налета'} text={'Удаление зубного налета'}/>
                    </div>
                </div>
            </div>
        )
    }

    //Работа мобильной версии теста
    questions = [
        `Поддержание обмена веществ`,
        `Иммунитет`,
        `Здоровье кожи и шерсти`,
        `Здоровье костей`,
        `Хорошее зрение`,
        `Здоровое пищеварение`,
        `Удаление зубного налета`,
    ]

    selectAnswer = (answerActive) => {
        document.body.scrollIntoView( true);
        document.body.style.overflow = "hidden";
        this.setState({
            showPopup: true,
            answerActive: answerActive
        })
    }

    selectQuestion = (questionActive) => {
        const {answerActive, answersMobile, questionsMobile, answersSelected} = this.state

        if (answersMobile.indexOf(answerActive) === -1) {
            answersMobile.push(answerActive)
        }
        this.setState({answersMobile})

        if (questionsMobile.indexOf(questionsMobile) === -1) {
            questionsMobile.push(questionActive)
        }
        this.setState({questionsMobile})

        answersSelected.splice(answerActive - 1, 1, this.questions[questionActive - 1])

        document.body.style.overflow = "";
        this.setState({
            showPopup: false,
            questionActive: questionActive,
            answersSelected: answersSelected
        })
    }

    checkAnswerMobile = () => {
        const {answerCorrectMobile, answersSelected} = this.state
        // eslint-disable-next-line
        answersSelected.map((answer, index) => {
            if (answer === this.questions[index]) {
                answerCorrectMobile.push('correct')
            } else {
                answerCorrectMobile.push('inCorrect')
            }
        })
        this.setState({
            answerCorrect: answerCorrectMobile,
        })
        if (answerCorrectMobile.indexOf('?') === -1 && answerCorrectMobile.indexOf('inCorrect') === -1) {
            this.setState({correctMobile: '1'})
        } else {
            this.setState({correctMobile: '0'})
        }
        this.props.showNextBtn()
    }

    closePopup = () => {
        document.body.style.overflow = "";
        this.setState({showPopup: false})
    }

    _renderTestMobile = () => {
        const {showPopup, answersMobile, questionsMobile, answersSelected, correctMobile, answerCorrectMobile} = this.state
        return (
            <div className="test_mobile">
                {correctMobile !== '' ?
                    <div className={'info ' + (correctMobile === '0' && 'error')}>
                        <div className="info_head">
                            {correctMobile === '1' ? 'все верно!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                    </div>
                    :
                    <button className="btn btn_blue" onClick={() => this.checkAnswerMobile()}>
                        Узнать правильный ответ
                    </button>
                }
                <div className="answers">
                    <div className="answers_box">
                        <div className="property">
                            Здоровые кости
                        </div>
                        <div
                            className={'answers_item ' + (answersMobile.indexOf(1) >=0 ? 'selected ' : '') + answerCorrectMobile[0]}
                            onClick={() => {this.selectAnswer(1)}}
                        >
                            {answersSelected[0]}
                        </div>
                    </div>
                    <div className="answers_box">
                        <div className="property">
                            Здоровое сердце
                        </div>
                        <div
                            className={'answers_item ' + (answersMobile.indexOf(2) >=0 ? 'selected ' : '') + answerCorrectMobile[1]}
                            onClick={() => {this.selectAnswer(2)}}
                        >
                            {answersSelected[1]}
                        </div>
                    </div>
                    <div className="answers_box">
                        <div className="property">
                            Контроль веса
                        </div>
                        <div
                            className={'answers_item ' + (answersMobile.indexOf(3) >=0 ? 'selected ' : '') + answerCorrectMobile[2]}
                            onClick={() => {this.selectAnswer(3)}}
                        >
                            {answersSelected[2]}
                        </div>
                    </div>
                    <div className="answers_box">
                        <div className="property">
                            Здоровое зрение
                        </div>
                        <div
                            className={'answers_item ' + (answersMobile.indexOf(4) >=0 ? 'selected ' : '') + answerCorrectMobile[3]}
                            onClick={() => {this.selectAnswer(4)}}
                        >
                            {answersSelected[3]}
                        </div>
                        <div className="answers_box">
                            <div className="property">
                                Здоровые суставы
                            </div>
                            <div
                                className={'answers_item ' + (answersMobile.indexOf(5) >=0 ? 'selected ' : '') + answerCorrectMobile[4]}
                                onClick={() => {this.selectAnswer(5)}}
                            >
                                {answersSelected[4]}
                            </div>
                        </div>
                        <div className="answers_box">
                            <div className="property">
                                Здоровье мочевыделительной системы
                            </div>
                            <div
                                className={'answers_item ' + (answersMobile.indexOf(6) >=0 ? 'selected ' : '') + answerCorrectMobile[5]}
                                onClick={() => {this.selectAnswer(6)}}
                            >
                                {answersSelected[5]}
                            </div>
                        </div>
                        <div className="answers_box">
                            <div className="property">
                                Устранение запаха туалета
                            </div>
                            <div
                                className={'answers_item ' + (answersMobile.indexOf(7) >=0 ? 'selected ' : '') + answerCorrectMobile[6]}
                                onClick={() => {this.selectAnswer(7)}}
                            >
                                {answersSelected[6]}
                            </div>
                        </div>
                    </div>
                    {/*{this.questions.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className={'answers_item ' + (answersMobile.indexOf(index + 1) >=0 ? 'selected ' : '') + answerCorrectMobile[index]}
                                onClick={() => {this.selectAnswer(index + 1)}}
                            >
                                {answersSelected[index]}
                            </div>
                        )
                    })

                    }*/}
                </div>
                {showPopup &&
                <div className="popup">
                    <div className="popup_wrp">
                        <div
                            className="popup_closed"
                            onClick={() => {this.closePopup()}}
                        />
                        <div className="popup_flex">
                            <div className="questions">
                                {this.questions.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={'questions_item ' + (questionsMobile.indexOf(index + 1) >=0 ? 'selected ' : '')}
                                            onClick={() => {this.selectQuestion(index + 1)}}
                                        >
                                            {item}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }

    render() {
        const {windowWidth, flexData, showPopup, popupActive} = this.state
        return (
            <div className="step12">
                <InfoBlock
                    text={`Нажмите на ключевые потребности взрослых кошек в питании и узнайте больше о каждой из них, а также Вы узнаете решения, которые предлагают рационы WHISKAS<sup>®</sup>.`}
                />
                <div className="head">
                    <div className="head_text">
                        Ключевые потребности взрослых кошек в питании
                    </div>
                    <img src={img1} alt="" className="head_img"/>
                </div>
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <div className="features">
                            <span className="features_pink">7</span> ключевых ключевых особенностей рациона кошек как плотоядных
                        </div>
                        <InfoBlock
                            text={`Нажмите на ключевые особенности рациона кошек и узнайте, почему они им необходимы.`}
                        />
                        <div className="substances">
                            <div className="substances_el">
                                <div className="substances_el_block" onClick={() => {this.showPopup(1)}}>
                                    Высокое содержание белка*
                                </div>
                                <div className="substances_el_block" onClick={() => {this.showPopup(2)}}>
                                    Витамин А*
                                </div>
                                <div className="substances_el_block" onClick={() => {this.showPopup(3)}}>
                                    Аргинин*
                                </div>
                                <div className="substances_el_block" onClick={() => {this.showPopup(4)}}>
                                    Ниацин
                                </div>
                                <div className="substances_el_block" onClick={() => {this.showPopup(5)}}>
                                    Таурин*
                                </div>
                                <div className="substances_el_block" onClick={() => {this.showPopup(6)}}>
                                    Ограниченное количество углеводов
                                </div>
                                <div className="substances_el_block" onClick={() => {this.showPopup(7)}}>
                                    Арахидоновая кислота*
                                </div>
                            </div>
                            <div className="substances_el">
                                <img src={img2} alt="" className="substances_el_img"/>
                            </div>
                        </div>
                        <div className="note">
                            *Источник - только продукты животного происхождения.
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Рационы WHISKAS<sup>®</sup> способствуют улучшению здоровья кошек по следующим показателям:`}
                        />
                        <InfoBlock
                            text={`Узнайте, благодаря чему достигаются те или иные улучшения, сопоставив их со свойствами рационов WHISKAS<sup>®</sup>.`}
                        />
                        {windowWidth >= 768 ?
                            <div className="test">
                                {this._renderTestDesktop()}
                            </div>
                            :
                            <div className="test">
                                {this._renderTestMobile()}
                            </div>
                        }
                    </div>
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_head">
                            Разнообразие в питании – репертуар вкусов и текстур
                        </div>
                        <img className="imgDiv_wrap_img imgCenter" src={img4} alt=""/>
                        <TextBlock
                            text={`Рационы WHISKAS<sup>®</sup> для взрослых кошек представлены во всех текстурах и вкусах.`}
                        />
                        {/*<InfoBlock*/}
                        {/*    text={`Нажмите на ключевые особенности рациона кошек и узнайте почему они им необходимы.`}*/}
                        {/*/>*/}
                        <img className="imgDiv_wrap_img imgCenter" src={img5} alt=""/>
                    </div>
                </OnClickBlock>
                {showPopup &&
                <Popup
                    closePopup={this.closePopup}
                >
                    <div className="Popup_head">
                        {this.popupData[popupActive].head}
                    </div>
                    <List
                        ListData={this.popupData[popupActive].list}
                    />
                </Popup>
                }
            </div>
        )
    }
}
