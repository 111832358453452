import React from 'react'
import OnClickTest from "../../../Components/LessonAndTestComponents/OnClickTest";
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

export default class step19 extends React.Component {

    state = {
        answersData: [
            `Никогда`,
            `Очень редко`,
            `Обычно попадают`,
        ],
        showImg: false
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step19">
                <TextBlock
                    text={`Как Вы думаете, как часто корма для животных попадают в одну корзину с продуктами питания?`}
                />
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'line'}
                    rightAnswers={[3]}
                    showNextBtn={this.showNextBtn}
                    correctText={`Корма для животных обычно попадают в одну корзину с продуктами питания.`}
                />
            </div>
        )
    }
}
