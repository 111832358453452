import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson5/step7-1.png";
import img2 from "../../../image/lessons/lesson5/step7-2.png";

export default class step7 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит и избыток'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step7">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Витамин К (менахинон-7) — важен для процессов свертывания крови.</b>
                           
                            Витамин K был открыт в 1936 году.
                            
                            На сегодня витамин K известен как группа нескольких подобных жирорастворимых веществ, обеспечивающих свертывание крови.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Витамин K:`}
                        />
                        <ul className="list">
                            <li className="list_item">является кофактором для многих ферментов;</li>
                            <li className="list_item">играет важную роль в процессах свертывания крови;</li>
                            <li className="list_item">участвует в метаболизме белка;</li>
                            <li className="list_item">способствует включению кальция в костную ткань.</li>
                        </ul>
                        <TextBlock
                            text={`Витамин К накапливается в печени.`}
                        />
                    </div>
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_flex">
                            <TextBlock
                                text={`Кишечные бактерии у кошек и собак вырабатывают витамин K. Однако этот процесс в любом случае не обеспечивает его дневную норму, поэтому витамин K обязательно должен поступать в организм с пищей.
                                
                                Основными источниками витамина К являются: печень, мясо и шпинат.`}
                            />
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img2} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Дефицит витамина K приводит к нарушению процессов свертывания крови и, как следствие, к кровоизлияниям в различных органах и тканях.
                            
                            Со временем эти незначительные кровоизлияния могут привести к анемии (малокровию).`}
                        />
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
