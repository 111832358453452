import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson8/step5-1.png";

export default class step6 extends React.Component {

    render() {
        return (
            <div className="step6">
                <TextBlock
                    text={`Используем рассмотренный ранее алгоритм из трех шагов для расчета суточной нормы рациона собаки.`}
                />
                <ol className="list-numeral">
                    <li className="list-numeral_item"><span className="list-numeral_text">Исходя из веса Рекса (34 кг), рассчитаем его суточную энергетическую потребность по формуле: <b>Э = 95 * 34 <sup>0,75</sup> = 1 338 ккал / сутки.</b></span></li>
                    <li className="list-numeral_item"><span className="list-numeral_text">Мы уже знаем энергетическую ценность корма — 360 ккал / 100 г. Можно переходить к финальному шагу.</span></li>
                    <li className="list-numeral_item"><span className="list-numeral_text">Рассчитаем суточную норму рациона: <b>1 338 / 360 * 100 = <span className="text_blue">372 г / сутки</span> (с учетом округления).</b></span></li>
                </ol>
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
