import React from 'react'

import img1 from '../../../image/lessons/lesson17/step5-1.png'
import img2 from '../../../image/lessons/lesson17/step5-2.png'

export default class step5 extends React.Component {


    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step5">
                <div className="block">
                    Категория кормов для животных в России уже сейчас входит в ТОП 10 FMCG категорий и в будущем будет только расти!
                </div>
                <img src={img1} alt="" className="imgCenter"/>
                <img src={img2} alt="" className="imgCenter"/>
            </div>
        )
    }
}
