import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickTest from '../../../Components/LessonAndTestComponents/OnClickTest';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import img1 from '../../../image/lessons/lesson4/step4-1.png'
import img2 from '../../../image/lessons/lesson4/step4-2.png'
import img3 from '../../../image/lessons/lesson4/step4-3.png'
import img4 from '../../../image/lessons/lesson4/step4-4.png'
import img5 from '../../../image/lessons/lesson4/step4-5.png'
import img6 from '../../../image/lessons/lesson4/step4-6.png'



export default class step4 extends React.Component {

    state = {
        answersData: {
            1: {
                img: img1,
                text: `яйца`
            },
            2: {
                img: img2,
                text: `масло`
            },
            3: {
                img: img3,
                text: `мясо`
            },
            4: {
                img: img4,
                text: `томаты`
            },
            5: {
                img: img5,
                text: `рыбий жир`
            },
            6: {
                img: img6,
                text: `мука`
            },
        }

    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step4">
                <TextBlock
                    text={`Как вы думаете, какие продукты являются главными источниками жиров?`}
                />
                <InfoBlock
                    text={`Выберите все подходящие варианты ответа и нажмите кнопку.`}
                />
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'img'}
                    rightAnswers={[2, 3, 5]}
                    showNextBtn={this.showNextBtn}
                    correctText={`Жиры бывают растительного и животного происхождения. Основными источниками жиров являются:`}
                >
                    <ul className="list">
                        <li className="list_item">рыбий жир;</li>
                        <li className="list_item">говяжий жир;</li>
                        <li className="list_item">подкожный жир домашней птицы;</li>
                        <li className="list_item">растительные масла (льняное, подсолнечное).</li>
                    </ul>
                </OnClickTest>
            </div>
        )
    }
}
