import React from 'react'
import Resume from '../../../Components/LessonAndTestComponents/Resume'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson10/step5-1.jpg'


export default class step5 extends React.Component{

    state = {
        resumeData: [
            `Беременность у кошек длится <b>9–9,5 недель.</b>`,
            `Беременным кошкам можно разрешить <b>свободный</b> доступ к корму, переедание в этот период ей не грозит.`,
            `В период беременности и лактации кошкам <b>подойдут рационы для котят</b>, так как они удовлетворят её повышенные требования к энергии и питательным веществами.`,
            `Первые <b>4 недели</b> жизни котята питаются исключительно материнским молоком.`,
        ]
    }

    render() {
        return (
            <div className="step5">
                <Resume
                    resumeData={this.state.resumeData}
                    img={img1}
                />
                <TextBlock
                    text={`В следующей теме вы узнаете о питании котят на разных стадиях жизни.`}
                />
            </div>
        )
    }
}
