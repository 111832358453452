import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from "../../../image/lessons/lesson20/step22-1.png";


export default class step33 extends React.Component {

    render() {
        return (
            <div className="step33">
                <TextBlock
                    text={`Сочетание влажного и сухого корма позволяет сбалансировать макронутриенты так, чтобы удовлетворить <b>естественные предпочтения кошек</b>.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Cухие рационы
                        </div>
                        <div className="flex_el_text">
                            Способствуют:
                        </div>
                        <ul className="list">
                            <li className="list_item">
                                <b>Профилактике заболеваний ротовой полости</b> за счет механической чистик зубов;
                            </li>
                            <li className="list_item">
                                <b>Профилактике жкт</b> за счет высокого содержания клетчатки.
                            </li>
                        </ul>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Влажные рационы
                        </div>
                        <div className="flex_el_text">
                            Способствуют:
                        </div>
                        <ul className="list">
                            <li className="list_item">
                                <b>Профилактике ожирения</b> за счет меньшей калорийности;
                            </li>
                            <li className="list_item">
                                <b>Профилактике заболеваний мочевыделительной системы</b> за счет высокого содержания жидкости.
                            </li>
                        </ul>
                    </div>
                </div>
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
