import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson10/step12-1.png";
import img2 from "../../../image/lessons/lesson10/step12-2.png";
import img3 from "../../../image/lessons/lesson10/step12-3.png";

export default class step12 extends React.Component {


    render() {
        return (
            <div className="step12">
                <TextBlock
                    text={`Поскольку кошки едят <b>маленькими порциями и часто</b>, у них в доступе всегда должен быть корм. Рекомендуется кормить кошек по следующей схеме:`}
                />
                <div className="flex">
                    <div className="flex_el text">
                        <div className="flex_el_text">
                            <span className="flex_el_text_blue">Влажный корм</span><br/> 2 раза в день.
                        </div>
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                    <div className="flex_el plus">+</div>
                    <div className="flex_el text">
                        <div className="flex_el_text">
                            <span className="flex_el_text_blue">Сухой корм</span><br/> в постоянном доступе в миске.
                        </div>
                        <img src={img2} alt="" className="flex_el_img"/>
                    </div>
                    <div className="flex_el plus">+</div>
                    <div className="flex_el text">
                        <div className="flex_el_text">
                            <span className="flex_el_text_blue">Вода</span><br/> в постоянном доступе в миске.
                        </div>
                        <img src={img3} alt="" className="flex_el_img"/>
                    </div>
                </div>

            </div>
        )
    }
}
