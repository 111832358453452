import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from "../../../image/lessons/lesson18/step9-1.png";

export default class step9 extends React.Component {

    render() {
        return (
            <div className="step9">
                <TextBlock
                    text={`В основе эффективности PEDIGREE<sup>®</sup> DENTASTIX™ лежит <span class="text_dark-blue">сочетание</span> механического действия и благоприятного воздействия активных компонентов`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
