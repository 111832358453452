import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from "../../../Components/LessonAndTestComponents/OnClickBlock";

import img1 from '../../../image/lessons/lesson3/step19-1.png';
import img2 from "../../../image/lessons/lesson3/step19-2.png";
import img3 from "../../../image/lessons/lesson3/step19-3.png";

export default class step19 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step19">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Фенилаланин и тирозин отвечают за работу щитовидной железы и надпочечников, пигментацию шерсти.</b>
                           
                            Фенилаланин и тирозин представляют собой ароматические аминокислоты (так называются аминокислоты с кольцевым строением).

                            Тирозин синтезируется из фенилаланина. Однако если в организм поступает достаточно тирозина, фенилаланин высвобождается для других функций.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Функции фенилаланина в организме:`}
                        />
                        <ul className="list">
                            <li className="list_item">выработка гормонов щитовидной железы и прочих метаболитов;</li>
                            <li className="list_item">синтез тирозина.</li>
                        </ul>
                        <TextBlock
                            text={`Значение тирозина:`}
                        />
                        <ul className="list">
                            <li className="list_item">определяет цвет глаз и шерсти;</li>
                            <li className="list_item">предшественник допамина, норадреналина и адреналина. Эти молекулы отвечают за работу мозга и репродуктивной системы.</li>
                        </ul>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Источниками фенилаланина являются животные белки, в частности говядина, рыба и домашняя птица.
                            
                            Тирозин поступает в организм с пищей или синтезируется из фенилаланина. Единственным растительным источником тирозина является рис.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img2} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Дефицит фенилаланина у <b>кошек</b> вызывает:`}
                        />
                        <ul className="list">
                            <li className="list_item">неврологическую дисфункцию;</li>
                            <li className="list_item">нарушение координации движений;</li>
                            <li className="list_item">гиперактивность.</li>
                        </ul>
                        <TextBlock
                            text={`У <b>собак</b> это приводит:`}
                        />
                        <ul className="list">
                            <li className="list_item">к потере веса;</li>
                            <li className="list_item">к уменьшению аппетита;</li>
                            <li className="list_item">к покраснению шерстного покрова.</li>
                        </ul>
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img3} alt=""/>
                        </div>
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
