import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson32/step21-1.png'
import img1xs from '../../../image/lessons/lesson32/step21-1xs.png'


export default class step22 extends React.Component {


    render() {
        return (
            <div className="step21">
                <TextBlock
                    text={`У собаки 42 постоянных зуба. Ниже представлена зубная формула собак.`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} alt="" className="imgCenter"/>
                </picture>
            </div>
        )
    }
}
