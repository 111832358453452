import React from "react";
import './style.scss'

const CheckboxInput = (props) => {
    const checkboxHandler = (e) => {
        if(e.target.checked){
            e.target.classList.remove('error')
        }
        else{
            e.target.classList.add('error')
        }
    }
    const createMarkup = () => {
        return {__html: props.label};
    }

    return(
        <div className="login__checkbox_rules">
            {props.star === "true" ? '*' : ' '}
            <input className="checkbox1" id={props.id} type="checkbox" onClick={(e => {checkboxHandler(e)})} data-required={props.required} name={props.name}/>
            <label className="checkbox1__label" htmlFor="siteRules" dangerouslySetInnerHTML={createMarkup()}></label>
        </div>
    )
}

export default CheckboxInput