import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson15/step4-2.png';


export default class step4 extends React.Component {
    state = {
    };

    render() {
        return (
            <div className="step4">

                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_number">1</div>
                        <TextBlock
                            text={`Россия – огромная страна. 
                            Численность населения составляет <b>143 млн</b> человек.`}
                        />
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_number">2</div>
                        <TextBlock
                            text={`Из всего населения, дети до 14 лет составляют <b>16%</b> (23 млн)`}
                        />
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_number">3</div>
                        <TextBlock
                            text={`А численность домашних животных <b>в 2 раза выше</b> детей! 
                            Их в нашей стране – 52.5 млн`}
                        />
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_number">4</div>
                        <img src={img1} alt="" className="flex_el_img"/>
                        <TextBlock
                            text={`Кошек <b>33.6 млн</b> #3 в мире
                            Собак <b>18.9 млн</b> #5 в мире`}
                        />
                    </div>
                </div>

            </div>
        )
    }
}
