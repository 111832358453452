import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson16/step16-1.png';
import img1xs from "../../../image/lessons/lesson16/step16-1xs.png";

export default class step16 extends React.Component {

    render() {
        return (
            <div className="step16">
                <TextBlock
                    text={`Несмотря на широкий выбор магазинов, у большинства покупателей все еще отсутствует привычка регулярного кормления. Большая доля покупателей приобретает корма реже 1 раза в месяц.`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} className="imgCenter" alt=""/>
                </picture>
                <TextBlock
                    text={`<b>53%</b> владельцев кошек и <b>89%</b> владельцев собак покупают корма реже <b>1 раза в месяц</b> или не покупают вообще.`}
                />
            </div>
        )
    }
}
