import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'
import Answer from './dnd/Answer';
import Questions from './dnd/Questions';

import img1 from '../../../image/lessons/lesson5/step19-1.png'
import img2 from '../../../image/lessons/lesson5/step18-7.png'
import img3 from '../../../image/lessons/lesson5/step19-2.png'
import img4 from '../../../image/lessons/lesson5/step18-5.png'
import img5 from '../../../image/lessons/lesson5/step18-6.png'
import img6 from '../../../image/lessons/lesson5/step18-1.png'
import img7 from '../../../image/lessons/lesson5/step19-3.png'
import img8 from '../../../image/lessons/lesson5/step18-4.png'
import img9 from '../../../image/lessons/lesson5/step19-4.png'

export default class step19 extends React.Component {

    state = {
        // Ресайз
        windowWidth: window.innerWidth,

        // Работа десктопной версии
        answers: ['2', '4', '5', '6', '8', '1', '3', '7', '9'], //Массив правильных ответов
        correct: '',
        answerCorrect: [],
        answersSelectedDesktop: ['?', '?', '?', '?', '?', '?', '?', '?', '?'], // Массив выбираемых значений

        //Работа мобильной версии
        answerActive: 0,
        questionActive: 0,
        answersMobile: [],
        questionsMobile: [],
        answersSelected: ['?', '?', '?', '?', '?', '?', '?', '?', '?'], // Массив выбираемых значений
        correctMobile: '',
        answerCorrectMobile: [],
        showPopup: false
    }


    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    //Работа десктопной версии
    setAnswer = (answer_el, question_el) => {
        const {answersSelectedDesktop} = this.state

        answersSelectedDesktop.splice(answer_el - 1, 1, question_el)
        this.setState({answersSelectedDesktop})
    };

    checkAnswer = () => {
        const {answerCorrect, answersSelectedDesktop, answers} = this.state
        // eslint-disable-next-line
        answersSelectedDesktop.map((answer, index) => {
            if (index < 5) {
                if (answer === answers[0] || answer === answers[1] || answer === answers[2] || answer === answers[3] || answer === answers[4]) {
                    answerCorrect.push('correct')
                } else {
                    answerCorrect.push('inCorrect')
                }
            } else if (index < 9) {
                if (answer === answers[5] || answer === answers[6] || answer === answers[7] || answer === answers[8]) {
                    answerCorrect.push('correct')
                } else {
                    answerCorrect.push('inCorrect')
                }
            }
        })
        this.setState({
            answerCorrect: answerCorrect,
        })
        if (answersSelectedDesktop.indexOf('?') === -1 && answerCorrect.indexOf('inCorrect') === -1) {
            this.setState({correct: '1'})
        } else {
            this.setState({correct: '0'})
        }
        this.props.showNextBtn()
    };

    _renderTestDesktop = () => {
        const {answerCorrect, correct} = this.state
        return (
            <div className="test_desktop">
                <div className="test_wrp">
                    <div className="questions">
                        <div className={'question_el'}>
                            <Questions targetKey="1" question_el={'1'} label={'B1'} text={''} img={img1}/>
                        </div>
                        <div className={'question_el'}>
                            <Questions targetKey="1" question_el={'2'} label={'B2'} text={''} img={img2}/>
                        </div>
                        <div className={'question_el'}>
                            <Questions targetKey="1" question_el={'3'} label={'B3'} text={''} img={img3}/>
                        </div>
                        <div className={'question_el'}>
                            <Questions targetKey="1" question_el={'4'} label={'B5'} text={''} img={img4}/>
                        </div>
                        <div className={'question_el'}>
                            <Questions targetKey="1" question_el={'5'} label={'B6'} text={''} img={img5}/>
                        </div>
                        <div className={'question_el'}>
                            <Questions targetKey="1" question_el={'6'} label={'B7'} text={''} img={img6}/>
                        </div>
                        <div className={'question_el'}>
                            <Questions targetKey="1" question_el={'7'} label={'B9'} text={''} img={img7}/>
                        </div>
                        <div className={'question_el'}>
                            <Questions targetKey="1" question_el={'8'} label={'B12'} text={''} img={img8}/>
                        </div>
                        <div className={'question_el'}>
                            <Questions targetKey="1" question_el={'9'} label={'Холин'} text={''} img={img9}/>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="answers">
                            <div className="product">
                                <h3 className="head">Водорастворимые витамины</h3>
                                <div className="product_box">
                                    <div className="answers_box">
                                        <div className="answers_item">
                                            <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'1'} count={'?'} answerCorrect={answerCorrect[0]}/>
                                        </div>
                                    </div>
                                    <div className="answers_box">
                                        <div className="answers_item">
                                            <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'2'} count={'?'} answerCorrect={answerCorrect[1]}/>
                                        </div>
                                    </div>
                                    <div className="answers_box">
                                        <div className="answers_item">
                                            <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'3'} count={'?'} answerCorrect={answerCorrect[2]}/>
                                        </div>
                                    </div>
                                    <div className="answers_box">
                                        <div className="answers_item">
                                            <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'4'} count={'?'} answerCorrect={answerCorrect[3]}/>
                                        </div>
                                    </div>
                                    <div className="answers_box">
                                        <div className="answers_item">
                                            <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'5'} count={'?'} answerCorrect={answerCorrect[4]}/>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="product">
                                <h3 className="head">Жирорастворимые витамины</h3>
                                <div className="product_box">
                                    <div className="answers_box">
                                        <div className="answers_item">
                                            <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'6'} count={'?'} answerCorrect={answerCorrect[5]}/>
                                        </div>
                                    </div>
                                    <div className="answers_box">
                                        <div className="answers_item">
                                            <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'7'} count={'?'} answerCorrect={answerCorrect[6]}/>
                                        </div>
                                    </div>
                                    <div className="answers_box">
                                        <div className="answers_item">
                                            <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'8'} count={'?'} answerCorrect={answerCorrect[7]}/>
                                        </div>
                                    </div>
                                    <div className="answers_box">
                                        <div className="answers_item">
                                            <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'9'} count={'?'} answerCorrect={answerCorrect[8]}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {correct !== '' ?
                    <div className={'info ' + (correct === '0' && 'error')}>
                        <div className="info_head">
                            {correct === '1' ? 'все верно!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                        {<div className="infoText">
                            {correct === '1'
                                ?
                                'Вы правильно расположили все ключевые питательные вещества'
                                :
                                'К водорастворимым витаминам относятся: В1, В5,В6,В7 и В9. К жирорастворимым: А,D, E и К'
                            }
                        </div>}
                    </div>
                    :
                    <button className="btn btn_blue" onClick={() => this.checkAnswer()}>
                        ПРОВЕРИТЬ
                    </button>
                }
            </div>
        )
    }

    //Работа мобильной версии
    questions = [
        img1,
        img2,
        img3,
        img4,
        img5,
        img6,
        img7,
        img8,
        img9,
    ]

    popupData = {
        1: {
            head: 'Водорастворимые витамины'
        },
        2: {
            head: 'Водорастворимые витамины'
        },
        3: {
            head: 'Водорастворимые витамины'
        },
        4: {
            head: 'Водорастворимые витамины'
        },
        5: {
            head: 'Водорастворимые витамины'
        },
        6: {
            head: 'Жирорастворимые витамины'
        },
        7: {
            head: 'Жирорастворимые витамины'
        },
        8: {
            head: 'Жирорастворимые витамины'
        },
        9: {
            head: 'Жирорастворимые витамины'
        },
    }

    selectAnswer = (answerActive) => {
        document.body.scrollIntoView( true);
        document.body.style.overflow = "hidden";
        this.setState({
            showPopup: true,
            answerActive: answerActive
        })
    }

    selectQuestion = (questionActive) => {
        const {answerActive, answersMobile, questionsMobile, answersSelected} = this.state

        if (answersMobile.indexOf(answerActive) === -1) {
            answersMobile.push(answerActive)
        }
        this.setState({answersMobile})

        if (questionsMobile.indexOf(questionsMobile) === -1) {
            questionsMobile.push(questionActive)
        }
        this.setState({questionsMobile})

        answersSelected.splice(answerActive - 1, 1, this.questions[questionActive - 1])

        document.body.style.overflow = "";
        this.setState({
            showPopup: false,
            questionActive: questionActive,
            answersSelected: answersSelected
        })
    }

    checkAnswerMobile = () => {
        const {answerCorrectMobile, answersSelected} = this.state
        // eslint-disable-next-line
        answersSelected.map((answer, index) => {
            if (index < 5) {
                if (answer === this.questions[1] || answer === this.questions[3] || answer === this.questions[4] || answer === this.questions[5] || answer === this.questions[7]) {
                    answerCorrectMobile.push('correct')
                } else {
                    answerCorrectMobile.push('inCorrect')
                }
            } else if (index < 9) {
                if (answer === this.questions[0] || answer === this.questions[2] || answer === this.questions[6] || answer === this.questions[8]) {
                    answerCorrectMobile.push('correct')
                } else {
                    answerCorrectMobile.push('inCorrect')
                }
            }
        })
        this.setState({
            answerCorrect: answerCorrectMobile,
        })
        if (answerCorrectMobile.indexOf('?') === -1 && answerCorrectMobile.indexOf('inCorrect') === -1) {
            this.setState({correctMobile: '1'})
        } else {
            this.setState({correctMobile: '0'})
        }
        this.props.showNextBtn()
    }

    closePopup = () => {
        document.body.style.overflow = "";
        this.setState({showPopup: false})
    }

    _renderTestMobile = () => {
        const {showPopup, answerActive, answersMobile, questionsMobile, answersSelected, correctMobile, answerCorrectMobile} = this.state
        return (
            <div className="test_mobile">
                {correctMobile !== '' ?
                    <div className={'info ' + (correctMobile === '0' && 'error')}>
                        <div className="info_head">
                            {correctMobile === '1' ? 'все верно!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                        {<div className="infoText">
                            {correctMobile === '1'
                                ?
                                `Вы правильно расположили все ключевые питательные вещества.`
                                :
                                'В мясе основными питательными веществами являются белки и жиры; в овощах: витамины, минералы и клетчатка; в злаках: углеводы, клетчатка и белки; маслах: жиры и витамины.'
                            }
                        </div>}
                    </div>
                    :
                    <button className="btn btn_blue" onClick={() => this.checkAnswerMobile()}>
                        ПРОВЕРИТЬ
                    </button>
                }
                <div className="answers">
                    <div className="flex">
                        <div className="flex_el">
                            <h3 className="flex_el_head">Водорастворимые витамины</h3>
                            <div className="flex_el_box">
                                <div className={'answers_item ' + (answersMobile.indexOf(1) >=0 ? 'selected ' : '') + answerCorrectMobile[0]} onClick={() => {this.selectAnswer(1)}}>
                                    {answersSelected[0] === '?' ?
                                        answersSelected[0]
                                        :
                                        <img src={answersSelected[0]} alt="" className="answers_item_img"/>
                                    }
                                </div>
                                <div className={'answers_item ' + (answersMobile.indexOf(2) >=0 ? 'selected ' : '') + answerCorrectMobile[2]} onClick={() => {this.selectAnswer(2)}}>
                                    {answersSelected[1] === '?' ?
                                        answersSelected[1]
                                        :
                                        <img src={answersSelected[1]} alt="" className="answers_item_img"/>
                                    }
                                </div>
                                <div className={'answers_item ' + (answersMobile.indexOf(3) >=0 ? 'selected ' : '') + answerCorrectMobile[3]} onClick={() => {this.selectAnswer(3)}}>
                                    {answersSelected[2] === '?' ?
                                        answersSelected[2]
                                        :
                                        <img src={answersSelected[2]} alt="" className="answers_item_img"/>
                                    }
                                </div>
                                <div className={'answers_item ' + (answersMobile.indexOf(4) >=0 ? 'selected ' : '') + answerCorrectMobile[4]} onClick={() => {this.selectAnswer(4)}}>
                                    {answersSelected[3] === '?' ?
                                        answersSelected[3]
                                        :
                                        <img src={answersSelected[3]} alt="" className="answers_item_img"/>
                                    }
                                </div>
                                <div className={'answers_item ' + (answersMobile.indexOf(5) >=0 ? 'selected ' : '') + answerCorrectMobile[4]} onClick={() => {this.selectAnswer(5)}}>
                                    {answersSelected[4] === '?' ?
                                        answersSelected[4]
                                        :
                                        <img src={answersSelected[4]} alt="" className="answers_item_img"/>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="flex_el">
                            <h3 className="flex_el_head">Жирорастворимые витамины</h3>
                            <div className="flex_el_box">
                                <div className={'answers_item ' + (answersMobile.indexOf(6) >=0 ? 'selected ' : '') + answerCorrectMobile[5]} onClick={() => {this.selectAnswer(6)}}>
                                    {answersSelected[5] === '?' ?
                                        answersSelected[5]
                                        :
                                        <img src={answersSelected[5]} alt="" className="answers_item_img"/>
                                    }
                                </div>
                                <div className={'answers_item ' + (answersMobile.indexOf(7) >=0 ? 'selected ' : '') + answerCorrectMobile[6]} onClick={() => {this.selectAnswer(7)}}>
                                    {answersSelected[6] === '?' ?
                                        answersSelected[6]
                                        :
                                        <img src={answersSelected[6]} alt="" className="answers_item_img"/>
                                    }
                                </div>
                                <div className={'answers_item ' + (answersMobile.indexOf(8) >=0 ? 'selected ' : '') + answerCorrectMobile[7]} onClick={() => {this.selectAnswer(8)}}>
                                    {answersSelected[7] === '?' ?
                                        answersSelected[7]
                                        :
                                        <img src={answersSelected[7]} alt="" className="answers_item_img"/>
                                    }
                                </div>
                                <div className={'answers_item ' + (answersMobile.indexOf(9) >=0 ? 'selected ' : '') + answerCorrectMobile[8]} onClick={() => {this.selectAnswer(9)}}>
                                    {answersSelected[8] === '?' ?
                                        answersSelected[8]
                                        :
                                        <img src={answersSelected[8]} alt="" className="answers_item_img"/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showPopup &&
                <div className="popup">
                    <div className="popup_wrp">
                        <div
                            className="popup_closed"
                            onClick={() => {this.closePopup()}}
                        />
                        <div className="popup_flex">
                            <p className="popup_text">
                                {this.popupData[answerActive].head}
                            </p>
                            <div className="questions">
                                {this.questions.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={'questions_item '  + (questionsMobile.indexOf(index + 1) >=0 ? 'selected ' : '')}
                                            onClick={() => {this.selectQuestion(index + 1)}}
                                        >
                                            <img src={item} alt="" className="question_item_img"/>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }

    render() {
        const {windowWidth} = this.state
        return (
            <div className="step19">
                <InfoBlock
                    text={`Вспомните, какие из этих витаминов являются жирорастворимыми, а какие — водорастворимыми. Нажмите «Проверить», чтобы узнать правильный ответ.`}
                />
                {windowWidth >= 768 ?
                    <div className="test">
                        {this._renderTestDesktop()}
                    </div>
                    :
                    <div className="test">
                        {this._renderTestMobile()}
                    </div>
                }
            </div>
        )
    }
}
