import React from 'react'
import img1 from '../../../image/lessons/lesson9/step18-1.jpg'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";


export default class step6 extends React.Component {


    render() {
        return (
            <div className="step18">
                <TextBlock
                    text={`При необходимости перевода щенка на новый рацион, делать это нужно <b>постепенно.</b> Оптимальная продолжительность приучения к новому корму составляет от <b>4 до 7 дней.</b>`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <TextBlock
                            text={`Каждый день к старому корму следует добавлять некоторое количество нового и <b>постепенно увеличивать</b> соотношение нового корма к старому.`}
                        />
                        <div className="attention">
                            Будьте внимательны! Слишком быстрый переход на новый рацион может вызвать последствия в виде расстройства пищеварения, диареи и тошноты.
                        </div>
                    </div>
                    <div className="flex_el">
                        <img className="flex_img" src={img1} alt=""/>
                    </div>
                </div>
            </div>
        )
    }
}
