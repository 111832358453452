import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson12/step4-1.png';
import img2 from '../../../image/lessons/lesson12/step4-2.png';
import img3 from '../../../image/lessons/lesson12/step4-3.png';

export default class step4 extends React.Component {

    render() {
        return (
            <div className="step4">

                <TextBlock
                    text={`Как вы знаете, существует три вида кормов для собак, различающихся по возрастным особенностям.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Для щенков
                        </div>
                        <div className="flex_el_wrp">
                            <ul>
                                <li>высокая калорийность;</li>
                                <li>высокая усвояемость;</li>
                                <li>повышенное содержание белка и некоторых микроэлементов для развития и роста.</li>
                            </ul>
                        </div>
                        <img className="flex_el_img" src={img1} alt=""/>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Для взрослых собак
                        </div>
                        <div className="flex_el_wrp">
                            <ul>
                                <li>сбалансированный состав для поддержания обмена веществ взрослых животных;</li>
                                <li>хорошая усвояемость;</li>
                                <li>высокая вкусовая привлекательность.</li>
                            </ul>
                        </div>
                        <img className="flex_el_img" src={img2} alt=""/>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Для стареющих собак
                        </div>
                        <div className="flex_el_wrp">
                            <ul>
                                <li>увеличенное содержание некоторых витаминов и минералов, например, витамина Е для укрепления иммунитета и защиты организма;</li>
                                <li>высокая усвояемость;</li>
                                <li>высокая вкусовая привлекательность.</li>
                            </ul>
                        </div>
                        <img className="flex_el_img" src={img3} alt=""/>
                    </div>
                </div>

            </div>
        )
    }
}
