import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson2/step14-1.png";
import img2 from "../../../image/lessons/lesson2/step14-2.png";


export default class step14 extends React.Component {

    render() {
        return (
            <div className="step14">
                <TextBlock
                    text={`Энергетические потребности животного определяются <b>исходя из его массы.</b>`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_imgBox">
                            <img className="flex_el_img" src={img1} alt=""/>
                        </div>
                        <div className="flex_el_box">
                            <p className="flex_el_text">Для взрослой кошки рассчитывается по формуле:</p>
                            <p className="flex_el_text blue">Э = 77.6 * M <sup>0,711</sup></p>
                            <p className="flex_el_text">Э — энергетическая потребность, М — масса тела кошки.</p>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_imgBox">
                            <img className="flex_el_img" src={img2} alt=""/>
                        </div>
                        <div className="flex_el_box">
                            <p className="flex_el_text">Для взрослой собаки рассчитывается по формуле:</p>
                            <p className="flex_el_text blue">Э = 95 * M <sup>0,75</sup></p>
                            <p className="flex_el_text">Э — энергетическая потребность, М — масса тела собаки.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
