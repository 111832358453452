import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson18/step5-1.png'


export default class step5 extends React.Component{

    render() {
        return (
            <div className="step5">
                <TextBlock
                    text={`<span class="text_dark-blue">Исследования показывают, что 4 из 5 собак старше 3 лет страдают заболеваниями зубов и десен.</span>`}
                />
                <img src={img1} alt="" className="imgCenter"/>
                <TextBlock
                    text={`Многие владельцы не знают или забывают о необходимости обеспечивать гигиену ротовой полости своих питомцев. В результате у собак появляется <b>зубной налёт и развивается зубной камень.</b>

                    Ежедневно на поверхности зубов образуется <b>налёт — плёнка с большим количеством бактерий</b>. Если его не счищать, образуется зубной камень, начинают кровоточить десны.`}
                />
            </div>
        )
    }
}
