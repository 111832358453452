import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";
import PlanInFirstStep from "../../../Components/LessonAndTestComponents/PlanInFirstStep";

export default class step1 extends React.Component {
    planData = [
        `Какая информация содержится на упаковках готовых кормов для животных`,
        `Какие ингредиенты используются в промышленных кормах`,
        `Как интерпретировать сырьевой состав корма`,
    ]

    render() {
        return (
            <div className="step1">
                <div className="head">План урока</div>

                <TextBlock
                    text={`Из этого урока вы узнаете:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'blue'}
                />

            </div>
        )
    }
}
