import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson14/step12-1.png'


export default class step12 extends React.Component {

    render() {
        return (
            <div className="step12">
                <TextBlock
                    text={`В этом уроке вы узнали, что у домашних животных есть четыре основные функциональные потребности:`}
                />
                <ul>
                    <li>хорошее пищеварение;</li>
                    <li>здоровые зубы;</li>
                    <li>иммунитет;</li>
                    <li>здоровая кожа и шерсть.</li>
                </ul>
                <TextBlock
                    text={`Для правильного <b>функционального ухода и поддержания здоровья</b> животных производители готовых рационов добавляют в состав кормов витамины, минералы и специальные добавки, отвечающие за каждую из этих функций.`}
                />
                <ul>
                    <li>Для правильного <b>пищеварения</b> необходима диетическая клетчатка и высокоусвояемые ингредиенты.</li>
                    <li><b>Здоровье зубов</b> поддерживается кальцием и фосфором.</li>
                    <li>Для <b>хорошего иммунитета</b> нужны цинк и витамин Е.</li>
                    <li><b>Здоровье кожи и шерсти</b> обеспечивают линолевая кислота и цинк.</li>
                </ul>
                <img src={img1} alt=""/>
            </div>
        )
    }
}
