import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson1/step15-1.png";

export default class step15 extends React.Component {

    render() {
        return (
            <div className="step15">
                <div className="flex">
                    <div className="flex_el">
                        <img className="flex_el_img" src={img1} alt=""/>
                    </div>
                    <div className="flex_el">
                        <TextBlock
                            text={`<b>Чистопородная собака –</b> это собака, имеющая гены только одной породы в нескольких поколениях. Все породистые собаки регистрируются в родословной.
                            
                            Каждая порода имеет свои стандарты – задокументированные физические качества, по которым определяют степень чистоты породы конкретной собаки.
                            
                            <b>Смешанная порода –</b> это перекрестная порода, состоящая из генов двух и более пород.
                            
                            Выведение новой породы получается путем скрещивания двух чистопородных собак разных пород.`}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
