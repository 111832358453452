import React from 'react'
import Resume from "../../../Components/LessonAndTestComponents/Resume";

import img1 from "../../../image/lessons/lesson3/step12-1.png";

export default class step12 extends React.Component {

    state = {
        resumeData: [
            `Белки могут иметь как животное, так и растительное происхождение.`,
            `Набор и усвояемость аминокислот <b>определяют белковую ценность</b> продукта.`,
            `<b>Усвояемость пищи</b> показывает, насколько питательные вещества из пищи легко доступны организму.`,
        ]
    }

    render() {
        return (
            <div className="step18">
                <Resume
                    resumeData={this.state.resumeData}
                    img={img1}
                />
            </div>
        )
    }
}
