import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson7/step36-1.png";

export default class step36 extends React.Component {

    render() {
        return (
            <div className="step36">
                <TextBlock
                    text={`Полноценный сбалансированный рацион:`}
                />
                <ul className="list">
                    <li className="list_item">
                        обеспечивает животных всеми необходимыми питательными веществами в правильных пропорциях;
                    </li>
                    <li className="list_item">
                        удовлетворяет их энергетические потребности на конкретной стадии жизни.
                    </li>
                </ul>
                <TextBlock
                    text={`<span class="text_blue">Домашняя еда в долгосрочном периоде не способна удовлетворить пищевые потребности животных</span>. Более того, мясо в отдельности также не является полноценным рационом.
                    
                    Существуют различные организации, изучающие пищевые потребности кошек и собак и разрабатывающие рекомендации по их кормлению. <b>Сбалансированные готовые корма обеспечивают животное всеми необходимыми ему макро- и микронутриентами</b>, а также <b>учитывают особенности вкусового восприятия</b> собак и кошек.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <TextBlock
                            text={`<b>Сочетание</b> сухого и влажного корма — самый <b>оптимальный способ кормления</b>, который совмещает преимущества сухих и влажных кормов. Такой тип кормления позволяет:`}
                        />
                        <ul className="list">
                            <li className="list_item">
                                контролировать вес питомца;
                            </li>
                            <li className="list_item">
                                избегать перекармливания;
                            </li>
                            <li className="list_item">
                                снизить риск мочекаменной болезни.
                            </li>
                        </ul>
                    </div>
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                </div>
            </div>
        )
    }
}
