import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson5/step4-1.png";
import img2 from "../../../image/lessons/lesson5/step4-2.png";

export default class step4 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит и избыток'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step4">
               <OnClickBlock
                   flexData={flexData}
                   hideElement={this.hideElement}
                   showNextBtn={this.showNextBtn}
               >
                   <div className="imgDiv_wrap">
                       <TextBlock
                           text={`<b>Витамин А (ретинол) — важен для зрения. Он участвует в синтезе зрительных пигментов, необходимых для восприятия цвета (йодопсина) и ночного зрения (родопсина).</b>
                           
                           Витамин А был открыт в 1913 году, его химическая структура была расшифрована в 1931 году. Этот жирорастворимый витамин всасывается в тонком кишечнике и накапливается в печени.
                           
                           Собаки могут синтезировать витамин А из бета-каротина, а у кошек для этого процесса отсутствует необходимый фермент.`}
                       />
                       <div className="imgBox">
                           <img className="imgDiv_wrap_img" src={img1} alt=""/>
                       </div>
                   </div>
                   <div className="imgDiv_wrap">
                       <TextBlock
                           text={`Витамин А выполняет следующие функции в организме:`}
                       />
                       <ul className="list">
                           <li className="list_item">необходим для зрения, в особенности для быстрой адаптации к темноте;</li>
                           <li className="list_item">участвует в синтезе белка и репродуктивных гормонов;</li>
                           <li className="list_item">регулирует рост клеток кожи;</li>
                           <li className="list_item">регулирует функционирование сальных желез.</li>
                       </ul>
                   </div>
                   <div className="imgDiv_wrap">
                       <TextBlock
                           text={`Главные источники витамина А:`}
                       />
                       <div className="imgDiv_wrap_flex">
                           <ul className="list">
                               <li className="list_item">печень;</li>
                               <li className="list_item">рыба;</li>
                               <li className="list_item">яйца.</li>
                           </ul>
                           <div className="imgBox">
                               <img className="imgDiv_wrap_img" src={img2} alt=""/>
                           </div>
                       </div>
                   </div>
                   <div className="imgDiv_wrap">
                       <TextBlock
                           text={`Дефицит витамина А вызывает:`}
                       />
                       <ul className="list">
                           <li className="list_item">нарушение зрения;</li>
                           <li className="list_item">сухость кожи;</li>
                           <li className="list_item">репродуктивные нарушения;</li>
                           <li className="list_item">легочную патологию;</li>
                           <li className="list_item">повышенную восприимчивость к инфекциям.</li>
                       </ul>
                       <TextBlock
                           text={`Избыток витамина А вызывает:`}
                       />
                       <ul className="list">
                           <li className="list_item">хрупкость костей;</li>
                           <li className="list_item">нарушение репродуктивной функции.</li>
                       </ul>
                   </div>
               </OnClickBlock>
            </div>
        )
    }
}
