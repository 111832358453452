import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import ArrowBack from "../../../Components/LessonAndTestComponents/ArrowBack";

import img1 from '../../../image/lessons/lesson9/step17-1.jpg';
import img2 from '../../../image/lessons/lesson9/step17-2.jpg';
import img3 from '../../../image/lessons/lesson9/step17-3.jpg';
import img4 from '../../../image/lessons/lesson9/step17-4.jpg';


export default class step17 extends React.Component {
    state = {
        flexActive: 0,
        flexs: [1],
        flexHidden: false,
    };

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }
        this.setState({flexActive, flexs, flexHidden: true})
        if (flexs.length === 4) {
            this.props.showNextBtn()
        }
    }

    closeImgDiv = () => {this.setState({flexHidden: false, flexActive: 0})}


    render() {
        const {flexActive, flexHidden} = this.state
        return (
            <div className="step17">
                <TextBlock
                    text={`Вы видите, что для здорового роста щенкам необходимо большое количество питательных веществ. Однако все эти вещества должны содержаться в пище в правильном соотношении.`}
                    hidden={flexHidden === true ? 'hidden' : ''}
                />

                <div className={'flex ' + (flexHidden === true && 'hidden')}>
                    <div className={'flex_el ' + (flexActive === 1 && 'active')} onClick={() => {
                        this.changeFlex(1)
                    }}>Дефицит воды
                    </div>
                    <div className={'flex_el ' + (flexActive === 2 && 'active')} onClick={() => {
                        this.changeFlex(2)
                    }}>Дефицит кальция
                    </div>
                    <div className={'flex_el ' + (flexActive === 3 && 'active')} onClick={() => {
                        this.changeFlex(3)
                    }}>Избыток кальция
                    </div>
                    <div className={'flex_el ' + (flexActive === 4 && 'active')} onClick={() => {
                        this.changeFlex(4)
                    }}>Перекармливание
                    </div>
                </div>
                {flexActive !== 0 &&
                <div className={'imgDiv ' + (flexActive === 2 ? 'imgDiv--2' : flexActive === 3 ? 'imgDiv--3': flexActive === 4 ? 'imgDiv--4' : '')}>
                    <ArrowBack
                        returnMenu={this.closeImgDiv}
                    />
                    {flexActive === 1 &&
                    <div className="imgDiv_wrap">
                        <div className="imgPhone">
                            <img className="imgPhoneBg" src={img1} alt=""/>
                        </div>
                        <div className="imgDiv_flex">
                            <TextBlock
                                text={`В раннем возрасте щенки получают необходимое количество воды из материнского молока. После отучения щенков от грудного вскармливания им <b>необходимо обеспечить постоянный доступ к чистой питьевой воде</b> во избежание нарушения водного баланса.
                                
                                Недостаточное потребление воды приводит к <b>обезвоживанию.</b>`}
                            />
                            <img src={img1} alt=""/>
                        </div>
                    </div>
                    }
                    {flexActive === 2 &&
                    <div className="imgDiv_wrap">
                        <div className="imgPhone">
                            <img className="imgPhoneBg" src={img2} alt=""/>
                        </div>
                        <div className="imgDiv_flex">
                            <TextBlock
                                text={`Кальций и фосфор необходимы для <b>формирования и укрепления костей.</b>
                                
                                Недостаточное количество этих минералов либо их неправильное соотношение могут привести к нарушениям развития скелета и ломкости костей.`}
                            />
                            <img src={img2} alt=""/>
                        </div>
                    </div>
                    }
                    {flexActive === 3 &&
                    <div className="imgDiv_wrap">
                        <div className="imgPhone">
                            <img className="imgPhoneBg" src={img3} alt=""/>
                        </div>
                        <div className="imgDiv_flex">
                            <TextBlock
                                text={`Избыток кальция и фосфора также вызывает <b>проблемы со скелетом.</b> Во избежание этого необходимо обеспечивать щенка этими минеральными веществами в соответствии с его потребностями.
                                
                                Поскольку готовые корма для щенков содержат оптимальное количество кальция, использование <b>дополнительных</b> источников кальция может вызвать <b>избыточное поступление</b> данного минерала в организм.`}
                            />
                            <img src={img3} alt=""/>
                        </div>
                    </div>
                    }
                    {flexActive === 4 &&
                    <div className="imgDiv_wrap">
                        <div className="imgPhone">
                            <img className="imgPhoneBg" src={img4} alt=""/>
                        </div>
                        <div className="imgDiv_flex">
                            <TextBlock
                                text={`Перекармливание — распространенная проблема, которая наносит ущерб здоровью щенка в будущем. Оно провоцирует ожирение и проблемы с <b>опорно-двигательной системой.</b> Ожирение намного проще предотвратить, чем вылечить.`}
                            />
                            <img src={img4} alt=""/>
                        </div>
                    </div>
                    }
                </div>
                }
            </div>
        )
    }
}
