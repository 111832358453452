import React from 'react'
import Resume from "../../../Components/LessonAndTestComponents/Resume";

import img1 from "../../../image/lessons/lesson3/step21-1.png";


export default class step21 extends React.Component {
    state = {
        resumeData: [
            `Метионин и цистеин участвуют в синтезе <b>кератина</b> — основного белка шерсти.`,
            `<b>Аргинин</b> необходим для синтеза мочевины из аммиака.`,
            `Таурин необходим для поддержания <b>репродуктивных функций, здорового сердца,</b> а также здорового <b>слуха и зрения.</b>`,
            `Лизин — незаменимая аминокислота, которая используется организмом <b>для синтеза белка.</b>`,
        ]
    }

    render() {
        return (
            <div className="step18">
                <Resume
                    resumeData={this.state.resumeData}
                    img={img1}
                />
            </div>
        )
    }
}
