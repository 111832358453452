import React from "react";
import "./style.scss"

const Wrapper = (props) => {
    return(
        <div className="wrapper">
            {props.children}
        </div>
    )
}

export default Wrapper