import React from 'react'
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

export default class step4 extends React.Component {

    render() {
        return (
            <div className="step4">
                <TextBlock
                    text={`Предлагаем ознакомиться с обучающими видео`}
                />
                <VideoBlock
                    src={'https://www.youtube.com/embed/0Sxqbr56xCY'}
                    title={'https://www.youtube.com/watch?v=0Sxqbr56xCY&feature=emb_logo'}
                />
                <TextBlock
                    text={`<span class="text_blue">Как приучить собаку к кличке</span>`}
                />
            </div>
        )
    }
}
