import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from "../../../image/lessons/lesson2/step19-1.png";


export default class step19 extends React.Component {

    render() {
        return (
            <div className="step19">
                <TextBlock
                    text={`Мы выяснили, что рацион домашних животных должен максимально удовлетворять их потребности в энергии.
                    
                    Источниками энергии являются три группы питательных веществ: <b>белки, жиры и углеводы.</b> Их оптимальное содержание в рационе рассчитывается математически.
                    
                    Энергетические потребности животного зависят от различных факторов, но в первую очередь — от его веса. В настоящее время процент страдающих от ожирения домашних животных увеличился, поэтому <b>очень важно соблюдать энергетический баланс,</b> сопоставляя количество потребляемой энергии с затрачиваемой.`}
                />
                <img src={img1} alt="" className="img"/>
            </div>
        )
    }
}
