import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import img1 from '../../../image/lessons/lesson9/step28-1.jpg'


export default class step6 extends React.Component {


    render() {
        return (
            <div className="step28">
                <TextBlock
                    text={`Как мы видим, оптимальный рацион для собаки зависит <b>от стадии жизни,</b> на которой она находится.
                    
                    Самая <b>высокая</b> потребность в питательных веществах приходится на период лактации. В этот период собака может есть <b>в 4 раза больше</b> ее стандартной нормы.`}
                />

                <div className="flex">
                    <div className="flex_el red">
                        <TextBlock
                            text={`Процесс отлучения щенков от грудного вскармливания начинается тогда, когда у них появляется интерес к еде матери, и они пытаются есть и пить из её миски. Чтобы получать необходимые для роста питательные вещества в достаточном количестве, щенки нуждаются <b>в большом количестве приемов пищи в течение дня.</b> Собаки разных размерных групп отличаются разными темпами роста и достигают взрослого состояния в разном возрасте. Это также необходимо учитывать при выборе рациона для щенка.`}
                        />
                    </div>
                    <div className="flex_el ">
                        <img src={img1} alt="" className="img"/>
                    </div>
                </div>
                <TextBlock
                    text={`Питание взрослой собаки должно быть <b>сбалансированным</b> и поддерживать ее состояние здоровья. Пожилым собакам нужен особый уход и питание с учетом особенностей их возраста.`}
                />
            </div>
        )
    }
}
