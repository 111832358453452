import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson4/step5-1.png';

export default class step5 extends React.Component {

    render() {
        return (
            <div className="step5">
                <TextBlock
                    text={`Жиры и жирные кислоты отвечают за ряд важнейших процессов в организме.`}
                />
                <img className="img" src={img1} alt=""/>
            </div>
        )
    }
}
